/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TableComponent from "../../../../SharedModules/Table/Table";
import TextField from "@material-ui/core/TextField";
import numeral from "numeral";

export default function LienLevyhistoryTable(props) {
  const headCells = [
    {
      id: "tcn",
      numeric: false,
      disablePadding: true,
      label: "FCN/TCN",
      isDate: false,
      width: 120,
      fontSize: 13,
      isRedirectable: true
    },
    {
      id: "lineItem",
      numeric: false,
      disablePadding: false,
      label: "Line Item",
      enableHyperLink: false,
      isDate: false,
      width: 120,
      fontSize: 13
    },
    {
      id: "reasonCode",
      numeric: false,
      disablePadding: false,
      label: "Reason Code",
      enableHyperLink: false,
      isDate: false,
      width: 120,
      fontSize: 13,
      isTooltip: true,
      isTitle: 'resonCodeLongDesc'

    },
    {
      id: "appliedDate",
      numeric: false,
      disablePadding: false,
      label: "Applied Date",
      enableHyperLink: false,
      isDate: true,
      width: 120,
      fontSize: 13
    },
    {
      id: "appliedAmount",
      numeric: false,
      disablePadding: false,
      label: "Applied Amount",
      enableHyperLink: false,
      isDate: false,
      isBalance: true,
      width: 120,
      fontSize: 13
    }
  ];

  const formatSearchCriteria = (row) => {
    return (row.tcn);
  };
  let history = props.history;
  if(history && typeof history === 'object' && Object.keys(history).length){
    history.activityType = 'Payout';
    history = [history];
  }else{
    history = [];
  }
  
  return (
    <div>
      <div className="tab-holder mb-3 mt-2">
        <h6 className="sub-header" style={ { fontWeight: "bold" } }>
          Overpayment Activity
        </h6>
        <TableComponent
          print={ props.print }
          headCells={ headCells }
          tableData={ props.values ? props.values : [] }
          isSearch={ true }
        />
      </div>
      <div className="tab-holder mb-3 mt-2">
        <h6 className="sub-header" style={ { fontWeight: "bold" } }>
          History
        </h6>
        <TableComponent
          print={ props.print }
          headCells={ headCells }
          tableData={ history }
          isSearch={ true }
        />
      </div>
    </div>
  );
}
