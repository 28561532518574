/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import FinancialReceiptSearchTable from "./FinancialReceiptSearchTable";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import * as FinancialReceiptConstants from "./FinancialReceiptConstants";
import {
  getDropdownDataAction,
  financialReceiptSearchAction,
  getPayeeIdTypeCodeAction,
  resetFinancialReceiptSearch
} from "../Store/Actions/FinancialReceipt/financialReceiptActions";
import {
  validateDateMinimumValue,
  compareTwoDatesGreaterThanOrEqual,
  getDateInMMDDYYYYFormat
} from "../../../SharedModules/DateUtilities/DateUtilities";
import TabPanel from "../../../SharedModules/TabPanel/TabPanel";
import dropdownCriteria from "./FinancialReceiptSearch.json";
import * as AppConstants from "../../../SharedModules/AppConstants";
import Footer from "../../../SharedModules/Layout/footer";
import {
  removeLeadingTrailingSpaces
} from "../../../SharedModules/CommonUtilities/commonUtilities";
import moment from "moment";
import Radio from "@material-ui/core/Radio";

import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from "../../../SharedModules/Dropdowns/NativeDropDown";
import { TRANSACTION_NUMBER_FORMAT } from "../../../SharedModules/Errors/ErrorMsgConstant";

function FinancialReceiptSearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  let errorMessagesArray = [];
  let dropdown = [];
  let payeeTypeIDDropdown = [];

  const [showTable, setShowTable] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [positiveBalalnceAmount, setpositiveBalalnceAmount] =
    React.useState(false);
  const [withOutPayerAssigned, setwithOutPayerAssigned] = React.useState(false);
  const defaultValues = {
    fcn: "",
    lob: DEFAULT_DD_VALUE,
    payerType: DEFAULT_DD_VALUE,
    payerIDTypeCode: DEFAULT_DD_VALUE,
    payerID: "",
    payerName: "",
    pendReason: DEFAULT_DD_VALUE,
    beginDate: null,
    endDate: new Date("9999-12-31T13:00:00.000+0000"),
    payerNameStartsWithOrContains: '',
    pendBeginDate: null,
    pendEndDate: new Date("9999-12-31T13:00:00.000+0000"),
    positiveBalanceAmount: false,
    withOutPayerAssigned: false,
    lockBoxNumber: "",
    batchNumber: "",
    checkNumber: "",
    intTrackingNumber: "",
    activityType: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE,
    atrNumber: ""
  };
  const [values, setValues] = React.useState(defaultValues);
  const [isFCNSearch, setIsFCNSearch] = React.useState(false);
  const [isPayeeSearch, setIsPayeeSearch] = React.useState(false);
  const [
    {
      showPayeeError,
      showPayeeTypeError,
      showPayeeTypeCodeError,
      showPayeeNameError,
      showestablishedBeginDateError,
      showestablishedEndDateError,
      showpendBeginDateError,
      showpendEndDateError,
      showATRErr
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      payeeErrorText,
      payeeTypeErrorText,
      payeeTypeCodeErrorText,
      establishedbeginDateerrorText,
      establishedendDateerrorText,
      pendbeginDateerrorText,
      pendendDateerrorText
    },
    setShowErrorText
  ] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [redirect, setRedirect] = React.useState(0);
  const [PayeeTypeReference, setPayeeTypeReference] = React.useState([]);
  const [payeeTypeIDDropdownReference, setpayeeTypeIDDropdownReference] =
    React.useState([]);
  const [pendReasonDropdownReference, setpendReasonDropdownReference] =
    React.useState([]);
  const [
    activityTypeCodeDropdownReference,
    setactivityTypeCodeDropdownReference
  ] = React.useState([]);
  const [
    financialReasonCodeDropdownReference,
    setfinancialReasonCodeDropdownReference
  ] = React.useState([]);
  const [payeeTableData, setpayeeTableData] = React.useState([]);
  const [fcnFieldErr, setFcnFieldErr] = React.useState({
    showfcnErr: false,
    showFcnErrMsg: ""
  });
  const dispatch = useDispatch();

  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const toPrintRef = useRef();

  const onReset = () => dispatch(resetFinancialReceiptSearch());

  dropdown = useSelector((state) => state.financialReceiptState.dropdownData);

  const onChangePayeeType = (payeeType) =>
    dispatch(getPayeeIdTypeCodeAction(payeeType));
  payeeTypeIDDropdown = useSelector(
    (state) => state.financialReceiptState.payeeIDTypeCodeDetails
  );

  const onSearchView = (searchviewvalues) =>
    dispatch(
      financialReceiptSearchAction(
        removeLeadingTrailingSpaces(searchviewvalues)
      )
    );
  const payloadViewData = useSelector(
    (state) => state.financialReceiptState.financialReceiptSearch
  );
  const [establishedBeginDate, setEstablishedBeginDate] = React.useState(null);
  const [establishedEndDate, setEstablishedEndDate] =
    React.useState("12/31/9999");
  const [pendBeginDate, setPendBeginDate] = React.useState(null);
  const [pendEndDate, setPendEndDate] = React.useState("12/31/9999");

  const handleEstablishedBDate = (date) => {
    setEstablishedBeginDate(date);
  };
  const handleEstablishedEDate = (date) => {
    setEstablishedEndDate(date);
  };
  const handlePendBDate = (date) => {
    setPendBeginDate(date);
  };
  const handlePendEDate = (date) => {
    setPendEndDate(date);
  };

  useEffect(() => {
onDropdowns([Dropdowns.F_RSN_CD, Dropdowns.PAYEE_TYPE, Dropdowns.LOB_CODE]);
  }, []);
  useEffect(() => {
    if (!props.location.tableRender) {
      financialFCNSearchReset();
      financialPayeeSearchReset();
    }
  }, [value]);

  // Check search results and navigate to edit screen
  useEffect(() => {
    if (payloadViewData && payloadViewData.systemFailure) {
      errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      payloadViewData &&
      !payloadViewData.systemFailure &&
      payloadViewData.respcode !== "01"
    ) {
      if (payloadViewData.respcode === "03") {
        errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      } else {
        errorMessagesArray.push(payloadViewData.respdesc);
      }
      seterrorMessages(errorMessagesArray);
    }
    if (
      payloadViewData &&
      !payloadViewData.systemFailure &&
      payloadViewData.respcode === "01" &&
      payloadViewData.enterpriseSearchresultVO &&
      payloadViewData.enterpriseSearchresultVO.searchResults &&
      payloadViewData.enterpriseSearchresultVO.searchResults.length === 0
    ) {
      errorMessagesArray.push(FinancialReceiptConstants.NO_RECORDS);
      seterrorMessages(errorMessagesArray);
    }

    // Updating search result with description for Reason Code and Payee Type Codes instead of displaying codes
    if (
      payloadViewData &&
      !payloadViewData.systemFailure &&
      payloadViewData.respcode === "01" &&
      payloadViewData.enterpriseSearchresultVO &&
      payloadViewData.enterpriseSearchresultVO.searchResults &&
      payloadViewData.enterpriseSearchresultVO.searchResults.length === 1
    ) {
      if (redirect === 1) {
        props.history.push({
          pathname: "/FinancialReceiptUpdate",
          state: {
            payloadViewData:
              payloadViewData.enterpriseSearchresultVO.searchResults,
            editFlag: true,
            fromSearch: true,
            values: {
              ...values,
              establishedEndDate,
              pendBeginDate,
              pendEndDate,
              establishedBeginDate,
              positiveBalalnceAmount
            },
            payeeTypeIDDropdown: payeeTypeIDDropdown
          }
        });
      }
    }
    if (
      payloadViewData &&
      !payloadViewData.systemFailure &&
      payloadViewData.respcode === "01" &&
      payloadViewData.enterpriseSearchresultVO &&
      payloadViewData.enterpriseSearchresultVO.searchResults &&
      payloadViewData.enterpriseSearchresultVO.searchResults.length >= 1
    ) {
      payloadViewData.enterpriseSearchresultVO.searchResults.map(
        (payLoadData) => {
          let reasonCodeDescription = [];
          reasonCodeDescription = financialReasonCodeDropdownReference.filter(
            (reasonCodeData) => {
              if (reasonCodeData.code === payLoadData.reasonCode) {
                return (
                  reasonCodeData.description, reasonCodeData.longDescription
                );
              }
            }
          );
          payLoadData.resonCodeLongDesc =
            reasonCodeDescription && reasonCodeDescription.length === 1
              ? reasonCodeDescription[0].longDescription
              : "";
        }
      );
      setShowTable(true);
      const tempTableData = JSON.parse(
        JSON.stringify(payloadViewData.enterpriseSearchresultVO.searchResults)
      );
      tempTableData.map((value) => {
        value.transDate = moment(new Date(value.transDate)).format("L");
      });
      setpayeeTableData(tempTableData);
    }
  }, [payloadViewData]);

  const ValuesFromFinReceipt =
    props.history &&
    props.history?.location?.state?.values;
  const payeeTypeIDDropdownRender = props.history?.location?.state?.payeeTypeIDDropdown || props.history?.state?.payeeTypeIDDropdown;
  useEffect(() => {
    if (props.location.tableRender && !isPayeeSearch && !isFCNSearch) {
      const getTabValue = sessionStorage.getItem("tabValue");
      setValue(Number(getTabValue));
      if (ValuesFromFinReceipt !== undefined) {
        setShowError(false);
        setShowErrorText(false);
        errorMessagesArray = [];
        seterrorMessages([]);
        setShowTable(true);

        if (getTabValue === "0") {
          setValues({ ...ValuesFromFinReceipt, fcn: ValuesFromFinReceipt.fcn });
          onSearchView({
            fcn: ValuesFromFinReceipt.fcn,
            fcnSearch: true
          });
        } else {
          const searchCriteria = {
            lob: [AppConstants.DEFAULT_LOB_VALUE],
            payerType: ValuesFromFinReceipt.payerType !== undefined && ValuesFromFinReceipt.payerType !== '' &&
            ValuesFromFinReceipt.payerType !== DEFAULT_DD_VALUE ? ValuesFromFinReceipt.payerType : null,
            payerIDTypeCode: ValuesFromFinReceipt.payerIDTypeCode !== '' &&
            ValuesFromFinReceipt.payerIDTypeCode !== undefined && ValuesFromFinReceipt.payerIDTypeCode !== DEFAULT_DD_VALUE ? ValuesFromFinReceipt.payerIDTypeCode : null,
            payerID: ValuesFromFinReceipt.payerID !== '' && ValuesFromFinReceipt.payerID !== undefined ? ValuesFromFinReceipt.payerID : null,
            payerName: ValuesFromFinReceipt.payerName !== undefined && ValuesFromFinReceipt.payerName !== '' ? ValuesFromFinReceipt.payerName : null,
            pendReason: ValuesFromFinReceipt.pendReason !== '' && ValuesFromFinReceipt.pendReason !== DEFAULT_DD_VALUE ? ValuesFromFinReceipt.pendReason : null,
            beginDate: getDateInMMDDYYYYFormat(ValuesFromFinReceipt?.establishedBeginDate) || getDateInMMDDYYYYFormat(ValuesFromFinReceipt?.beginDate) || null,
            endDate: getDateInMMDDYYYYFormat(ValuesFromFinReceipt?.establishedEndDate) || getDateInMMDDYYYYFormat(ValuesFromFinReceipt?.endDate) || null,
            payerNameStartsWithOrContains: ValuesFromFinReceipt.payerNameStartsWithOrContains === 'StartsWith' || ValuesFromFinReceipt.payerNameStartsWithOrContains === 0 ? 0 : ValuesFromFinReceipt.payerNameStartsWithOrContains === 'Contains' || ValuesFromFinReceipt.payerNameStartsWithOrContains === 1 ? 1 : null,
            pendBeginDate: ValuesFromFinReceipt.pendBeginDate,
            pendEndDate: ValuesFromFinReceipt.pendEndDate,
            positiveBalalnceAmount: ValuesFromFinReceipt.positiveBalalnceAmount !== '' && ValuesFromFinReceipt.positiveBalalnceAmount !== undefined ? ValuesFromFinReceipt.positiveBalalnceAmount : false,
            withOutPayerAssigned: ValuesFromFinReceipt.withOutPayerAssigned !== '' && ValuesFromFinReceipt.withOutPayerAssigned !== undefined ? ValuesFromFinReceipt.withOutPayerAssigned : false,
            lockBoxNumber: ValuesFromFinReceipt.lockBoxNumber !== '' && ValuesFromFinReceipt.lockBoxNumber !== undefined ? ValuesFromFinReceipt.lockBoxNumber : null,
            batchNumber: ValuesFromFinReceipt.batchNumber !== '' && ValuesFromFinReceipt.batchNumber !== undefined ? ValuesFromFinReceipt.batchNumber : null,
            checkNumber: ValuesFromFinReceipt.checkNumber !== '' && ValuesFromFinReceipt.checkNumber !== undefined ? ValuesFromFinReceipt.checkNumber : null,
            activityType: ValuesFromFinReceipt.activityType !== '' && ValuesFromFinReceipt.activityType !== DEFAULT_DD_VALUE && ValuesFromFinReceipt.activityType !== undefined
             ? ValuesFromFinReceipt.activityType : 'RC',
            intTrackingNumber: ValuesFromFinReceipt.intTrackingNumber !== undefined && ValuesFromFinReceipt.intTrackingNumber !== '' ? ValuesFromFinReceipt.intTrackingNumber : null,
            reasonCode: ValuesFromFinReceipt.reasonCode !== undefined && ValuesFromFinReceipt.reasonCode !== DEFAULT_DD_VALUE && ValuesFromFinReceipt.reasonCode !== ''
             ? ValuesFromFinReceipt.reasonCode : null,
            payeeSearch: true,
            atrNumber: ValuesFromFinReceipt.atrNumber !== '' && ValuesFromFinReceipt.atrNumber !== undefined ? ValuesFromFinReceipt.atrNumber : null
          };
          setTimeout(() => {
            onSearchView(searchCriteria);
          }, 210);
          setEstablishedEndDate(ValuesFromFinReceipt.establishedEndDate || ValuesFromFinReceipt.endDate || null);
          setPendBeginDate(ValuesFromFinReceipt.pendBeginDate);
          setPendEndDate(ValuesFromFinReceipt.pendEndDate);
          setEstablishedBeginDate(ValuesFromFinReceipt.establishedBeginDate || ValuesFromFinReceipt.beginDate || null);
          setpositiveBalalnceAmount(
            ValuesFromFinReceipt.positiveBalalnceAmount
          );

          const cancelValues = {
            fcn: ValuesFromFinReceipt.fcn,
            lob: ValuesFromFinReceipt.lob || DEFAULT_DD_VALUE,
            payerType: ValuesFromFinReceipt.payerType || ValuesFromFinReceipt.entityType || DEFAULT_DD_VALUE,
            payerIDTypeCode: ValuesFromFinReceipt.payerIDTypeCode || DEFAULT_DD_VALUE,
            payerID: ValuesFromFinReceipt.payerID || "",
            payerName: ValuesFromFinReceipt.payerName || "",
            pendReason: ValuesFromFinReceipt.pendReason || DEFAULT_DD_VALUE,
            establishedBeginDate: ValuesFromFinReceipt.establishedBeginDate || ValuesFromFinReceipt.beginDate || null,
            establishedEndDate: ValuesFromFinReceipt.establishedEndDate || ValuesFromFinReceipt.endDate || new Date("9999-12-31T13:00:00.000+0000"),
            payerNameStartsWithOrContains: ValuesFromFinReceipt.payerNameStartsWithOrContains || '',
            pendBeginDate: ValuesFromFinReceipt.pendBeginDate || null,
            pendEndDate: ValuesFromFinReceipt.pendEndDate || new Date("9999-12-31T13:00:00.000+0000"),
            positiveBalanceAmount: ValuesFromFinReceipt.positiveBalalnceAmount || false,
            withOutPayerAssigned: ValuesFromFinReceipt.withOutPayerAssigned || false,
            lockBoxNumber: ValuesFromFinReceipt.lockBoxNumber || "",
            batchNumber: ValuesFromFinReceipt.batchNumber || "",
            checkNumber: ValuesFromFinReceipt.checkNumber || "",
            intTrackingNumber: ValuesFromFinReceipt.intTrackingNumber || "",
            activityType: ValuesFromFinReceipt.activityType || DEFAULT_DD_VALUE,
            reasonCode: ValuesFromFinReceipt.reasonCode || DEFAULT_DD_VALUE
          };
          setValues(cancelValues);
          setwithOutPayerAssigned(ValuesFromFinReceipt.withOutPayerAssigned);
        }
      }
      if (payeeTypeIDDropdownRender !== undefined) {
        setpayeeTypeIDDropdownReference(payeeTypeIDDropdownRender);
      }
    }
  }, [props.location.tableRender]);

  useEffect(() => {
    onReset();
    dispatch(getDropdownDataAction(dropdownCriteria));
  }, []);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj["General#6002"]) {
        if (dropdown.listObj["General#6002"].length === 1) {
          tempData.payerType = dropdown.listObj["General#6002"][0].code;
        }
        setPayeeTypeReference(dropdown.listObj["General#6002"]);
      }
      if (dropdown.listObj["Financial#3012"]) {
        if (dropdown.listObj["Financial#3012"].length === 1) {
          tempData.activityType = dropdown.listObj["Financial#3012"][0].code;
        }
        setactivityTypeCodeDropdownReference(
          dropdown.listObj["Financial#3012"]
        );
      }
      if (dropdown.listObj["Financial#3007"]) {
        if (dropdown.listObj["Financial#3007"].length === 1) {
          tempData.reasonCode =
            dropdown.listObj["Financial#3007"][0].code;
        }
        setfinancialReasonCodeDropdownReference(
          dropdown.listObj["Financial#3007"]
        );
      }
      if (dropdown.listObj["Financial#1003"]) {
        if (dropdown.listObj["Financial#1003"].length === 1) {
          tempData.pendReason = dropdown.listObj["Financial#1003"][0].code;
        }
        setpendReasonDropdownReference(dropdown.listObj["Financial#1003"]);
      }
      setValues(tempData);
    }
  }, [dropdown]);

  useEffect(() => {
    if (!props.location.tableRender) {
      if (values.payerType) {
        setValues({
          ...values,
          payeeIdTypeCode: values.payerIDTypeCode || DEFAULT_DD_VALUE
        });
        setpayeeTypeIDDropdownReference([]);
        if (values.payerType !== DEFAULT_DD_VALUE) {
          onChangePayeeType(values.payerType);
        }
      }
    } else if (ValuesFromFinReceipt !== undefined) {
      setValues({
        ...ValuesFromFinReceipt,
        payeeIdTypeCode:
          ValuesFromFinReceipt.payeeIdTypeCode || DEFAULT_DD_VALUE
      });
      setpayeeTypeIDDropdownReference([]);
      if (ValuesFromFinReceipt.payerType !== DEFAULT_DD_VALUE) {
        onChangePayeeType(ValuesFromFinReceipt.payerType);
      }
    }
  }, [values.payerType]);

  useEffect(() => {
    if (payeeTypeIDDropdown && payeeTypeIDDropdown.length > 0) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (payeeTypeIDDropdown.length === 1) {
        tempData.payerIDTypeCode = payeeTypeIDDropdown[0];
      }
      setpayeeTypeIDDropdownReference(payeeTypeIDDropdown);
      props.history.state = {
        PayeeTypeIDDropdown: payeeTypeIDDropdown
      };
      props.history.state = {
        values: values
      };
      setValues(tempData);
    }
  }, [payeeTypeIDDropdown]);

  const handleChangeTabs = (event, newValue) => {
    sessionStorage.clear();
    props.location.tableRender = false;
    setValue(newValue);
  };

  const handleChange = (name) => (event) => {
    if (name === "positiveBalalnceAmount") {
      setpositiveBalalnceAmount(!positiveBalalnceAmount);
    }
    if (name === "withOutPayerAssigned") {
      setwithOutPayerAssigned(!withOutPayerAssigned);
    }
    setValues({ ...values, [name]: event.target.value });
  };
  const financialFCNSearch = () => {
    setIsPayeeSearch(false);
    sessionStorage.setItem("tabValue", 0);
    setShowTable(false);
    setShowError(false);
    setShowErrorText(false);
    errorMessagesArray = [];
    seterrorMessages([]);
    var showFCNError = false;
    var fcnErrorText = "";
    if (values.fcn === "" || values.fcn === null || values.fcn === undefined) {
      showFCNError = true;
      fcnErrorText = FinancialReceiptConstants.FCN_REQUIRED;
      errorMessagesArray.push(FinancialReceiptConstants.FCN_REQUIRED);
      seterrorMessages(errorMessagesArray);
    } else if (errorMessagesArray.length === 0) {
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
      setIsFCNSearch(true);
      setValues({ ...values });
      onSearchView({
        fcn: values.fcn,
        fcnSearch: true
      });
      if (!props.history?.state?.values) {
        props.history.state = {};
      }
      props.history.state.values = values;
     props.history.location.state = {
      values: values
     };
}
    // set fcn field level error
    setFcnFieldErr({
      showfcnErr: showFCNError,
      showFcnErrMsg: fcnErrorText
    });
  };

  const financialFCNSearchReset = () => {
    setShowTable(false);
    props.location.tableRender = false;
    setpositiveBalalnceAmount(false);
    setwithOutPayerAssigned(false);
    seterrorMessages([]);
    setValues(defaultValues);
    setEstablishedEndDate("12/31/9999");
    setPendEndDate("12/31/9999");
    setFcnFieldErr({
      showfcnErr: false,
      showFcnErrMsg: ""
    });
  };

  const settingDefault = () => {
    if (establishedBeginDate !== null) {
      if (establishedEndDate === null) {
        setEstablishedEndDate("12/31/9999");
      }
    }
    if (pendBeginDate !== null) {
      if (pendEndDate === null) {
        setPendEndDate("12/31/9999");
      }
    }
  };

  // On search Check Validations
  const financialPayeeSearch = () => {
    setIsFCNSearch(false);
    sessionStorage.setItem("tabValue", 1);
    setShowTable(false);
    setShowError(false);
    setShowErrorText(false);
    setpayeeTableData([]);
    seterrorMessages([]);
    errorMessagesArray = [];
    var showPayeeError;
    var showPayeeTypeError;
    var showPayeeTypeCodeError;
    var showPayeeNameError;
    let showATRErr;
    var showestablishedBeginDateError;
    var showestablishedEndDateError;
    var showpendBeginDateError;
    var showpendEndDateError = false;
    var payeeErrorText;
    var payeeTypeErrorText;
    var payeeTypeCodeErrorText;
    var payeeNameErrorText;
    var establishedbeginDateerrorText;
    var establishedendDateerrorText;
    var pendbeginDateerrorText;
    var pendendDateerrorText = "";
    if (
      (values.payerID && values.payerID.trim() !== "") ||
      (values.payerType && values.payerType !== "" && values.payerType !== DEFAULT_DD_VALUE) ||
      (values.payerIDTypeCode && values.payerIDTypeCode !== "" && values.payerIDTypeCode !== DEFAULT_DD_VALUE)
    ) {
      if (values.payerType === "" || values.payerType === DEFAULT_DD_VALUE) {
        showPayeeTypeError = true;
        payeeTypeErrorText = FinancialReceiptConstants.PAYEETYPE_REQUIRED;
        errorMessagesArray.push(FinancialReceiptConstants.PAYEETYPE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
      if (
        values.payerIDTypeCode === "" ||
        values.payerIDTypeCode === DEFAULT_DD_VALUE
      ) {
        showPayeeTypeCodeError = true;
        payeeTypeCodeErrorText =
          FinancialReceiptConstants.PAYEEIDTYPECODE_REQUIRED;
        errorMessagesArray.push(
          FinancialReceiptConstants.PAYEEIDTYPECODE_REQUIRED
        );
        seterrorMessages(errorMessagesArray);
      }
      if (!values.payerID || (values.payerID && values.payerID.trim() === "")) {
        showPayeeError = true;
        payeeErrorText = FinancialReceiptConstants.PAYEEID_REQUIRED;
        errorMessagesArray.push(FinancialReceiptConstants.PAYEEID_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
    }
    if (values.payerNameStartsWithOrContains) {
      if (values.payerName.length < 2) {
        showPayeeNameError = true;
        payeeNameErrorText = FinancialReceiptConstants.PAYEENAME_ERROR;
        errorMessagesArray.push(FinancialReceiptConstants.PAYEENAME_ERROR);
        seterrorMessages(errorMessagesArray);
      }
    }
    if (values.atrNumber) {
      const regex = /[^A-Za-z0-9]/;
       if (regex.test(values.atrNumber)) {
        showATRErr = true;
        errorMessagesArray.push(process.env.REACT_APP_TRANSACTION_NUMBER_LABEL + ' ' + TRANSACTION_NUMBER_FORMAT);
         seterrorMessages(errorMessagesArray);
       } else {
        showATRErr = false;
      }
    }
    // ESTABLISHED DATE VALIDATIONS
    if (establishedBeginDate) {
      if (new Date(establishedBeginDate).toString() !== "Invalid Date") {
        if (validateDateMinimumValue(establishedBeginDate)) {
          showestablishedBeginDateError = true;
          establishedbeginDateerrorText = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          seterrorMessages(errorMessagesArray);
        }
      } else {
        showestablishedBeginDateError = true;
        establishedbeginDateerrorText =
          FinancialReceiptConstants.EST_BEGINDATE_FORMAT_INVALID;
        errorMessagesArray.push(
          FinancialReceiptConstants.EST_BEGINDATE_FORMAT_INVALID
        );
        seterrorMessages(errorMessagesArray);
      }
    }
    if (establishedEndDate) {
      if (new Date(establishedEndDate).toString() !== "Invalid Date") {
        if (validateDateMinimumValue(establishedEndDate)) {
          showestablishedEndDateError = true;
          establishedendDateerrorText = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          seterrorMessages(errorMessagesArray);
        }
      } else {
        showestablishedEndDateError = true;
        establishedendDateerrorText =
          FinancialReceiptConstants.EST_ENDDATE_FORMAT_INVALID;
        errorMessagesArray.push(
          FinancialReceiptConstants.EST_ENDDATE_FORMAT_INVALID
        );
        seterrorMessages(errorMessagesArray);
      }
    }

    if (
      establishedBeginDate &&
      establishedEndDate &&
      !establishedbeginDateerrorText &&
      !establishedendDateerrorText &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(establishedEndDate),
        new Date(establishedBeginDate)
      )
    ) {
      showestablishedEndDateError = true;
      establishedendDateerrorText =
        FinancialReceiptConstants.ESTABLISHED_BEGIN_DATE;
      errorMessagesArray.push(FinancialReceiptConstants.ESTABLISHED_BEGIN_DATE);
      seterrorMessages(errorMessagesArray);
    }

    // PEND DATE VALIDATIONS
    if (pendBeginDate) {
      if (new Date(pendBeginDate).toString() !== "Invalid Date") {
        if (validateDateMinimumValue(pendBeginDate)) {
          showpendBeginDateError = true;
          pendbeginDateerrorText = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          seterrorMessages(errorMessagesArray);
        }
      } else {
        showpendBeginDateError = true;
        pendbeginDateerrorText =
          FinancialReceiptConstants.PEND_BEGINDATE_FORMAT_INVALID;
        errorMessagesArray.push(
          FinancialReceiptConstants.PEND_BEGINDATE_FORMAT_INVALID
        );
        seterrorMessages(errorMessagesArray);
      }
    }
    if (pendEndDate) {
      if (new Date(pendEndDate).toString() !== "Invalid Date") {
        if (validateDateMinimumValue(pendEndDate)) {
          showpendEndDateError = true;
          pendendDateerrorText = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          seterrorMessages(errorMessagesArray);
        }
      } else {
        showpendEndDateError = true;
        pendendDateerrorText =
          FinancialReceiptConstants.PEND_ENDDATE_FORMAT_INVALID;
        errorMessagesArray.push(
          FinancialReceiptConstants.PEND_ENDDATE_FORMAT_INVALID
        );
        seterrorMessages(errorMessagesArray);
      }
    }
    if (
      pendBeginDate &&
      pendEndDate &&
      !pendbeginDateerrorText &&
      !pendendDateerrorText &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(pendEndDate),
        new Date(pendBeginDate)
      )
    ) {
      showpendEndDateError = true;
      pendendDateerrorText = FinancialReceiptConstants.BEGIN_DATE;
      errorMessagesArray.push(FinancialReceiptConstants.BEGIN_DATE);
      seterrorMessages(errorMessagesArray);
    }
    if (errorMessagesArray.length === 0) {
      setIsPayeeSearch(true);

      setValues({ ...values, activityType: values.activityType !== '' && values.activityType !== DEFAULT_DD_VALUE ? values.activityType : 'RC' });
      const searchCriteria = {
        lob: [AppConstants.DEFAULT_LOB_VALUE],
        payerType: values.payerType !== '' && values.payerType !== DEFAULT_DD_VALUE ? values.payerType : null,
        payerIDTypeCode: values.payerIDTypeCode !== '' && values.payerIDTypeCode !== DEFAULT_DD_VALUE ? values.payerIDTypeCode : null,
        payerID: values.payerID !== '' ? values.payerID : null,
        payerName: values.payerName !== '' ? values.payerName : null,
        pendReason: values.pendReason !== '' && values.pendReason !== DEFAULT_DD_VALUE ? values.pendReason : null,
        beginDate: establishedBeginDate !== null ? getDateInMMDDYYYYFormat(establishedBeginDate) : null,
        endDate: establishedEndDate !== null ? getDateInMMDDYYYYFormat(establishedEndDate) : establishedBeginDate !== null ? '12/31/9999' : null,
        payerNameStartsWithOrContains: values.payerNameStartsWithOrContains === 'StartsWith' || values.payerNameStartsWithOrContains === 0
         ? 0 : values.payerNameStartsWithOrContains === 'Contains' || values.payerNameStartsWithOrContains === 1 ? 1 : null,
        pendBeginDate: pendBeginDate !== null ? getDateInMMDDYYYYFormat(pendBeginDate) : null,
        pendEndDate: pendEndDate !== null ? getDateInMMDDYYYYFormat(pendEndDate) : values.pendBeginDate !== null ? '12/31/9999' : null,
        positiveBalalnceAmount: positiveBalalnceAmount !== '' ? positiveBalalnceAmount : false,
        withOutPayerAssigned: withOutPayerAssigned !== '' ? withOutPayerAssigned : false,
        lockBoxNumber: values.lockBoxNumber !== '' ? values.lockBoxNumber : null,
        batchNumber: values.batchNumber !== '' ? values.batchNumber : null,
        checkNumber: values.checkNumber !== '' ? values.checkNumber : null,
        activityType: values.activityType !== '' && values.activityType !== DEFAULT_DD_VALUE ? values.activityType : 'RC',
        intTrackingNumber: values.intTrackingNumber !== '' ? values.intTrackingNumber : null,
        reasonCode: values.reasonCode !== '' && values.reasonCode !== DEFAULT_DD_VALUE ? values.reasonCode : null,
        payeeSearch: true,
        atrNumber: values.atrNumber !== "" ? values?.atrNumber?.toUpperCase() : null
      };
      onSearchView(searchCriteria);
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = searchCriteria;
     props.history.location.state = {
      values: searchCriteria
     };
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }
    setShowError({
      showATRErr: showATRErr,
      showPayeeError: showPayeeError,
      showPayeeTypeError: showPayeeTypeError,
      showPayeeTypeCodeError: showPayeeTypeCodeError,
      showPayeeNameError: showPayeeNameError,
      showestablishedBeginDateError: showestablishedBeginDateError,
      showestablishedEndDateError: showestablishedEndDateError,
      showpendBeginDateError: showpendBeginDateError,
      showpendEndDateError: showpendEndDateError
    });
    setShowErrorText({
      payeeErrorText: payeeErrorText,
      payeeTypeErrorText: payeeTypeErrorText,
      payeeTypeCodeErrorText: payeeTypeCodeErrorText,
      payeeNameErrorText: payeeNameErrorText,
      establishedbeginDateerrorText: establishedbeginDateerrorText,
      establishedendDateerrorText: establishedendDateerrorText,
      pendbeginDateerrorText: pendbeginDateerrorText,
      pendendDateerrorText: pendendDateerrorText
    });
  };
  const financialPayeeSearchReset = () => {
    props.location.tableRender = false;
    setShowTable(false);
    setpayeeTableData([]);
    setpositiveBalalnceAmount(false);
    setwithOutPayerAssigned(false);
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText("");
    setValues(defaultValues);
    setEstablishedEndDate("12/31/9999");
    setPendEndDate("12/31/9999");
    setEstablishedBeginDate(null);
    setPendBeginDate(null);
  };
  const onRowClick = (row) => () => {
    setValues({ ...values, fcn: row.fcn });
    onSearchView({
      fcn: row.fcn,
      fcnSearch: true
    });
    props.history.location.state.values = values;

    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };

  const addFinancailReceipt = () => {
    sessionStorage.clear();
    props.history.push({
      pathname: "/FinancialReceiptAdd"
    });
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />

        <div className="tab-header">
          <h1 className="tab-heading float-left">Search Receipt</h1>
          <div className="float-right mt-2">
            <Button
              title="Add Financial Receipt"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addFinancailReceipt()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body padTop-10">
          <div className="tab-panelbody">
            <div className="tab-holder my-3">
              {/* form goes here */}
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="FCN" />
                  <Tab label="Payee" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <div className="form-wrapper">
                  <div className="mui-custom-form input-md">
                    <TextField
                      id="FCN"
                      label="FCN"
                      required
                      type="number"
                      inputProps={{ maxLength: 14 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 14);
                      }}
                      value={values.fcn}
                      name="fcn"
                      onChange={handleChange("fcn")}
                      helperText={
                        fcnFieldErr.showfcnErr
                          ? fcnFieldErr.showFcnErrMsg
                          : null
                      }
                      error={
                        fcnFieldErr.showfcnErr
                          ? fcnFieldErr.showFcnErrMsg
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="float-right mr-3 mb-2">
                  <Button
                    className="btn-tab-pannel"
                    onClick={() => {
                      financialFCNSearch();
                    }}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                    Search
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="bt-tabreset btn-transparent ml-1"
                    onClick={() => {
                      financialFCNSearchReset();
                    }}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i>
                    Reset
                  </Button>
                </div>
                <div className="clearfix"></div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <form autoComplete="off">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <div className="form-wrapper">
                          <NativeDropDown
                            id="standard-select-payeeType"
                            className="override-width-22 override-m-1"
                            value={values.payerType || DEFAULT_DD_VALUE}
                            label="Payee Type"
                            name="payerType"
                            onChange={handleChange("payerType")}
                            inputProps={{ maxLength: 2 }}
                            errTxt={
                              showPayeeTypeError ? payeeTypeErrorText : null
                            }
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            dropdownList={PayeeTypeReference.map((option) => (
                              <option key={option.code} value={option.code}>
                                {option.description}
                              </option>
                            ))}
                          />
                          <NativeDropDown
                            id="standard-select-payeeIDTypeCode"
                            className="override-width-22 override-m-1"
                            value={values.payerIDTypeCode || DEFAULT_DD_VALUE }
                            disabled={values.payerType === DEFAULT_DD_VALUE}
                            label="Payee ID Type Code"
                            name="payerIDTypeCode"
                            onChange={handleChange("payerIDTypeCode")}
                            inputProps={{ maxLength: 3 }}
                            errTxt={
                              showPayeeTypeCodeError
                                ? payeeTypeCodeErrorText
                                : null
                            }
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            dropdownList={payeeTypeIDDropdownReference
                              ? payeeTypeIDDropdownReference.map((option) => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))
                              : null}
                          />
                        <div className="mui-custom-form  override-width-22 override-m-1">
                          <TextField
                            id="standard-select-PayeeID"
                            fullWidth
                            value={values.payerID}
                            label="Payee ID"
                            name="payerID"
                            onChange={handleChange("payerID")}
                            inputProps={{ maxLength: 15 }}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            helperText={showPayeeError ? payeeErrorText : null}
                            error={showPayeeError ? payeeErrorText : null}
                          ></TextField>
                        </div>
                      </div>
                      <div className="form-wrapper">
                          <NativeDropDown
                            id="standard-select-ActivityType"
                            className="override-width-22 override-m-1"
                            value={values.activityType || DEFAULT_DD_VALUE}
                            label="Activity Type"
                            name="activityType"
                            inputProps={{ maxLength: 1 }}
                            onChange={handleChange("activityType")}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            dropdownList={activityTypeCodeDropdownReference
                              ? activityTypeCodeDropdownReference.map(
                                  (option) => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  )
                                )
                              : null}
                          />
                        <div className="mui-custom-form override-width-22 override-m-1">
                          <TextField
                            id="standard-select-PayeeName"
                            fullWidth
                            value={values.payerName}
                            label="Payee Name"
                            name="payerName"
                            helperText={
                              showPayeeNameError
                                ? FinancialReceiptConstants.PAYEENAME_ERROR
                                : null
                            }
                            error={
                              showPayeeNameError
                                ? FinancialReceiptConstants.PAYEENAME_ERROR
                                : null
                            }
                            onChange={handleChange("payerName")}
                            inputProps={{ maxLength: 60 }}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                          <div className="sub-radio">
                            <Radio
                              value="StartsWith"
                              id="businessNameStartId"
                              checked={
                                values.payerNameStartsWithOrContains ===
                                "StartsWith" || values.payerNameStartsWithOrContains === 0
                              }
                              inputProps={{ maxLength: 1 }}
                              onChange={handleChange(
                                "payerNameStartsWithOrContains"
                              )}
                            />
                            <label
                              className="text-black"
                              htmlFor="businessNameStartId"
                            >
                              Starts With
                            </label>
                            <Radio
                              value="Contains"
                              id="businessNameContainsId"
                              inputProps={{ maxLength: 1 }}
                              checked={
                                values.payerNameStartsWithOrContains ===
                                "Contains" || values.payerNameStartsWithOrContains === 1
                              }
                              onChange={handleChange(
                                "payerNameStartsWithOrContains"
                              )}
                              className="ml-2"
                            />
                            <label
                              htmlFor="businessNameContainsId"
                              className="text-black"
                            >
                              Contains
                            </label>
                          </div>
                        </div>
                        <div className="mui-custom-form with-date override-width-22 override-m-1">
                          <KeyboardDatePicker
                            maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                            id="begindate-picker-dialog"
                            label="Established Begin Date"
                            format="MM/dd/yyyy"
                            value={establishedBeginDate}
                            name="establishedBeginDate"
                            helperText={
                              showestablishedBeginDateError
                                ? establishedbeginDateerrorText
                                : null
                            }
                            error={
                              showestablishedBeginDateError
                                ? establishedbeginDateerrorText
                                : null
                            }
                            placeholder="mm/dd/yyyy"
                            InputLabelProps={{
                              shrink: true
                            }}
                            onBlur={settingDefault}
                            onChange={handleEstablishedBDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </div>
                        <div className="mui-custom-form with-date override-width-22 override-m-1">
                          <KeyboardDatePicker
                            id="enddate-picker-dialog"
                            label="Established End Date"
                            format="MM/dd/yyyy"
                            maxDate={new Date("9999-12-31T13:00:00.000+0000")}
                            value={establishedEndDate}
                            name="establishedEndDate"
                            InputLabelProps={{
                              shrink: true
                            }}
                            onblur={settingDefault}
                            helperText={
                              showestablishedEndDateError
                                ? establishedendDateerrorText
                                : null
                            }
                            error={
                              showestablishedEndDateError
                                ? establishedendDateerrorText
                                : null
                            }
                            placeholder="mm/dd/yyyy"
                            onChange={handleEstablishedEDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-wrapper">
                          <NativeDropDown
                            id="standard-select-FinancialReasonCode"
                            className="override-width-22 override-m-1"
                            value={values.reasonCode || DEFAULT_DD_VALUE}
                            label="Financial Reason Code"
                            name="reasonCode"
                            inputProps={{ maxLength: 3 }}
                            onChange={handleChange("reasonCode")}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            dropdownList={financialReasonCodeDropdownReference
                              ? financialReasonCodeDropdownReference.map(
                                  (option) => (
                                    <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </option>
                                  )
                                )
                              : null}
                          />
                          <NativeDropDown
                            id="standard-select-PendReason"
                            className="override-width-22 override-m-1"
                            value={values.pendReason || DEFAULT_DD_VALUE}
                            inputProps={{ maxLength: 1 }}
                            label="Pend Reason"
                            name="pendReason"
                            onChange={handleChange("pendReason")}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                            dropdownList={pendReasonDropdownReference
                              ? pendReasonDropdownReference.map((option) => (
                                  <option
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.description}
                                  </option>
                                ))
                              : null}
                          />
                        <div className="mui-custom-form with-date override-width-22 override-m-1">
                          <KeyboardDatePicker
                            maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                            id="begindate-picker-dialog-pendBeginDate"
                            label="Pend Begin Date"
                            format="MM/dd/yyyy"
                            value={pendBeginDate}
                            name="pendBeginDate"
                            helperText={
                              showpendBeginDateError
                                ? pendbeginDateerrorText
                                : null
                            }
                            error={
                              showpendBeginDateError
                                ? pendbeginDateerrorText
                                : null
                            }
                            placeholder="mm/dd/yyyy"
                            InputLabelProps={{
                              shrink: true
                            }}
                            onBlur={settingDefault}
                            onChange={handlePendBDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </div>
                        <div className="mui-custom-form with-date override-width-22 override-m-1">
                          <KeyboardDatePicker
                            id="enddate-picker-dialog-PendEndDate"
                            label="Pend End Date"
                            format="MM/dd/yyyy"
                            maxDate={new Date("9999-12-31T13:00:00.000+0000")}
                            value={pendEndDate}
                            name="pendEndDate"
                            InputLabelProps={{
                              shrink: true
                            }}
                            onBlur={settingDefault}
                            helperText={
                              showpendEndDateError ? pendendDateerrorText : null
                            }
                            error={
                              showpendEndDateError ? pendendDateerrorText : null
                            }
                            placeholder="mm/dd/yyyy"
                            onChange={handlePendEDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form override-width-22 override-m-1">
                          <TextField
                            id="standard-select-lockBoxNumber"
                            fullWidth
                            value={values.lockBoxNumber}
                            label="Lockbox Number"
                            name="lockBoxNumber"
                            inputProps={{ maxLength: 7 }}
                            onChange={handleChange("lockBoxNumber")}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </div>
                        <div className="mui-custom-form override-width-22 override-m-1">
                          <TextField
                            id="standard-select-batchNumber"
                            fullWidth
                            value={values.batchNumber}
                            label="Batch Number"
                            name="batchNumber"
                            inputProps={{ maxLength: 3 }}
                            onChange={handleChange("batchNumber")}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </div>
                        <div className="mui-custom-form override-width-22 override-m-1">
                          <TextField
                            id="standard-select-checkNumber"
                            fullWidth
                            value={values.checkNumber}
                            label="Check Number"
                            name="checkNumber"
                            inputProps={{ maxLength: 15 }}
                            onChange={handleChange("checkNumber")}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </div>
                        <div className="mui-custom-form override-width-22 override-m-1">
                          <TextField
                            id="standard-select-internalTrackingNumber"
                            fullWidth
                            value={values.intTrackingNumber}
                            label="Internal Tracking Number"
                            name="intTrackingNumber"
                            inputProps={{ maxLength: 30 }}
                            onChange={handleChange("intTrackingNumber")}
                            placeholder=""
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form override-width-22 override-m-1">
                        <TextField
                          id='ATRnumber'
                          fullWidth
                          label={ process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                          placeholder=""
                          value={values?.atrNumber}
                          onChange={handleChange('atrNumber')}
                          inputProps={{ maxlength: 18 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          helperText={showATRErr ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}` : null}
                          error= {showATRErr ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}` : null}
                        ></TextField>
                        </div>
                      </div>
                      <div className="form-wrapper w-100">
                        <div className="mui-custom-form override-width-40 override-m-1">
                          <div className="sub-radio">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={handleChange(
                                    "positiveBalalnceAmount"
                                  )}
                                  value={positiveBalalnceAmount}
                                  checked={positiveBalalnceAmount}
                                />
                              }
                              label="Only Select Receipts With a Balance Amount Greater Than Zero"
                            />
                          </div>
                        </div>
                        <div className="mui-custom-form override-width-40 override-m-1">
                          <div className="sub-radio">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={handleChange(
                                    "withOutPayerAssigned"
                                  )}
                                  value={withOutPayerAssigned}
                                  checked={withOutPayerAssigned}
                                />
                              }
                              label="Without Payee Assigned"
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div className="float-right mb-2 mr-3">
                          <Button
                            className="btn-tab-pannel"
                            onClick={() => {
                              financialPayeeSearch();
                            }}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                            Search
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="bt-tabreset btn-transparent ml-1"
                            onClick={() => {
                              financialPayeeSearchReset();
                            }}
                          >
                            <i className="fa fa-undo" aria-hidden="true"></i>
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <div className="clearfix"></div>
                </form>
              </TabPanel>
            </div>
            {showTable ? (
              <div className="tab-holder pb-1">
                <FinancialReceiptSearchTable
                dropdowns={addDropdowns}
                  tableData={payeeTableData}
                  onRowClick={onRowClick}
                  dropdownData={dropdown}
                  values={{
                    ...values,
                    establishedEndDate,
                    pendBeginDate,
                    pendEndDate,
                    establishedBeginDate,
                    positiveBalalnceAmount
                  }}
                  payeeTypeIDDropdown={payeeTypeIDDropdown}
                />
              </div>
            ) : null}

          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
export default withRouter(FinancialReceiptSearch);
