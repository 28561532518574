export const MAPSET_HEADER = 'Search Map Definition';
export const SEARCH_FILTERS = 'Search Filters.';
export const LINE_OF_BUSINESS = 'LOB';
export const MAP_TYPE = 'Map Type';
export const MAP_ID = 'Map ID';
export const MAP_DESCRIPTION = 'Map Description';
export const DATA_ELEMENT_NAME = 'Data Element Name';
export const FIELD_VALUE = 'Field Value';
export const MAP_DESC_ERROR = 'Map Description must not contain special characters.';
export const MAP_ID_ERROR = 'Map ID must not contain special characters.';
export const ADD_LOB_ERROR = 'LOB is required.';
export const ADD_MAP_TYPE_ERROR = 'Map Type is required.';
export const ADD_MAP_ID_ERROR = 'Map ID is required.';
export const ADD_MAP_DESC_ERROR = 'Map Description is required.';
export const ADD_MAP_LONG_DESC_ERROR = 'Map Long Description is required.';
export const ADD_DATAELEMENT_CRITERIA = 'Data Element Criteria is required.';
export const ADD_FUNCTIONAL_AREA = 'Functional Area is required.';
export const ADD_VALUE_ERROR = 'Value is required.';
export const ADD_FROM_VALUE = 'From Value is required.';
export const ADD_THRU_VALUE = 'Thru Value is required.';
export const FROM_THRU_VALUE = 'From value should be less than Thru value.';
export const BEGINDATE_ERROR = 'Begin Date that was entered is invalid.';
export const ENDDATE_ERROR = 'End Date that was entered is invalid.';
export const BEGIN_DATE_OVERLAPPING = 'Exact duplicate rows are not allowed within same map definition.';
export const MCT_BEGIN_END_DATE_FIX = 'The begin and end dates of map definition rows entered on MCT map definitions must fall within the plan begin and end dates of the plan to which the map definition is connected (linked).'
export const DEFINITION_DATA_OVERLAPPING = 'Exact duplicate rows are not allowed within same map definition.';
export const BEGINDATE_ENDDATE_MATCH = 'For a given map definition row, the begin date must be less than or equal to the end date.';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const ERROR_OCCURED_DURING_TRANSACTION = 'No records found for the search criteria entered.';
export const MAP_SET_ID_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const ADD_SUCCESSFULLY = 'System successfully saved the information.';
export const DUPLICATE_MAPID = 'Duplication of the same Map ID is not allowed.';
export const DEFINITIONS_LENGTH_ERROR = 'Can not add more than 15 rows.';
export const DEFINITION_DATA_LENGTH = 'Atleast one definiton data row is required.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const BEGINDATE_REQUIRED = 'Begin Date is required.';
export const ENDDATE_REQUIRED = 'End Date is required.';
export const CHANGE_MAY_IMPACT = 'This change may have impact on the other LOBs and Benefit Plans associated with Map ID. Do you still want to save the changes.';
export const DATA_ELEMENT_NAME_REQUIRED = 'Data Element Name is required.';