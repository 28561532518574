/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './ActionTypes';
import * as serviceEndPoint from '../../../Modules/ApplicationConfiguration/services/service';
import * as commonServiceEndPoint from '../../services/service';
import * as sharedActionTypes from '../../../Modules/ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActionTypes';
import { R_PARAM_TB, R_PARAM_DTL_TB } from '../../../Modules/ApplicationConfiguration/Components/SystemParameter/AuditLogConstants';
import { F_HDR_TB, F_PMT_TB, F_RECV_TB } from '../../AuditLog/AuditTableComponent';
import axios from 'axios';
import { commonAPIHandler } from '../../ServerErrorHandler/index';
import Cookies from 'js-cookie'
let tokenIntervalID = null, inactivityTimeOutID = null;

export const getCurrentPath = (currentPath) => {
  return {
    type: actionTypes.GET_CURRENT_PATH,
    currentPath: currentPath
  };
};

export const getSystemParameterSpecificValue = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.SYSTEM_PARAMETER_SPECIFIC_VALUE}?paramNumber=${value.paramNumber}&functionalArea=${value.functionalArea}
    &lobCode=${value.lobCode}&asOfDate=${value.asOfDate}`)
      .then(response => {
        if (response && response.data) {
          dispatch(getSystemParameterSpecificValueAction(response.data));
        }
      })
      .catch(error => {
        dispatch(getSystemParameterSpecificValueAction({ systemFailure: true }));
      });
  };
}
export const getSystemParameterSpecificValueAction = (data) => {
  return {
    type: actionTypes.SYSTEM_PARAMETER_SPECIFIC_VALUE,
    SystemParameterData: data
  };
};

export const getMaxFrequencyParameter = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.SYSTEM_PARAMETER_SPECIFIC_VALUE}?paramNumber=${value.paramNumber}&functionalArea=${value.functionalArea}
    &lobCode=${value.lobCode}&asOfDate=${value.asOfDate}`)
      .then(response => {
        if (response && response.data) {
          dispatch(getMaxFrequencyParameterValue(response.data));
        }
      })
      .catch(error => {
        dispatch(getMaxFrequencyParameterValue({ systemFailure: true }));
      });
  };
}
export const getMaxFrequencyParameterValue = (data) => {
  return {
    type: actionTypes.FREQUENCY_PARAM,
    frequencyParameter: data
  };
};

export const multiDropDownGetAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.DROPDOWS}${value}`)
      .then(response => {
        if (response && response.data) {
          dispatch(multiDropDownGetValues(response.data));
        } else {
          dispatch(multiDropDownGetValues([]));
        }
      })
      .catch(error => {
        dispatch(multiDropDownGetValues([]));
      });
  };
};
export const getAttachmentsDDValues = (value) => {
  return dispatch => {
    return axios.get(`${commonServiceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        if (response && response.data) {
          dispatch(setAttachmentDDValues(response.data));
        } else {
          dispatch(setAttachmentDDValues([]));
        }
      })
      .catch(error => {
        dispatch(setAttachmentDDValues({ systemFailue: true }));
      });
  };
};
export const getUserDetails = (value) => {
  return dispatch => {
    // return axios.get(`${commonServiceEndPoint.GET_USER_PRIVILEGES}?userId=${value}&password=${password}`)
    return axios.get(`${commonServiceEndPoint.GET_USER_PRIVILEGES}?userId=${value}`)
      .then(response => {
        if (response) {
        }
        dispatch(setUserprivileges(response.data));
      })
      .catch(error => {
        dispatch(setUserprivileges([]));
      });
  };
};

export const multiDropDownGetValues = (data) => {
  return {
    type: actionTypes.MULTI_DROPDOWN_VALUES_ACTION,
    multiDropDownData: data
  };
};
export const setAttachmentDDValues = (data) => {
  return {
    type: actionTypes.SET_ATTACHMENTS_DDD_VALUES,
    attachmentDDValues: data
  };
};

export const verifyAddressAPI = (value) => {
  return dispatch => {
    return axios.post(`${commonServiceEndPoint.ADDRESS_VERIFY}`, value)
      .then(response => {
        if (response.data) {
          dispatch(setValidAddress(response.data));
        } else {
          dispatch(setValidAddress({ systemFailue: true }));
        }
      })
      .catch(error => {
        dispatch(setValidAddress({ systemFailue: true }));
      });
  };
};

export const downloadFileAction = (folder, fileName) => {
  return dispatch => {
    return axios.get(`${commonServiceEndPoint.DOWNLOAD_ACTION_LINK}?folder=${folder}&&fileName=${fileName}`
      , {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream'
        }
      }
    )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setDownloadFile({ systemFailure: true }));
        } else {
          var myURL = window.URL || window.webkitURL;
          const objectUrl = myURL.createObjectURL(new Blob([sendingResponse]));
          dispatch(setDownloadFile(objectUrl));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setDownloadFile(sendingResponse));
      });
  };
};

export const downloadPDFPreview = (folder, fileName) => {
  return dispatch => {
    return axios.get(`${commonServiceEndPoint.DOWNLOAD_ACTION_LINK}?folder=${folder}&&fileName=${fileName}`
      , {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream'
        }
      }
    )
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setDownloadFile({ systemFailure: true }));
        } else {
          var myURL = window.URL || window.webkitURL;
          let objectUrlReview = myURL.createObjectURL(new Blob([sendingResponse],{ type: 'application/pdf' }));
          window.open(objectUrlReview,'_blank');
          dispatch(setPDFReview(objectUrlReview));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setPDFReviewError(true));
      });
  };
};

export const setPDFReview = (data) => {
  return {
    type: actionTypes.DOWNLOAD_PDF_FILE_ACTION,
    downloadeFilePDF: data
  };
}

export const setPDFReviewError = (data) => {
  return {
    type: actionTypes.DOWNLOAD_PDF_FILE_ACTION_ERROR,
    downloadeFilePDFError: data
  };
}

export const resetPDFReviewError = () => {
  return {
    type: actionTypes.DOWNLOAD_PDF_FILE_ACTION_ERROR,
    downloadeFilePDFError: false
  };
}
export const refreshAttachmentsAction = (fcn) => {
  return dispatch => {
    return axios.get(`${commonServiceEndPoint.REFRESH_ATTACHMENTS_LINK}?fcn=${fcn}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse === undefined) {
          dispatch(setAttachmnetsAfterRefresh([]));
        } else {
          dispatch(setAttachmnetsAfterRefresh(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setAttachmnetsAfterRefresh(sendingResponse));
      });
  };
};
export const setAttachmnetsAfterRefresh = (data) => {
  return {
    type: actionTypes.REFRESH_ATTACHMENTS,
    newAttachmentResult: data
  };
};
export const setDownloadFile = (data) => {
  return {
    type: actionTypes.DOWNLOAD_FILE_ACTION,
    downloadeFile: data
  };
};
export const setAttachmentResult = (data) => {
  return {
    type: actionTypes.ATTACHMENT_API,
    attachmentResult: data
  };
};

export const setValidAddress = (data) => {
  return {
    type: actionTypes.ADDRESS_VERIFY,
    addressValidatedData: data
  };
};
export const setUserprivileges = (data) => {
  return {
    type: actionTypes.USER_LOGGIN_ACTION,
    userPrivileges: data
  };
};
export const getUserInquiryPrivilege = (data) => {
  return {
    type: actionTypes.USER_INQUIRY_PRIVILEGE,
    userInquiryPrivileges: data
  };
};
export const setLogInUserID = (data) => {
  return {
    type: actionTypes.LOGIN_USER_ID,
    logInUserID: data ? data.toString().toUpperCase() : data
  };
};


export const getAuditLogDetailsAction = (functionalArea, parameterNumber, parameterDetailSK, isLogDetail, auditData) => {
  let body = {
    "tableName": R_PARAM_TB,
    "keyValue": {
      "r_func_area_cd": functionalArea,
      "r_param_num": parameterNumber
    }
  }
  if (isLogDetail) {
    body = {
      "tableName": R_PARAM_DTL_TB,
      "keyValue": {
        "r_func_area_cd": functionalArea,
        "r_param_num": parameterNumber,
        "r_param_dtl_sk": parameterDetailSK
      }
    }
  }

  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}
export const setAuditLogData = (data, keyName) => {
  return {
    type: sharedActionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};
export const getAuditLogDetailsAccountingAction = (fcn_num, schdled_date, isLogDetail, auditData) => {
  let body = [
    {
      "tableName": F_HDR_TB,
      "keyValue": {
        f_fcn_num: fcn_num
      }
    }, {
      "tableName": F_RECV_TB,
      "keyValue": {
        f_fcn_num: fcn_num
      }
    }
  ];
  if (isLogDetail) {
    body = [
      {

      "keyValue": {
        "f_recv_sched_sk": fcn_num,
      },
      "tableName": F_PMT_TB

    }
    ]
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogAccountingData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}
export const setAuditLogAccountingData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_COMMON,
    payload: data,
    keyName
  };
};

export const getUserDetailsFromSSO = (value) => {
  let cookie_value = Cookies.get('uid')
  return dispatch => {
    return axios.get(`${commonServiceEndPoint.GET_USER_DETAILS}?uid=${value}`
      , {
        headers: { "Authorization": `Bearer ${cookie_value}` }, cookies: { 'uid': cookie_value }
      }
    )
      .then(response => {
        dispatch(setUserprivileges(response.data));
      })
      .catch(error => {
      });
  };
};

export const userLogout = (isReload,usr=safeLocalStorage('get', 'loginState')) => {  
  return dispatch => {
    return axios.post(`${commonServiceEndPoint.USER_LOGOUT}`, {
      "username": usr ? JSON.parse(usr).loginUserName : null,
      "accessToken": localStorage.getItem('accessToken'),
      "ssoToken": localStorage.getItem('ssoToken'),
      })
      .then(response => {
        if (response && response.data) {
          dispatch(logOut(isReload));
        }
      })
      .catch(error => {
        dispatch(logOut(isReload));
      });
  };
};


export const dispatchUserRoles = (data,userData) => ({
  type: actionTypes.USERROLES,
  roles: data,
  userData:userData
});

export const resetSearchClaimsCorrection = tabValue => ({
  type: actionTypes.RESETDATA,
  resetData: [],
  tabValue: tabValue,
});
export const resetPDFData = () => ({
  type: actionTypes.RESETPDFDATA,
  resetPDFData: [],
  downloadeFilePDFError:null
});
export const ValidateUser = (credentials) => dispatch => {
  return axios.post(commonServiceEndPoint.LOGIN,credentials, {headers:{'X-CMDS-APP-CODE':'FIN'}})
      .then(response => {
          if (response.status == 200 && response.data!="" && response.data.isRecordExist) {
            dispatch(GetUserRoles(credentials,response.data));
            dispatch(setUserprivileges(response.data));
          }
          else if(response.status == 200 && response.data!="" && !response.data.isRecordExist){
              dispatch(dispatchUserRoles("",response.data));
              dispatch(setUserprivileges(response.data));
          }
          else  {
              dispatch(dispatchUserRoles(response.data.message));
          }
      })
      .catch(error => {
          dispatch(dispatchUserRoles(error.data));
      })
}

export const GetUserRoles = (userId,userData) => dispatch => {
  return axios.get(commonServiceEndPoint.USER_ROLES + "/"+userId)
      .then(response => {
          if (response.status == 200) {
              dispatch(dispatchUserRoles(response.data,userData));
          } else {
              dispatch(dispatchUserRoles(response.data.message,userData));
          }
      })
      .catch(error => {
          dispatch(dispatchUserRoles(error.data));
      })
}
export const safeLocalStorage = (actType, storeName, data) => {
  let res;
  try {
    if (actType === "set") {
      res = localStorage.setItem(storeName, data);
    } else if (actType === "get") {
      res = localStorage.getItem(storeName);
    } else if (actType === "remove") {
      res = localStorage.removeItem(storeName);
    }
  } catch {
  }
  return res;
};
export const refreshToken = (refreshToken,userName) => dispatch => {
  return axios.get(`${commonServiceEndPoint.REFRESH_TOKEN}/${JSON.parse(userName).logInUser}/${refreshToken}`)
      .then(response => {
          dispatch(setTokenInStore(response.data));          
      })
      .catch(error => {
          dispatch(userLogout(true));
 if(error?.message == 'Request failed with status code 401'){
            setTimeout(()=>{
              window.location = '/public/401.html';
            },1000);
          }
      });
};
export const tokenInterval = () => {
  return dispatch => {
    tokenIntervalID = setTimeout(() => {
      dispatch(refreshToken(localStorage.getItem('refreshToken'),safeLocalStorage('get', 'loginState')));
    }, (localStorage.getItem('expiresInSeconds')*1000) - 10000);
  };
};
export const setTokenInStore = (userData) => { 
  return dispatch => { 
    localStorage.setItem('accessToken', userData.accessToken);
    localStorage.setItem('refreshToken', userData.refreshToken);
    localStorage.setItem('expiresInSeconds', userData.expiresInSeconds);
    localStorage.setItem('expirationDateTime', userData.expirationDateTime);
    if(!localStorage.getItem('lsso')){
      dispatch(tokenInterval());
    }       
  };
};
export const CheckLandingPageUrl = (userData) => {
  if (userData && userData.data) {
    let childDetails;
    let defaultUrl='/dashboard';
    var menuItems = userData.data;
    childDetails=menuItems.filter(arrr => arrr.rootUrl === defaultUrl)
    if(childDetails.length > 0){
        return defaultUrl;
    }else{
        if(menuItems[0] && menuItems[0].children && menuItems[0].children.length > 0){
            return menuItems[0].children[0].url;
        }else{
            return menuItems[0].rootUrl;
        }  }
  }
};
export const dispatchChangePassword = (response,fieldsValue) => ({
  type: actionTypes.CHANGE_PASSWORD,
  searchData: response,
  fieldsValue:fieldsValue
});
export const ChangePasswordAction = (values,fieldsValue) => async dispatch => {
  return await axios.post(commonServiceEndPoint.CHANGE_PASSWORD, values)
  .then(response => {
      if (response.data.statusCode == "01") {
          dispatch(dispatchChangePassword(response.data,fieldsValue));
      }
      else if (response.data.statusCode == "02") {
          dispatch(dispatchChangePassword(response.data));
      }
  })
  .catch(error => {
      dispatch(dispatchChangePassword(error));
  })
}
export const ssoLogin = (data) => {
  return {
    type: actionTypes.SSO_LOGIN,
    ssoLogin: data
  };
};
export const setNavigationPrivileges = (data) => {
    return {
      type: actionTypes.SET_NAVIGATION_PRIVILEGES,
      privileges: data
    };
  };
export const setMenuStateChanged = (data) => {
  return {
    type: actionTypes.MENU_STATE_CHANGED,
    isMenuChanged: data
  };
};
export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};
export const updateUserInOutAction = (status) => {
  return {
    type: actionTypes.USER_IN_OUT_ACTION,
    status: status   
  };
};
export const dispatchSessionExpiry = (status) => {
  return {
    type: actionTypes.SHOW_SESSION_EXPIRY_DIALOG,
    status: status   
  };
};
export const dispatchSessionExpand = (status) => {
  return {
    type: actionTypes.SESSION_EXPANDED,
    status: status   
  };
};
export const stoptUsrInactivityTimeOut = () => {     
  clearTimeout(inactivityTimeOutID);   
};
export const logOut = (isReload, isSSOLogin) => {
  return dispatch => {    
    const lsso = localStorage.getItem('lsso');
    dispatch(setUserprivileges([]));
    clearInterval(tokenIntervalID);      
    window.removeEventListener('blur', updateUserInOutAction("OUT"));
    window.removeEventListener('focus', updateUserInOutAction("IN"));
    localStorage.clear();
    Cookies.remove('userProfile');
    document.cookie = `iPlanetDirectoryPro=; path=/; domain=.${window.location.host};Secure; expires=` + new Date(0).toUTCString();
    if(lsso){
      if(window.opener){
        window.opener.postMessage("logOut", process.env.REACT_APP_SSO_LOGIN_URL);     
      }
      window.location.replace(process.env.REACT_APP_SSO_LOGIN_URL);
    }
    else if(isReload && !isSSOLogin){
      window.location = '/';
    }
  };
};

export const Checkprivileges = (path) => {
  const userRole = JSON.parse(localStorage.getItem("loginState"));
  if(path==='/PasswordToBeExpire' || path==='/ChangePassword'){
    return{name:path}
  }
  if (userRole) {
    let childDetails = [];
    userRole.menuItems.map(arrr => {
      if (arrr.children.length === 0) {
        if (arrr.rootUrl === path) {
          childDetails.push(arrr)
        }
      } else {
        let newArr = arrr.children.map(childData => {
          if (childData.url === path || childData.url === '/TextManagement') {
            childDetails.push(childData)
          }
        })
      }
    })

    return childDetails.length > 0 ? childDetails[0] : [] ;
  }
};

export const ResetAddressResponseData = () => {
  return {
    type: actionTypes.RESET_ADDRESS_RESPONSE
  };
};