/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { makeStyles, lighten, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import BudgetFundingSourceTable from './BudgetFundingSourceTable';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as BudgetMaintenanceConstants from '../BudgetMaintenanceConstants';
import TablePaginationActions from '../../../../SharedModules/Table/TablePagination';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  stableSort,
  getSorting
} from '../../../../SharedModules/Table/TableSort';
import DialogContentText from '@material-ui/core/DialogContentText';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import numeral from 'numeral';
import { Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSearchBudgetMaintenance,
  budgetMaintenanceAddSaveAPI,
  searchBudgetMaintenanceIDAction,
  getDropdownDataAction,
  getSubDropdownDataAction,
  budgetEndDateAPI
} from '../../Store/Actions/budgetMaintenance/budgetMaintenanceActions';
import {
  validateDateMinimumValue,
  setSelectedDate,
  overlapCheckWithDateRange,
  getDateInMMDDYYYYFormatWithApendZero,
  generateUUID,
  getUTCTimeStamp,
  compareTwoDatesGreaterThanOrEqual,
  handelDateRangeGap,
  handelDateRangeGapHeaderCI
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import { withRouter } from 'react-router';

import Moment from 'react-moment';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import moment from 'moment';
import dropdownCriteria from '../FinancialBudgetAddEdit.json';
import subDropdownCriteria from '../FinancialBudgetSub.json';
import Footer from '../../../../SharedModules/Layout/footer';
import Notes from '../../../../SharedModules/Notes/Notes';

import Radio from '@material-ui/core/Radio';
import { getLongDescription, removeLeadingTrailingSpaces, checkingDecimal } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

let errorMessagesArray = [];
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: 'left'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const headCells = [
  {
    id: 'fundCodeCode',
    numeric: false,
    disablePadding: false,
    label: 'Fund Code',
    width: '15%',
    enableHyperLink: true,
    isTooltip: true,
    isTitle: 'fundCodeLongDesc'
  },
  {
    id: 'fundCodeCos',
    numeric: false,
    disablePadding: false,
    label: 'COS',
    width: '15%',
    isDropDown: true,
    isTooltip: true,
    isTitle: 'cosCodeLongDesc'
  },
  {
    id: 'fundCodeBeginDate',
    numeric: false,
    disablePadding: true,
    label: 'Start Date',
    width: '15%'
  },
  {
    id: 'fundCodeEndDate',
    numeric: false,
    disablePadding: false,
    label: 'End Date',
    width: '10%'
  },
  {
    id: 'fundCodeBudgetAmount',
    numeric: false,
    disablePadding: false,
    label: 'Budget Amt',
    width: '15%'
  },
  {
    id: 'fundCodeADJ',
    numeric: false,
    disablePadding: false,
    label: 'Adj Auth',
    width: '15%'
  },
  {
    id: 'fundCodeExpenditure',
    numeric: false,
    disablePadding: false,
    label: 'Expenditure',
    width: '15%'
  }
];

function EnhancedTableHead (props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount && numSelected !== 0}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
          <a aria-hidden="true" hidden="true">
            check box
          </a>
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 100%'
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

function BudgetMaintenanceAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [fundCodeBeginDate, setFundCodeBeginDate] = React.useState(null);
  const [fundCodeEndDate, setFundCodeEndDate] = React.useState(null);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [isFundCodeError, setFundCodeError] = React.useState(false);
  const classes = useStyles();
  const [
    {
      showStateFiscalYearError,
      showBudgetDescError,
      showAgencyError,
      showGLCompanyError,
      showGLAccountingUnitError,
      showGLAccountError,
      showTotalPercentageError,
      showFundCodeError,
      showBeginDateError,
      showEndDateError,
      showBudgetAmountError,
      showBudgetAmountFormatError,
      showBudgetLessThanZero,
      showFederalError,
      showStateError,
      showCountyError,
      showOtherError,
      showFederalFormat,
      showStateFormat,
      showCountyFormat,
      showOtherFormat,
      showFSourceBeginDateError,
      showFSourceEndDateError
    },
    setShowError
  ] = React.useState(false);
  const handleBlurPEr = (name) => event => {
    var num = event.target.value;
    if (num && !isNaN(num)) {
      const newNum = Number.parseFloat(num).toFixed(2);
      setDataElement({ ...dataElement, [name]: event.target && newNum ? newNum : event });
    }
  };
  const [
    { beginDateerrorText, endDateerrorText },
    setShowErrorText
  ] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('fundCodeCode');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  let EndDateFromDB = useSelector(
    state => state.budgetMaintenanceState.budgetEndDateInfo
  );
  useEffect(() => {
    rowDeleteFCode(selected);
    EndDateFromDB = '';
  }, [selected]);

  /// INTEGRATION PART
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const dispatch = useDispatch();
  const [fiscalYearData, setFiscalYearData] = React.useState([]);
  const [agencyData, setAgencyData] = React.useState([]);
  const [glCompanyData, setGLCompanyData] = React.useState([]);
  const [unitData, setUnitData] = React.useState([]);
  const [glAccountData, setGLAccountData] = React.useState([]);
  const [glSubAccountData, setGLSubAccountData] = React.useState([]);
  const [acAccountCategoryData, setACAccountCategoryData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [cosData, setCosData] = React.useState([]);
  const [acActivityData, setACActivityData] = React.useState([]);
  const [selectedFSourceRow, setSelectedFSourceRow] = React.useState(null);
  const [selectedFundCodeRow, setSelectedFundCodeRow] = React.useState(null);
  const [rowFSourceData, setrowFSourceData] = React.useState([{}]);
  const [rowFCodeeData, setrowFCodeData] = React.useState([{}]);
  const [endDateDefault, setEndDateDefault] = React.useState('');
  const onReset = () => dispatch(resetSearchBudgetMaintenance());
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const getSubDropdownData = dropdownValues =>
    dispatch(getSubDropdownDataAction(dropdownValues));
  // Notes
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});

  useEffect(() => {
    delete props?.history?.state?.values;
    onReset();
    getDropdownData(dropdownCriteria);
    getSubDropdownData(subDropdownCriteria);
  }, []);
  const [redirect, setRedirect] = React.useState(0);

  const dropdown = useSelector(
    state => state.budgetMaintenanceState.dropdownData
  );
  const subdropdown = useSelector(
    state => state.budgetMaintenanceState.subdropdownData
  );

  const toPrintRef = useRef();

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Financial#1021']) {
        setFiscalYearData(dropdown.listObj['Financial#1021'].sort((a, b) => a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? 1 : -1));
      }
      if (dropdown.listObj['Financial#1125']) {
        setAgencyData(dropdown.listObj['Financial#1125']);
      }
      if (dropdown.listObj['Financial#1124']) {
        setGLCompanyData(dropdown.listObj['Financial#1124']);
      }
      if (dropdown.listObj['Financial#1016']) {
        setUnitData(dropdown.listObj['Financial#1016']);
      }
      if (dropdown.listObj['Financial#1015']) {
        setGLAccountData(dropdown.listObj['Financial#1015']);
      }
      if (dropdown.listObj['Financial#1130']) {
        setACAccountCategoryData(dropdown.listObj['Financial#1130']);
      }
      if (dropdown.listObj['Claims#1020']) {
        setCosData(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Reference#1042']) {
        setFundCodeData(dropdown.listObj['Reference#1042']);
      }
      if (dropdown.listObj['Financial#1129']) {
        setACActivityData(dropdown.listObj['Financial#1129']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (subdropdown && subdropdown.listObj) {
      if (subdropdown.listObj['Financial#F_SUB_OBJ_CD']) {
        setGLSubAccountData(subdropdown.listObj['Financial#F_SUB_OBJ_CD']);
      }
    }
  }, [subdropdown]);

  const budgetMaintenanceAddSave = (saveObj) =>
    dispatch(budgetMaintenanceAddSaveAPI(removeLeadingTrailingSpaces(saveObj)));

  const budgetMaintenanceSaveResponse = useSelector(
    state => state.budgetMaintenanceState.BudgetMaintenanceAddSaveResponse
  );
  const successArray = [];

  const onAddSuccess = values =>
    dispatch(searchBudgetMaintenanceIDAction(values));
  const addSuccessData = useSelector(
    state => state.budgetMaintenanceState.searchBudgetID
  );

  useEffect(() => {
    if (redirect === 1) {
      if (addSuccessData) {
        props.history.push({
          pathname: '/BudgetDataUpdate',
          state: { addSuccessData, successMessage, isFormAdd: false }
        });
      }
    }
  }, [addSuccessData]);

  useEffect(() => {
    if (EndDateFromDB) {
      if (EndDateFromDB.systemFailure) {
        const errorArray = [];
        errorArray.push(BudgetMaintenanceConstants.DATABASE_ERROR);
        seterrorMessages(errorArray);
      } else {
        if (EndDateFromDB.respcode === '01') {
          setEndDateDefault(EndDateFromDB.enterpriseBaseVO.fundCodeVO.endDate);
          setFundCodeEndDate(
            new Date(EndDateFromDB.enterpriseBaseVO.fundCodeVO.endDate)
          );
          seterrorMessages([]);
        }
        if (EndDateFromDB.respcode !== '01') {
          const errorArray = [];
          errorArray.push(BudgetMaintenanceConstants.DATABASE_ERROR);
          seterrorMessages(errorArray);
        }
      }
    }
  }, [EndDateFromDB]);

  useEffect(() => {
    if (
      budgetMaintenanceSaveResponse &&
      budgetMaintenanceSaveResponse.respcode &&
      budgetMaintenanceSaveResponse.respcode === '01'
    ) {
      setAllowNavigation(false);
      const viewSearchCriteria =
        budgetMaintenanceSaveResponse.enterpriseBaseVO.budgetID;
      successArray.push(AppConstants.SAVE_SUCCESS);
      setSuccessMessage(successArray);
      setFocusCheck(false);
      onAddSuccess(viewSearchCriteria);
      const valuetoredirect = redirect + 1;
      setRedirect(valuetoredirect);
    } else if (
      budgetMaintenanceSaveResponse &&
      budgetMaintenanceSaveResponse.respcode &&
      budgetMaintenanceSaveResponse.respcode !== '01'
    ) {
      const errorArray = [];

      errorArray.push(budgetMaintenanceSaveResponse.respdesc);
      seterrorMessages(errorArray);
    } else if (
      budgetMaintenanceSaveResponse &&
      budgetMaintenanceSaveResponse.systemFailure
    ) {
      const errorArray = [];

      errorArray.push(BudgetMaintenanceConstants.DATABASE_ERROR);
      seterrorMessages(errorArray);
    }
  }, [budgetMaintenanceSaveResponse]);

  // INTEGRATION PART
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = newFundData.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const openEditModal = row => () => {
    setSourceErrors([]);
    seterrorMessages([]);
    setAllowNavigation(true);
    setSelectedFundCodeRow(row);
    handleClickOpen(row);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Add fund code begin
  const [showResults, setshowResults] = React.useState(false);
  const handleFundCodeBeginDate = date => {
    setFundCodeBeginDate(date);
  };

  const handleFundCodeEndDate = date => {
    setFocusCheck(true);
    setFundCodeEndDate(date);
  };

  const handleStartDateChange = date => {
    setFocusCheck(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = date => {
    setFocusCheck(true);
    setSelectedEndDate(date);
  };

  const [data, setData] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    budgetID: null,
    voidIndicator: null,
    lob: DEFAULT_DD_VALUE,
    noteSet: null,
    fundCodeVOList: [],
    accountCategoryCode: DEFAULT_DD_VALUE,
    fiscalYear: DEFAULT_DD_VALUE,
    agencyCode: DEFAULT_DD_VALUE,
    activityCode: null,
    organizationCode: DEFAULT_DD_VALUE,
    subOrganizationCode: null,
    objectCode: DEFAULT_DD_VALUE,
    subObjectCode: DEFAULT_DD_VALUE,
    programCode: null,
    classCode: null,
    glCompanyCode: DEFAULT_DD_VALUE,
    budgetDesc: null,
    funcCode: null,
    voidDate: null,
    voidValue: 'No',
    voidProtect: false,
    componentCode: null,
    subAppropriationSequenceNumber: null,
    subAppropriationCode: null,
    subAppropriationBeginDate: null,
    subAppropriationEndDate: null,
    subAppropriationOriginalBudgetAmount: null,
    subAppropriationAdjustedAuthorzedAmount: null,
    subAppropriationAuthorized: null,
    subAppropriationExpenditures: null,
    subAppropriationVoidIndicator: null
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [values, setValues] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    voidIndicator: false,
    lob: null,
    fundSourceHistoryList: [],
    fundCodeHistoryList: [],
    beginDate: null,
    endDate: null,
    fundCode: '',
    budgetAmount: 0,
    adjustedAuthorizedAmount: '0.00',
    expenditureAmount: '0.00',
    tempExpAmount: '0.00',
    cos: null,
    cmsTos: null,
    jobNumber: null,
    inKindIndicator: 'N',
    federal: '0.00',
    state: '0.00',
    county: '0.00',
    other: '0.00',
    sequenceNumber: null,
    fundVoidDate: null,
    voidValue: 'No',
    fundCodeVoidProtect: false,
    benefitPlanID: null,
    oldFundCode: null,
    oldBeginDate: null,
    oldEndDate: null,
    voSeqNum: 0,
    fundSourceVOList: []
  });

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const [dataElement, setDataElement] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    voidIndicator: false,
    activeInd: false,
    dateDisable: false,
    beginDate: null,
    fundVO: null,
    endDate: null,
    federalPercentage: '',
    federal: null,
    statePercentage: '',
    countyPercentage: '',
    otherPercentage: '',
    totalPercentage: 0,
    sequenceNumber: null,
    fundSourceVoidDate: null,
    voidValue: 'No',
    fundSourceVoidProtect: false
  });

  const handleChangeMain = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setData({ ...data, [name]: event.target.value });
    if (name === 'fiscalYear') {
      setFundCodeEndDate(new Date(endDateDefault));
      setSelectedEndDate(new Date(endDateDefault));
      if (event.target.value !== DEFAULT_DD_VALUE) {
        dispatch(budgetEndDateAPI({ fiscalYear: event.target.value }));
      }
    }
  };
  const duplicateFundCodeValidation = () => {
    let count = 0;
    for (var i = 0; i < newFundData.length; i++) {
      if (i !== newFundData.indexOf(selectedFundCodeRow)) {
        if (
          (newFundData[i].cos === values.cos ||
          (newFundData[i].cos === null &&
              values.cos === DEFAULT_DD_VALUE)) &&
        newFundData[i].fundCode === values.fundCode &&
        (moment(fundCodeBeginDate).isBetween(newFundData[i].beginDate, newFundData[i].endDate) ||
          moment(newFundData[i].beginDate).isBetween(fundCodeBeginDate, fundCodeEndDate) ||
          moment(newFundData[i].endDate).isBetween(fundCodeBeginDate, fundCodeEndDate))
        ) {
          count++;
        }
        if (
          (newFundData[i].cos === values.cos ||
          (newFundData[i].cos === null &&
              values.cos === DEFAULT_DD_VALUE)) &&
        newFundData[i].fundCode === values.fundCode &&
        ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) &&
         (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)))
        ) {
          count++;
        }
        if (
          (!newFundData[i].voidIndicator) &&
        (newFundData[i].cos === values.cos ||
          (newFundData[i].cos === null &&
              values.cos === DEFAULT_DD_VALUE)) &&
        newFundData[i].fundCode === values.fundCode &&
          (new Date(fundCodeBeginDate) <= new Date(newFundData[i].beginDate) &&
          ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) &&
          (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)) ||
          new Date(fundCodeEndDate) > new Date(newFundData[i].endDate)))
        ) {
          count++;
        }
      }
    }
    if (count > 0) {
      errorMessagesArray.push(
        BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION
      );
      seterrorMessages(errorMessagesArray);
    }
  };
  const handleFundingSourceOpen = () => {
    if (validateFundCode(false)) {
      setFundCodeError(false);
      setOpen(true);
      setDataElement({
        selectedStartDate: '',
        selectedEndDate: '',
        federal: '',
        state: '',
        county: '',
        other: ''
      });
      seterrorMessages([]);
      setSelectedStartDate(null);
      setSelectedEndDate(new Date(endDateDefault));
    } else {
      setFundCodeError(true);
    }
  };

  useEffect(() => {
    if (open && document.getElementById('form_pop_up')) {
      document.getElementById('form_pop_up').scrollIntoView();
    }
  }, [open]);

  const [newFundData, setnewFundData] = React.useState([]);

  const validateFundCode = add => {
    errorMessagesArray = [];
    var beginDateerrorText = '';
    var endDateerrorText = '';
    var showBeginDateError = false;
    var showEndDateError = false;
    var showFundCodeError = false;
    var showBudgetAmountError = false;
    var showACActivityError = false;
    var showBudgetAmountFormatError = false;
    var showDateOverlappingError = false;
    var showFundingSourceEmptyError = false;
    var showBudgetLessThanZero = false;

    // Fund code date validations
    if (fundCodeBeginDate) {
      if (fundCodeBeginDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(fundCodeBeginDate)) {
          showBeginDateError = true;
          beginDateerrorText = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          seterrorMessages(errorMessagesArray);
        } else if (compareTwoDatesGreaterThanOrEqual(moment(new Date(fundCodeBeginDate)).format('MM/DD/YYYY'), moment(new Date()).format('MM/DD/YYYY'))) {
          showBeginDateError = true;
          beginDateerrorText =
            BudgetMaintenanceConstants.FCODE_BEGINDATE_IS_PASTDATE;
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FCODE_BEGINDATE_IS_PASTDATE
          );
          seterrorMessages(errorMessagesArray);
        }
      } else {
        showBeginDateError = true;
        beginDateerrorText =
          BudgetMaintenanceConstants.FCODE_BEGINDATE_INVALID_ERROR;
        errorMessagesArray.push(
          BudgetMaintenanceConstants.FCODE_BEGINDATE_INVALID_ERROR
        );
        seterrorMessages(errorMessagesArray);
      }
    } else {
      showBeginDateError = true;
      beginDateerrorText = BudgetMaintenanceConstants.BEGINDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.BEGINDATE_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (fundCodeEndDate) {
      if (fundCodeEndDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(fundCodeEndDate)) {
          showEndDateError = true;
          endDateerrorText = AppConstants.DATE_ERROR_1964;
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          seterrorMessages(errorMessagesArray);
        }
      } else {
        showEndDateError = true;
        endDateerrorText =
          BudgetMaintenanceConstants.FCODE_ENDDATE_INVALID_ERROR;
        errorMessagesArray.push(
          BudgetMaintenanceConstants.FCODE_ENDDATE_INVALID_ERROR
        );
        seterrorMessages(errorMessagesArray);
      }
    } else {
      showEndDateError = true;
      endDateerrorText = BudgetMaintenanceConstants.ENDDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.ENDDATE_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      fundCodeBeginDate &&
      fundCodeEndDate &&
      !showBeginDateError &&
      !showEndDateError &&
      compareTwoDatesGreaterThanOrEqual(fundCodeEndDate, fundCodeBeginDate)
    ) {
      showBeginDateError = true;
      beginDateerrorText =
        BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH_FUND_CODE;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH_FUND_CODE
      );
      seterrorMessages(errorMessagesArray);
    }

    if (!values.fundCode || values.fundCode === DEFAULT_DD_VALUE) {
      showFundCodeError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_FUND_CODE_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (!values.budgetAmount || values.budgetAmount === '') {
      showBudgetAmountError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_BUDGET_AMOUNT_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }
    if (parseFloat(values.budgetAmount) < 0) {
      showBudgetLessThanZero = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.BUDGET_LESS);
      seterrorMessages(errorMessagesArray);
    }
    if (values.budgetAmount) {
      if (
        values.budgetAmount
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null
      ) {
        showBudgetAmountFormatError = true;
        errorMessagesArray.push(BudgetMaintenanceConstants.DATA_INVALID);
        seterrorMessages(errorMessagesArray);
      } else {
        if (
          !(
            values.budgetAmount
              .replace(/,/g, '')
              .match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null
          )
        ) {
          showBudgetAmountFormatError = true;
          errorMessagesArray.push(BudgetMaintenanceConstants.DATA_INVALID);
          seterrorMessages(errorMessagesArray);
        }
      }
    }
    if (add && newDialogData.length <= 0) {
      showFundingSourceEmptyError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_FUNDING_SOURCE_EMPTY_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }
    // Dates Gap
    if (add && newDialogData.length > 0) {
      const daysGapChecker = handelDateRangeGapHeaderCI(fundCodeBeginDate, fundCodeEndDate, newDialogData) ||
       handelDateRangeGap(newDialogData);

      if (daysGapChecker) {
        showDateOverlappingError = true;
        errorMessagesArray.push(
          BudgetMaintenanceConstants.FUND_CODE_COVERAGE_ERROR
        );
        seterrorMessages(errorMessagesArray);
      }
    }

    duplicateFundCodeValidation();
    setShowError({
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError,
      showDateOverlappingError: showDateOverlappingError,
      showFundCodeError: showFundCodeError,
      showBudgetAmountError: showBudgetAmountError,
      showBudgetAmountFormatError: showBudgetAmountFormatError,
      showBudgetLessThanZero: showBudgetLessThanZero,
      showFundingSourceEmptyError: showFundingSourceEmptyError,
      showACActivityError: showACActivityError
    });
    setShowErrorText({
      beginDateerrorText: beginDateerrorText,
      endDateerrorText: endDateerrorText
    });
    if (errorMessagesArray.length === 0) {
      seterrorMessages([]);
      return true;
    } else {
      return false;
    }
  };

  const addFundCode = () => {
    var updatedBeginDate = null;
    var updatedEndDate = null;
    var isValidateFundCode = validateFundCode(true);
    if (open && !validateFundSourceCode()) {
      return false;
    }
    if (selectedFundCodeRow) {
      if (isValidateFundCode) {
        setFundCodeError(false);
        let count = 0;
        for (var i = 0; i < newFundData.length; i++) {
          if (i !== newFundData.indexOf(selectedFundCodeRow)) {
            if (
              (newFundData[i].cos === values.cos ||
                (newFundData[i].cos === null &&
                  values.cos === DEFAULT_DD_VALUE)) &&
              newFundData[i].fundCode === values.fundCode ||
              (moment(fundCodeBeginDate).isBetween(newFundData[i].beginDate, newFundData[i].endDate) ||
                moment(newFundData[i].beginDate).isBetween(fundCodeBeginDate, fundCodeEndDate) ||
                moment(newFundData[i].endDate).isBetween(fundCodeBeginDate, fundCodeEndDate))

            ) {
              count++;
            }
            if (
              (newFundData[i].cos === values.cos ||
                (newFundData[i].cos === null &&
                  values.cos === DEFAULT_DD_VALUE)) &&
              newFundData[i].fundCode === values.fundCode &&
              ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) && (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)))
            ) {
              count++;
            }
            if (
              (!newFundData[i].voidIndicator) &&
              (newFundData[i].cos === values.cos ||
                (newFundData[i].cos === null &&
                  values.cos === DEFAULT_DD_VALUE)) &&
              newFundData[i].fundCode === values.fundCode && (
                (new Date(fundCodeBeginDate) <= new Date(newFundData[i].beginDate) && ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) && (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)) || new Date(fundCodeEndDate) > new Date(newFundData[i].endDate)))
              )
            ) {
              count++;
            }
          }
        }
        if (count > 0) {
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION
          );
          seterrorMessages(errorMessagesArray);
        }
        if (errorMessagesArray.length === 0) {
          setSourceErrors([]);
          setShowError(false);
          updatedBeginDate = setSelectedDate(new Date(fundCodeBeginDate));
          setFundCodeBeginDate(new Date(updatedBeginDate));
          updatedEndDate = setSelectedDate(new Date(fundCodeEndDate));
          setFundCodeEndDate(new Date(updatedEndDate));
          if (newDialogData.length > 0) {
            const tempNewFundData = newFundData;
            values.fundSourceVOList = newDialogData;
            values.beginDate = fundCodeBeginDate;
            values.endDate = fundCodeEndDate;
            values.adjustedAuthorizedAmount = values.budgetAmount;
            tempNewFundData[
              tempNewFundData.indexOf(selectedFundCodeRow)
            ] = values;
            setnewFundData(tempNewFundData);
            setSelectedFundCodeRow(null);
            ResetAddFundCodeForm();
            setshowResults(false);
            return true;
          }
        }
      }
    } else if (isValidateFundCode) {
      setFundCodeError(false);
      duplicateFundCodeValidation();
      if (errorMessagesArray.length === 0) {
        setSourceErrors([]);
        setShowError(false);
        updatedBeginDate = setSelectedDate(new Date(fundCodeBeginDate));
        setFundCodeBeginDate(new Date(updatedBeginDate));
        updatedEndDate = setSelectedDate(new Date(fundCodeEndDate));
        setFundCodeEndDate(new Date(updatedEndDate));
        if (newDialogData.length > 0) {
          values.fundSourceVOList = newDialogData;
          values.beginDate = fundCodeBeginDate;
          values.endDate = fundCodeEndDate;
          values.adjustedAuthorizedAmount = values.budgetAmount;
          values.id = generateUUID();
          let newTableData = [];
          newTableData = newFundData;
          newTableData.push(values);
          setnewFundData(newTableData);
          ResetAddFundCodeForm();
          setshowResults(false);
          return true;
        }
      }
    } else {
      setFundCodeError(true);
    }
    const errorArray = [];
    errorMessagesArray.map(var1 => {
      if (var1 === BudgetMaintenanceConstants.FUND_CODE_COVERAGE_ERROR) {
        errorArray.push(var1);
      }
      if (var1 === BudgetMaintenanceConstants.ADD_FUNDING_SOURCE_EMPTY_ERROR) {
        errorArray.push(var1);
      }
      if (var1 === BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION) {
        errorArray.push(var1);
      }
    });
    setSourceErrors(errorArray);
  };
  const [newDialogData, setnewDialogData] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [sourceErrors, setSourceErrors] = React.useState([]);
  const [addUpdateFundCode, setAddUpdateFundCode] = React.useState(false);

  const validateFundSourceCode = () => {
    errorMessagesArray = [];
    var beginDateerrorText = '';
    var endDateerrorText = '';
    var showBeginDateError;
    var showEndDateError;
    var showDateOverlappingError;
    var showFederalError;
    var showStateError;
    var showCountyError;
    var showOtherError;
    var showFederalFormat;
    var showStateFormat;
    var showCountyFormat;
    var showOtherFormat;
    var showTotalPercentageError = false;
    if (dataElement.federalPercentage) {
      if (
        dataElement.federalPercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showFederalFormat = true;
      } else {
        if (
          !(
            dataElement.federalPercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showFederalFormat = true;
        } else {
          showFederalFormat = false;
        }
      }
    }

    if (dataElement.statePercentage) {
      if (
        dataElement.statePercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showStateFormat = true;
      } else {
        if (
          !(
            dataElement.statePercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showStateFormat = true;
        } else {
          showStateFormat = false;
        }
      }
    }

    if (dataElement.countyPercentage) {
      if (
        dataElement.countyPercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showCountyFormat = true;
      } else {
        if (
          !(
            dataElement.countyPercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showCountyFormat = true;
        } else {
          showCountyFormat = false;
        }
      }
    }

    if (dataElement.otherPercentage) {
      if (
        dataElement.otherPercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showOtherFormat = true;
      } else {
        if (
          !(
            dataElement.otherPercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showOtherFormat = true;
        } else {
          showOtherFormat = false;
        }
      }
    }
    dataElement.totalPercentage =
      parseFloat(dataElement.federalPercentage) +
      parseFloat(dataElement.statePercentage) +
      parseFloat(dataElement.countyPercentage) +
      parseFloat(dataElement.otherPercentage);
    if (
      !dataElement.federalPercentage ||
      dataElement.federalPercentage === ''
    ) {
      dataElement.federalPercentage = '0.00';
    }
    if (!dataElement.statePercentage || dataElement.statePercentage === '') {
      dataElement.statePercentage = '0.00';
    }
    if (!dataElement.countyPercentage || dataElement.countyPercentage === '') {
      dataElement.countyPercentage = '0.00';
    }
    if (!dataElement.otherPercentage || dataElement.otherPercentage === '') {
      dataElement.otherPercentage = '0.00';
    }

    dataElement.totalPercentage =
      parseFloat(dataElement.federalPercentage) +
      parseFloat(dataElement.statePercentage) +
      parseFloat(dataElement.countyPercentage) +
      parseFloat(dataElement.otherPercentage);

    if (parseFloat(dataElement.totalPercentage).toFixed(2) !== parseFloat(100).toFixed(2)) {
      showTotalPercentageError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.TOTAL_PERCENTAGE_ERROR
      );
    }
    if (
      selectedStartDate &&
      selectedEndDate &&
      compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedStartDate)

    ) {
      showBeginDateError = true;
      beginDateerrorText = BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH
      );
    }
    if (!selectedStartDate || selectedStartDate === '') {
      showBeginDateError = true;
      beginDateerrorText = BudgetMaintenanceConstants.BEGINDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.BEGINDATE_ERROR);
    }
    if (selectedStartDate && selectedStartDate.toString() === 'Invalid Date') {
      showBeginDateError = true;
      beginDateerrorText =
        BudgetMaintenanceConstants.FSOURCE_BEGINDATE_INVALID_ERROR;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.FSOURCE_BEGINDATE_INVALID_ERROR
      );
    }
    if (selectedStartDate && selectedStartDate.toString() !== 'Invalid Date') {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(selectedStartDate);
      if (isDateValid) {
        showBeginDateError = true;
        beginDateerrorText = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    }

    if (!selectedEndDate || selectedEndDate === '') {
      showEndDateError = true;
      endDateerrorText = BudgetMaintenanceConstants.ENDDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.ENDDATE_ERROR);
    }
    if (selectedEndDate && selectedEndDate.toString() === 'Invalid Date') {
      showEndDateError = true;
      endDateerrorText =
        BudgetMaintenanceConstants.FSOURCE_ENDDATE_INVALID_ERROR;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.FSOURCE_ENDDATE_INVALID_ERROR
      );
    }
    if (selectedEndDate && selectedEndDate.toString() !== 'Invalid Date') {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(selectedEndDate);
      if (isDateValid) {
        showEndDateError = true;
        endDateerrorText = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    }
    // FSource Date validation
    if (!showBeginDateError && selectedStartDate.toString() !== 'Invalid Date') {
      if ((Date.parse(selectedStartDate) < Date.parse(fundCodeBeginDate)) || (Date.parse(selectedStartDate) < Date.parse(moment(new Date()).format('MM/DD/YYYY')))) {
        showBeginDateError = true;
        beginDateerrorText = BudgetMaintenanceConstants.FSOUCE_STARTDATE_GREATEROREQALTO_FCODE_STARTDATE;
        errorMessagesArray.push(BudgetMaintenanceConstants.FSOUCE_STARTDATE_GREATEROREQALTO_FCODE_STARTDATE);
      }
    }
    if (selectedEndDate && selectedEndDate.toString() !== 'Invalid Date') {
      if (
        !showEndDateError && compareTwoDatesGreaterThanOrEqual(fundCodeEndDate, selectedEndDate)
      ) {
        showEndDateError = true;
        endDateerrorText =
          BudgetMaintenanceConstants.FSOUCE_ENDDATE_LESSTHANOREQALTO_FCODE_ENDDATE;
        errorMessagesArray.push(
          BudgetMaintenanceConstants.FSOUCE_ENDDATE_LESSTHANOREQALTO_FCODE_ENDDATE
        );
      }
    }

    if (
      !showFederalFormat &&
      !showStateFormat &&
      !showCountyFormat &&
      !showOtherFormat &&
      !showEndDateError &&
      !showBeginDateError &&
      !showTotalPercentageError
    ) {
      // Overlapping Dates
      if (newDialogData.length > 0) {
        let ovelappingDate = false;
        const dateRanges = [];
        for (var i = 0; i < newDialogData.length; i++) {
          if (
            selectedFSourceRow &&
            newDialogData.indexOf(selectedFSourceRow) === i
          ) {
            continue;
          }
          dateRanges.push({
            start: new Date(newDialogData[i].beginDate),
            end: new Date(newDialogData[i].endDate)
          });
        }
        dateRanges.push({
          start: new Date(selectedStartDate),
          end: new Date(selectedEndDate)
        });
        ovelappingDate = overlapCheckWithDateRange(dateRanges).overlap;
        if (ovelappingDate) {
          showEndDateError = true;
          endDateerrorText =
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR;
          showBeginDateError = true;
          beginDateerrorText =
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR;
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR
          );
        }
      }
    }
    setShowError({
      showTotalPercentageError: showTotalPercentageError,
      showFSourceBeginDateError: showBeginDateError,
      showFSourceEndDateError: showEndDateError,
      showDateOverlappingError: showDateOverlappingError,
      showFederalError: showFederalError,
      showStateError: showStateError,
      showCountyError: showCountyError,
      showOtherError: showOtherError,
      showFederalFormat: showFederalFormat,
      showStateFormat: showStateFormat,
      showCountyFormat: showCountyFormat,
      showOtherFormat: showOtherFormat
    });
    setShowErrorText({
      beginDateerrorText: beginDateerrorText,
      endDateerrorText: endDateerrorText
    });
    seterrorMessages(errorMessagesArray);
    if (errorMessagesArray.length === 0) {
      seterrorMessages([]);
      return true;
    } else {
      return false;
    }
  };
  const saveFundingSourceData = () => {
    if (validateFundSourceCode()) {
      setShowError(false);
      var updatedBeginDate = setSelectedDate(new Date(selectedStartDate));
      setSelectedStartDate(new Date(updatedBeginDate));
      var updatedEndDate = setSelectedDate(new Date(selectedStartDate));
      setSelectedEndDate(new Date(updatedEndDate));

      if (selectedFSourceRow) {
        newDialogData[
          newDialogData.indexOf(selectedFSourceRow)
        ].beginDate = selectedStartDate;
        newDialogData[
          newDialogData.indexOf(selectedFSourceRow)
        ].endDate = selectedEndDate;
        newDialogData[
          newDialogData.indexOf(selectedFSourceRow)
        ].federalPercentage = dataElement.federalPercentage;
        newDialogData[
          newDialogData.indexOf(selectedFSourceRow)
        ].statePercentage = dataElement.statePercentage;
        newDialogData[
          newDialogData.indexOf(selectedFSourceRow)
        ].countyPercentage = dataElement.countyPercentage;
        newDialogData[
          newDialogData.indexOf(selectedFSourceRow)
        ].otherPercentage = dataElement.otherPercentage;
        newDialogData[
          newDialogData.indexOf(selectedFSourceRow)
        ].totalPercentage =
          +dataElement.federalPercentage +
          +dataElement.statePercentage +
          +dataElement.countyPercentage +
          +dataElement.otherPercentage;
      } else {
        let newTableArray = [];
        dataElement.beginDate = selectedStartDate;
        dataElement.endDate = selectedEndDate;
        dataElement.totalPercentage =
          +dataElement.federalPercentage +
          +dataElement.statePercentage +
          +dataElement.countyPercentage +
          +dataElement.otherPercentage;
        dataElement.id = generateUUID();
        newTableArray = newDialogData;
        newTableArray.push(dataElement);
        setnewDialogData(newTableArray);
      }
      setOpen(false);
      setSelectedFSourceRow(null);
    }
  };

  const resetFundingSourceData = () => {
    if (selectedFSourceRow) {
      setDataElement({
        selectedStartDate: selectedFSourceRow.beginDate,
        selectedEndDate: selectedFSourceRow.endDate,
        federalPercentage: selectedFSourceRow.federalPercentage,
        statePercentage: selectedFSourceRow.statePercentage,
        countyPercentage: selectedFSourceRow.countyPercentage,
        otherPercentage: selectedFSourceRow.otherPercentage,
        totalPercentage:
          +selectedFSourceRow.federalPercentage +
          +selectedFSourceRow.statePercentage +
          +selectedFSourceRow.countyPercentage +
          +selectedFSourceRow.otherPercentage
      });
      setSelectedStartDate(new Date(selectedFSourceRow.beginDate));
      setSelectedEndDate(new Date(selectedFSourceRow.endDate));
    } else {
      setDataElement({
        federalPercentage: '',
        statePercentage: '',
        countyPercentage: '',
        otherPercentage: '',
        totalPercentage: '',
        selectedStartDate: '',
        selectedEndDate: ''
      });
      setSelectedStartDate(null);
      setSelectedEndDate(new Date(endDateDefault));
    }

    setShowError(false);
    setShowErrorText('');
  };

  const validateBudgetData = () => {
    var showStateFiscalYearError = false;
    var showBudgetDescError = false;
    var showAgencyError = false;
    var showGLCompanyError = false;
    var showGLAccountingUnitError = false;
    var showGLAccountError = false;
    errorMessagesArray = [];
    if (!data.fiscalYear || data.fiscalYear === DEFAULT_DD_VALUE) {
      showStateFiscalYearError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_FISCAL_YEAR_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      !data.organizationCode ||
      data.organizationCode === DEFAULT_DD_VALUE
    ) {
      showGLAccountingUnitError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_GL_ACCOUNTING_UNIT_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }
    if (!data.objectCode || data.objectCode === DEFAULT_DD_VALUE) {
      showGLAccountError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_GL_ACCOUNT_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (!data.agencyCode || data.agencyCode === DEFAULT_DD_VALUE) {
      showAgencyError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_AGENCY_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (!data.glCompanyCode || data.glCompanyCode === DEFAULT_DD_VALUE) {
      showGLCompanyError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_GL_COMPANY_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (!data.budgetDesc || data.budgetDesc === ' ') {
      showBudgetDescError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_BUDGET_DESCRIPTION_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }

    /*     if (
      !data.accountCategoryCode ||
      data.accountCategoryCode === DEFAULT_DD_VALUE
    ) {
      showACAccountCategoryError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_AC_ACCOUNT_CATEGORY_ERROR
      );
      seterrorMessages(errorMessagesArray);
    } */
    setShowError({
      showStateFiscalYearError: showStateFiscalYearError,
      showBudgetDescError: showBudgetDescError,
      showAgencyError: showAgencyError,
       showGLCompanyError: showGLCompanyError,
      showGLAccountingUnitError: showGLAccountingUnitError,
      showGLAccountError: showGLAccountError
       // showACAccountCategoryError: showACAccountCategoryError
    });

    if (errorMessagesArray.length === 0) {
      seterrorMessages([]);
      return true;
    } else {
      return false;
    }
  };

  const handleClickOpen = rowData => {
    setSelectedFundCodeRow(null);
    setSourceErrors([]);
    if (validateBudgetData()) {
      if (rowData) {
        setSelectedFundCodeRow(rowData);
        setValues({ ...rowData });
        setnewDialogData(rowData.fundSourceVOList);
        setFundCodeBeginDate(rowData.beginDate);
        setFundCodeEndDate(rowData.endDate);
        setAddUpdateFundCode(true);
      } else {
        setValues({
          fundCode: DEFAULT_DD_VALUE,
          adjustedAuthorizedAmount: '0.00',
          beginDate: '',
          budgetAmount: '',
          endDate: '',
          expenditureAmount: '0.00',
          cos: DEFAULT_DD_VALUE,
          jobNumber: '-1',
          federal: '0.00',
          state: '0.00',
          county: '0.00',
          other: '0.00',
          inKindIndicator: 'N',
          fundSourceVOList: []
        });
        setFundCodeBeginDate(null);
        setFundCodeEndDate(new Date(endDateDefault));
        setAddUpdateFundCode(false);
        setnewDialogData([]);
      }
      setshowResults(true);
    }
  };

  const handleCloseAddFundcode = () => {
    seterrorMessages([]);
    setshowResults(false);
    handleCloseFundigSource();
  };
  const ResetAddFundCodeForm = () => {
    setSourceErrors([]);
    seterrorMessages([]);
    if (selectedFundCodeRow) {
      setValues({
        fundCode: selectedFundCodeRow.fundCode,
        adjustedAuthorizedAmount: selectedFundCodeRow.budgetAmount,
        beginDate: selectedFundCodeRow.beginDate,
        budgetAmount: selectedFundCodeRow.budgetAmount,
        endDate: selectedFundCodeRow.endDate,
        expenditureAmount: selectedFundCodeRow.expenditureAmount,
        cos:
          selectedFundCodeRow.cos !== null
            ? selectedFundCodeRow.cos
            : DEFAULT_DD_VALUE,
        jobNumber: selectedFundCodeRow.jobNumber || '-1',
        inKindIndicator: selectedFundCodeRow.inKindIndicator,
        fundSourceVOList: selectedFundCodeRow.fundSourceVOList
      });
      setnewDialogData(selectedFundCodeRow.fundSourceVOList || []);
      setFundCodeBeginDate(new Date(selectedFundCodeRow.beginDate));
      setFundCodeEndDate(new Date(selectedFundCodeRow.endDate));
      setShowError(false);
      setShowErrorText('');
    } else {
      setValues({
        fundCode: DEFAULT_DD_VALUE,
        adjustedAuthorizedAmount: '0.00',
        beginDate: '',
        budgetAmount: '',
        endDate: '',
        expenditureAmount: '0.00',
        cos: DEFAULT_DD_VALUE,
        jobNumber: '-1',
        federal: '0.00',
        state: '0.00',
        county: '0.00',
        other: '0.00',
        inKindIndicator: 'N',
        fundSourceVOList: []
      });
      setnewDialogData([]);
      setFundCodeBeginDate(null);
      setFundCodeEndDate(new Date(endDateDefault));
      setShowError(false);
      setShowErrorText('');
    }
  };
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeDataElement = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setDataElement({ ...dataElement, [name]: event.target.value });
  };

  const formatDecimalForBudget = () => {
    if (values.budgetAmount !== '') {
      setValues({
        ...values,
        budgetAmount: numeral(values.budgetAmount)
          .format('0,0.00')
      });
    }
  };
  const removeFormatDecimalForBudget = () => {
    if (values.budgetAmount !== '') {
      setValues({
        ...values,
        budgetAmount: numeral(values.budgetAmount)
          .format('0.00')
      });
    }
  };

  const handleChangeData = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
  };
  const handleDecimalValues = name => event => {
    if (name === 'budgetAmount') {
      setFocusCheck(true);
        setAllowNavigation(true);
    if (event.target.value && event.target.value.includes('.')) {
      if (checkingDecimal(event.target.value)) {
        setValues({ ...values, [name]: event.target.value });
      }
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  } else {
    setValues({ ...values, [name]: event.target.value });
  }
  };

  const addBudgetData = () => {
    if (validateBudgetData()) {
      if (open && !validateFundSourceCode()) {
        return false;
      }
      const saveObject = {
        ...data,
        lob: AppConstants.DEFAULT_LOB_VALUE
      };
      saveObject.fundCodeVOList = [...newFundData];
      newFundData.map(value => {
        if (value.cos === DEFAULT_DD_VALUE) {
          value.cos = null;
        }
        value.lob = data.lob;
        value.beginDate = getDateInMMDDYYYYFormatWithApendZero(value.beginDate); // Moment(value.beginDate).format('MM/DD/YYYY');
        value.endDate = getDateInMMDDYYYYFormatWithApendZero(value.endDate); // Moment(value.endDate).format('MM/DD/YYYY');
        if (value.fundSourceVOList && value.fundSourceVOList.length > 0) {
          value.fundSourceVOList.map(data => {
            data.beginDate = getDateInMMDDYYYYFormatWithApendZero(
              data.beginDate
            );
            data.endDate = getDateInMMDDYYYYFormatWithApendZero(data.endDate); // Moment(value.endDate).format('MM/DD/YYYY');
          });
        }
      });
      if (isFundCodeError) {
        validateFundCode(true);
      }
      if (errorMessagesArray.length === 0) {
        seterrorMessages([]);
        const finalSaveObj = formatRequest(saveObject);

        budgetMaintenanceAddSave(finalSaveObj);
      }
    }
  };

  const formatRequest = saveObject => {
    Object.keys(saveObject).forEach(key => {
      if (saveObject[key] === DEFAULT_DD_VALUE) {
        saveObject[key] = null;
      }
    });
    saveObject = { ...saveObject, noteSetVO: notesInput };
    return saveObject;
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, newFundData.length - page * rowsPerPage);
  function fSourceRowDeleteClick () {
    let temNewDialogData = [...newDialogData];
    for (let i = 0; i < rowFSourceData.rowFSourceData.length; i++) {
      temNewDialogData = temNewDialogData.filter(
        fSource => fSource.id !== rowFSourceData.rowFSourceData[i]
      );
    }
    setnewDialogData(temNewDialogData);
    setrowFSourceData([{}]);
  }
  function fCodeRowDeleteClick () {
    let temNewDialogData = [...newFundData];
    for (let i = 0; i < rowFCodeeData.rowFCodeeData.length; i++) {
      temNewDialogData = temNewDialogData.filter(
        fCode => fCode.id !== rowFCodeeData.rowFCodeeData[i]
      );
    }
    setnewFundData(temNewDialogData);
    setrowFCodeData([{}]);
  }
  const rowDeleteFSource = data => {
    setrowFSourceData({ ...rowFSourceData, rowFSourceData: data });
  };
  const rowDeleteFCode = data => {
    setrowFCodeData({ ...rowFCodeeData, rowFCodeeData: data });
  };
  const editFundingSource = row => () => {
    setAllowNavigation(true);
    setSelectedFSourceRow(row);
    if (row) {
      setOpen(true);
      setDataElement({
        selectedStartDate: row.beginDate,
        selectedEndDate: row.endDate,
        federalPercentage: row.federalPercentage,
        statePercentage: row.statePercentage,
        countyPercentage: row.countyPercentage,
        otherPercentage: row.otherPercentage,
        totalPercentage:
          +row.federalPercentage +
          +row.statePercentage +
          +row.countyPercentage +
          +row.otherPercentage
      });
      setSelectedStartDate(new Date(row.beginDate));
      setSelectedEndDate(new Date(row.endDate));
    }
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);

    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    let flag = false;
    if (!data.fiscalYear || data.fiscalYear === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (
      !data.organizationCode ||
      data.organizationCode === DEFAULT_DD_VALUE
    ) {
      flag = flag || true;
    }
    if (!data.objectCode || data.objectCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!data.agencyCode || data.agencyCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!data.glCompanyCode || data.glCompanyCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!data.budgetDesc || data.budgetDesc === '') {
      flag = flag || true;
    }

    if (
      !data.accountCategoryCode ||
      data.accountCategoryCode === DEFAULT_DD_VALUE
    ) {
      flag = flag || true;
    }
    return flag;
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [data]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessage([]);
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText('');
  };

  const handleCloseFundigSource = () => {
    setOpen(false);
    seterrorMessages([]);
    setSelectedFSourceRow(null);
    resetFundingSourceData();
  };

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/FinancialBudgetSearch'
      });
    }, 100);
  };

  const majorCancel = () => {
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
      pathname: '/FinancialBudgetSearch'
          });
        }
      };

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      <div className="tabs-container" ref={toPrintRef}>

        {errorMessages.length !== 0 ? (<ErrorMessages errorMessages={errorMessages}/>) : null}
        {successMessage.length > 0 && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={successMessage} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Add Budget Data</h1>
          <div className="float-right mt-2">
            <Button className="btn btn-success ml-1" onClick={addBudgetData}>
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>

            {window.location.pathname === '/BudgetDataAdd' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div>
          <div className="tab-body">
            <form autoComplete="off">
              <div className="form-wrapper">
                <NativeDropDown
                  id="state-fiscal-year"
                    label="State Fiscal Year"
                  isRequired
                    value={data.fiscalYear}
                    onChange={handleChangeMain('fiscalYear')}
                  errTxt={
                      showStateFiscalYearError
                        ? BudgetMaintenanceConstants.ADD_FISCAL_YEAR_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 4 }}
                  dropdownList={fiscalYearData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />
                <div className="mui-custom-form override-width-45">
                  <TextField
                    id="budget-description"
                    fullWidth
                    label="Budget Description"
                    required
                    rows="1"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 60 }}
                    value={data.budgetDesc}
                    onChange={handleChangeMain('budgetDesc')}
                    helperText={
                      showBudgetDescError
                        ? BudgetMaintenanceConstants.ADD_BUDGET_DESCRIPTION_ERROR
                        : null
                    }
                    error={
                      showBudgetDescError
                        ? BudgetMaintenanceConstants.ADD_BUDGET_DESCRIPTION_ERROR
                        : null
                    }
                  />
                </div>
              </div>
              <div className="form-wrapper">
                <NativeDropDown
                  id="agency"
                    label="Agency"
                  isRequired
                    value={data.agencyCode}
                    onChange={handleChangeMain('agencyCode')}
                  errTxt={
                      showAgencyError
                        ? BudgetMaintenanceConstants.ADD_AGENCY_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 3 }}
                  dropdownList={agencyData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />
                <NativeDropDown
                  isRequired
                  id="gl-company"
                    label="GL Company"
                    value={data.glCompanyCode}
                    onChange={handleChangeMain('glCompanyCode')}
                  errTxt={
                      showGLCompanyError
                        ? BudgetMaintenanceConstants.ADD_GL_COMPANY_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 4 }}
                  dropdownList={glCompanyData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    ))}
                />
                <NativeDropDown
                  id="gl-accounting-unit"
                    label="GL Accounting Unit"
                  isRequired
                    value={data.organizationCode}
                    onChange={handleChangeMain('organizationCode')}
                  errTxt={
                      showGLAccountingUnitError
                        ? BudgetMaintenanceConstants.ADD_GL_ACCOUNTING_UNIT_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 8 }}
                  dropdownList={unitData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />
                <NativeDropDown
                  id="gl-account"
                    label="GL Account"
                  isRequired
                    value={data.objectCode}
                    onChange={handleChangeMain('objectCode')}
                  errTxt={
                      showGLAccountError
                        ? BudgetMaintenanceConstants.ADD_GL_ACCOUNT_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 6 }}
                  dropdownList={glAccountData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    ))}
                />
              </div>
              <div className="form-wrapper">
                <NativeDropDown
                  id="gl-sub-account"
                    label="GL Sub-Account"
                    value={data.subObjectCode}
                    onChange={handleChangeMain('subObjectCode')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 4 }}
                  dropdownList={glSubAccountData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    ))}
                />
                <NativeDropDown
                  id="ac-account-category"
                  label="AC Account Category"
                    value={data.accountCategoryCode}
                  onChange={handleChangeMain('accountCategoryCode')}
                    inputProps={{ maxLength: 4 }}
                  dropdownList={acAccountCategoryData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    ))}
                />
              </div>
            </form>
            <div className="tab-panelbody">
              <div className="tab-holder mb-3 mt-4 p-0">
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Associated Fund Codes/COS" />
                    <Tab label="Notes" />
                  </Tabs>
                </AppBar>
                <TabPanel
                  value={value}
                  index={0}
                >
                  <div className="tab-holder m-0">
                    <div className="float-right my-3">
                      <Button
                        className="btn-text-main-delete btn-transparent  ml-1"
                        title="Delete"
                        onClick={fCodeRowDeleteClick}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </Button>
                      <Button
                        className="btn-text-main-add btn-success ml-1"
                        onClick={() => {
                          handleClickOpen(null);
                        }}
                        title={'Add Fund Code'}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        Add
                      </Button>
                    </div>

                    <div className="clearfix"></div>
                    <Paper className={classes.paper} style={{ width: '100%' }}>
                      <div className="table-p-5 ">
                        <Table
                          className={clsx(
                            classes.table,
                            'customDataTable',
                            'with-link'
                          )}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={newFundData.length}
                          />
                          <TableBody>
                            {stableSort(newFundData, getSorting(order, orderBy))
                              .slice(
                                ...([
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  ])
                              )
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          'aria-labelledby': labelId
                                        }}
                                        onClick={event => {
                                          handleClick(event, row.id);
                                        }}
                                      />
                                    </TableCell>

                                    {row.id ? (
                                      <TableCell
                                        component="th"
                                        id={labelId}
                                        hidden="true"
                                        scope="row"
                                        padding="default"
                                      >
                                        {row.id}
                                      </TableCell>
                                    ) : null}
                                    <TableCell
                                      onClick={openEditModal(row)}
                                      className="has-link"
                                    >
                                      <a title={getLongDescription(fundCodeData, row.fundCode)}>{row.fundCode}</a>
                                    </TableCell>
                                    <TableCell onClick={openEditModal(row)} >
                                      <span title={getLongDescription(cosData, row.cos)} >{row.cos === DEFAULT_DD_VALUE
                                        ? ''
                                        : row.cos}</span>
                                    </TableCell>
                                    <TableCell onClick={openEditModal(row)}>
                                      <div>
                                        {' '}
                                        <Moment
                                          format="MM/DD/YYYY"
                                          date={row.beginDate}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell onClick={openEditModal(row)}>
                                      <div>
                                        {' '}
                                        <Moment
                                          format="MM/DD/YYYY"
                                          date={row.endDate}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell onClick={openEditModal(row)}>
                                      {numeral(row.budgetAmount).format(
                                        '$0,0.00'
                                      )}
                                    </TableCell>
                                    <TableCell onClick={openEditModal(row)}>
                                      {numeral(
                                        row.adjustedAuthorizedAmount
                                      ).format('$0,0.00')}
                                    </TableCell>
                                    <TableCell onClick={openEditModal(row)}>
                                      {row.expenditureAmount}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            {emptyRows > 0 && (
                              <TableRow>
                                <TableCell colSpan={7} />
                              </TableRow>
                            )}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell className="table-pagination">
                                Page: <span>{page + 1}</span>
                              </TableCell>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={7}
                                count={newFundData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: { 'aria-label': 'rows per page' },
                                  native: true
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </div>
                    </Paper>

                    {showResults ? (
                      <div className="tabs-container" hidden={!showResults}>
                        <div className="tab-header mini-tab-header">
                          <div className="tab-heading float-left">
                            {addUpdateFundCode
                              ? 'Update Fund Code'
                              : 'Add Fund Code'}
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              className="btn btn-success ml-1"
                              onClick={addFundCode}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {addUpdateFundCode ? 'Update' : 'Add'}
                            </Button>
                            <Button
                              className="bt-reset btn-transparent ml-1"
                              onClick={ResetAddFundCodeForm}
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>

                              Reset
                            </Button>
                            <Button
                              variant="outlined" color="primary" className='btn btn-primary btn btn-outlined ml-1'
                              onClick={handleCloseAddFundcode}
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        <div>
                          <form noValidate autoComplete="off">
                            <div className="form-wrapper">
                              <NativeDropDown
                                id="fund-code"
                                  label="Fund Code"
                                isRequired
                                  value={values.fundCode}
                                  onChange={handleChangeData('fundCode')}
                                errTxt={
                                    showFundCodeError
                                      ? BudgetMaintenanceConstants.ADD_FUND_CODE_ERROR
                                      : null
                                  }
                                  inputProps={{ maxLength: 5 }}
                                  SelectProps={{}}
                                  title={getLongDescription(fundCodeData, values.fundCode)}
                                dropdownList={fundCodeData.map(option => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                  </option>
                                  ))}
                              />
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="fundCodeBudgetAmount"
                                  fullWidth
                                  name="budgetAmount"
                                  label="Budget Amount"
                                  required
                                  inputProps={{ maxLength: 12 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    )
                                  }}
                                  onInput={e => {
                                    e.target.value = e.target.value.replace(
                                      /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                                      ''
                                    );
                                  }}
                                  value={values.budgetAmount}
                                  onChange={handleDecimalValues('budgetAmount')}
                                  onBlur={formatDecimalForBudget}
                                  onFocus={removeFormatDecimalForBudget}
                                  helperText={
                                    showBudgetAmountError
                                      ? BudgetMaintenanceConstants.ADD_BUDGET_AMOUNT_ERROR
                                      : showBudgetAmountFormatError
                                        ? BudgetMaintenanceConstants.BUDGET_AMOUNT_FORMAT_ERROR
                                        : showBudgetLessThanZero
                                          ? BudgetMaintenanceConstants.BUDGET_LESS
                                          : null
                                  }
                                  error={
                                    showBudgetAmountError
                                      ? BudgetMaintenanceConstants.ADD_BUDGET_AMOUNT_ERROR
                                      : showBudgetAmountFormatError
                                        ? BudgetMaintenanceConstants.BUDGET_AMOUNT_FORMAT_ERROR
                                        : showBudgetLessThanZero
                                          ? BudgetMaintenanceConstants.BUDGET_LESS
                                          : null
                                  }
                                />
                              </div>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-date with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      '31 Dec 9999 00:00:00 GMT'
                                    )}
                                    required
                                    id="addFundingBeginDate"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    placeholder="mm/dd/yyyy"
                                    value={fundCodeBeginDate}
                                    onChange={handleFundCodeBeginDate}
                                    helperText={
                                      showBeginDateError
                                        ? beginDateerrorText
                                        : null
                                    }
                                    error={
                                      showBeginDateError
                                        ? beginDateerrorText
                                        : null
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-date with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      '31 Dec 9999 00:00:00 GMT'
                                    )}
                                    required
                                    id="endDate"
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    placeholder="mm/dd/yyyy"
                                    value={fundCodeEndDate}
                                    onChange={handleFundCodeEndDate}
                                    helperText={
                                      showEndDateError ? endDateerrorText : null
                                    }
                                    error={
                                      showEndDateError ? endDateerrorText : null
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                            </div>
                            <div className="form-wrapper">
                              <NativeDropDown
                                id="fundCodeCos"
                                  label="COS"
                                  value={values.cos}
                                  onChange={handleChangeData('cos')}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  SelectProps={{}}
                                  title={getLongDescription(cosData, values.cos)}
                                dropdownList={cosData.map((item, index) => (
                                  <option key={index} value={item.code}>
                                      {item.description}
                                  </option>
                                  ))}
                              />
                              <NativeDropDown
                                id="fundCodeACActivity"
                                  label="AC Activity"
                                  value={values.jobNumber || '-1'}
                                  onChange={handleChangeData('jobNumber')}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  SelectProps={{}}
                                dropdownList={acActivityData.map(option => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                  </option>
                                  ))}
                              />
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="fundCodeExpenditure"
                                  fullWidth
                                  name="expenditure"
                                  label="Expenditure"
                                  type="number"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.expenditureAmount}
                                  onChange={handleChangeData(
                                    'expenditureAmount'
                                  )}
                                />
                              </div>

                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="fundCodeADJ"
                                  fullWidth
                                  name="adjustedAuthorized"
                                  label="Adjusted Authorized"
                                  type="number"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.adjustedAuthorizedAmount}
                                  onChange={handleChangeData(
                                    'adjustedAuthorizedAmount'
                                  )}
                                />
                              </div>
                            </div>
                            <div className="form-wrapper">
                              {/*  <div className="mui-custom-form input-sm">
                                <TextField
                                  id="Federal"
                                  fullWidth
                                  name="Federal"
                                  label="Federal"
                                  type="number"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.federal}
                                  onChange={handleChangeData('federal')}
                                />
                              </div>
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="State"
                                  fullWidth
                                  name="State"
                                  label="State"
                                  type="number"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.state}
                                  onChange={handleChangeData('state')}
                                />
                              </div>
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="County"
                                  fullWidth
                                  name="County"
                                  label="County"
                                  type="number"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.county}
                                  onChange={handleChangeData('county')}
                                />
                              </div>
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="other"
                                  fullWidth
                                  name="other"
                                  label="Other"
                                  type="number"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.other}
                                  onChange={handleChangeData('other')}
                                />
                              </div> */}
                              <div
                                className="mui-custom-form"
                                style={{ lineHeight: '100%' }}
                              >
                                <label
                                  className="MuiFormLabel-root small-label"
                                  htmlFor="inKind"
                                >
                                  In-Kind Indicator
                                </label>
                                <input id="inKind" hidden aria-hidden="true" />
                                <div className="sub-radio">
                                  <Radio
                                    value="Y"
                                    id="YesEOMB"
                                    name="eombType"
                                    checked={
                                      values.inKindIndicator === 'Yes' ||
                                      values.inKindIndicator === 'Y'
                                    }
                                    onChange={handleChangeData(
                                      'inKindIndicator'
                                    )}
                                    style={{ marginLeft: '0px' }}
                                  />
                                  <label
                                    className="text-black"
                                    htmlFor="YesEOMB"
                                    style={{ fontSize: '14px' }}
                                  >
                                    Yes{' '}
                                  </label>
                                  <Radio
                                    defaultChecked="true"
                                    value="N"
                                    className="ml-2"
                                    id="NoEOMB"
                                    name="eombType"
                                    checked={
                                      values.inKindIndicator === 'No' ||
                                      values.inKindIndicator === 'N'
                                    }
                                    onChange={handleChangeData(
                                      'inKindIndicator'
                                    )}
                                  />
                                  <label
                                    className="text-black"
                                    htmlFor="NoEOMB"
                                    style={{ fontSize: '14px' }}
                                  >
                                    No{' '}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </form>

                          <div className="mui-custom-form w-100">
                            <div className="float-right mr-4">
                              <Button
                                className="btn-text-main-delete btn-transparent ml-1"
                                onClick={fSourceRowDeleteClick}
                                title={'Delete'}
                              >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                                Delete
                              </Button>
                              <Button
                                className="btn-text-main-add btn-success ml-1"
                                onClick={handleFundingSourceOpen}
                                title={'Add Funding Source'}
                              >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                Add
                              </Button>
                            </div>
                            <div className="clearfix"></div>

                          </div>
                          {value === 0 &&
                            <div className="p-0 mt-3 tab-holder">
                              <BudgetFundingSourceTable
                                tableData={newDialogData}
                                onRowClick={editFundingSource}
                                rowDeleteFSource={rowDeleteFSource}
                              />
                              {
                                open

                                  ? <div className="tabs-container" id='form_pop_up'>
                                    <div className="tab-header  mini-tab-header">
                                      <div className="tab-heading float-left">
                                        {selectedFSourceRow
                                          ? 'Edit Funding Source'
                                          : 'Add Funding Source'}
                                      </div>
                                      <div className="float-right mt-1">
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          className="btn btn-success ml-1"
                                          onClick={saveFundingSourceData}
                                        >
                                          <i className="fa fa-check" aria-hidden="true"></i>
                                          {selectedFSourceRow ? 'Update' : 'Add'}
                                        </Button>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          className="bt-reset btn-transparent ml-1"
                                          onClick={resetFundingSourceData}
                                        >
                                          <i className="fa fa-undo" aria-hidden="true"></i>

                                  Reset
                                        </Button>
                                        <Button
                                          variant="outlined" color="primary" className='btn btn-primary btn btn-outlined ml-1'
                                          onClick={handleCloseFundigSource}
                                          title={'Cancel'}
                                        >
                                  Cancel
                                        </Button>
                                      </div>
                                    </div>
                                    <form noValidate autoComplete="off">
                                      <div className="form-wrapper">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <div className="mui-custom-form input-date with-date">
                                            <KeyboardDatePicker
                                              maxDate={Date.parse(
                                                '31 Dec 9999 00:00:00 GMT'
                                              )}
                                              required
                                              id="selectedStartDate"
                                              label="Begin Date"
                                              format="MM/dd/yyyy"
                                              InputLabelProps={{
                                                shrink: true
                                              }}
                                              placeholder="mm/dd/yyyy"
                                              value={selectedStartDate}
                                              onChange={handleStartDateChange}
                                              helperText={
                                                showFSourceBeginDateError
                                                  ? beginDateerrorText
                                                  : null
                                              }
                                              error={
                                                showFSourceBeginDateError
                                                  ? beginDateerrorText
                                                  : null
                                              }
                                              KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                              }}
                                            />
                                          </div>
                                        </MuiPickersUtilsProvider>

                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <div className="mui-custom-form input-date with-date ">
                                            <KeyboardDatePicker
                                              maxDate={Date.parse(
                                                '31 Dec 9999 00:00:00 GMT'
                                              )}
                                              required
                                              id="selectedEndDate"
                                              label="End Date"
                                              format="MM/dd/yyyy"
                                              InputLabelProps={{
                                                shrink: true
                                              }}
                                              placeholder="mm/dd/yyyy"
                                              value={selectedEndDate}
                                              onChange={handleEndDateChange}
                                              helperText={
                                                showFSourceEndDateError
                                                  ? endDateerrorText
                                                  : null
                                              }
                                              error={
                                                showFSourceEndDateError
                                                  ? endDateerrorText
                                                  : null
                                              }
                                              KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                              }}
                                            />
                                          </div>
                                        </MuiPickersUtilsProvider>
                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="federal"
                                            fullWidth
                                            label="Federal"
                                            required
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                              %
                                                </InputAdornment>
                                              )
                                            }}
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            value={dataElement.federalPercentage}
                                            onChange={handleChangeDataElement(
                                              'federalPercentage'
                                            )}
                                            onBlur={handleBlurPEr('federalPercentage')}
                                            helperText={
                                              showFederalError
                                                ? BudgetMaintenanceConstants.ADD_FEDERAL_ERROR
                                                : showFederalFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showFederalError
                                                ? BudgetMaintenanceConstants.ADD_FEDERAL_ERROR
                                                : showFederalFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>
                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="state"
                                            fullWidth
                                            label="State"
                                            required
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                              %
                                                </InputAdornment>
                                              )
                                            }}
                                            value={dataElement.statePercentage}
                                            onChange={handleChangeDataElement(
                                              'statePercentage'
                                            )}
                                            onBlur={handleBlurPEr('statePercentage')}
                                            helperText={
                                              showStateError
                                                ? BudgetMaintenanceConstants.ADD_STATE_ERROR
                                                : showStateFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showStateError
                                                ? BudgetMaintenanceConstants.ADD_STATE_ERROR
                                                : showStateFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>

                                      </div>

                                      <div className="form-wrapper">

                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="county"
                                            fullWidth
                                            label="County"
                                            required
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                              %
                                                </InputAdornment>
                                              )
                                            }}
                                            value={dataElement.countyPercentage}
                                            onChange={handleChangeDataElement(
                                              'countyPercentage'
                                            )}
                                            onBlur={handleBlurPEr('countyPercentage')}
                                            helperText={
                                              showCountyError
                                                ? BudgetMaintenanceConstants.ADD_COUNTY_ERROR
                                                : showCountyFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showCountyError
                                                ? BudgetMaintenanceConstants.ADD_COUNTY_ERROR
                                                : showCountyFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>

                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="sourceOther"
                                            fullWidth
                                            label="Other"
                                            required
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                              %
                                                </InputAdornment>
                                              )
                                            }}
                                            value={dataElement.otherPercentage}
                                            onChange={handleChangeDataElement(
                                              'otherPercentage'
                                            )}
                                            onBlur={handleBlurPEr('otherPercentage')}
                                            helperText={
                                              showOtherError
                                                ? BudgetMaintenanceConstants.ADD_OTHER_ERROR
                                                : showOtherFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showOtherError
                                                ? BudgetMaintenanceConstants.ADD_OTHER_ERROR
                                                : showOtherFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>
                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="total"
                                            fullWidth
                                            label="Total"
                                            required
                                            type="number"
                                            inputProps={{ maxLength: 8 }}
                                            disabled
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                              %
                                                </InputAdornment>
                                              )
                                            }}
                                            onInput={e => {
                                              e.target.value = Math.max(
                                                0,
                                                parseFloat(e.target.value)
                                              )
                                                .toString()
                                                .slice(0, 7);
                                            }}
                                            value={parseFloat(dataElement.totalPercentage).toFixed(2)}
                                            onChange={handleChangeDataElement(
                                              'totalPercentage'
                                            )}
                                            onBlur={handleBlurPEr('totalPercentage')}
                                            helperText={
                                              showTotalPercentageError
                                                ? BudgetMaintenanceConstants.TOTAL_PERCENTAGE_ERROR
                                                : null
                                            }
                                            error={
                                              showTotalPercentageError
                                                ? BudgetMaintenanceConstants.TOTAL_PERCENTAGE_ERROR
                                                : null
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="form-wrapper">

                                      </div>
                                    </form>
                                  </div> : null
                              }
                            </div>}

                        </div>
                      </div>
                    ) : null}
                  </div>
                </TabPanel>

                <TabPanel value={value}
                  index={1}>
                  {value === 1 && <div className="tab-holder my-3 p-0">
                    <Notes
                      disableNotes={disableNotes}
                      addNotes={addNotes}
                      setFocusCheck={setFocusCheck}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      setErrorMessages={seterrorMessages}
                    />
                  </div>}
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
        <Footer print />
        <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
      </div>
    </div>
  );
}
export default withRouter(BudgetMaintenanceAdd);
