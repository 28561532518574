/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FCN_REQUIRED = 'FCN is required.';
export const LOB_REQUIRED = 'LOB is required.';
export const PAYEE_REQUIRED = 'Payee ID is required.';
export const FCN_TEXT = 'FCN';
export const PAYEE_TEXT = 'Payee';
export const NO_RECODRS_FOUND = 'No records found for the search criteria entered.';
export const FCN_INVALID = 'Please enter the FCN in correct format.';
export const PAYEEID_ERROR = 'Payee Type and Payee ID Type Code are required when Payee ID is entered.';
export const ESTABLISHED_BEGIN_DATE = 'Established From Date must be less than or equal to Established Thru Date.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const FROM_DATE_INVALID = 'Established From Date entered is invalid.';
export const THRU_DATE_INVALID = 'Established Thru Date entered is invalid.';
export const PAYEE_TYPE_REQUIRED = 'Payee Type is required.';
export const PAYEE_ID_TYPE_REQUIRED = 'Payee ID Type Code is required.';
export const DATE_INCORRECT_FORMAT = 'Please enter the data in correct format.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';
