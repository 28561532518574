/**

* � [2020] Conduent, Inc. All rights reserved. Conduent�and Conduent Agile

* Star�are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import {
  search1099CorrectionSearchAction,
  resetState,
  getCorrectionDetailList,
  modifyStartWithOrContains
} from '../../Store/Actions/CorrectionSearchActions';
import CorrectionSearchResults from '../Search/CorrectionSearchResults';
import * as ErrorConstants from '../ErrorConstants';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';

import CorrectionDetails from './CorrectionDetails';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import Footer from '../../../../SharedModules/Layout/footer';
import { removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import Radio from '@material-ui/core/Radio';

function CorrectionSearch(props) {
	// SET INTIAL VALUES OF INPUT FIELDS
	const [values, setValues] = React.useState({
		providerMedicaidId: '',
		providerTaxID: '',
		providerName: '',
		ssn: '',
		providerNameSelected:''
	  });
  const [parentTableRow, setParentTableRow] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [
    correctionDetailTableData,
    setShowCorrectionDetailTableData
  ] = useState([]);
  const [
    showCorrectionDetailTable,
    setShowCorrectionDetailTable
  ] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [searchTable, setSearchTable] = useState([]);
  const [systemSuccessMessages, setSystemSuccesMessages] = useState([]);
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  let errorMessagesArray = [];
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const resetSearchState = () => dispatch(resetState());
  let tableData = useSelector(
    state => state.correctionSearchState?.search1099CorrectionData
  );
  const [{showProviderNameError, showProviderNameErrorText}, setShowError] = useState(false);

  const onRowclickAPICall = row => dispatch(getCorrectionDetailList(row));
  let onCorrectionDetailData = useSelector(
    state => state.correctionSearchState?.correctionDeatilListData
  );
  const noteSetResponse = useSelector(
    state => state.correctionSearchState?.noteSet
  );
  const [noteSetDetails, setNoteSetDetails] = useState([]);

  
 
  // FETCH TABLE DATA
  useEffect(() => {
    if (tableData) {
      if (!tableData.systemFailure && tableData.respcode && tableData.respcode === '02') {
        errorMessagesArray.push(tableData.respdesc);

        setErrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (tableData.systemFailure) {
        errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
        setErrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if ((
        tableData === null ||
        tableData.length === 0) && !tableData.systemFailure
      ) {
        errorMessagesArray.push(ErrorConstants.NO_RECORDS_FOUND);
        setErrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (tableData !== null && !tableData.systemFailure) {
        setSearchTable(tableData);
        setShowTable(true);
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (onCorrectionDetailData) {
      if (
        !onCorrectionDetailData.systemFailure &&
        onCorrectionDetailData.respcode &&
        onCorrectionDetailData.respcode === '02'
      ) {
        errorMessagesArray.push(onCorrectionDetailData.respdesc);
        setErrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (
        onCorrectionDetailData.systemFailure
      ) {
        errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
        setErrorMessages(errorMessagesArray);
        setShowCorrectionDetailTable(false);
      } else if (
        onCorrectionDetailData !== null &&
        !onCorrectionDetailData.systemFailure
      ) {
        setShowCorrectionDetailTableData(onCorrectionDetailData);
        setShowCorrectionDetailTable(true);
      }
    }
  }, [onCorrectionDetailData]);

  useEffect(() => {
    if (noteSetResponse) {
      if (noteSetResponse.respcode && noteSetResponse.respcode === '02') {
        setShowCorrectionDetailTable(false);
        errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
        setErrorMessages(errorMessagesArray);
      } else if (noteSetResponse.systemFailure || noteSetResponse.message) {
        setShowCorrectionDetailTable(false);
        errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
        setErrorMessages(errorMessagesArray);
      } else if (
        noteSetResponse.searchResults !== null &&
        !noteSetResponse.systemFailure
      ) {
        setNoteSetDetails(noteSetResponse.searchResults);
      }
    }
  }, [noteSetResponse]);

  // PERFORM VALIDATIONS AND DISPLAY SEARCH RESULTS ON CLICK OF SEARCH BUTTON
  const displayCorrectionSearchResults = () => {
    errorMessagesArray = [];
    setShowCorrectionDetailTable(false);
    setSystemSuccesMessages([]);
    setErrorMessages([]);
    setShowError([]);
    var showProviderNameError = false;
    var showProviderNameErrorText = false;
    const searchCriteria = {
      providerID: values.providerMedicaidId || null,
      providerTaxID: values.providerTaxID || null,
      providerName: values.providerName || null,
      providerNameStartsWithOrContains: modifyStartWithOrContains(values.providerNameSelected),
      ssn: values.ssn || null
    };
    if (values.providerTaxID == '' && values.providerNameSelected == undefined && values.providerName == '' && values.providerMedicaidId == '' && values.ssn == '') {
      showProviderNameError = true;
      errorMessagesArray.push(ErrorConstants.DATA_REQUIRED);
      setErrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (values.providerNameSelected && (!values.providerName || values.providerName.trim()?.length < 2)) {
      showProviderNameErrorText = true;
      errorMessagesArray.push(ErrorConstants.MIN_CHAR_ERROR);
      setErrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (errorMessagesArray.length <= 0) {
      setErrorMessages([]);
      setShowError([]);
      dispatch(search1099CorrectionSearchAction(removeLeadingTrailingSpaces(searchCriteria)));
    }
    setShowError({
      showProviderNameError: showProviderNameError,
      showProviderNameErrorText: showProviderNameErrorText
    });
  };

  // HANDLE CHANGE IN ANY INPUT FIELD
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  // RESET TABLE DATA
  useEffect(() => {
    tableData = [];
    onCorrectionDetailData = null;
    resetSearchState();
    setErrorMessages([]);
    setShowError([]);
  }, []);

  // RESET PAGE ON CLICK OF RESET BUTTON
  const resetPage = () => {
    setShowTable(false);
    setShowCorrectionDetailTable(false);
    setErrorMessages([]);
    setShowError([]);
    setSystemSuccesMessages([]);
    setValues({
      providerMedicaidId: '',
      providerTaxID: '',
      providerName: '',
      ssn: ''
    });
  };
  return (
    <div className="pos-relative w-100 h-100" ref={toPrintRef}>

      <div className="tabs-container">
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.lenth === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">1099 Correction Search</h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-provider-id"
                  label="Provider Medicaid Id"
                  value={values.providerMedicaidId}
                  inputProps={{ maxlength: 15 }}
                  onChange={handleChange('providerMedicaidId')}
                  InputLabelProps={{
                    shrink: true
                  }}
				  data-testid='provider-id'
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-provider-tax-id"
                  label="Provider Tax ID"
                  value={values.providerTaxID}
                  inputProps={{ maxlength: 15 }}
                  onChange={handleChange('providerTaxID')}
				  data-testid='provider-tax-id'
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-provider-name"
                  label="Provider Name"
                  value={values.providerName}
                  inputProps={{ maxlength: 60 }}
                  onChange={handleChange('providerName')}
                  InputLabelProps={{
                    shrink: true,
					
                  }}
				  data-testid='provider-name-test'
				  
                  helperText={
                    showProviderNameErrorText ? ErrorConstants.MIN_CHAR_ERROR : null
                  }
                  error={showProviderNameErrorText ? ErrorConstants.MIN_CHAR_ERROR : null}

                ></TextField>
        <div className="sub-radio">
            <Radio
              value="Starts With"
              id="providernamestart"
			  data-testid='start-with'
              checked={values.providerNameSelected === 'Starts With'}
              onChange={handleChange('providerNameSelected')}
            /> <label className="text-black" htmlFor="providernamestart">Starts With </label>
            <Radio
              value="Contains"
              checked={values.providerNameSelected === 'Contains'}
              id="providernamecontains"
              onChange={handleChange('providerNameSelected')}
			  data-testid='contains'
              className="ml-2"
            /><label className="text-black" htmlFor="providernamecontains">Contains</label>
        </div>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-ssn"
                  label="SSN"
                  value={values.ssn}
                  inputProps={{ maxlength: 9 }}
                  onChange={handleChange('ssn')}
                  InputLabelProps={{
                    shrink: true
                  }}
				  data-testid='ssn-test'
				  
                ></TextField>
              </div>
            </div>
            <div className="float-right mr-3 mb-2">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary ml-1"
				data-testid='search-test'
                onClick={() => {
                  displayCorrectionSearchResults();
                }}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
                Search
              </Button>
              <Button
                variant="outlined"
                color="primary"
				data-testid='reset-test'
                className="bt-reset btn-transparent ml-1"
                onClick={() => resetPage()}
              >
                <i className="fa fa-undo" aria-hidden="true"></i> Reset
              </Button>
            </div>
            <div className="clearfix"></div>
          </form>
          {showTable ? (
            <div className="tab-holder my-3 p-0">
              <CorrectionSearchResults
                tableData={searchTable && searchTable.keySort({ providerTaxID: 'asc', providerMedicaidId: 'asc' })}
                onRowclickAPICall={onRowclickAPICall}
                setShowCorrectionDetailTable={setShowCorrectionDetailTable}

                setParentTableRow={setParentTableRow}
                setErrorMessages={setErrorMessages}
              />
            </div>
          ) : null}

          
		  {(!showCorrectionDetailTable) && <Footer print={true} /> }
          {showCorrectionDetailTable ? (
            <div className="tab-holder my-3 p-0">
              <CorrectionDetails
                tableData={correctionDetailTableData.keySort({ providerTaxID: 'asc', providerMedicaidId: 'asc' })}
                onRowclickAPICall={onRowclickAPICall}

                setSystemSuccesMessages={setSystemSuccesMessages}
                setErrorMessages={setErrorMessages}
                noteSetDetails={noteSetDetails}
                parentTableRow={parentTableRow}
              ></CorrectionDetails>
            </div>
          ) : null}

        </div>
      </div>

    </div>
  );
}

export default withRouter(CorrectionSearch);
