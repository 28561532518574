/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { Prompt } from "react-router-dom";
import ErrorMessages from "../../../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from "../../../../../SharedModules/Errors/SuccessMessage";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import BulkWriteOffTable from "./BulkWriteOffTable";
import {
  DialogContent,
  DialogActions
} from '../../../../../SharedModules/Dialog/DialogUtilities';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  getDropdownDataAction,
  saveBulkViewWriteOff
} from "../../../Store/Actions/accountReceivable/accountReceivableActions";
import dropDownCriteria from "./DropDownCriteria.json";
import {
  getUTCTimeStamp
} from "../../../../../SharedModules/DateUtilities/DateUtilities";
import * as constants from "./WriteOffconstants";
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import { TextField } from "@material-ui/core";

function BulkWriteOff (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [values, setValues] = React.useState({ atrNumber: '' });
  const [reasonCodeDropdown, setReasonCodeDropdown] = React.useState([]);
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const [tableData, setTableData] = React.useState([]);
  const [showError, setShowError] = React.useState({ showATRError: false });
  const [showErrorMessage, setShowErrorMessage] = React.useState({ atrErrorMsg: '' });

  const handleChange = (name) => e => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleTableReasonCode = row => event => {
    setFocusCheck(true);
    const value = event.target.value;
    const tableDataTemp = tableData;
    tableDataTemp.map(record => {
      if (record.fcn === row.fcn) {
        record.reasonCode = value;
      }
    });
    setTableData(tableDataTemp);
  };
  const DropDownAPI = dropdownValues => {
    dispatch(getDropdownDataAction(dropdownValues));
  };
  const saveBulkWriteOffApi = values => {
    dispatch(saveBulkViewWriteOff(values));
  };
  const dropDownDetails = useSelector(
    state => state.accountReceivableState.dropdownData
  );
  const bulkWriteOffresult = useSelector(
    state => state.accountReceivableState.saveBulkViewWriteOffResult
  );
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const dropdownData = useSelector(state => state.appDropDowns.appdropdowns);
  const setRsnCdData = data => {
    setTableData(JSON.parse(JSON.stringify(data)));
  };

  useEffect(() => {
    if (
      bulkWriteOffresult &&
      !bulkWriteOffresult.systemFailure &&
      bulkWriteOffresult.errorMessages &&
      bulkWriteOffresult.errorMessages.length > 0
    ) {
      const errorMessages = [];
      bulkWriteOffresult.errorMessages.map(value => {
        const message = `${bulkWriteOffresult.originalFCN}: ${value}`;
        errorMessages.push(message);
      });
      setErrorMessages(errorMessages);
    } else if (
      bulkWriteOffresult &&
      !bulkWriteOffresult.systemFailure &&
      bulkWriteOffresult.respcode === "01"
    ) {
      const successMessage = [];
      const message = constants.SAVED_SUCCESS;
      successMessage.push(message);
      setSystemSuccesMessages(successMessage);
      setFocusCheck(false);
      const tempData = JSON.parse(JSON.stringify(tableData));
      tempData.map(value => {
        value.balanceAmount = "0.00";
        bulkWriteOffresult.fcnamountlist.map(values => {
          if (value.receivableFCN === values.oldFCN) {
            value.newTransferFcn = values.newFCN;
            value.newBalanceAmount = values.newAmount;
          }
        });
      });
      setTableData(tempData);
    } else if (bulkWriteOffresult && bulkWriteOffresult.systemFailure) {
      const errorMessages = [];
      errorMessages.push(constants.GENERIC_SYSTEM_ERROR);
      setErrorMessages(errorMessages);
    }
  }, [bulkWriteOffresult]);

  useEffect(() => {
    DropDownAPI(dropDownCriteria);
    onDropdowns([Dropdowns.F_RSN_CD]);
  }, []);

  useEffect(() => {
    if (dropDownDetails) {
      const tableValues = JSON.parse(
        JSON.stringify(props.location.state.selectedFcnObj)
      );
      if (
        dropDownDetails.listObj &&
        dropDownDetails.listObj["Financial#3004"]
      ) {
        tableValues.map(value => {
          value.transferreasonCode =
            dropDownDetails.listObj["Financial#3004"][0]?.code;
        });
        setTableData(tableValues);

        setReasonCodeDropdown(dropDownDetails.listObj["Financial#3004"]);
      }
    }
  }, [dropDownDetails]);

  const saveWriteOff = () => {
    setSystemSuccesMessages([]);
    const regex = /[^A-Za-z0-9]/;
    const errorMsgs = [];
    var atrErrorTemp = false;
    var atrErrorMsgTemp = '';
    if (tableData && tableData.length > 0) {
      let count = 0;
      if (values.atrNumber && regex.test(values.atrNumber)) {
        atrErrorTemp = true;
        atrErrorMsgTemp = `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${constants.TRANSACTION_NUMBER_FORMAT}`;
        errorMsgs.push(atrErrorMsgTemp);
      } else { atrErrorTemp = false; }
      for (let i = 0; i <= tableData.length - 1; i++) {
        if (!tableData[i].transferreasonCode ||
          tableData[i].transferreasonCode === DEFAULT_DD_VALUE) {
          tableData[i].errorKey = true;
          count = count + 1;
        } else {
          tableData[i].errorKey = false;
        }
      }
      if (count > 0) {
        errorMsgs.push(constants.WRITE_OFF_RC_REQUIRED);
        setTableData(tableData);
      } else {
        setTableData(tableData);
      }
    }
    if (errorMsgs.length === 0) {
      setErrorMessages([]);
      setShowError({
        showATRError: null
      });

      const bulkWriteOffResult = props.location.state.viewBulkWriteOffResult;
      const selectedTransferResult = tableData;
      const saveReverseArray = [];
      for (let i = 0; i < bulkWriteOffResult.length; i++) {
        if (
          bulkWriteOffResult[i].receivableFCN ===
          selectedTransferResult[i].receivableFCN
        ) {
          bulkWriteOffResult[i].auditUserID = logInUserID;
          bulkWriteOffResult[i].auditTimeStamp = getUTCTimeStamp();
          bulkWriteOffResult[i].addedAuditUserID = logInUserID;
          bulkWriteOffResult[i].addedAuditTimeStamp = getUTCTimeStamp();
          bulkWriteOffResult[i].lob = props.location.state.selectedPayee.lob;
          bulkWriteOffResult[i].atrNumber = values?.atrNumber?.toUpperCase();
          bulkWriteOffResult[i].writeOffReasonCode =
            selectedTransferResult[i].transferreasonCode;
          saveReverseArray.push(bulkWriteOffResult[i]);
        }
      }
      saveBulkWriteOffApi(saveReverseArray);
    } else {
      setErrorMessages(errorMsgs);
      setShowError({
        showATRError: atrErrorTemp
      });
      setShowErrorMessage({
        atrErrorMsg: atrErrorMsgTemp
      });
    }
  };

  const valuesFromBulkMaintenance = props.history && props.history.location && props.history.location.state &&
  props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }, 100);
  };
  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/BulkMaintenance',
        tableRender: true,
        state: { valuesFromBulkMaintenance: valuesFromBulkMaintenance }
      });
    }
  };
const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const reasonCode =
          dropdownData &&
          dropdownData['F1#F_RSN_CD'] &&
          dropdownData['F1#F_RSN_CD'].filter(
            value => value.code === each.reasonCode?.split("-")[0]
          );
        // updating the record with desc
        each.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
            ? reasonCode[0].description
            : each.reasonCode ? each.reasonCode : '';
        }
        each.transReasCodeCheck = reasonCodeDropdown.find(value => value.code === each.transferreasonCode);
        if (!each.transReasCodeCheck || each.transReasCodeCheck.length === 0) {
          each.transferreasonCode = DEFAULT_DD_VALUE;
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      <ErrorMessages errorMessages={errorMessages} />
      {systemSuccessMessages.length > 0 && errorMessages.length === 0 ? (
        <SuccessMessages successMessages={systemSuccessMessages} />
      ) : null}

      <div className="tabs-container" ref={toPrintRef}>
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Account Receivable Write-off
          </h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveWriteOff()}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
                  STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>

        <div className="tab-body table-no-wrap">

        <form noValidate autoComplete="off">
              <div className="tab-holder">
                <div className="row mb-2">
                  <div className="col-6" style={{ marginTop: '10px', marginBottom: '20px' }}>
                  <div className="form-wrapper">
                        <div className="mui-custom-form with-select override-width-45 override-m-3">
                          <TextField
                            id="atrNumber"
                            label= {process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
                            inputProps={{ maxLength: 18 }}
                            value={values.atrNumber}
                            onChange={handleChange('atrNumber')}
                           helperText={showError.showATRError ? showErrorMessage.atrErrorMsg : null}
                           error={showError.showATRError ? showErrorMessage.atrErrorMsg : null}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                          </TextField>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </form>
          <BulkWriteOffTable
            reasonCodeDropdown={reasonCodeDropdown}
            handleTableReasonCode={handleTableReasonCode}
            setRsnCdData={setRsnCdData}
            tableData={getTableData(tableData || [])}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(BulkWriteOff);
