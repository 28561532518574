/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import TableComponent from "../../../../SharedModules/Table/Table";
import EditDisposition from "./editDisposition";
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../../../../SharedModules/Dialog/DialogUtilities";
import { getUTCTimeStamp } from "../../../../SharedModules/DateUtilities/DateUtilities";
import Dialog from "@material-ui/core/Dialog";
import * as moment from "moment";
import * as ErrorConstants from "../FinancialReceiptConstants";
import { mapCodeDescription } from "../AddValidationUtility";
import { Button } from "react-bootstrap";
import numeral from "numeral";
import { getAuditLogDetailsAction } from "../../Store/Actions/FinancialReceipt/financialReceiptActions";
import AuditTableComponent from "../../../../SharedModules/AuditLog/AuditTableComponent";
import { useDispatch, useSelector } from "react-redux";
import { checkingDecimal } from '../../../../SharedModules/CommonUtilities/commonUtilities';

const headCells = [
  {
    id: "receivableFCN",
    numeric: false,
    disablePadding: true,
    label: "Receivable FCN",
    width: "16.6%",
    fontSize: 14,
    enableHyperLink: true,
    isLink: true
  },
  {
    id: "establishedDate",
    numeric: false,
    disablePadding: false,
    label: "Established Date",
    width: "16.6%",
    fontSize: 14
  },
  {
    id: "_recvRsnCdDescr",
    numeric: false,
    disablePadding: false,
    label: "Financial Reason Code",
    width: "16.6%",
    fontSize: 14,
    isTooltip: true,
    isTitle: "rcLongDesc"
  },
  {
    id: "originalAmount",
    numeric: false,
    disablePadding: false,
    label: "Original Amount",
    isBalance: true,
    width: "21.6%",
    fontSize: 14
  },
  {
    id: "balanceAmount",
    numeric: false,
    disablePadding: false,
    label: "Balance Amount",
    isBalance: true,
    width: "21.6%",
    fontSize: 14
  },
  {
    id: "amountToApply",
    numeric: false,
    disablePadding: false,
    label: "Amount to Apply",
    isBalance: true,
    width: "16.6%",
    fontSize: 14
  }
];

export default function DispositionDetails(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const {
    dispositoinTabTableData,
    onRowClick,
    editFlag,
    dispositionReasonCodeDD,
    setAllowNavigation,
    originalTab,
    seterrorMessages,
    setAuditLogRow,
    auditRowData,
    setAuditRowData,
    showLogDialogTable,
    setShowLogTable,
    setFocusCheck,
    crswalk_FS_9301,
    crswalk_FS_9302,
    validValRsnCodes
  } = props;
  const [openDisposition, setOpenDispositon] = React.useState(false);
  const loginUserID = useSelector(state => state.sharedState.logInUserID);
  const [editDispositionValues, setEditDispositionValues] = React.useState({
    receivableFcn: "",
    dispositionReasonCode: "",
    arOriginalAmount: "",
    arBalanceAmount: "",
    amountToApply: ""
  });
  const [editData, setEditData] = React.useState({});
  const [retainData, setRetainData] = React.useState({});
  const [establishedDate, setEsatblishedDate] = React.useState(null);
  const [dispositionError, setDispositionError] = React.useState("");
  const [amountError, setAmountError] = React.useState("");
  const [amountError2, setAmountError2] = React.useState("");

  const handleEstablishedDate = date => {
    setEsatblishedDate(date);
  };
  const dispositionRowClick = row => event => {
    setAuditLogRow(row);
    setRetainData(row);
    setEditData(row);
    onRowClick(row);
    if (editFlag) {
      setOpenDispositon(true);
      let countflag = 0;
      dispositionReasonCodeDD.map(var1 => {
        if (var1.code === row.reasonCode) {
          countflag = 1;
          setEditDispositionValues({
            receivableFcn: row.receivableFCN,
            recvRsnCd: row.reasonCode,
            arOriginalAmount: row.originalAmount,
            arBalanceAmount: row.balanceAmount,
            amountToApply:
              row.amountToApply === null
                ? "0.00"
                : numeral(row.amountToApply).format("0,0.00")
          });
        }
      });
      if (countflag === 0) {
        setEditDispositionValues({
          receivableFcn: row.receivableFCN,
          recvRsnCd: DEFAULT_DD_VALUE,
          arOriginalAmount: numeral(row.originalAmount).format("0,0.00"),
          arBalanceAmount: numeral(row.balanceAmount).format("0,0.00"),
          amountToApply:
            row.amountToApply === null
              ? "0.00"
              : numeral(row.amountToApply).format("0,0.00")
        });
      }
      setEsatblishedDate(row.establishedDate);
    }
  };
  const handleChangeEditDisposition = name => event => {
    if(name === 'amountToApply'){
      if (event.target.value && event.target.value.includes('.')) {
        if(checkingDecimal(event.target.value)){
        setFocusCheck(true);{}
        setAllowNavigation(true);
        setEditDispositionValues({
        ...editDispositionValues,
        [name]: event.target.value
    })
  }}
  else{
    setFocusCheck(true);
    setAllowNavigation(true);
        setEditDispositionValues({
          ...editDispositionValues,
          [name]: event.target.value
        });
    } 
}
else{
    setFocusCheck(true);
    setAllowNavigation(true);
        setEditDispositionValues({
          ...editDispositionValues,
          [name]: event.target.value
        });
      }    
  };
  const handleClose = () => {
    setAuditLogRow(null);
    seterrorMessages([]);
    setOpenDispositon(false);
    setAmountError2("");
    setAmountError("");
    setDispositionError("");
  };
  const updateDisposition = () => {
    setAllowNavigation(true);
    const index = dispositoinTabTableData.indexOf(editData);
    const errorArray = [];
    if (
      Number(
        dispositoinTabTableData[index].balanceAmount
          .toString()
          .replace(/,/g, "")
      ) <
      Number(editDispositionValues.amountToApply.toString().replace(/,/g, ""))
    ) {
      setAmountError2(ErrorConstants.DISPOSITION_BALANCE_AMOUNT);
      errorArray.push(ErrorConstants.DISPOSITION_BALANCE_AMOUNT);
    }
    if (editDispositionValues.amountToApply === "") {
      setAmountError(ErrorConstants.DISPOSITION_AMOUNT_REQUIRED);
      errorArray.push(ErrorConstants.DISPOSITION_AMOUNT_REQUIRED);
    } else {
      if (
        Number(
          editDispositionValues.amountToApply.toString().replace(/,/g, "")
        ) > Number(originalTab.balanceAmount.toString().replace(/,/g, ""))
      ) {
        setAmountError2(ErrorConstants.DISPOSITION_GREATERTHAN_RECIEPT);
        errorArray.push(ErrorConstants.DISPOSITION_GREATERTHAN_RECIEPT);
      }
      if (errorArray.length === 0) {
        setAmountError2("");
      }
      if (
        Number(
          editDispositionValues.amountToApply.toString().replace(/,/g, "")
        ) <= 0
      ) {
        setAmountError(ErrorConstants.DISPOSITION_GREATERTHAN_ZERO);
        errorArray.push(ErrorConstants.DISPOSITION_GREATERTHAN_ZERO);
      } else {
        if (
          editDispositionValues.amountToApply
            .toString()
            .replace(/,/g, "")
            .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null
        ) {
          setAmountError(ErrorConstants.INVALID_FORMAT);
          errorArray.push(ErrorConstants.INVALID_FORMAT);
        } else {
          if (
            !(
              editDispositionValues.amountToApply
                .toString()
                .replace(/,/g, "")
                .match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            setAmountError(ErrorConstants.INVALID_FORMAT);
            errorArray.push(ErrorConstants.INVALID_FORMAT);
          } else {
            setAmountError("");
          }
        }
      }
    }
    if (editDispositionValues.recvRsnCd === DEFAULT_DD_VALUE) {
      setDispositionError(
        ErrorConstants.ADDREFUND_DISPOSITION_REASON_CODE_REQUIRED
      );
      errorArray.push(
        ErrorConstants.ADDREFUND_DISPOSITION_REASON_CODE_REQUIRED
      );
    } else {
      setDispositionError("");
    }
    if (errorArray.length === 0) {
      seterrorMessages([]);
      setEditData({
        ...editData,
        auditUserID: loginUserID,
        auditTimestamp: getUTCTimeStamp(),
        receivableFCN: editDispositionValues.receivableFcn,
        reasonCode: editDispositionValues.recvRsnCd,
        originalAmount: editDispositionValues.arOriginalAmount,
        originalAmountStr: editDispositionValues.arOriginalAmount,
        balanceAmount: editDispositionValues.arBalanceAmount,
        balanceAmountStr: editDispositionValues.arBalanceAmount,
        amountToApply: editDispositionValues.amountToApply.replace(/,/g, ""),
        amountToApplyStr: editDispositionValues.amountToApply.replace(/,/g, "")
      });
      dispositoinTabTableData[index] = {
        ...editData,
        auditUserID: loginUserID,
        auditTimestamp: getUTCTimeStamp(),
        receivableFCN: editDispositionValues.receivableFcn,
        reasonCode: editDispositionValues.recvRsnCd,
        originalAmount: editDispositionValues.arOriginalAmount,
        originalAmountStr: editDispositionValues.arOriginalAmount,
        balanceAmount: editDispositionValues.arBalanceAmount,
        balanceAmountStr: editDispositionValues.arBalanceAmount,
        amountToApply: editDispositionValues.amountToApply.replace(/,/g, ""),
        amountToApplyStr: editDispositionValues.amountToApply.replace(/,/g, ""),
        establishedDate: moment(new Date(establishedDate).getTime()).format(
          "MM/DD/YYYY"
        )
      };
      setOpenDispositon(false);
    } else {
      seterrorMessages(errorArray);
    }
  };
  const editDispositionFormat = () => {
    if (editDispositionValues.amountToApply) {
      setEditDispositionValues({
        ...editDispositionValues,
        amountToApply: numeral(editDispositionValues.amountToApply).format(
          "0,0.00"
        )
      });
    }
  };
  const removeEditDispositionFormat = () => {
    if (editDispositionValues.amountToApply) {
      setEditDispositionValues({
        ...editDispositionValues,
        amountToApply: numeral(editDispositionValues.amountToApply).format(
          "0.00"
        )
      });
    }
  };
  const resetDisposition = () => {
    seterrorMessages([]);
    setEditDispositionValues({
      receivableFcn: retainData.receivableFCN,
      recvRsnCd: retainData.reasonCode
        ? retainData.reasonCode
        : DEFAULT_DD_VALUE,
      arOriginalAmount: numeral(retainData.originalAmount).format("0,0.00"),
      arBalanceAmount: numeral(retainData.balanceAmount).format("0,0.00"),
      amountToApply: numeral(retainData.amountToApply).format("0,0.00")
    });
    setEsatblishedDate(retainData.establishedDate);
    setAmountError2("");
    setAmountError("");
    setDispositionError("");
  };
  const formatSearchCriteria = row => {
    return row.receivableFCN;
  };
  const onLinkClick = row => event => {
    window.open("fcn", "_blank");
  };
  return (
    <div>
      <div>
        <div className="tab-holder table-no-wrap my-3">
          <TableComponent
            pathTo="/FinancialAccountGrossLevelUpdate?data="
            formatSearchCriteria={formatSearchCriteria}
            print={props.print}
            isSearch={true}
            headCells={headCells}
            tableData={dispositoinTabTableData?.filter(e => crswalk_FS_9301?.indexOf(e.recvRsnCd) > -1)}
            onTableRowClick={dispositionRowClick}
            defaultSortColumn={"receivableFCN"}
            onLinkClick={onLinkClick}
            onTableCellButtonClick={props.onClickAuditDialogLog}
            uniqueKey={"receivableFCN"}
            setClose={props.setClose}
            close={props.close}
          />
        </div>
      </div>
      {openDisposition ? (
        <div className="tabs-container">
          <div className="tab-header  mini-tab-header">
            <div className="tab-heading float-left">
              {"Edit Disposition Details"}
            </div>
            <div className="float-right mt-1">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-success ml-1"
                onClick={updateDisposition}
                disabled={!props.userInquiryPrivileges}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                {"Update"}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="bt-reset btn-transparent  ml-1"
                onClick={resetDisposition}
                disabled={!props.userInquiryPrivileges}
              >
                <i className="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary btn btn-outlined ml-1"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
          <EditDisposition
            dispositionError={dispositionError}
            amountError={amountError}
            amountError2={amountError2}
            editDispositionValues={editDispositionValues}
            dispositionReasonCode={validValRsnCodes?.filter(e => crswalk_FS_9302?.filter(e => e.startingValue === editData.recvRsnCd)?.map(e => e.endingValue)?.indexOf(e.code) > -1)}
            establishedDate={establishedDate}
            setEsatblishedDate={setEsatblishedDate}
            handleEstablishedDate={handleEstablishedDate}
            handleChangeEditDisposition={handleChangeEditDisposition}
            updateDisposition={updateDisposition}
            resetDisposition={resetDisposition}
            removeEditDispositionFormat={removeEditDispositionFormat}
            editDispositionFormat={editDispositionFormat}
            openDisposition={openDisposition}
            seterrorMessages={seterrorMessages}
            {...props}
          />
          {showLogDialogTable ? (
            <div id="audit-dialog-table">
              <AuditTableComponent
                print
                auditRowData={auditRowData}
                setAuditRowData={setAuditRowData}
                setShowLogTable={setShowLogTable}
              />{" "}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
