/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import { Button } from 'react-bootstrap';
import ClaimSummary from './ClaimSummary';
import ARCreditBalanceTable from './ARCreditBalanceTable';
import ClaimsHistory from './ClaimsHistory';
import numeral from 'numeral';
import PriorCalenderYear from './PriorCalenderYear';
import PriorStateFiscalYear from './PriorStateFiscalYear';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDropdownDataAction,
  GetPayeeIdTypeCodeAction,
  FinancialClaimDetails
} from '../../Store/Actions/FinancialInquiry/FinancialInquiryActions';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import TextField from '@material-ui/core/TextField';
import dropdownCriteria from '../ClaimSummary.json';

import * as Errorconstants from '../../../../SharedModules/Attachments/AttachmentsConstants';
import { systemListActions } from "../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions";
import Footer from '../../../../SharedModules/Layout/footer';
import { getUTCTimeStamp } from '../../../../SharedModules/DateUtilities/DateUtilities';
import NavHOC from '../../../../SharedModules/Navigation/NavHOC';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { resetPDFData } from '../../../../SharedModules/store/Actions/SharedAction';

export default NavHOC({
  Wrapped: ClaimsReceivables,
  action: FinancialClaimDetails,
  selector: 'financialInquiryState.summaryDetails',
  url: '/ClaimSummary',
  actionKey: 'claimSummary'
});
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
function ClaimsReceivables (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [value, setValue] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const summaryDetails = props.location?.state?.summaryDetails[0];
  const searchDetails = props.location.state?.summaryDetails?.searchDetails;
  const inquiryValues = props?.location?.state?.values;
  const [fiscalYearSearchCriteria, setFiscalYearSearchCriteria] = React.useState({
    dataElementName: "DM_YR_NUM",
    description: "F_FSCL_YR_NUM",
    funcAreaDesc: null,
    functionalArea: "F1",
    listBusinessName: "F_FSCL_YR_NUM",
    listNumber: 1021,
    listType: "UIS"
  });
  const [valuesFromFinInq, setValuesFromFinInq] = React.useState([]);
  const entityIDTypeCode = searchDetails?.payeeType?.split('-')[0] || inquiryValues?.payeeType?.split('-')[0];
  const payeeIDTypeDropdown = entityIDTypeCode === 'P' ? 'F1#P_ALT_ID_TY_CD'
      : entityIDTypeCode === 'M' ? 'F1#B_ALT_ID_TY_CD'
      : entityIDTypeCode === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD'
      : entityIDTypeCode === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null;
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const dropdown = useSelector(
    state => state.financialInquiryState.dropdownData
  );
  const onChangePayeeType = payeeType =>
    dispatch(GetPayeeIdTypeCodeAction(payeeType));
  const payeeTypeIDDropdown = useSelector(
    state => state.financialInquiryState.payeeIDTypeCodeDetails
  );

  const getFiscalYearsList = searchValue =>
    dispatch(systemListActions(searchValue));
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);

  const [PayeeTypeReference, setPayeeTypeReference] = React.useState([]);
  const [
    payeeTypeIDDropdownReference,
    setpayeeTypeIDDropdownReference
  ] = React.useState([]);
  const [historyData, setHistoryData] = React.useState([]);
  const [payoutRCodes, setPayoutRCodes] = React.useState([]);
  const [receiptRCodes, setReceiptRCodes] = React.useState([]);
  const [accountRRCodes, setAccountRRCodes] = React.useState([]);
  const [dispositionRCodes, setDispositionRCodes] = React.useState([]);
  const dispatch = useDispatch();

  const toPrintRef = useRef();

  const fiscalYearData = useSelector(state => state.appConfigState.systemListState.payload);
  const [fiscalYearNumber, setFiscalYearNumber] = React.useState();
  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    payeeId: '',
    payeeName: '',
    checkStartsWith: false,
    ssn: '',
    fein: ''
  });
  const [errorMessages, setErrorMessages] = React.useState([]);
  let downloadeFile = useSelector(state => state.sharedState.downloadeFile);
  const downloadeFilePDFError = useSelector(state => state.sharedState.downloadeFilePDFError);

  useEffect(() => {
    if (downloadeFilePDFError) {
      const errorMessageArray = [];
      const downloadFileErrorMessage = Errorconstants.FILE_NOT_FOUND;
      errorMessageArray.push(downloadFileErrorMessage);
      setErrorMessages(errorMessageArray);
    }
  }, [downloadeFilePDFError]);

  useEffect(() => {
    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.F_RSN_CD
    ]);
  }, []);

  useEffect(() => {
    if (fiscalYearData && fiscalYearData[0]?.systemListDetails && fiscalYearData[0]?.systemListDetails?.length !== 0) {
      const currentdate = getUTCTimeStamp();
      let currentFiscalYear = '';
      const fiscalyearList = fiscalYearData[0].systemListDetails;
      fiscalyearList.filter(fiscalyearData => {
        if (new Date(fiscalyearData.beginDate) < new Date(currentdate) && new Date(fiscalyearData.endDate) > new Date(currentdate)) {
          currentFiscalYear = fiscalyearData.startingValue;
          setFiscalYearNumber(+currentFiscalYear);
        }
      });
    }
  }, [fiscalYearData]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeReference(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setPayoutRCodes(dropdown.listObj['Financial#3006']);
      }
      if (dropdown.listObj['Financial#3007']) {
        setReceiptRCodes(dropdown.listObj['Financial#3007']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setAccountRRCodes(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['Financial#3009']) {
        setDispositionRCodes(dropdown.listObj['Financial#3009']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (payoutRCodes.length > 0 && receiptRCodes.length > 0 && accountRRCodes.length > 0 && dispositionRCodes.length > 0) {
      const historyTemp = JSON.parse(JSON.stringify(historyData));
      for (var historyValue in historyTemp) {
        if (historyTemp[historyValue].financialCtrlNumber) {
          if (historyTemp[historyValue].financialCtrlNumber.length === 14) {
            payoutRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'Payout';
                historyTemp[historyValue].reasonCodeDesc = value.description;
                historyTemp[historyValue].reasonCodeLongDesc = value.longDescription;
              }
            });
            receiptRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'Receipt';
                historyTemp[historyValue].reasonCodeDesc = value.description;
                historyTemp[historyValue].reasonCodeLongDesc = value.longDescription;
              }
            });
            accountRRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'AccountR';
                historyTemp[historyValue].reasonCodeDesc = value.description;
                historyTemp[historyValue].reasonCodeLongDesc = value.longDescription;
              }
            });
            dispositionRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'AccountR';
                historyTemp[historyValue].reasonCodeDesc = value.description;
                historyTemp[historyValue].reasonCodeLongDesc = value.longDescription;
              }
            });
          } else {
            historyTemp[historyValue].activityType = 'FTTCN';
          }
        }
      }
      setHistoryData(historyTemp);
    }
  }, [payoutRCodes, receiptRCodes, accountRRCodes, dispositionRCodes]);

  useEffect(() => {
    if (payeeTypeIDDropdown && payeeTypeIDDropdown.length > 0) {
      setpayeeTypeIDDropdownReference(payeeTypeIDDropdown);
    }
  }, [payeeTypeIDDropdown]);

  useEffect(() => {
    getDropdownData(dropdownCriteria);
    setHistoryData(summaryDetails.creditARBList);
    downloadeFile = null;
    setErrorMessages([]);
  }, []);

  useEffect(() => {
    if (downloadeFile && downloadeFile.systemFailure) {
      const temerr = [];
      temerr.push(Errorconstants.ERROR_IN_DOWNLOADING_FILE);
      setErrorMessages(temerr);
    }
  }, [downloadeFile]);

  useEffect(() => {
    if (searchDetails) {
      getFiscalYearsList(fiscalYearSearchCriteria);
    }
    setValues({
      lob: searchDetails.lob,
      payeeType: searchDetails.payeeType === null || searchDetails.payeeType === "" && inquiryValues?.payeeType === "" && summaryDetails.payeeType === null
        ? DEFAULT_DD_VALUE
        : searchDetails.payeeType || summaryDetails.payeeType?.split("-")[0] || inquiryValues?.payeeType,
      payeeIdTypeCode:
        searchDetails.payeeIdTypeCode === null && inquiryValues?.payeeType === "" && summaryDetails.payeeIDTypeCode === null
          ? DEFAULT_DD_VALUE
          : searchDetails.payeeIdTypeCode || summaryDetails.payeeIDTypeCode?.split("-")[0] || inquiryValues?.payeeIdTypeCode,
      payeeId: searchDetails.payeeID ? searchDetails.payeeID : summaryDetails.payeeID,
      payeeName: summaryDetails.payeeName === null
        ? searchDetails.payeeName
        : summaryDetails.payeeName,
      checkStartsWith: searchDetails.startsWith,
      ssn: searchDetails.ssn ? searchDetails.ssn : summaryDetails.ssn,
      fein: searchDetails.fein ? searchDetails.fein : summaryDetails.fein
    });

    if (
      searchDetails.payeeType &&
      searchDetails.payeeType !== DEFAULT_DD_VALUE ||
      summaryDetails.payeeType &&
      summaryDetails.payeeType !== DEFAULT_DD_VALUE
    ) {
     onChangePayeeType(searchDetails.payeeType || summaryDetails.payeeType?.split("-")[0]);
    }
    }, [searchDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onRowClick = row => event => { };

  const onLinkClick = row => event => {
    window.open('fcn', '_blank');
  };

  React.useEffect(() => {
    if (props.history.location?.state?.values !== undefined) {
      setValuesFromFinInq(props.history.location?.state?.values);
    }
  }, [props.history.location.state.values]);

   const cancelMinorSection = () => {
     setDialogOpen(false);
     setFocusCheck(false);
     props.history.push({
       pathname: '/FinancialInquirySearch',
       tableRender: true,
       state: { valuesFromFinInq: valuesFromFinInq }
     });
   };

   const majorCancel = () => {
    dispatch(resetPDFData());
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/FinancialInquirySearch',
        tableRender: true,
        state: { valuesFromFinInq: valuesFromFinInq }
      });
    }
  };

  return (
    <div>

      <div className="tabs-container" ref={ toPrintRef }>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">Financial Inquiry</h1>
          <div className="float-right mt-2">
            {window.location.pathname === '/ClaimSummary' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body my-3">
          <form noValidate autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                id="payeeType"
                  label="Payee Type"
                  value={ values.payeeType }
                  InputLabelProps={ {
                    shrink: true
                  } }
                  disabled
                dropdownList={values.payeeType?.length === 1 ? PayeeTypeReference.map(option => (
                  <option key={option.code} value={option.code}>
                      {option.description}
                  </option>
                  )) : [
                  <option
                    selected
                    key={DEFAULT_DD_VALUE}
                    value={values.payeeType}
                  >
                   {values.payeeType}
                  </option>]}
              />
              <NativeDropDown
                id="payeeIdCode"
                  label="Payee ID Type Code"
                  value={ values.payeeIdTypeCode?.length > 1 ? values.payeeIdTypeCode.split('-')[0] : values.payeeIdTypeCode}
                  InputLabelProps={ {
                    shrink: true
                  } }
                  disabled
                dropdownList={addDropdowns
                    ? addDropdowns[payeeIDTypeDropdown] && addDropdowns[payeeIDTypeDropdown].map(option => (
                      <option key={option.code} value={option.code}>
                        { option.description }
                      </option>
                    ))
                    : null }
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeId"
                  label="Payee ID"
                  InputLabelProps={ {
                    shrink: true
                  } }
                  value={ values.payeeId }
                  inputProps={ { maxLength: 15 } }
                  InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeName"
                  label="Payee Name"
                  value={ values.payeeName }
                  InputLabelProps={ {
                    shrink: true
                  } }
                  inputProps={ { maxLength: 60 } }
                  InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                />
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="ssn"
                  label="SSN"
                  value={ values.ssn }
                  InputLabelProps={ {
                    shrink: true
                  } }
                  inputProps={ { maxLength: 11 } }
                  InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="fein"
                  label="FEIN"
                  value={ values.fein }
                  InputLabelProps={ {
                    shrink: true
                  } }
                  inputProps={ { maxLength: 10 } }
                  InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                />
              </div>
            </div>
          </form>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-4">
              <AppBar position="static">
                <Tabs
                  value={ value }
                  onChange={ handleChange }
                  aria-label="simple tabs example"
                  variant="fullWidth"
                >
                  <Tab label="Claim Summary" />
                  <Tab label="Credit A/R Balance" />
                  <Tab label="Prior Calendar Year Summary" />
                  <Tab label="Prior State Fiscal Year Summary" />
                  <Tab label="History" style={ { maxWidth: '30px' } } />
                </Tabs>
              </AppBar>
              <TabPanel
                value={ value }
                index={ 0 }
              >
                <div>
                  { value === 0 && (
                    <ClaimSummary summaryDetails={ summaryDetails } />
                  ) }
                </div>
              </TabPanel>
              <TabPanel
                value={ value }
                index={ 1 }
              >
                <div className="tab-holder">
                  <div className="table-no-wrap">
                    <div style={ { width: '100%', marginTop: '20px' } }>
                      { summaryDetails && value === 1 && (
                        <div>
                          <p style={ { fontSize: '14px', marginLeft: '15px' } }>
                            Last A/R Payment Applied Date :{ ' ' }
                            { summaryDetails.paymentDateStr }{ ' ' }
                          </p>
                          <p style={ { fontSize: '14px', marginLeft: '15px' } }>
                            Total Outstanding A/R Amounts :
                            { numeral(summaryDetails.totalAmount).format(
                              '$0,0.00'
                            ) }
                          </p>
                        </div>
                      ) }
                    </div>
                    { summaryDetails &&
                      value === 1 && (
<ARCreditBalanceTable
                        addDropdowns={addDropdowns}
                          tableData={
                            historyData || []
                          }
                          onRowClick={ onRowClick }
                          onLinkClick={ onLinkClick }
                        />
                      ) }
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                value={ value }
                index={ 2 }
              >
                <div>
                  { value === 2 && (
                   <PriorCalenderYear summaryDetails={ summaryDetails } setErrorMessages={setErrorMessages}/>
                  ) }
                </div>
              </TabPanel>
              <TabPanel
                value={ value }
                index={ 3 }
              >
                <div>
                  { value === 3 && (
                    <div className="mt-3 table-p-5">
                      <PriorStateFiscalYear summaryDetails={ summaryDetails } fiscalYear={ fiscalYearNumber } />
                    </div>
                  ) }
                </div>
              </TabPanel>
              <TabPanel
                value={ value }
                index={ 4 }
              >
                <div>
                <div className="table-no-wrap">
                  { summaryDetails &&
                    value === 4 && (
                        <ClaimsHistory
                          tableData={
                            summaryDetails.historyList
                              ? summaryDetails.historyList
                              : []
                          }
                          onRowClick={ onRowClick }
                          setErrorMessages={setErrorMessages}
                        />
                      ) }
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
        <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
                  color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
