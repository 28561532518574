/**

* � [2020] Conduent, Inc. All rights reserved. Conduent�and Conduent Agilelet

* Star�are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from 'react-bootstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchClaimsReceivableAction,
  getPayeeIdType,
  getReasonCodeMapDataAction,
  searchPayeeAccountReceivableAction,
  resetPayeeSearchAccountReceivable,
  getDropdownDataAction
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import reasonCodeDDCriteria from './ReasonCodeDropdown.json'
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../../../SharedModules/CommonUtilities/commonUtilities';

import {
  compareTwoDates,
  checkForValidDateString,
  getDateInMMDDYYYYFormat,
  validateDateMinimumValue
} from '../../../../../SharedModules/DateUtilities/DateUtilities';
import * as AppConstants from '../../../../../SharedModules/AppConstants';
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import AccountRecevSearchResultsComponent from './AccountRecevSearchResultsComponent';
import * as ARSearchConstants from './ARSearchConstants';
import moment from 'moment';
import '../../../AccountReceivables.scss';
import Swal from 'sweetalert2';
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

/* PayeeReceivable()
 * This component is used to search for Account Receivables Payee/Payer
 */
function PayeeReceivable (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetPayeeSearchAccountReceivable());

  const [payeeTableData, setPayeeTableData] = React.useState(null);
  const [showTable, setShowTable] = React.useState(false);
  const [isSearch, isSetSearch] = React.useState(false);
  const [outstandingBalanceCheck, setOutstandingBalanceCheck] = React.useState(
    false
  );
  let errorMessagesArray = [];
  const [errorMegFromDate, setErrorMegFromDate] = React.useState('');
  const [errorMegThruDate, setErrorMegThruDate] = React.useState('');
  const [errorMegLastDate, setErrorMegLastDate] = React.useState('');
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedThruDate, setSelectedThruDate] = React.useState(null);
  const [selectedLastDate, setSelectedLastDate] = React.useState(null);
  const [redirect, setRedirect] = React.useState(0);
  const [payeeTypeCodeData, setPayeeTypeCodeData] = React.useState([]);
  const [errorMeg, setErrorMeg] = React.useState('');
  const [errorTypeMeg, setPayeeTypeErrorMeg] = React.useState('');
  const [errorCodeMeg, setPayeeCodeErrorMeg] = React.useState('');
  const [atrError, showATRError] = React.useState(false);

  /* Setting payee type and payee id code values array initially with some default inputs */
  const [
    activityReasonCodeValues,
    setActivityReasonCodeValues
  ] = React.useState({
    activityCode: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE
  });
  /* Setting values array initially with some default inputs */
  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    payee: '',
    id: '',
    fundCode: DEFAULT_DD_VALUE,
    lastDate: '',
    payeeIdCode: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    payeeStatus: DEFAULT_DD_VALUE
  });
  const [payloadPayees, setPayloadPayees] = React.useState([]);
  const [payloadPayeesOutstanding, setPayloadPayeesOutstanding] = React.useState([]);
  const setSearchData = { ...values, ...activityReasonCodeValues, selectedFromDate, selectedThruDate, selectedLastDate, outstandingBalanceCheck };
  const valuesFromAREdit = props?.history?.state?.values;
  let payeeIdTypeDropdown = '';
  const getReasonCodeMapData = (value) => dispatch(getReasonCodeMapDataAction(value));
  const onSearch = values =>
    dispatch(searchPayeeAccountReceivableAction(values));

  const onSearchPayeeIdType = values => dispatch(getPayeeIdType(values));

  const onOutstandingRowSearch = values =>
    dispatch(searchClaimsReceivableAction(values));
  const claimsReceivableData = useSelector(
    state => state.accountReceivableState.payloadClaims
  );

  const searchValues = useSelector(
    state => state.accountReceivableState.searchValues);
  const onReasonCd = (values) => dispatch(getDropdownDataAction(values));
  const reasonCodeDropdownList = useSelector(state => state.accountReceivableState.dropdownData);

  const payloadPayeesFrmStr = useSelector(
    state => state.accountReceivableState.payloadPayees
  );
  const payloadPayeesOutstandingFrmStr = useSelector(
    state => state.accountReceivableState.payloadPayeesOutstanding
  );
  payeeIdTypeDropdown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );

  const reasonCodeMapdropdown = useSelector(
    state => state.accountReceivableState.reasonCodeMapdropdownData
  );
  const dropdownData = useSelector(state => state.appDropDowns.appdropdowns);
  /* Setting all dropdown fields with response coming from API */
  useEffect(() => {
    if (reasonCodeMapdropdown && reasonCodeMapdropdown.reasonCodesMap && reasonCodeMapdropdown.reasonCodesMap.reasonCodeList) {
      const reasonCodeValue = JSON.parse(JSON.stringify(reasonCodeMapdropdown.reasonCodesMap.reasonCodeList));
      props.setReasonCodeVal(reasonCodeValue);
    }
  }, [reasonCodeMapdropdown]);

  useEffect(() => {
    if (payloadPayeesFrmStr) {
      setPayloadPayees(payloadPayeesFrmStr);
    }
  }, [payloadPayeesFrmStr]);

  useEffect(() => {
    if (payloadPayeesOutstandingFrmStr) {
      setPayloadPayeesOutstanding(payloadPayeesOutstandingFrmStr);
    }
  }, [payloadPayeesOutstandingFrmStr]);

  /*
   * Shwoing error message if payee not found
   * Displaying results in table if more than one payee found
   */
  useEffect(() => {
    if (
      payloadPayeesOutstanding != null &&
      payloadPayeesOutstanding.length === 0
    ) {
      errorMessagesArray.push(ARSearchConstants.NO_RECORDS_FOUND);
      props.showError(errorMessagesArray);
      setShowTable(false);
    }
    if (
      payloadPayeesOutstanding &&
      payloadPayeesOutstanding != null &&
      payloadPayeesOutstanding.length > 0
    ) {
      payloadPayeesOutstanding.map(data => {
        if (data.lobCode != null && data.lobCode !== undefined) {
          data.lob = data.lobCode;
        }

        if (
          data.payout.reasonCode != null &&
          data.payout.reasonCode !== undefined
        ) {
          data.reasonCode = data.payout.reasonCode;
        }

        if (data.payeePayerID != null && data.payeePayerID !== undefined) {
          data.payeePayerType = data.payeePayerID;
        }

        if (
          data.financialCtrlNumber != null &&
          data.financialCtrlNumber !== undefined
        ) {
          data.receivableFCN = data.financialCtrlNumber;
        }

        if (data.lob != null && data.lob !== undefined) {
          for (var lobCode in props.lobData) {
            if (props.lobData[lobCode].code === data.lob) {
              data.lob = props.lobData[lobCode].description;
            }
          }
        }

        if (
          data.payeePayerTypeCode != null &&
          data.payeePayerTypeCode !== undefined
        ) {
          for (var pTypeCode in props.payeeTypeData) {
            if (
              props.payeeTypeData[pTypeCode].code === data.payeePayerTypeCode
            ) {
              data.payeePayerTypeCode =
                props.payeeTypeData[pTypeCode].description;
            }
          }
        }

        if (data.reasonCode != null && data.reasonCode !== undefined) {
          for (var key in props.initialReasonCodeArray) {
            if (props.initialReasonCodeArray[key].code === data.reasonCode || props.initialReasonCodeArray[key].description === data.reasonCode) {
              data.reasonCode = props.initialReasonCodeArray[key].description;
              data.resonCodeLongDesc = props.initialReasonCodeArray[key].longDescription;
            }
          }
        }
      });

      setShowTable(true);
      errorMessagesArray = [];
      props.showFieldError(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      props.showError(errorMessagesArray);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }

    if (
      payloadPayeesOutstanding != null &&
      payloadPayeesOutstanding.message !== undefined &&
      payloadPayeesOutstanding.message === ARSearchConstants.NETWORK_ERROR
    ) {
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);
    }
  }, [payloadPayeesOutstanding]);

  /*
   * Shwoing error message if payee not found
   * Displaying results in table if more than one payee found
   */
  useEffect(() => {
    if (payloadPayees != null && payloadPayees.length === 0) {
      errorMessagesArray.push(ARSearchConstants.NO_RECORDS_FOUND);
      props.showError(errorMessagesArray);

      setShowTable(false);
    }
    if (payloadPayees != null && payloadPayees.systemFailure) {
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);

      setShowTable(false);
    }
    if (payloadPayees != null && payloadPayees.length > 1) {
      payloadPayees.map(data => {
        if (
          data.payeePayerTypeCode != null &&
          data.payeePayerTypeCode !== undefined
        ) {
          for (var pTypeCode in props.payeeTypeData) {
            if (
              props.payeeTypeData[pTypeCode].code === data.payeePayerTypeCode
            ) {
              data.payeePayerTypeCode =
                props.payeeTypeData[pTypeCode].description;
            }
          }
        }

        if (data.reasonCode != null && data.reasonCode !== undefined) {
          for (var key in props.initialReasonCodeArray) {
            if (props.initialReasonCodeArray[key].code === data.reasonCode || props.initialReasonCodeArray[key].description === data.reasonCode) {
              data.reasonCode = props.initialReasonCodeArray[key].description;
              data.resonCodeLongDesc = props.initialReasonCodeArray[key].longDescription;
            }
          }
        }

        if (data.lob != null && data.lob !== undefined) {
          for (var lobCode in props.lobData) {
            if (props.lobData[lobCode].code === data.lob) {
              data.lob = props.lobData[lobCode].description;
            }
          }
        }
        data.establishedDate = data.establishedDate ? moment(data.establishedDate).format('L') : data.establishedDate;
      });
      errorMessagesArray = [];
      props.showFieldError(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      props.showError(errorMessagesArray);

      setShowTable(true);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }
    if (payloadPayees != null && payloadPayees.length === 1) {
      const withHoldSearchResult = payloadPayees[0];
      if (!props.location.tableRender) {
        if (
          withHoldSearchResult.accountReceivableReasonCode === '107' ||
          withHoldSearchResult.accountReceivableReasonCode === '108'
        ) {
          const advancePaymentData = payloadPayees[0];
          props.history.push({
            pathname: '/AdvancePayment',
            state: { advancePaymentData, values: setSearchData, payloadPayees: payloadPayees }
          });
        } else if (payloadPayees.length !== 0 && payloadPayees[0].activityTypeCode === 'WO') {
          const claimsReceivableData = payloadPayees[0];
          props.history.push({
            pathname: '/FinancialAccountGrossLevelUpdate',
            state: { claimsReceivableData, values: setSearchData, payloadPayees: payloadPayees }
          });
        }
        if (payloadPayees.length !== 0 && (payloadPayees[0].activityTypeCode === 'CR' || payloadPayees[0].activityTypeCode === 'CR-Claims Rec')) {
          const claimsReceivableData = payloadPayees[0];
          props.history.push({
            pathname: '/ClaimsReceivable',
            state: { claimsReceivableData, values: setSearchData, payloadPayees: payloadPayees }
          });
        } else if (payloadPayees.length !== 0 && payloadPayees[0].activityTypeCode === 'WL') {
          const withHoldSearchResult = payloadPayees[0];
          props.history.push({
            pathname: '/FinancialAccountLienLevyUpdate',
            state: { withHoldSearchResult, values: setSearchData, payloadPayees: payloadPayees }
          });
        } else if (payloadPayees.length !== 0 && payloadPayees[0].activityTypeCode === 'GL') {
          const claimsReceivableData = payloadPayees[0];
          props.history.push({
            pathname: '/FinancialAccountGrossLevelUpdate',
            state: { claimsReceivableData, values: setSearchData, payloadPayees: payloadPayees }
          });
        } else if (payloadPayees.length !== 0 && payloadPayees[0].activityTypeCode === 'AP') {
          const advancePaymentData = payloadPayees[0];
          props.history.push({
            pathname: '/AdvancePayment',
            state: { advancePaymentData, values: setSearchData, payloadPayees: payloadPayees }
          });
        } else {
          if (payloadPayees.length !== 0 && payloadPayees[0].activityTypeCode === undefined) {
            errorMessagesArray = [];
            errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
            props.showError(errorMessagesArray);
          }
        }
      } else {
        setShowTable(true);
        let valuetoredirect = 0;
        valuetoredirect = valuetoredirect + 1;
        setRedirect(valuetoredirect);
        setPayeeTableData(payloadPayees);
      }
    }
    if (payloadPayees != null && payloadPayees.message !== undefined) {
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);
    }

    if (payloadPayees !== undefined && payloadPayees == null) {
      errorMessagesArray.push(ARSearchConstants.NO_RECORDS_FOUND);
      props.showError(errorMessagesArray);

      setShowTable(false);
    }
  }, [payloadPayees]);
  const [RsnCdGL, setRsnCdGL] = React.useState([]);
  const [RsnCdAP, setRsnCdAP] = React.useState([]);
  const [RsnCdWL, setRsnCdWL] = React.useState([]);
  const [RsnCdAR, setRsnCdAR] = React.useState([]);
  const [RsnCdRR, setRsnCdRR] = React.useState([]);
  const [RsnCdRT, setRsnCdRT] = React.useState([]);
  const [RsnCdCR, setRsnCdCR] = React.useState([]);
  const [RsnCdWR, setRsnCdWR] = React.useState([]);
  const [RsnCdWO, setRsnCdWO] = React.useState([]);
  useEffect(() => {   
    onReasonCd(reasonCodeDDCriteria);
    setSelectedFromDate(null);
    setSelectedThruDate('12/31/9999');
    setShowTable(false);
    props.showError([]);
  }, []);

  useEffect(() => {
    if (payeeIdTypeDropdown && payeeIdTypeDropdown.payeeIdTypeCode) {
      setPayeeTypeCodeData(payeeIdTypeDropdown.payeeIdTypeCode);
      setPayeeValues({
        payeeIdCode: DEFAULT_DD_VALUE,
        payeeType: values.payeeType
      });
      errorMessagesArray = [];
      props.showError(errorMessagesArray);
    } else if (payeeIdTypeDropdown && payeeIdTypeDropdown.length === 0) {
      setPayeeTypeCodeData(payeeIdTypeDropdown.payeeIdTypeCode);
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);
    } else if (payeeIdTypeDropdown && payeeIdTypeDropdown.systemFailure) {
      setPayeeTypeCodeData(payeeIdTypeDropdown.payeeIdTypeCode);
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);
    }
  }, [payeeIdTypeDropdown]);

  /* Redirecting to edit screen if payee search result(payload) have one record
   * redirect to claims receivable if reason code is "119" or "119-ARClaimFA"
   */
  if (redirect === 1) {
    if (payloadPayees != null) {
      if (payloadPayees.length === 1) {
        if (payloadPayees[0].activityTypeCode === 'CR' ||
          payloadPayees[0].activityTypeCode === 'CR-Claims Rec' ||
          payloadPayees[0].accountReceivableReasonCode === '119' ||
          payloadPayees[0].accountReceivableReasonCode === '119-ARClaimFA'
        ) {
          setShowTable(false);
          const valuetoredirect = redirect - 1;
          setRedirect(valuetoredirect);
          onOutstandingRowSearch(payloadPayees[0].receivableFCN);
        } else {
          if (!props.location.tableRender) {
            props.history.push({
              pathname: '/PayeeSearchResults',
              state: { payloadPayees, values: setSearchData }
            });
          } else {
            setShowTable(true);
            const valuetoredirect = redirect + 1;
            setRedirect(valuetoredirect);
            setPayeeTableData(payloadPayees);
          }
        }
      }
    }
  }

  /* Redirecting to edit screen if payee search result using outstanding(payloadPayeesOutstanding) have one record
   * redirect to claims receivable if activity code is "CR"
   */
  if (redirect === 1) {
    if (payloadPayeesOutstanding != null) {
      if (payloadPayeesOutstanding.length === 1) {
        if (payloadPayeesOutstanding[0].activeTypeCode === 'CR' || payloadPayeesOutstanding[0].activityTypeCode === 'CR-Claims Rec') {
          setShowTable(false);
          const valuetoredirect = redirect - 1;
          setRedirect(valuetoredirect);
          onOutstandingRowSearch(
            payloadPayeesOutstanding[0].financialCtrlNumber
          );
        } else {
          if (!props.location.tableRender) {
            props.history.push({
              pathname: '/PayeeSearchResults',
              state: { payloadPayeesOutstanding, values: setSearchData, payloadPayees: payloadPayeesOutstanding }
            });
          } else {
            setShowTable(true);
            const valuetoredirect = redirect + 1;
            setRedirect(valuetoredirect);
            setPayeeTableData(payloadPayeesOutstanding);
          }
        }
      }
    }
  }

  /* Redirecting to Claims Receivable screen */
  if (claimsReceivableData != null && claimsReceivableData !== '' && !claimsReceivableData.systemFailure) {
    if (claimsReceivableData.activityTypeCode === 'CR' || claimsReceivableData.activityTypeCode === 'CR-Claims Rec') {
      props.history.push({
        pathname: '/ClaimsReceivable',
        state: { claimsReceivableData, values: setSearchData, payloadPayees: payloadPayees }
      });
    } else if (claimsReceivableData.activityTypeCode === 'WL') {
      const withHoldSearchResult = claimsReceivableData;
      props.history.push({
        pathname: '/FinancialAccountLienLevyUpdate',
        state: { withHoldSearchResult, values: setSearchData, payloadPayees: payloadPayees }
      });
    } else if (claimsReceivableData.activityTypeCode === 'GL') {
      props.history.push({
        pathname: '/FinancialAccountGrossLevelUpdate',
        state: { claimsReceivableData, values: setSearchData, payloadPayees: payloadPayees }
      });
    } else if (claimsReceivableData.activityTypeCode === 'AP') {
      const advancePaymentData = claimsReceivableData;
      props.history.push({
        pathname: '/AdvancePayment',
        state: { advancePaymentData, values: setSearchData, payloadPayees: payloadPayees }
      });
    } else if (claimsReceivableData.activityTypeCode === 'WO') {
      props.history.push({
        pathname: '/FinancialAccountGrossLevelUpdate',
        state: { claimsReceivableData, values: setSearchData, payloadPayees: payloadPayees }
      });
    }
  } else {
    if (claimsReceivableData && claimsReceivableData.systemFailure) {
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);
    }
  }

  /* This function is used to set established from date using date picker */
  const handleFromDateChange = date => {
    setSelectedFromDate(date);
  };

  /* This function is used to set established thru date using date picker */
  const handleThruDateChange = date => {
    setSelectedThruDate(date);
  };

  /* This function is used to set last date using date picker */
  const handleLastDateChange = date => {
    setSelectedLastDate(date);
  };

  /* Setting payee type and payee id code values array initially with some default inputs */
  const [payeeValues, setPayeeValues] = React.useState({
    payeeIdCode: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE
  });

  /* This function is used to handle and set the outstanding balance checkbox */
  const handleOutstandingBalanceCheck = () => {
    setOutstandingBalanceCheck(!outstandingBalanceCheck);
  };

  /* This function is used to handle and set the values of fields selected by user */
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  useEffect(() => {
    setValues({ ...values });
    if (values.payeeType && values.payeeType !== '' && values.payeeType !== DEFAULT_DD_VALUE) {
      onSearchPayeeIdType(values.payeeType);
    } else {
      setValues({
        ...values,
        payeeIdCode: DEFAULT_DD_VALUE
      });
    }
  }, [values.payeeType]);

  /* This function is used to handle and set the values of payee id type code based on payee type selected by user */
  useEffect(() => {
    if (reasonCodeDropdownList && reasonCodeDropdownList.listObj) {
      if (reasonCodeDropdownList.listObj['Financial#5']) {
        setRsnCdGL(reasonCodeDropdownList.listObj['Financial#5'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#6']) {
        setRsnCdAP(reasonCodeDropdownList.listObj['Financial#6'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#7']) {
        setRsnCdWL(reasonCodeDropdownList.listObj['Financial#7'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#8']) {
        setRsnCdAR(reasonCodeDropdownList.listObj['Financial#8'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#10']) {
        setRsnCdRR(reasonCodeDropdownList.listObj['Financial#10'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#11']) {
        setRsnCdRT(reasonCodeDropdownList.listObj['Financial#11'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#24']) {
        setRsnCdWR(reasonCodeDropdownList.listObj['Financial#24'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#25']) {
        setRsnCdCR(reasonCodeDropdownList.listObj['Financial#25'].map(item => item.code));
      }
      if (reasonCodeDropdownList.listObj['Financial#3004']) {
        setRsnCdWO(reasonCodeDropdownList.listObj['Financial#3004'].map(item => item.code));
      }
    }
  }, [reasonCodeDropdownList])

  const mapRsnCD = (value) => {
    const activityType = props.activityTypeData.map(x => x.code);
    if (RsnCdAP.includes(value)) {
      return (activityType.includes('AP') ? 'AP' : DEFAULT_DD_VALUE);
    } else if (RsnCdWL.includes(value)) {
      return (activityType.includes('WL') ? 'WL' : DEFAULT_DD_VALUE);
    } else if (RsnCdAR.includes(value)) {
      return (activityType.includes('AR') ? 'AR' : DEFAULT_DD_VALUE);
    } else if (RsnCdRR.includes(value)) {
      return (activityType.includes('RR') ? 'RR' : DEFAULT_DD_VALUE);
    } else if (RsnCdRT.includes(value)) {
      return (activityType.includes('RT') ? 'RT' : DEFAULT_DD_VALUE);
    } else if (RsnCdCR.includes(value)) {
      return (activityType.includes('CR') ? 'CR' : DEFAULT_DD_VALUE);
    } else if (RsnCdWR.includes(value)) {
      return (activityType.includes('WR') ? 'WR' : DEFAULT_DD_VALUE);
    } else if (RsnCdWO.includes(value)) {
      return (activityType.includes('WO') ? 'WO' : DEFAULT_DD_VALUE);
    } else if (RsnCdGL.includes(value)) {
      return (activityType.includes('GL') ? 'GL' : DEFAULT_DD_VALUE);
    } else {
      return DEFAULT_DD_VALUE;
    }
  };

  /* This function is used to handle and set the values of reason code based on activity type selected by user and vice versa */
  const handleChangeActivityType = name => event => {
    setActivityReasonCodeValues({ ...values, [name]: event.target.value });

    if (name === ARSearchConstants.ACTIVITY_CODE) {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        getReasonCodeMapData(event.target.value);
        setActivityReasonCodeValues({
          activityCode: event.target.value,
          reasonCode: DEFAULT_DD_VALUE
        });
      } else {
        setActivityReasonCodeValues({
          activityCode: DEFAULT_DD_VALUE,
          reasonCode: DEFAULT_DD_VALUE
        });
        props.setReasonCodeVal(props.initialReasonCodeArray);
      }
    } else if (name === ARSearchConstants.REASON_CODE) {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        const activityTypeCd = mapRsnCD(event.target.value);
        setActivityReasonCodeValues({
          activityCode: activityTypeCd,
          reasonCode: event.target.value
        });
      } else {
        setActivityReasonCodeValues({
          activityCode: activityReasonCodeValues.activityCode,
          reasonCode: DEFAULT_DD_VALUE
        });
      }
    }
  };

  /* This function is used to resetting values, error messages and search results to its default state */
  const resetPayee = () => {
    onReset();
    setOutstandingBalanceCheck(false);
    showATRError(false);
    setValues({
      lob: DEFAULT_DD_VALUE,
      payee: '',
      fundCode: DEFAULT_DD_VALUE,
      payeeStatus: DEFAULT_DD_VALUE,
      payeeType: DEFAULT_DD_VALUE,
      atrNumber: ''
    });
    setPayeeValues({
      payeeIdCode: DEFAULT_DD_VALUE
    });
    setActivityReasonCodeValues({
      activityCode: DEFAULT_DD_VALUE,
      reasonCode: DEFAULT_DD_VALUE
    });

    setSelectedFromDate(null);
    setSelectedThruDate('12/31/9999');
    setSelectedLastDate(null);
    setPayeeTypeCodeData([]);
    props.setReasonCodeVal(props.initialReasonCodeArray);

    setShowTable(false);
    errorMessagesArray = [];
    props.showFieldError(
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    );
    props.showError(errorMessagesArray);
    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  /* Check if text enetered is empty or contains only white spaces */

  /* This function is used to validate all input fields and search payee/payer if validations passed */
  const searchPayee = () => {
    sessionStorage.setItem('tabValue', 1);
    props.location.tableRender = false;
    var showPayeeError = false;
    var showPayeeIdTypeCodeError = false;
    var showPayeeTypeError = false;
    var showFromDateError = false;
    var showThruDateError = false;
    var showLastDateError = false;
    var showThDateError = false;
    var showFrDateError = false;
    var lastDateErrorTx = false;
    const regex = /[^A-Za-z0-9]/;

    errorMessagesArray = [];

    setShowTable(false);
    props.setPayeeTab(true);

    if (
      (values.payeeType &&
        values.payeeType !== DEFAULT_DD_VALUE) ||
      (values.payeeIdCode &&
        values.payeeIdCode !== DEFAULT_DD_VALUE) ||
      (values.payee.trim() !== null && values.payee.trim() !== '')
    ) {
      if (
        !values.payeeType ||
        values.payeeType === DEFAULT_DD_VALUE
      ) {
        setShowTable(false);
        showPayeeTypeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_TYPE_REQUIRED);
        setPayeeTypeErrorMeg(ARSearchConstants.PAYEE_TYPE_REQUIRED);
        props.showError(errorMessagesArray);
      }
      if (
        !values.payeeIdCode ||
        values.payeeIdCode === DEFAULT_DD_VALUE
      ) {
        setShowTable(false);
        showPayeeIdTypeCodeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
        setPayeeCodeErrorMeg(ARSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
        props.showError(errorMessagesArray);
      }
      if (values.payee.trim() === null || values.payee.trim() === '') {
        setShowTable(false);
        showPayeeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_REQUIRED);
        setErrorMeg(ARSearchConstants.PAYEE_REQUIRED);
        props.showError(errorMessagesArray);
      }
    }
    if (values.atrNumber && regex.test(values.atrNumber)) {
      showATRError(true);
      errorMessagesArray.push(`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ARSearchConstants.TRANSACTION_NUMBER_FORMAT}`);
      props.showError(errorMessagesArray);
    } else { showATRError(false); }

    /* if user enters any text in from date field it will validate that entered text for a valid date format */
    if (selectedFromDate) {
      let isDateInValid = false;
      if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedFromDate))) {
        isDateInValid = validateDateMinimumValue(
          getDateInMMDDYYYYFormat(selectedFromDate)
        );
        if (isDateInValid) {
          showFromDateError = true;
          setErrorMegFromDate(AppConstants.DATE_ERROR_1964);
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          props.showError(errorMessagesArray);
        }
      }
    }

    /* if user enters any text in thru date field it will validate that entered text for a valid date format */
    if (selectedThruDate) {
      let isDateInValid = false;
      if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedThruDate))) {
        isDateInValid = validateDateMinimumValue(
          getDateInMMDDYYYYFormat(selectedThruDate)
        );
        if (isDateInValid) {
          showThruDateError = true;
          setErrorMegThruDate(AppConstants.DATE_ERROR_1964);
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          props.showError(errorMessagesArray);
        }
      }
    }

    /* if user enters any text in last date field it will validate that entered text for a valid date format */
    if (selectedLastDate) {
      let isDateInValid = false;
      if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedLastDate))) {
        isDateInValid = validateDateMinimumValue(
          getDateInMMDDYYYYFormat(selectedLastDate)
        );
        if (isDateInValid) {
          showLastDateError = true;
          setErrorMegLastDate(AppConstants.DATE_ERROR_1964);
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          props.showError(errorMessagesArray);
        }
      }
    }

    // field level error msg
    if (
      (selectedFromDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedFromDate)))
    ) {
      showFrDateError = true;
    }
    if (
      (selectedThruDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedThruDate)))
    ) {
      showThDateError = true;
    }
    if (
      (selectedLastDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedLastDate)))
    ) {
      lastDateErrorTx = true;
    }
    // header level error msg
    if (
      (selectedFromDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedFromDate))) ||
      (selectedThruDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedThruDate))) ||
      (selectedLastDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedLastDate)))
    ) {
      setErrorMegFromDate(AppConstants.INCORRECT_DATA_FORMAT);
      errorMessagesArray.push(AppConstants.INCORRECT_DATA_FORMAT);
      props.showError(errorMessagesArray);
    }

    /* if user selects from date and thru date, compare dates */
    if (selectedFromDate && selectedThruDate) {
      var fromDate = new Date(selectedFromDate);
      var thruDate = new Date(selectedThruDate);
      if (!compareTwoDates(fromDate, thruDate)) {
        errorMessagesArray.push(ARSearchConstants.DATE_GREATER_ERROR);
        errorMessagesArray.push(ARSearchConstants.DATE_GREATER_ERROR2);
        props.showError(errorMessagesArray);
        showFromDateError = false;
        showThruDateError = false;
      }
    }

    props.showFieldError(
      false,
      false,
      showPayeeError,
      showThruDateError,
      showFromDateError,
      showLastDateError,
      showPayeeTypeError,
      showPayeeIdTypeCodeError,
      showThDateError,
      showFrDateError,
      lastDateErrorTx
    );

    /* if all validations passed on click of Search button it will hit the API to search for a payee/payer */
    if (errorMessagesArray.length === 0) {
      isSetSearch(true);
      errorMessagesArray = [];
      props.showError(errorMessagesArray);
      onReset();
      const searchCriteria = removeLeadingTrailingSpaces({
        lob: [AppConstants.DEFAULT_LOB_VALUE],
        atrNumber: values?.atrNumber?.toUpperCase(),
        payeePayerID: values.payee !== '' ? values.payee : null,
        activityType:
          activityReasonCodeValues.activityCode !==
            DEFAULT_DD_VALUE
            ? activityReasonCodeValues.activityCode
            : null,
        fundCode:
          values.fundCode !== DEFAULT_DD_VALUE
            ? values.fundCode
            : null,
        payeeStatus:
          values.payeeStatus !== DEFAULT_DD_VALUE
            ? values.payeeStatus
            : null,
        payeePayerTypeCode:
          values.payeeIdCode !== DEFAULT_DD_VALUE
            ? values.payeeIdCode
            : null,
        reasonCode:
          activityReasonCodeValues.reasonCode !== DEFAULT_DD_VALUE
            ? activityReasonCodeValues.reasonCode
            : null,
        payeePayerType:
          values.payeeType !== DEFAULT_DD_VALUE
            ? values.payeeType
            : null,
        establishedDateFrom:
          selectedFromDate
            ? moment(selectedFromDate).format('MM/DD/YYYY')
            : null,
        establishedDateTo:
          selectedThruDate
            ? moment(selectedThruDate).format('MM/DD/YYYY')
            : null,
        lastAppliedDate:
          selectedLastDate
            ? moment(selectedLastDate).format('MM/DD/YYYY')
            : null,
        payeeSearch: true,
        outstandingBalanceIndicator: outstandingBalanceCheck
      });
      if (
        !searchCriteria.payeePayerID &&
        !searchCriteria.outstandingBalanceIndicator
      ) {
        Confirm(searchCriteria);
        if (!props.history.state) {
          props.history.state = {};
        }
        props.history.state.values = searchCriteria;
      } else {
        onSearch(searchCriteria);
        if (!props.history.state) {
          props.history.state = {};
        }
        props.history.state.values = searchCriteria;
      }
    }
  };

  function Confirm (searchCriteria) {
    Swal.fire({
      customClass: {
        confirmButton: 'btn-text1 btn-transparent mr-3',
        cancelButton: 'btn1 btn-success',
        container: 'unsaved-changes-popup1'
      },
      allowOutsideClick: false,
      buttonsStyling: false,
      heightAuto: false,
      icon: 'warning',
      html:
        '<h4>Search without Payee ID will be defaulted to retrieve A/Rs with outstanding balance.</h4><h4>Please click Cancel to narrow your search results.</h4 >',
      showCancelButton: true,
      confirmButtonText: 'Cancel!',
      cancelButtonText: 'Continue! <i class="fa fa-arrow-right ml-1"></i>'
    }).then(result => {
      if (result.value) {
      } else {
        searchCriteria.outstandingBalanceIndicator = true;
        setOutstandingBalanceCheck(true);
        onSearch(searchCriteria);
      }
    });
  }

  useEffect(() => {
    /**
 * props.reasonCodeData will get sorted correctly
*/
    props.reasonCodeData.sort((a, b) => (a.code - b.code));
  }, []);

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (valuesFromAREdit !== undefined) {
        errorMessagesArray = [];
        props.showError(errorMessagesArray);
        const searchCriteria = removeLeadingTrailingSpaces({
          lob: [AppConstants.DEFAULT_LOB_VALUE],
          payeePayerID: valuesFromAREdit.payeePayerID !== '' ? valuesFromAREdit.payeePayerID : null,
          atrNumber: valuesFromAREdit.atrNumber,
          activityType:
          valuesFromAREdit.activityType !==
              DEFAULT_DD_VALUE
            ? valuesFromAREdit.activityType
            : null,
          fundCode:
            valuesFromAREdit.fundCode !== DEFAULT_DD_VALUE
              ? valuesFromAREdit.fundCode
              : null,
          payeePayerTypeCode:
            valuesFromAREdit.payeePayerTypeCode !== DEFAULT_DD_VALUE
              ? valuesFromAREdit.payeePayerTypeCode
              : null,
          reasonCode:
            valuesFromAREdit.reasonCode !== DEFAULT_DD_VALUE
              ? valuesFromAREdit.reasonCode
              : null,
          payeePayerType:
            valuesFromAREdit.payeePayerType !== DEFAULT_DD_VALUE
              ? valuesFromAREdit.payeePayerType
              : null,
          payeeStatus:
            valuesFromAREdit.payeeStatus !== DEFAULT_DD_VALUE
              ? valuesFromAREdit.payeeStatus
              : null,
          establishedDateFrom:
          valuesFromAREdit?.establishedDateFrom,
          establishedDateTo:
          valuesFromAREdit?.establishedDateTo,
          lastAppliedDate:
          valuesFromAREdit.lastAppliedDate,
          payeeSearch: true,
          outstandingBalanceIndicator: valuesFromAREdit.outstandingBalanceIndicator
        });

        if (
          !searchCriteria.outstandingBalanceIndicator
        ) {
          setOutstandingBalanceCheck(true);
          onSearch(searchCriteria);
        } else {
          onSearch(searchCriteria);
        }
        setValues({
          ...valuesFromAREdit,
          lob: valuesFromAREdit.lob ? valuesFromAREdit.lob[0] : DEFAULT_DD_VALUE,
          payee: valuesFromAREdit.payeePayerID || '',
          fundCode: valuesFromAREdit.fundCode || DEFAULT_DD_VALUE,
          lastDate: valuesFromAREdit.lastAppliedDate || '',
          payeeIdCode: valuesFromAREdit.payeePayerTypeCode || DEFAULT_DD_VALUE,
          payeeType: valuesFromAREdit.payeePayerType || DEFAULT_DD_VALUE,
          payeeStatus: valuesFromAREdit.payeeStatus || DEFAULT_DD_VALUE
        });

        setActivityReasonCodeValues({
          activityCode: valuesFromAREdit.activityType || DEFAULT_DD_VALUE,
          reasonCode: valuesFromAREdit.reasonCode || DEFAULT_DD_VALUE
        });
        setSelectedFromDate(valuesFromAREdit.establishedDateFrom);
        setSelectedThruDate(valuesFromAREdit.establishedDateTo);
        setOutstandingBalanceCheck(valuesFromAREdit.outstandingBalanceCheck || valuesFromAREdit.outstandingBalanceIndicator);
      };
    }
  }, [props.location.tableRender]);

  useEffect(() => {
    if (searchValues) {
      setValues({
        ...values,
        lob: searchValues.lob ? searchValues.lob[0] : DEFAULT_DD_VALUE,
        payee: searchValues.payeePayerID || '',
        fundCode: searchValues.fundCode || DEFAULT_DD_VALUE,
        lastDate: searchValues.lastAppliedDate || '',
        payeeIdCode: searchValues.payeePayerTypeCode || DEFAULT_DD_VALUE,
        payeeType: searchValues.payeePayerType || DEFAULT_DD_VALUE,
        payeeStatus: searchValues.payeeStatus || DEFAULT_DD_VALUE
      });
      setActivityReasonCodeValues({
        activityCode: searchValues.activityType || DEFAULT_DD_VALUE,
        reasonCode: searchValues.reasonCode || DEFAULT_DD_VALUE
      });
      setSelectedFromDate(searchValues.establishedDateFrom);
      setSelectedThruDate(searchValues.establishedDateTo);
      setOutstandingBalanceCheck(searchValues.outstandingBalanceCheck || searchValues.outstandingBalanceIndicator);
    }
  }, [searchValues]);

  const activityCode = props.activityTypeData && props.activityTypeData.find((value) => value.code === activityReasonCodeValues.activityCode);
  const reasonCodeVal = props.reasonCodeData && props.reasonCodeData.find((value) => value.code === activityReasonCodeValues.reasonCode);

  return (
    <div>
      <form noValidate autoComplete="off">
        <div className="form-wrapper">
          <NativeDropDown
            id="payeeType"
            label="Payee Type"
            value={values.payeeType}
            onChange={handleChange('payeeType')}
            errTxt={props.showPayeeTypeError ? errorTypeMeg : null}
            dropdownList={props.payeeTypeData.map((item, index) => (
              <option key={index} value={item.code}>
                {item.description}
              </option>
            ))}
          />

          <NativeDropDown
            id="payeeIdCode"
            label="Payee ID Type Code"
            value={values.payeeIdCode || DEFAULT_DD_VALUE}
            disabled={values.payeeType === DEFAULT_DD_VALUE}
            onChange={handleChange('payeeIdCode')}
            errTxt={props.showPayeeIdTypeCodeError ? errorCodeMeg : null}
            dropdownList={payeeTypeCodeData ? payeeTypeCodeData.map(option => (
              <option key={option.code} value={option.code}>
                {option.description}
              </option>
            )) : []}
          />

          <div className="mui-custom-form input-md">
            <TextField
              id="payee"
              fullWidth
              label="Payee ID"
              InputLabelProps={{
                shrink: true
              }}
              value={values.payee}
              onChange={handleChange('payee')}
              inputProps={{ maxLength: 15 }}
              helperText={props.showPayeeError ? errorMeg : null}
              error={props.showPayeeError ? errorMeg : null}
            />
          </div>
        </div>
        <div className="form-wrapper">
          <NativeDropDown
            id="activityCode"
            label="AR Group Type"
            value={activityCode !== undefined ? activityReasonCodeValues.activityCode : DEFAULT_DD_VALUE}
            onChange={handleChangeActivityType('activityCode')}
            dropdownList={props.activityTypeData.map((item, index) => (
              <option key={index} value={item.code}>
                {item.description}
              </option>
            ))}
          />

          <NativeDropDown
            id="reasonCode"
            label="Reason Code"
            value={reasonCodeVal !== undefined ? activityReasonCodeValues.reasonCode : DEFAULT_DD_VALUE}
            onChange={handleChangeActivityType('reasonCode')}
            title={getLongDescription(props.reasonCodeData, activityReasonCodeValues.reasonCode)}
            dropdownList={props.reasonCodeData.map((item, index) => (
              <option key={index} value={item.code}>
                {item.description}
              </option>
            ))}
          />

          <NativeDropDown
            id="fundCode"
            label="Fund Code"
            value={values.fundCode}
            onChange={handleChange('fundCode')}
            title={getLongDescription(props.fundCodeData, values.fundCode)}
            dropdownList={props.fundCodeData
              ? props.fundCodeData.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              ))
              : null}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form input-date with-date">
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="lastDate"
                data-testid="last_date"
                label="Last Applied Date"
                format="MM/dd/yyyy"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="mm/dd/yyyy"
                value={selectedLastDate}
                onChange={handleLastDateChange}
                helperText={props.showLastDateError ? errorMegLastDate : props.showLastDtError ? AppConstants.INCORRECT_FORMAT : null}
                error={props.showLastDateError ? errorMegLastDate : props.showLastDtError ? AppConstants.INCORRECT_FORMAT : null}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="form-wrapper">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form input-xxl with-date">
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="fromDate"
                label="Established From Date"
                data-testid="from_date"
                format="MM/dd/yyyy"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="mm/dd/yyyy"
                value={selectedFromDate}
                onChange={handleFromDateChange}
                helperText={props.showFromDateError ? errorMegFromDate : props.showFrDateError ? AppConstants.INCORRECT_FORMAT : null}
                error={props.showFromDateError ? errorMegFromDate : props.showFrDateError ? AppConstants.INCORRECT_FORMAT : null}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form input-xxl with-date">
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="thruDate"
                data-testid="thru_date"
                label="Established Thru Date"
                format="MM/dd/yyyy"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="mm/dd/yyyy"
                value={selectedThruDate}
                onChange={handleThruDateChange}
                helperText={props.showThruDateError ? errorMegThruDate : props.showThDateError ? AppConstants.INCORRECT_FORMAT : null}
                error={props.showThruDateError ? errorMegThruDate : props.showThDateError ? AppConstants.INCORRECT_FORMAT : null}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
          </MuiPickersUtilsProvider>

          <div className="mui-custom-form input-md">
            <TextField
              id="txtATRNumber"
              fullWidth
              label= {process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
              InputLabelProps={{
                shrink: true
              }}
              value={values.atrNumber}
              onChange={handleChange('atrNumber')}
              inputProps={{ maxLength: 18 }}
              helperText={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ARSearchConstants.TRANSACTION_NUMBER_FORMAT}` : null}
              error= {atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ARSearchConstants.TRANSACTION_NUMBER_FORMAT}` : null}
            />
          </div>

          <NativeDropDown
            id="payeeStatus"
            label="Payee Status"
            value={values.payeeStatus}
            onChange={handleChange('payeeStatus')}
            title={getLongDescription(props.payeePaymentStatusFrmState, values.payeeStatus)}
            dropdownList={props.payeePaymentStatusFrmState
              ? props.payeePaymentStatusFrmState.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              ))
              : null}
          />

          <div className="mui-custom-form sub-radio float-left no-margin-label margin-top-24 override-width-45">
            <FormControlLabel
              value="balance"
              control={
                <Checkbox
                  color="primary"
                  checked={outstandingBalanceCheck}
                  value={outstandingBalanceCheck}
                  onChange={handleOutstandingBalanceCheck}
                />
              }
              label="Select A/R's with an Outstanding Balance"
              labelPlacement="start"
            />
          </div>
        </div>
        <div>
          <div className="float-right mr-3 mb-2">
            <Button className="btn-tab-pannel" onClick={() => searchPayee()} data-testid="SearchButton">
              <i className="fa fa-search" aria-hidden="true"></i>
              Search
            </Button>

            <Button
              variant="outlined"
              color="primary"
              className="bt-tabreset btn-transparent ml-1"
              onClick={() => resetPayee()}
            >
              <i className="fa fa-undo" aria-hidden="true"></i>
              Reset
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
      </form>
      {redirect > 0 && showTable ? (
        <div className="tab-holder my-3 p-0 tableNoWrap">
          <AccountRecevSearchResultsComponent
            tableData={payloadPayees || payloadPayeesOutstanding || payeeTableData}
            payeeTypeCodeDataInitial={props.payeeTypeCodeDataInitial}
            values= {setSearchData}
            payloadPayees={payloadPayees}
            dropdowns={dropdownData}
          />
        </div>
      ) : null}

    </div>
  );
}

export default withRouter(PayeeReceivable);
