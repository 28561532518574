/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDropdownDataAction,
  searchLockBoxDetailsAction
} from "../Store/Actions/BankAccountAndLockBoxSearchActions";
import { withRouter } from "react-router";
import { dateSubString } from "../../../SharedModules/DateUtilities/DateUtilities";
import BankAccountAndLockboxTable from "./BankAccountAndLockBoxTable";
import BankAccountAndLockBoxSearchform from "./BankAccountAndLockBoxSearchform";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";

import * as BankAccountLockBoxConstants from "./ErrorConstants";
import dropdownCriteria from "./BankAccountAndLockBox.json";
import Footer from "../../../SharedModules/Layout/footer";
import { removeLeadingTrailingSpaces } from "../../../SharedModules/CommonUtilities/commonUtilities";

function BankAccountAndLockbox (props) {
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const [lobData, setLobData] = React.useState([]);
  const [searchTableData, setSearchTableData] = React.useState([]);

  const [showTable, setShowTable] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);

  // Redux call for lob data
  const getLobData = () => {
    dispatch(getDropdownDataAction(dropdownCriteria));
  };
  const [values, setValues] = React.useState({
    lob: "Please Select One",
    bankName: "",
    routingBank: "",
    account: "",
    accountName: "",
    lockBox: "",
    lockBoxName: ""
  });

  const lobDataFrmState = useSelector(
    (state) => state.bankAccountState.dropdownData
  );
  useEffect(() => {
    seterrorMessages([]);
    if (
      lobDataFrmState &&
      lobDataFrmState.listObj &&
      lobDataFrmState.listObj["Reference#1019"]
    ) {
      setLobData(lobDataFrmState.listObj["Reference#1019"]);
    }
    if (lobDataFrmState && lobDataFrmState.systemFailure) {
      const errorArray = [];
      errorArray.push(BankAccountLockBoxConstants.SYSTEM_ERROR);
      seterrorMessages(errorArray);
      setLobData([]);
    }
  }, [lobDataFrmState]);

  useEffect(() => {
    if (lobData.length === 0) {
      getLobData();
    }
    payload = null;
  }, []);

  // Redux calls for search
  const onSearch = (values) => {
    setSearchTableData([]);

    setValues(values);
    dispatch(searchLockBoxDetailsAction(removeLeadingTrailingSpaces(values)));
  };
  let payload = useSelector((state) => state.bankAccountState.lockBoxData);
  // let newPayload = payload.flat();
  useEffect(() => {
    const errorArray = [];
    if (payload && payload.systemFailure) {
      errorArray.push(BankAccountLockBoxConstants.SYSTEM_ERROR);
      seterrorMessages(errorArray);
      setSearchTableData([]);
    }
    if (payload && !payload.systemFailure) {
      if (payload && payload.length > 1) {
        payload.map((val) => {
          if (val.beginDate) {
            val.beginDate = dateSubString(val.beginDate);
          }
          if (val.endDate) {
            val.endDate = dateSubString(val.endDate);
          }
        });
        setSearchTableData(payload);
        seterrorMessages(errorArray);
      } else if (payload && payload.length === 0) {
        errorArray.push(BankAccountLockBoxConstants.NO_RECORDS_FOUND);
        seterrorMessages(errorArray);
        setShowTable(false);
        setSearchTableData([]);
      } else if (payload && payload.length === 1) {
        if (!props.location.tableRender) {
          var row = payload;
          props.history.push({
            pathname: "/BankAccountLockBoxEdit",
            state: { payloadData: row[0], values: values }
          });
        } else {
          setShowTable(true);
          payload[0].lockBoxName = payload[0].lockBoxList[0].lockBoxName;
          payload[0].lockBoxNumber = payload[0].lockBoxList[0].lockBoxNumber;
          setSearchTableData(payload);
        }
      }
    }
  }, [payload]);

  useEffect(() => {
    if (props?.history?.state?.values !== undefined) {

    }
  }, [props?.history?.state?.values]);

  const addBankAcc = () => {
    props.location.tableRender = false;
    props.history.push({
      pathname: "/BankAccountLockBoxAdd"
    });
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <div className="tab-heading float-left">Search Bank Account</div>
          <div className="float-right mt-1 pt-1">
            <Button
              title="Add Bank Account"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addBankAcc()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>

          </div>
          <div className="clearfix"></div>
        </div>
        <div className="MuiExpansionPanel-root">
          <div className="MuiCollapse-container MuiCollapse-entered">
            <div className="MuiExpansionPanelDetails-root">
              {/* form goes here */}
              <div className="tab-body">
                <BankAccountAndLockBoxSearchform
                  lobDataFrmState={lobData || []}
                  showTable={!!showTable}
                  setShowTable={setShowTable}
                  seterrorMessages={seterrorMessages}
                  onSearch={onSearch}
                />
                {showTable ? (
                  <div className="">
                    <BankAccountAndLockboxTable
                      tableData={searchTableData || []}
                      values={values}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
export default withRouter(BankAccountAndLockbox);
