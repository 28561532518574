/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import SystemListSearchTableComponent from './SystemListSearchTableComponent';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
  systemListActions,
  resetSystemList
} from '../../../Store/Actions/systemList/systemListActions';
import * as SystemListConstants from '../Search/SystemListSearchConstants';

import { withRouter } from 'react-router';

import {
  AppConfigDropdownActions,
  DataElementDropdownActions
} from '../../../Store/Actions/AppConfigCommon/AppConfigActions';
import dropdownCriteria from './SystemListSearch.json';
import { Checkprivileges } from '../../../../../SharedModules/store/Actions/SharedAction';
import * as AppConstants from '../../../../../SharedModules/AppConstants';
import Footer from '../../../../../SharedModules/Layout/footer';
import Radio from '@material-ui/core/Radio';
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

function SystemListSearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();

  const toPrintRef = useRef();
  const onReset = () => dispatch(resetSystemList());
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));
  const dataElemDropDownDispatch = () => dispatch(DataElementDropdownActions());
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
    dataElemDropDownDispatch();
    onDropdowns([
      Dropdowns.R_FUNC_AREA_CD
    ]);
  }, []);
  const [showTable, setShowTable] = React.useState(false);

  let errorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    { showListNumberError, showBusinessNameError, showDescriptionError, showDataElementNameError },
    setShowError
  ] = React.useState(false);

  const [listNumberStartsWith, setListNumberStartsWith] = React.useState(false);
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [listTypeData, setListTypeData] = React.useState([]);
  const [dataElementNameData, setDataElementNameData] = React.useState([]);

  const [showNoRecords, setShowNoRecords] = React.useState(false);

  const handleChangeList = () => {
    setListNumberStartsWith(!listNumberStartsWith);
  };
  const dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );
  const datElemDropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.dataElementNameDropdown
  );

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1017']) {
        setFunctionalAreaData(dropdown.listObj['Reference#1017']);
      }
      if (dropdown.listObj['Reference#2']) {
        setListTypeData(dropdown.listObj['Reference#2']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (datElemDropdown && datElemDropdown.listDataElements) {
      setDataElementNameData(datElemDropdown.listDataElements);
    }
  }, [datElemDropdown]);
  const defaultValues = {
    functionalArea: DEFAULT_DD_VALUE,
    listNumber: null,
    businessName: null,
    dataElementName: DEFAULT_DD_VALUE,
    description: "",
    listType: DEFAULT_DD_VALUE,
    value: null,
    columnName: null,
    tableName: null
  };

  const [values, setValues] = React.useState(defaultValues);

  const handleChanges = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const resetTable = () => {
    onReset();
    setShowNoRecords(false);
    seterrorMessages([]);
    setListNumberStartsWith(false);
    setShowError({
      showListNumberError: false,
      showBusinessNameError: false,
      showDescriptionError: false
    });
    setValues(defaultValues);
    setShowTable(false);
  };

  const [isSearch, setIsSearch] = React.useState(false);

  const [redirect, setRedirect] = React.useState(0);
  const onSearch = values => {
    setIsSearch(true);
    dispatch(systemListActions(values));
  };
  let paylod = useSelector(state => state.appConfigState.systemListState.payload);
  useEffect(() => {
    onReset();
    paylod = null;
  }, []);

  useEffect(() => {
    if (paylod && !paylod.systemFailure && paylod.length === 0) {
      setShowNoRecords(true);
    }
    if (paylod && paylod.systemFailure) {
      seterrorMessages([AppConstants.ERR_PROCESSING_REQ]);
    }
    if (paylod && !paylod.systemFailure && paylod.length > 0) {
      setShowTable(true);
    }
  }, [paylod]);

  const payloadData = paylod && !paylod.systemFailure ? paylod[0] : {};

  const functionalAreaDesc = data => {
    const filteredValue = functionalAreaData.filter(
      (prv, index) => prv.code === data
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return data;
  };

  if (paylod && paylod.length && paylod != null) {
    paylod.map((data, index) => {
      if (data.functionalArea !== '') {
        data.functionalAreaDesc = functionalAreaDesc(data.functionalArea);
      }
    });
  }

  const setValuesFromSysList = props?.history?.state?.values;
  useEffect(() => {
    if (redirect === 1) {
      if (paylod != null) {
        if (paylod.length === 1) {
          props.history.push({
            pathname: '/SystemListAddUpdate',
            state: { payloadData, values: { ...values, listNumberStartsWith } }
          });
        }
      }
    }
  }, [paylod]);

  useEffect(() => {
    if (props.location.tableRender && !isSearch && !props.history.state?.isFromAdd) {
       if (setValuesFromSysList !== undefined) {
        const searchCriteria = {
          listNumber: setValuesFromSysList.listNumber !== null ? +setValuesFromSysList.listNumber : null,
          businessName: setValuesFromSysList.businessName !== null ? setValuesFromSysList.businessName : null,
          functionalArea:
            setValuesFromSysList.functionalArea !== DEFAULT_DD_VALUE
              ? setValuesFromSysList.functionalArea
              : null,
          dataElementName:
            setValuesFromSysList.dataElementName !== DEFAULT_DD_VALUE
              ? setValuesFromSysList.dataElementName
              : null,
          description:
            setValuesFromSysList.descriptionescription !== '' && setValuesFromSysList.descriptionescription !== null ? setValuesFromSysList.description : null,
          value: setValuesFromSysList.value !== null ? setValuesFromSysList.value : null,
          listType:
            setValuesFromSysList.listType !== DEFAULT_DD_VALUE ? setValuesFromSysList.listType : null,
          descriptionStartsOrContains:
            setValuesFromSysList?.descriptionStartsOrContains,
          businessNameStartsOrContains:
            setValuesFromSysList.businessNameStartsOrContains,
          listNumberStartsWith: setValuesFromSysList.listNumberStartsWith
        };
        onSearch(searchCriteria);
        setValues({
...setValuesFromSysList,
          descriptionSelected: setValuesFromSysList.descriptionStartsOrContains === 0 ? "StartsWith" : setValuesFromSysList.descriptionStartsOrContains === 1 ? "Contains" : null,
          businessNameSelected: setValuesFromSysList.businessNameStartsOrContains === 0 ? "StartsWith" : setValuesFromSysList.businessNameStartsOrContains === 1 ? "Contains" : null
        });
        setListNumberStartsWith(setValuesFromSysList.listNumberStartsWith);
        setShowTable(true);
        seterrorMessages([]);
      }
    }
  }, [props.location.tableRender]);

  const searchCheck = values => {
    setRedirect(0);
    errorMessagesArray = [];
    seterrorMessages([]);
    setShowNoRecords(false);
    var showListNumberError;
    var showBusinessNameError;
    var showDescriptionError = false;
    var showDataElementNameError = false;
    if (
      listNumberStartsWith &&
      (!values.listNumber || values.listNumber.length < 2)
    ) {
      showListNumberError = true;
      errorMessagesArray.push(SystemListConstants.LIST_NUMBER_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (
      values.businessNameSelected &&
      (!values.businessName ||
        values.businessName.length < 2 ||
        (values.businessName && !values.businessName.trim()))
    ) {
      showBusinessNameError = true;
      errorMessagesArray.push(SystemListConstants.BUSINESS_NAME_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (values.value && values.value.trim() && values.dataElementName === DEFAULT_DD_VALUE) {
      showDataElementNameError = true;
      errorMessagesArray.push(SystemListConstants.DATAELEMENT_NAME_REQUIRED);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (
      values.descriptionSelected &&
      (!values.description ||
        values.description.length < 2 ||
        (values.description && !values.description.trim()))
    ) {
      showDescriptionError = true;
      errorMessagesArray.push(SystemListConstants.DESCRIPTION_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    } else if (errorMessagesArray.length === 0) {
      setShowTable(true);
      const searchCriteria = {
        listNumber: values.listNumber !== null ? +values.listNumber : null,
        businessName: values.businessName !== null ? values.businessName : null,
        functionalArea:
          values.functionalArea !== DEFAULT_DD_VALUE
            ? values.functionalArea
            : null,
        dataElementName:
          values.dataElementName !== DEFAULT_DD_VALUE
            ? values.dataElementName
            : null,
        description:
          values.descriptionescription !== '' ? values.description : null,
        value: values.value !== null ? values.value : null,
        listType:
          values.listType !== DEFAULT_DD_VALUE ? values.listType : null,
        descriptionStartsOrContains:
          values.descriptionSelected === 'StartsWith'
            ? 0
            : values.descriptionSelected === 'Contains'
              ? 1
              : null,
        businessNameStartsOrContains:
          values.businessNameSelected === 'StartsWith'
            ? 0
            : values.businessNameSelected === 'Contains'
              ? 1
              : null,
        listNumberStartsWith: listNumberStartsWith
      };
      onSearch(searchCriteria);
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = searchCriteria;
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }
    setShowError({
      showListNumberError: showListNumberError,
      showBusinessNameError: showBusinessNameError,
      showDescriptionError: showDescriptionError,
      showDataElementNameError: showDataElementNameError
    });
    setShowTable(false);
  };

  const addsysList = () => {
    props.history.push({
      pathname: '/SystemListAdd'
    });
  };
  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>
        {errorMessages.length > 0 ? (
          <div className="alert alert-danger custom-alert" role="alert">
            {errorMessages.map(message => (
              <li key={message}>{message}</li>
            ))}
          </div>
        ) : null}
        {errorMessages.length === 0 && paylod !== undefined && showNoRecords ? (
          <div className="alert alert-danger custom-alert" role="alert">
            <li>{SystemListConstants.NO_RECORDS_FOUND}</li>
          </div>
        ) : null}
        {errorMessages.length === 0 && paylod === null ? (
          <div className="alert alert-danger custom-alert" role="alert">
            <li>{SystemListConstants.ERROR_OCCURED_DURING_TRANSACTION}</li>
          </div>
        ) : null}
        <div className="tab-header">
          <div className="tab-heading float-left">Search System List</div>
          <div className="float-right mt-2">
            <Button
              title="Add System List"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addsysList()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="collapsable-panel"></div>
        <div>
          <div className="tab-body">
            <form autoComplete="off">
              <div className="form-wrapper">
                  <NativeDropDown
                    id="standard-select-functionalArea"
                    label="Functional Area"
                    value={values.functionalArea || ''}
                    onChange={handleChanges('functionalArea')}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    dropdownList={functionalAreaData
                      ? functionalAreaData.map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      ))
                      : null}
                  />

                <div className="mui-custom-form">
                  <TextField
                    id="standard-listNumber"
                    fullWidth
                    label="List Number"
                    type="number"
                    value={values.listNumber || ""}
                    onChange={handleChanges('listNumber')}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    onInput={e => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    onKeyDown={evt =>
                      (evt.key === '.' || evt.key === 'e') &&
                      evt.preventDefault()
                    }
                    inputProps={{ maxLength: 10 }}
                    helperText={
                      showListNumberError
                        ? SystemListConstants.LIST_NUMBER_ERROR
                        : null
                    }
                    error={
                      showListNumberError
                        ? SystemListConstants.LIST_NUMBER_ERROR
                        : null
                    }
                  />
                  <div className="sub-radio">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={listNumberStartsWith}
                          value={listNumberStartsWith}
                          onChange={handleChangeList}
                        />
                      }
                      label="Starts With"
                    />
                  </div>
                </div>

                 <NativeDropDown
                    id="standard-select-dataElement"
                    label="Data Element Name"
                    value={values.dataElementName || ''}
                    onChange={handleChanges('dataElementName')}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    errTxt={
                      showDataElementNameError
                        ? SystemListConstants.DATAELEMENT_NAME_REQUIRED
                        : null
                    }
                    dropdownList={dataElementNameData
                      ? dataElementNameData.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))
                      : null}
                  />
                <div className="mui-custom-form input-xl">
                  <TextField
                    id="standard-value"
                    fullWidth
                    label="Value"
                    value={values.value || ""}
                    onChange={handleChanges('value')}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
              </div>
              <div className="form-wrapper">
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-description"
                    fullWidth
                    label="Description"
                    value={values.description || ""}
                    onChange={handleChanges('description')}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 30 }}
                    helperText={
                      showDescriptionError
                        ? SystemListConstants.DESCRIPTION_ERROR
                        : null
                    }
                    error={
                      showDescriptionError
                        ? SystemListConstants.DESCRIPTION_ERROR
                        : null
                    }
                  />
                  <div className="sub-radio">
                    <Radio
                      id="standard-radio-desc-startswith"
                      value="StartsWith"
                      name="descriptionSelected"
                      checked={values.descriptionSelected === 'StartsWith'}
                      onChange={handleChanges('descriptionSelected')}
                    />
                    <label
                      htmlFor="standard-radio-desc-startswith"
                      className="text-black"
                    >
                      Starts With
                    </label>
                    <Radio
                      id="standard-radio-desc-contains"
                      value="Contains"
                      name="descriptionSelected"
                      checked={values.descriptionSelected === 'Contains'}
                      onChange={handleChanges('descriptionSelected')}
                      className="ml-2"
                    />
                    <label
                      htmlFor="standard-radio-desc-contains"
                      className="text-black"
                    >
                      Contains
                    </label>
                  </div>
                </div>
                <div
                  className="mui-custom-form input-md" // style ={ { marginLeft: '0px' } }
                >
                  <TextField
                    id="standard-businessName"
                    fullWidth
                    label="Business Name"
                    value={values.businessName || ""}
                    onChange={handleChanges('businessName')}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 50 }}
                    helperText={
                      showBusinessNameError
                        ? SystemListConstants.BUSINESS_NAME_ERROR
                        : null
                    }
                    error={
                      showBusinessNameError
                        ? SystemListConstants.BUSINESS_NAME_ERROR
                        : null
                    }
                  />
                  <div className="sub-radio">
                    <Radio
                      id="standard-radio-bname-startswith"
                      value="StartsWith"
                      name="businessNameSelected"
                      checked={values.businessNameSelected === 'StartsWith'}
                      onChange={handleChanges('businessNameSelected')}
                    />
                    <label
                      htmlFor="standard-radio-bname-startswith"
                      className="text-black"
                    >
                      Starts With
                    </label>
                    <Radio
                      id="standard-radio-bname-contains"
                      value="Contains"
                      name="businessNameSelected"
                      checked={values.businessNameSelected === 'Contains'}
                      onChange={handleChanges('businessNameSelected')}
                      className="ml-2"
                    />
                    <label
                      htmlFor="standard-radio-bname-contains"
                      className="text-black"
                    >
                      Contains
                    </label>
                  </div>
                </div>
                  <NativeDropDown
                    id="standard-select-listType"
                    label="List Type"
                    value={values.listType || ''}
                    onChange={handleChanges('listType')}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    dropdownList={listTypeData
                      ? listTypeData.map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      ))
                      : null}
                  />
              </div>
              <div className="float-right mr-3 mb-2">
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => searchCheck(values)}
                >
                  <i className="fa fa-search" aria-hidden="true"></i>
                  Search
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="bt-reset btn-transparent  ml-1"
                  onClick={() => resetTable()}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                  Reset
                </Button>
              </div>
              <div className="clearfix"></div>
            </form>

            {showTable && paylod && paylod.length > 0 ? (
             <div className="tab-holder pb-1">
                <SystemListSearchTableComponent tableData={paylod} values ={{ ...values, listNumberStartsWith }} dropdowns = {addDropdowns} listTypeDrop={dropdown}/>
              </div>
            ) : null}
            <Footer print />
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(SystemListSearch);
