/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import './PayeeReceivablesComponent.scss';
import { withRouter } from 'react-router';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  setSelectedDate,
  checkForValidDateString,
  compareTwoDatesGreaterThanOrEqual,
  validateDateMinimumValue,
  getDateInMMDDYYYYFormat
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import * as FPSearchConstants from './FPSearchConstants';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPayeeIdTypeCodeAction,
  financialPayOutSearchAction,
  getFinancialPayoutDetails,
  getDropdownDataAction
} from '../../Store/Actions/financialPayout/financialPayoutActions';
import FinancialPayoutSearchResultsComponent from './FinancialPayoutSearchResultsComponent';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import { removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import dropdownCriteria from './PayeeSearch.json';
import moment from 'moment';
import Radio from '@material-ui/core/Radio';

import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { Default_Establishment_ThruDate } from '../../../../SharedModules/AppConstants';

function PayeeReceivable (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [showTable, setShowTable] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);
  const [
    {
      showPayeeTypeError,
      showPayeeError,
      showPayeeIDError,
      showThruDateError,
      showFromDateError
    },
    setShowError
  ] = React.useState(false);
  let errorMessagesArray = [];

  let dropdown = [];
  let payeeTypeIDDropdown = [];
  const dispatch = useDispatch();
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const { value } = props;
  React.useEffect(() => {
    setShowError(false);
  }, [value]);
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  dropdown = useSelector(state => state.financialPayoutState.dropdownData);
  const [paymentStatusReference, setPaymentStatusReference] = React.useState([]);
  const [PayeeTypeReference, setPayeeTypeReference] = React.useState([]);
  const [
    payeeTypeIDDropdownReference,
    setpayeeTypeIDDropdownReference
  ] = React.useState([]);
  const [reasonCode, setReasonCodeDropdown] = React.useState([]);
  const [payeeTableData, setpayeeTableData] = React.useState([]);
  const [errorMegFromDate, setErrorMegFromDate] = React.useState('');
  const [errorMegThruDate, setErrorMegThruDate] = React.useState('');
  const [payeeApiCall, setPayeeApiCall] = React.useState(false);

  let payloadViewData = [];
  const onChangePayeeType = payeeType =>
    dispatch(getPayeeIdTypeCodeAction(payeeType));
  payeeTypeIDDropdown = useSelector(
    state => state.financialPayoutState.payeeIDTypeCodeDetails
  );
  const onSearchView = searchviewvalues =>
    dispatch(financialPayOutSearchAction(removeLeadingTrailingSpaces(searchviewvalues)));
  payloadViewData = useSelector(
    state => state.financialPayoutState.payeeRes
  );
  const onPayoutDetails = searchValues =>
    dispatch(getFinancialPayoutDetails(searchValues));

  const payoutDetails = useSelector(
    state => state.financialPayoutState.singlePayoutDetails
  );

  const [atrError, showATRError] = useState(false);

  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    payeeIdCode: DEFAULT_DD_VALUE,
    payee: '',
    reasonCode: DEFAULT_DD_VALUE,
    selectedFromDate: null,
    selectedThruDate: new Date('9999-12-31T13:00:00.000+0000'),
    selectedOption: 'Both',
    paymentStatus: DEFAULT_DD_VALUE,
    atrNumber: ''
  });

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeReference(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setReasonCodeDropdown(dropdown.listObj['Financial#3006']);
      }
      if (dropdown.listObj['Financial#6003']) {
        setPaymentStatusReference(dropdown.listObj['Financial#6003']);
      }
      setValues(tempData);
    }
  }, [dropdown]);

  useEffect(() => {
    getDropdownData(dropdownCriteria);
    setValues({
      lob: DEFAULT_DD_VALUE,
      payeeType: DEFAULT_DD_VALUE,
      payeeIdCode: DEFAULT_DD_VALUE,
      payee: '',
      reasonCode: DEFAULT_DD_VALUE,
      selectedFromDate: null,
      selectedThruDate: new Date('9999-12-31T13:00:00.000+0000'),
      selectedOption: 'Both',
      atrNumber: '',
      paymentStatus: DEFAULT_DD_VALUE
    });
  }, []);

  // On change Payee Type dropdown to load Payee Id Type Code dropdown values
  useEffect(() => {
    setValues({ ...values });
    if (values.payeeType && values.payeeType !== DEFAULT_DD_VALUE) {
      onChangePayeeType(values.payeeType);
    } else {
      setValues({
        ...values,
        payeeIdCode: DEFAULT_DD_VALUE
      });
    }
  }, [values.payeeType]);

  useEffect(() => {
    if (payeeTypeIDDropdown && payeeTypeIDDropdown.length > 0) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (payeeTypeIDDropdown.length === 1) {
        tempData.payeeIDCode = payeeTypeIDDropdown[0].code;
      }
      setpayeeTypeIDDropdownReference(payeeTypeIDDropdown);
      setValues(tempData);
    }
  }, [payeeTypeIDDropdown]);

  const handleChange = name => event => {
    if (name === 'selectedFromDate' || name === 'selectedThruDate') {
      var updatedDateString = event != null ? setSelectedDate(event) : null;
      setValues({ ...values, [name]: updatedDateString });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };
  const resetPayee = () => {
    setValues({
      lob: DEFAULT_DD_VALUE,
      payeeType: DEFAULT_DD_VALUE,
      payeeIdCode: DEFAULT_DD_VALUE,
      payee: '',
      reasonCode: DEFAULT_DD_VALUE,
      selectedFromDate: null,
      selectedThruDate: new Date('9999-12-31T13:00:00.000+0000'),
      selectedOption: 'Both',
      atrNumber: '',
      paymentStatus: DEFAULT_DD_VALUE
    });
    setShowTable(false);
    errorMessagesArray = [];
    setShowError(false);
    showATRError(false);
    props.showError(errorMessagesArray);
  };

  const searchPayee = () => {
    sessionStorage.setItem('tabValuePayout', 1);
    props.location.tableRender = false;
    var showPayeeError = false;
    var showPayeeIDError = false;
    var showPayeeTypeError = false;
    var showSelectedFromDateError = false;
    var showSelectedThruDateError = false;
    var dataIncorrectFormat = false;
    errorMessagesArray = [];
    setShowError(false);
    setShowTable(false);

    if (
      (values.payeeType &&
        values.payeeType !== DEFAULT_DD_VALUE) ||
      (values.payeeIdCode &&
        values.payeeIdCode !== DEFAULT_DD_VALUE) ||
      (values.payee.trim() !== null && values.payee.trim() !== '')
    ) {
      if (
        !values.payeeType ||
        values.payeeType === DEFAULT_DD_VALUE
      ) {
        setShowTable(false);
        showPayeeError = true;
        errorMessagesArray.push(FPSearchConstants.PAYEE_TYPE_REQUIRED);
        props.showError(errorMessagesArray);
      }
      if (
        !values.payeeIdCode ||
        values.payeeIdCode === DEFAULT_DD_VALUE
      ) {
        setShowTable(false);
        showPayeeTypeError = true;
        errorMessagesArray.push(FPSearchConstants.PAYEE_ID_TYPE_REQUIRED);
        props.showError(errorMessagesArray);
      }
      if (values.payee.trim() === null || values.payee.trim() === '') {
        setShowTable(false);
        showPayeeIDError = true;
        errorMessagesArray.push(FPSearchConstants.PAYEE_REQUIRED);
        props.showError(errorMessagesArray);
      }
    }

    if (values.selectedFromDate) {
      const isDateInValid = validateDateMinimumValue(
        getDateInMMDDYYYYFormat(values.selectedFromDate)
      );
      if (isDateInValid) {
        showSelectedFromDateError = true;
        setErrorMegFromDate(AppConstants.DATE_ERROR_1964);
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }

      if (!checkForValidDateString(values.selectedFromDate)) {
        showSelectedFromDateError = true;
        setErrorMegFromDate(FPSearchConstants.DATE_INCORRECT_FORMAT);
        dataIncorrectFormat = true;
      }
    }

    if (values.selectedThruDate) {
      const isDateInValid = validateDateMinimumValue(
        getDateInMMDDYYYYFormat(values.selectedThruDate)
      );
      if (isDateInValid) {
        showSelectedThruDateError = true;
        setErrorMegThruDate(AppConstants.DATE_ERROR_1964);
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }

      if (
        !checkForValidDateString(
          moment(values.selectedThruDate).format('MM/DD/YYYY')
        )
      ) {
        showSelectedThruDateError = true;
        setErrorMegThruDate(FPSearchConstants.DATE_INCORRECT_FORMAT);
        dataIncorrectFormat = true;
      }
    }

    if (values.atrNumber) {
      const regex = /[^A-Za-z0-9]/;
       if (regex.test(values.atrNumber)) {
        setShowTable(false);
         showATRError(true);
         errorMessagesArray.push(process.env.REACT_APP_TRANSACTION_NUMBER_LABEL + ' ' + FPSearchConstants.TRANSACTION_NUMBER_FORMAT);
         props.showError(errorMessagesArray);
       } else { showATRError(false); }
    }

    if (values.selectedThruDate &&
      !showSelectedFromDateError &&
      !showSelectedThruDateError &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(values.selectedThruDate),
        new Date(values.selectedFromDate)
      )
    ) {
      showSelectedFromDateError = true;
      errorMessagesArray.push(FPSearchConstants.ESTABLISHED_BEGIN_DATE);
      setErrorMegFromDate(FPSearchConstants.ESTABLISHED_BEGIN_DATE);
      props.showError(errorMessagesArray);
    }
    if (
      dataIncorrectFormat
    ) {
      errorMessagesArray.push(FPSearchConstants.DATE_INCORRECT_FORMAT);
    }
    props.showError(errorMessagesArray);

    if (errorMessagesArray.length === 0) {
      setIsSearch(true);
      showPayeeError = false;
      showPayeeTypeError = false;
      setShowError(false);
      errorMessagesArray = [];
      props.showError(errorMessagesArray);
      setPayeeApiCall(true);
      const establismentThruDatecheck = values.selectedFromDate ? Default_Establishment_ThruDate : null;
      const searchCriteria = {
        fcn: null,
        lob: [AppConstants.DEFAULT_LOB_VALUE],
        searchBy: 'searchByPayee',
        lineOfBusiness: AppConstants.DEFAULT_LOB_VALUE,
        fcnSearch: 0,
        payeeSearch: 1,
        payeeType:
          values.payeeType !== '' && values.payeeType !== DEFAULT_DD_VALUE
            ? values.payeeType
            : null,
        payeeID: values.payee !== '' ? values.payee : null,
        payeeIDTypeCode:
          values.payeeIdCode !== '' &&
            values.payeeIdCode !== DEFAULT_DD_VALUE
            ? values.payeeIdCode
            : null,
        reasonCode:
          values.reasonCode !== '' && values.reasonCode !== DEFAULT_DD_VALUE
            ? values.reasonCode
            : null,
        establishedFromDate:
          values.selectedFromDate !== null
            ? moment(values.selectedFromDate).format('MM/DD/YYYY')
            : null,
        establishedThruDate:
          values.selectedThruDate !== null
            ? moment(values.selectedThruDate).format('MM/DD/YYYY')
            : establismentThruDatecheck,
        approvalStatus:
          values.selectedOption === 'Approved'
            ? 'A'
            : values.selectedOption === 'Unapproved'
              ? 'U'
              : values.selectedOption === 'Both'
                ? 'B'
                : null,
        paymentStatus: values.paymentStatus !== '' && values.paymentStatus !== DEFAULT_DD_VALUE
          ? values.paymentStatus
          : null,
          atrNumber: values?.atrNumber.toUpperCase()
      };
      onSearchView(searchCriteria);
      if (!props.history.state) {
        props.history.state = {};
      }
      props.history.state.values = searchCriteria;
    }
    setShowError({
      showPayeeError: showPayeeError,
      showPayeeTypeError: showPayeeTypeError,
      showPayeeIDError: showPayeeIDError,
      showThruDateError: showSelectedThruDateError,
      showFromDateError: showSelectedFromDateError
    });
  };

  // Check search results and navigate to edit screen
  useEffect(() => {
    if (payloadViewData && payloadViewData.systemFailure) {
      errorMessagesArray.push(FPSearchConstants.GENERIC_SYSTEM_ERROR);
      props.showError(errorMessagesArray);
    }
    if (payloadViewData && !payloadViewData.systemFailure && payloadViewData.respcode === '02') {
      if (payloadViewData.errorMessages === null) {
        errorMessagesArray.push(FPSearchConstants.GENERIC_SYSTEM_ERROR);
      } else {
        errorMessagesArray.push(payloadViewData.errorMessages[0]);
      }
      props.showError(errorMessagesArray);
    }
    if (payloadViewData && payloadViewData.enterpriseSearchresultVO && payloadViewData.enterpriseSearchresultVO.searchResults && payloadViewData.enterpriseSearchresultVO.searchResults.length === 0) {
      errorMessagesArray.push(FPSearchConstants.NO_RECODRS_FOUND);
      props.showError(errorMessagesArray);
    }
    if (payloadViewData && !payloadViewData.systemFailure && payloadViewData.length === 0) {
      errorMessagesArray.push(FPSearchConstants.NO_RECODRS_FOUND);
      props.showError(errorMessagesArray);
    }
    if (
      payloadViewData && payloadViewData.enterpriseSearchresultVO && payloadViewData.enterpriseSearchresultVO.searchResults &&
      payloadViewData.enterpriseSearchresultVO.searchResults.length === 1 &&
      !payloadViewData.systemFailure
    ) {
      if (!props.location.tableRender) {
      const searchCriteria = {
        fcn: payloadViewData.enterpriseSearchresultVO.searchResults[0].fcn,
        searchBy: 'searchByFCN',
        lob: [AppConstants.DEFAULT_LOB_VALUE],
        fcnSearch: 1,
        payeeSearch: 0,
        payeeType: null,
        payeeID: null,
        payeeIDTypeCode: null
      };
      onPayoutDetails(searchCriteria);
      } else {
        setShowTable(true);
        setpayeeTableData(payloadViewData.enterpriseSearchresultVO.searchResults);
      }
    }
    if (
      payeeApiCall && payloadViewData && payloadViewData.enterpriseSearchresultVO && payloadViewData.enterpriseSearchresultVO.searchResults &&
      payloadViewData.enterpriseSearchresultVO.searchResults.length > 1 &&
      !payloadViewData.systemFailure
    ) {
      setShowTable(true);
      payloadViewData.enterpriseSearchresultVO.searchResults.map((data, index) => {
        data.reasonCodeDesc = fieldDescription(reasonCode, data.reasonCode);
        data.payeeTypeDesc = fieldDescription(PayeeTypeReference, data.payeeType);
        data.resonCodeLongDesc = fieldLongDescription(reasonCode, data.reasonCode);
      });
      setpayeeTableData(payloadViewData.enterpriseSearchresultVO.searchResults);
    }
  }, [payloadViewData]);

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode, index) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };
  const fieldLongDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode, index) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].longDescription;
    }
    return Code;
  };

  const onRowClick = row => event => {
    props.location.tableRender = false;
    const searchCriteria = formatSearchCriteria(row);
    onPayoutDetails(searchCriteria);
  };

  const formatSearchCriteria = (_row) => {
    return ({
      fcn: _row.fcn,
      searchBy: 'searchByFCN',
      lob: [_row.lob],
      fcnSearch: 1,
      payeeSearch: 0,
      payeeType: null,
      payeeID: null,
      payeeIDTypeCode: null
    });
  };

  const ValuesFromFinPayout = props?.history?.state?.values;
  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (ValuesFromFinPayout !== undefined) {
        setShowError(false);
        errorMessagesArray = [];
        props.showError(errorMessagesArray);

        setPayeeApiCall(true);
        onSearchView({
          fcn: null,
          lob: [AppConstants.DEFAULT_LOB_VALUE],
          searchBy: 'searchByPayee',
          lineOfBusiness: ValuesFromFinPayout.lineOfBusiness,
          fcnSearch: 0,
          payeeSearch: 1,
          payeeType:
            ValuesFromFinPayout.payeeType !== '' && ValuesFromFinPayout.payeeType !== DEFAULT_DD_VALUE
              ? ValuesFromFinPayout.payeeType
              : null,
          payeeID: ValuesFromFinPayout.payeeID !== '' ? ValuesFromFinPayout.payeeID : null,
          payeeIDTypeCode:
            ValuesFromFinPayout.payeeIDTypeCode !== '' &&
              ValuesFromFinPayout.payeeIDTypeCode !== DEFAULT_DD_VALUE
              ? ValuesFromFinPayout.payeeIDTypeCode
              : null,
          reasonCode:
            ValuesFromFinPayout.reasonCode !== '' && ValuesFromFinPayout.reasonCode !== DEFAULT_DD_VALUE
              ? ValuesFromFinPayout.reasonCode
              : null,
          establishedFromDate:
            ValuesFromFinPayout.establishedFromDate !== null
              ? moment(ValuesFromFinPayout.establishedFromDate).format('MM/DD/YYYY')
              : null,
          establishedThruDate:
            ValuesFromFinPayout.establishedThruDate !== null
              ? moment(ValuesFromFinPayout.establishedThruDate).format('MM/DD/YYYY')
              : null,
          approvalStatus:
            ValuesFromFinPayout?.approvalStatus,
          paymentStatus: ValuesFromFinPayout.paymentStatus !== '' && ValuesFromFinPayout.paymentStatus !== DEFAULT_DD_VALUE
            ? ValuesFromFinPayout.paymentStatus
            : null,
            atrNumber: ValuesFromFinPayout.atrNumber.toUpperCase()
        });
        setValues({
...ValuesFromFinPayout,
          lob: ValuesFromFinPayout.lob || DEFAULT_DD_VALUE,
          payeeType: ValuesFromFinPayout.payeeType || DEFAULT_DD_VALUE,
          payeeIdCode: ValuesFromFinPayout.payeeIDTypeCode || DEFAULT_DD_VALUE,
          payee: ValuesFromFinPayout.payeeID || '',
          reasonCode: ValuesFromFinPayout.reasonCode || DEFAULT_DD_VALUE,
          selectedFromDate: ValuesFromFinPayout.establishedFromDate || null,
          selectedThruDate: ValuesFromFinPayout.establishedThruDate || new Date('9999-12-31T13:00:00.000+0000'),
          selectedOption: ValuesFromFinPayout.approvalStatus === 'A'
          ? 'Approved'
          : ValuesFromFinPayout.approvalStatus === 'U'
            ? 'Unapproved'
            : ValuesFromFinPayout.approvalStatus === 'B'
              ? 'Both'
              : null,
              atrNumber: ValuesFromFinPayout.atrNumber.toUpperCase() || '',
          paymentStatus: ValuesFromFinPayout.paymentStatus || DEFAULT_DD_VALUE
        });
      };
    }
  }, [props.location.tableRender]);

  useEffect(() => {
    if (
      payoutDetails &&
      payoutDetails.enterpriseSearchresultVO &&
      payoutDetails.enterpriseSearchresultVO.searchResults &&
      payoutDetails.enterpriseSearchresultVO.searchResults.length === 1
    ) {
      const payoutUpdate = true;
      if (!props.location.tableRender) {
        sessionStorage.setItem('isFromAdd', JSON.stringify(false));
        props.history.push({
          pathname: '/FinancialPayoutUpdate',
          state: {
            payoutUpdate,
            addSuccessData: payoutDetails.enterpriseSearchresultVO.searchResults[0],
            values: values,
            dropdowns: addDropdowns
          }
        });
      }
    }
  }, [payoutDetails]);


  useEffect(()=>{
    onDropdowns([Dropdowns.PAYEE_TYPE, Dropdowns.F_RSN_CD, Dropdowns.MEMBER_TYPE_DD])
  }, []);

  return (
    <div >
      <form noValidate autoComplete="off">
        <div className="form-wrapper">
            <NativeDropDown
              id="payeeType"
              label="Payee Type"
              value={values.payeeType}
              onChange={handleChange('payeeType')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showPayeeError ? FPSearchConstants.PAYEE_TYPE_REQUIRED : null
              }
              dropdownList={PayeeTypeReference.map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              ))}
            />

            <NativeDropDown
              id="payeeIdCode"
              label="Payee ID Type Code"
              value={values.payeeIdCode}
              disabled={values.payeeType === DEFAULT_DD_VALUE}
              onChange={handleChange('payeeIdCode')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showPayeeTypeError
                  ? FPSearchConstants.PAYEE_ID_TYPE_REQUIRED
                  : null
              }
              dropdownList={payeeTypeIDDropdownReference
                ? payeeTypeIDDropdownReference.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />

          <div className="mui-custom-form input-md">
            <TextField
              id="payee"
              fullWidth
              label="Payee ID"
              InputLabelProps={{
                shrink: true
              }}
              value={values.payee}
              onChange={handleChange('payee')}
              inputProps={{ maxLength: 15 }}
              helperText={
                showPayeeIDError ? FPSearchConstants.PAYEE_REQUIRED : null
              }
              error={showPayeeIDError ? FPSearchConstants.PAYEE_REQUIRED : null}
            />
          </div>
        </div>
        <div className="form-wrapper">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form input-md with-select">
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="fromDate"
                fullWidth
                label="Established From Date"
                format="MM/dd/yyyy"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="mm/dd/yyyy"
                value={values.selectedFromDate}
                name="selectedFromDate"
                onChange={handleChange('selectedFromDate')}
                helperText={showFromDateError ? errorMegFromDate : null}
                error={showFromDateError ? errorMegFromDate : null}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form input-md with-select">
              <KeyboardDatePicker
                id="thruDate"
                fullWidth
                label="Established Thru Date"
                format="MM/dd/yyyy"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="mm/dd/yyyy"
                value={values.selectedThruDate}
                onChange={handleChange('selectedThruDate')}
                helperText={showThruDateError ? errorMegThruDate : null}
                error={showThruDateError ? errorMegThruDate : null}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                maxDate={new Date('9999-12-31T13:00:00.000+0000')}
              />
            </div>
          </MuiPickersUtilsProvider>

            <NativeDropDown
              id="reasonCode"
              label="Reason Code"
              placeholder={DEFAULT_DD_VALUE}
              value={values.reasonCode}
              onChange={handleChange('reasonCode')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={reasonCode.map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              ))}
            />

          
        </div>
        <div className="form-wrapper">
            <NativeDropDown
              id="paymentstatus"
              label="Payment Status"
              value={values.paymentStatus}
              onChange={handleChange('paymentStatus')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={paymentStatusReference.map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              ))}
            />
          <div className="mui-custom-form input-md">
            <TextField
              id="atrNumber"
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              InputLabelProps={{
                shrink: true, maxLength: 18
              }}
              value={values.atrNumber}
              onChange={handleChange('atrNumber')}
              inputProps={{}}
              helperText={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${FPSearchConstants.TRANSACTION_NUMBER_FORMAT}` : null}
              error={atrError ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${FPSearchConstants.TRANSACTION_NUMBER_FORMAT}` : null}
            />
          </div>
          <div className="mui-custom-form radio-box">
            <label className="MuiFormLabel-root small-label no-margin">
              Approval Status
            </label>
            <div className="sub-radio">
              <Radio
                id="Approved"
                fullWidth
                value="Approved"
                checked={values.selectedOption === 'Approved'}
                name="selectedOption"
                onChange={handleChange('selectedOption')}
                style={{ height: '13px', width: '13px' }}
              />
              <span className="text-black sub-label">
                <label htmlFor="Approved">Approved</label>
              </span>{' '}
              <Radio
                id="Unapproved"
                fullWidth
                value="Unapproved"
                checked={values.selectedOption === 'Unapproved'}
                name="selectedOption"
                onChange={handleChange('selectedOption')}
                style={{ height: '13px', width: '13px' }}
              />
              <span className="text-black sub-label">
                <label htmlFor="Unapproved">Unapproved</label>
              </span>{' '}
              <Radio
                id="Both"
                fullWidth
                value="Both"
                checked={values.selectedOption === 'Both'}
                name="selectedOption"
                onChange={handleChange('selectedOption')}
                style={{ height: '13px', width: '13px' }}
              />
              <span className="text-black sub-label">
                <label htmlFor="Both">Both</label>
              </span>
            </div>
          </div>
        </div>

        <div className="float-right mb-2 mr-3">
          <Button className="btn-tab-pannel" onClick={() => searchPayee()}>
            <i className="fa fa-search" aria-hidden="true"></i>
            Search
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className="bt-tabreset btn-transparent ml-1"
            onClick={() => resetPayee()}
            data-testid="test_reset_btn"
          >
            <i className="fa fa-undo" aria-hidden="true"></i>
            Reset
          </Button>
        </div>

        <div className="clearfix"></div>
      </form>
      <div className="tab-holder mt-3">
        {showTable ? (
            <div className="tab-holder-p-8 tableNoWrap">
              <FinancialPayoutSearchResultsComponent
                tableData={payeeTableData}
                onRowClick={onRowClick}
                dropdowns={addDropdowns}
              />
            </div>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(PayeeReceivable);
