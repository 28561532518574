/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableComponent from '../../../../SharedModules/Table/Table';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { NO_REFUND_TO_PAYEE } from '../FinancialReceiptConstants';

const headCells = [
  {
    id: 'lienItems',
    numeric: false,
    disablePadding: true,
    label: 'Line Items',
    enableHyperLink: false,
    isDate: false,
    width: 100,
    fontSize: 12
  },
  {
    id: 'tcn',
    numeric: false,
    disablePadding: false,
    label: 'TCN',
    enableHyperLink: true,
    isDate: false,
    width: 100,
    fontSize: 12
  },
  {
    id: 'overPaymentAmount',
    numeric: false,
    disablePadding: false,
    label: 'OverPayment Amount',
    enableHyperLink: false,
    isDate: false,
    width: 100,
    fontSize: 12
  },
  {
    id: 'linkTo',
    numeric: false,
    disablePadding: false,
    label: 'Link To',
    enableHyperLink: false,
    isDate: false,
    width: 100,
    fontSize: 12
  }
];

export default function AddDisposition (props) {
  const {
    addRefndValues,
    addRefundHandleChanges,
    selectedFFPCalculateDate,
    handleFFPCalculateDateChange,
    showAddRefundPopUpErr,
    addRefundPopUpErrMsg,
    refundDispositionRsnCodeDD,
    collationCodeDD,
    cosDD,
    payeeTypeDD,
    refundPayeeIDTypeDD,
    overPaymentsData,
    RefundTabFormat,
    userInquiryPrivileges,
    refundErrorMessages,
    removeRefundTabFormat,
    isDisableFields,
    disablePaymentStatus,
    paymentStatus,
    crswalk_FS_9304,
    validValRsnCodes
  } = props;

  return (
    <div className='form-border my-3'>
      <form autoComplete="off">
        <ErrorMessages errorMessages={refundErrorMessages} />
        <div className="form-wrapper">
          <div className="mui-custom-form input-15">
            <TextField
              id="fcn"
              label="FCN"
              inputProps={{ maxLength: 14 }}
              InputProps={{ className: 'Mui-disabled' }}
              value={addRefndValues.fcn}
              InputLabelProps={{
                shrink: true
              }}
              disabled={isDisableFields || disablePaymentStatus}
            ></TextField>
          </div>
            <NativeDropDown
            id="refundTabPayeeType"
              disabled={isDisableFields || !userInquiryPrivileges || disablePaymentStatus}
              label="Payee Type"
              inputProps={{ maxLength: 3 }}
              value={addRefndValues.entityType}
              onChange={addRefundHandleChanges('entityType')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={payeeTypeDD
                ? payeeTypeDD.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />
            <NativeDropDown
            id="refundTabPayeeIDTypeCode"
              disabled={isDisableFields || !userInquiryPrivileges || disablePaymentStatus}
              label="Payee ID Type Code"
              inputProps={{ maxLength: 3 }}
              value={addRefndValues.entityIDTypeCode}
              onChange={addRefundHandleChanges('entityIDTypeCode')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList = {refundPayeeIDTypeDD
                ? refundPayeeIDTypeDD.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />
          <div className="mui-custom-form input-15">
            <TextField
              id="RefundpayeeID"
              label="Payee ID"
              type="string"
              inputProps={{ maxLength: 15 }}
              value={addRefndValues.entityID}
              onChange={addRefundHandleChanges('entityID')}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? 'Mui-disabled' : ''
              }}
              disabled={isDisableFields || disablePaymentStatus}
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form input-15">
            <TextField
              id="fiscal-year"
              label="Fiscal Year"
              required
              inputProps={{ maxLength: 4 }}
              value={addRefndValues.fiscalYear}
              onChange={addRefundHandleChanges('fiscalYear')}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? 'Mui-disabled' : ''
              }}
              helperText={
                showAddRefundPopUpErr.showFinancialYearErr
                  ? addRefundPopUpErrMsg.financialYearErrMsg
                  : null
              }
              error={
                showAddRefundPopUpErr.showFinancialYearErr
                  ? addRefundPopUpErrMsg.financialYearErrMsg
                  : null
              }
              disabled={isDisableFields || disablePaymentStatus}
            ></TextField>
          </div>
            <NativeDropDown
              id="collocation-code"
              isRequired
              label="Fund Code"
              disabled={isDisableFields || !userInquiryPrivileges || disablePaymentStatus}
              inputProps={{ maxLength: 10 }}
              value={addRefndValues.fundCode}
              onChange={addRefundHandleChanges('fundCode')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showAddRefundPopUpErr.showFCodeErr
                  ? addRefundPopUpErrMsg.fCodeErrMsg
                  : null
              }
              dropdownList={collationCodeDD
                ? collationCodeDD.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />
            <NativeDropDown
              id="disposition-reason-code"
              isRequired
              disabled={isDisableFields || !userInquiryPrivileges || disablePaymentStatus}
              label="Disposition Reason Code"
              inputProps={{ maxLength: 3 }}
              value={addRefndValues.reasonCode}
              onChange={addRefundHandleChanges('reasonCode')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                (showAddRefundPopUpErr.showDispositonCodeErr && addRefundPopUpErrMsg.dispositonCodeErrMsg) || (!crswalk_FS_9304?.length && NO_REFUND_TO_PAYEE)               
              }
              dropdownList={validValRsnCodes
                ? validValRsnCodes.filter(e => crswalk_FS_9304?.indexOf(e.code) > -1)?.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form input-sm with-date">
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="ffp-calculated-date"
                label="FFP Calculated Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                disabled={isDisableFields || !userInquiryPrivileges || disablePaymentStatus}
                value={selectedFFPCalculateDate}
                onChange={handleFFPCalculateDateChange}
                InputLabelProps={{
                  shrink: true
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                InputProps={{
                  readOnly: !userInquiryPrivileges,
                  className: !userInquiryPrivileges ? 'Mui-disabled' : ''
                }}
                helperText={
                  showAddRefundPopUpErr.showFfpDateErr
                    ? addRefundPopUpErrMsg.fFPDateErrMsg
                    : null
                }
                error={
                  showAddRefundPopUpErr.showFfpDateErr
                    ? addRefundPopUpErrMsg.fFPDateErrMsg
                    : null
                }
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form with-icon with-icon-margin">
            <TextField
              id="RefundpaymentAmount"
              name="paymentAmount"
              label="Payment Amount"
              required
              onBlur={RefundTabFormat}
              onFocus={removeRefundTabFormat}
              inputProps={{ maxLength: 11 }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? 'Mui-disabled' : ''
              }}
              value={addRefndValues.payerAmount}
              onChange={addRefundHandleChanges('payerAmount')}
              helperText={
                showAddRefundPopUpErr.showPaymentAmtErr
                  ? addRefundPopUpErrMsg.paymentAmtErrMsg
                  : null
              }
              error={
                showAddRefundPopUpErr.showPaymentAmtErr
                  ? addRefundPopUpErrMsg.paymentAmtErrMsg
                  : null
              }
              disabled={isDisableFields || disablePaymentStatus}
            />
          </div>
            <NativeDropDown
              id="category-of-service"
              label="Category of Service"
              inputProps={{ maxLength: 3 }}
              value={addRefndValues.cos}
              disabled={isDisableFields || !userInquiryPrivileges || disablePaymentStatus}
              onChange={addRefundHandleChanges('cos')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={cosDD
                ? cosDD.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />
             <div className="mui-custom-form with-icon with-icon-margin">
            <TextField
              id='ATRnumber'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={addRefndValues?.atrNumber}
              disabled = {disablePaymentStatus}
              inputProps={{ maxlength: 18 }}
              onChange ={addRefundHandleChanges('atrNumber')}
              helperText={showAddRefundPopUpErr.showATRErr ? addRefundPopUpErrMsg.atrErrMsg : null}
              error= {showAddRefundPopUpErr.showATRErr ? addRefundPopUpErrMsg.atrErrMsg : null}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form with-icon with-icon-margin">
            <TextField
              id='Payment Status'
              fullWidth
              label= 'Payment Status'
              placeholder=""
              value={paymentStatus}
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
        </div>
      </form>
      <div className="tab-holder">
        <TableComponent
          headCells={headCells}
          tableData={overPaymentsData}
          onTableRowClick={() => { }}
          defaultSortColumn={'tcn'}
        />
      </div>
      <div className="clearfix"></div>
    </div>
  );
}
