/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FCNComponent from './FCNComponent';
import PayeeReceivablesComponent from './PayeeReceivablesComponent';
import ManualCheckComponent from './ManualCheckComponent';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';

import TabPanel from '../../../../../SharedModules/TabPanel/TabPanel';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import {
  getDropdownDataAction,
  resetFCNSearchAccountReceivable
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import dropdownCriteria from './FinancialAccountSearch.json';
import Footer from '../../../../../SharedModules/Layout/footer';
import { GET_APP_DROPDOWNS } from '../../../../../SharedModules/Dropdowns/actions';
import * as Dropdowns from '../../../../../SharedModules/Dropdowns/dropdowns';

function FinancialAccountInquiry (props) {
  const [value, setValue] = React.useState(Number(sessionStorage.getItem('tabValue')) || 0);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [errorMessages, seterrorMessages] = React.useState([]);

  const [
    {
      showFCNError,
      showMCNError,
      showLOBError,
      showPayeeError,
      showThruDateError,
      showFromDateError,
      showLastDateError,
      showPayeeIdTypeCodeError,
      showPayeeTypeError,
      showThDateError,
      showFrDateError,
      showLastDtError
    },
    setShowError
  ] = React.useState(false);
  const [lobData, setLobData] = React.useState([]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [initialReasonCodeArray, setInitialReasonCodeArray] = React.useState(
    []
  );
  const [payeeTab, setPayeeTab] = React.useState(false);
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const onReset = () => dispatch(resetFCNSearchAccountReceivable());
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));

  const dropdown = useSelector(
    state => state.accountReceivableState.dropdownData
  );

  useEffect(() => {
    onReset();
    getDropdownData(dropdownCriteria);
    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.F_RSN_CD,
      Dropdowns.LOB_CODE
    ]);
  }, []);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1019']) {
        setLobData(dropdown.listObj['Reference#1019']);
      }
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#3013']) {
        setActivityTypeData(dropdown.listObj['Financial#3013']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setReasonCodeVal(dropdown.listObj['Financial#6001']);
        setInitialReasonCodeArray(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['Financial#3200']) {
        setFundCodeData(dropdown.listObj['Financial#3200']);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023']);
      }
    }
  }, [dropdown]);

  const setReasonCodeVal = reasonCodeVal => {
    setReasonCodeData(reasonCodeVal);
  };

  const handleChange = (event, newValue) => {
    sessionStorage.clear();
    setValue(newValue);
    setPayeeTab(false);
    seterrorMessages([]);
    showFieldError(false, false);
    showValidationError([]);
    props.location.tableRender = false;
  };

  const showValidationError = errorMsgArray => {
    seterrorMessages(errorMsgArray);
  };

  const showFieldError = (
    fcnVal,
    mcnVal,
    lobVal,
    payeeVal,
    thruDateVal,
    fromDateVal,
    lastDateVal,
    payeeTypeVal,
    payeeIdCodeVal,
    dateThrVal,
    dateFromVal,
    dateforLastVal
  ) => {
    setShowError({
      showFCNError: fcnVal,
      showMCNError: mcnVal,
      showLOBError: lobVal,
      showPayeeError: payeeVal,
      showThruDateError: thruDateVal,
      showFromDateError: fromDateVal,
      showLastDateError: lastDateVal,
      showPayeeTypeError: payeeTypeVal,
      showPayeeIdTypeCodeError: payeeIdCodeVal,
      showThDateError: dateThrVal,
      showFrDateError: dateFromVal,
      showLastDtError: dateforLastVal
    });
  };

  const addARInquiry = () => {
    sessionStorage.clear();
    props.history.push({
      pathname: '/GrossLevelAddPayeeComponent'
    });
  };

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <div className="float-left tab-heading">
            Search Accounts Receivable
          </div>

          <div className="float-right mt-2">
            <Button
              title="Add Accounts Receivable"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addARInquiry()}
              disabled={global.globalIsReadOnly()}

            >
              Add
            </Button>
          </div>

          <div className="clearfix"></div>
        </div>
        <div className="tab-body padTop-10">
          <div className="tab-panelbody">
            <div className="tab-holder my-3">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="FCN" />
                  <Tab label="Payee" />
                  <Tab label="Manual Check Number" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder my-3 p-0">
                    <FCNComponent
                      print
                      showLOBError={showLOBError}
                      showPayeeError={showPayeeError}
                      showThruDateError={showThruDateError}
                      showFromDateError={showFromDateError}
                      showLastDateError={showLastDateError}
                      showThDateError={showThDateError}
                      showFrDateError ={showFrDateError}
                      showLastDtError={showLastDtError}
                      showFCNError={showFCNError}
                      showMCNError={showMCNError}
                      showPayeeIdTypeCodeError={showPayeeIdTypeCodeError}
                      showPayeeTypeError={showPayeeTypeError}
                      showFieldError={showFieldError}
                      showError={showValidationError}

                      lobData={lobData}
                      payeeTypeData={payeeTypeData}
                      dropdowns={addDropdowns}
                    ></FCNComponent>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <PayeeReceivablesComponent
                      print
                      showLOBError={showLOBError}
                      showPayeeError={showPayeeError}
                      showThruDateError={showThruDateError}
                      showFromDateError={showFromDateError}
                      showLastDateError={showLastDateError}
                      showFCNError={showFCNError}
                      showMCNError={showMCNError}
                      showThDateError={showThDateError}
                      showFrDateError ={showFrDateError}
                      showLastDtError={showLastDtError}
                      showPayeeIdTypeCodeError={showPayeeIdTypeCodeError}
                      showPayeeTypeError={showPayeeTypeError}
                      showFieldError={showFieldError}
                      showError={showValidationError}

                      lobData={lobData}
                      payeeTypeData={payeeTypeData}
                      fundCodeData={fundCodeData}
                      payeePaymentStatusFrmState={payeePaymentStatusFrmState}
                      reasonCodeData={reasonCodeData}
                      initialReasonCodeArray={initialReasonCodeArray}
                      activityTypeData={activityTypeData}
                      setReasonCodeVal={setReasonCodeVal}
                      payeeTab = {payeeTab}
                      setPayeeTab = {setPayeeTab}
                    ></PayeeReceivablesComponent>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {value === 2 && (
                  <div className="tab-holder my-3 p-0">
                    <ManualCheckComponent
                      print
                      showLOBError={showLOBError}
                      showPayeeError={showPayeeError}
                      showThruDateError={showThruDateError}
                      showFromDateError={showFromDateError}
                      showLastDateError={showLastDateError}
                      showFCNError={showFCNError}
                      showMCNError={showMCNError}
                      showThDateError={showThDateError}
                      showFrDateError ={showFrDateError}
                      showLastDtError={showLastDtError}
                      showPayeeIdTypeCodeError={showPayeeIdTypeCodeError}
                      showPayeeTypeError={showPayeeTypeError}
                      showFieldError={showFieldError}
                      showError={showValidationError}

                      reasonCodeData={reasonCodeData}
                      lobData={lobData}
                      payeeTypeData={payeeTypeData}
                      initialReasonCodeArray={initialReasonCodeArray}
                      dropdowns={addDropdowns}
                    ></ManualCheckComponent>
                  </div>
                )}
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}

export default withRouter(FinancialAccountInquiry);
