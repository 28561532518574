/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { CLAIMS_INQUIRY_TCN_LINK } from "../../../../SharedModules/services/service";
import * as AppConstants from "../../../../SharedModules/AppConstants";
import Radio from "@material-ui/core/Radio";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";
import { TRANSACTION_NUMBER_FORMAT } from "../../../../SharedModules/Errors/ErrorMsgConstant";
export default function OriginalTabDetail(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const {
    activityTypeCodeDD,
    originalTabFormat,
    financialReasCodeDD,
    pendingReasonDD,
    collationCodeDD,
    originalTab,
    originalTabHandleChanges,
    selectedDepositDate,
    handleDepositDateChange,
    validateOriginalDetails,
    showOriginalError,
    originalErrorMsg,
    editFlag,
    userInquiryPrivileges,
    checkNumberPad,
    removeOriginalTabFormat
  } = props;
  // check these values and assign
  const value = { checkNumber: "", internalTrackingNumber: "" };
  let reasonCode = financialReasCodeDD && financialReasCodeDD.find(value => value.code === originalTab.financialReason);
  let activityCode = activityTypeCodeDD && activityTypeCodeDD.find(value => value.code === originalTab.activityTypeCode)
  let fundCodeValue = collationCodeDD && collationCodeDD.find(value => value.code === originalTab.fundCode)
  let pendReason = pendingReasonDD && pendingReasonDD.find(value => value.code === originalTab.pendingReason)

  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="fcn"
              fullWidth
              label="FCN"
              inputProps={{ maxLength: 14 }}
              value={originalTab.fcn}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
            <NativeDropDown
              id="activityTypeCode"
              className="override-width-22 override-m-1"
              isRequired
              disabled={
                !userInquiryPrivileges ? !userInquiryPrivileges : !editFlag
              }
              label="Activity Type Code"
              inputProps={{ maxLength: 3 }}
              value={activityCode !== undefined ? originalTab.activityTypeCode : DEFAULT_DD_VALUE}
              onChange={originalTabHandleChanges("activityTypeCode")}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showOriginalError.showATCodeErr
                  ? originalErrorMsg.aTCodeErrMsg
                  : null
              }
              dropdownList={activityTypeCodeDD
                ? activityTypeCodeDD.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                : null}
            />
            <NativeDropDown
              id="financialReasonCode"
              className="override-width-22 override-m-1"
              isRequired
              disabled={!userInquiryPrivileges}
              label="Financial Reason Code"
              inputProps={{ maxLength: 3 }}
              value={reasonCode !== undefined ? originalTab.financialReason : DEFAULT_DD_VALUE}
              onChange={originalTabHandleChanges("financialReason")}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showOriginalError.showFRCodeErr
                  ? originalErrorMsg.fRCodeErrMsg
                  : null
              }
              dropdownList={financialReasCodeDD
                ? financialReasCodeDD.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                : null}
            />
            <NativeDropDown
              id="collocationCode"
              label="Fund Code"
              className="override-width-22 override-m-1"
              disabled={!userInquiryPrivileges}
              inputProps={{ maxLength: 8 }}
              value={fundCodeValue !== undefined ? originalTab.fundCode : DEFAULT_DD_VALUE}
              onChange={originalTabHandleChanges("fundCode")}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showOriginalError.showFCodeErr
                  ? originalErrorMsg.fCodeErrMsg
                  : null
              }
              dropdownList={collationCodeDD
                ? collationCodeDD.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                : null}
            />
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form with-icon with-icon-margin override-width-22 override-m-1">
            <TextField
              id="original-amount"
              fullWidth
              data-testid="test_Original_amount"
              name="original-amount"
              label="Original Amount"
              required
              type="string"
              inputProps={{ maxLength: 12 }}
              value={originalTab.originalAmount}
              onChange={originalTabHandleChanges("originalAmount")}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                  ""
                );
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges || editFlag,
                className:
                  !userInquiryPrivileges || editFlag ? "Mui-disabled" : "",
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              onBlur={originalTabFormat}
              onFocus={removeOriginalTabFormat}
              helperText={
                showOriginalError.showOAmtErr
                  ? originalErrorMsg.oAmtErrMsg
                  : null
              }
              error={
                showOriginalError.showOAmtErr
                  ? originalErrorMsg.oAmtErrMsg
                  : null
              }
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1 has-link">
            <TextField
              id="related-ft-tcn"
              fullWidth
              label="Related FT TCN"
              inputProps={{ maxLength: 17 }}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true,
                startAdornment: (
					editFlag &&
                  <InputAdornment position="start">
                     <a
                      href={CLAIMS_INQUIRY_TCN_LINK + originalTab.relatedFTTCN}
                      target="_blank"
                    >
                      {originalTab.relatedFTTCN}
                    </a>
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="lockBoxNumber"
              fullWidth
              label="Lockbox Number"
              type="string"
              inputProps={{ maxLength: 7 }}
              value={originalTab.lockBoxNumber}
              onChange={originalTabHandleChanges("lockBoxNumber")}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                 readOnly: !userInquiryPrivileges,
                 className: !userInquiryPrivileges ? 'Mui-disabled' : ''
              }}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~` ]/,
                  ""
                );
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="check-number"
              fullWidth
              label="Check Number"
              type="string"
              required
              inputProps={{ maxLength: 15 }}
              value={originalTab.checkNumber}
              onChange={originalTabHandleChanges("checkNumber")}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  AppConstants.NUMBER_ONLY_REGEX,
                  ""
                );
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges || editFlag,
                className:
                  !userInquiryPrivileges || editFlag ? "Mui-disabled" : ""
              }}
              helperText={
                showOriginalError.showCheckNumErr
                  ? originalErrorMsg.checkNumErrMsg
                  : null
              }
              error={
                showOriginalError.showCheckNumErr
                  ? originalErrorMsg.checkNumErrMsg
                  : null
              }
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1 with-icon with-icon-margin">
            <TextField
              id="balance-amount"
              fullWidth
              label="Balance Amt"
              inputProps={{ maxLength: 11 }}
              value={originalTab.balanceAmount}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="deposit-number"
              fullWidth
              label="Deposit Number"
              type="string"
              inputProps={{ maxLength: 10 }}
              value={originalTab.depositNumber}
              onChange={originalTabHandleChanges("depositNumber")}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? "Mui-disabled" : ""
              }}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  AppConstants.NUMBER_ONLY_REGEX,
                  ""
                );
              }}
            ></TextField>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form override-width-22 override-m-1 with-date">
              <KeyboardDatePicker
                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                id="deposit-date"
                label="Deposit Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={selectedDepositDate}
                onChange={handleDepositDateChange}
                disabled={!userInquiryPrivileges}
                InputLabelProps={{
                  shrink: true
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                InputProps={{
                  readOnly: !userInquiryPrivileges,
                  className: !userInquiryPrivileges ? "Mui-disabled" : ""
                }}
                helperText={
                  showOriginalError.showDipositDateErr
                    ? originalErrorMsg.depositDateErrMsg
                    : null
                }
                error={
                  showOriginalError.showDipositDateErr
                    ? originalErrorMsg.depositDateErrMsg
                    : null
                }
              />
            </div>
          </MuiPickersUtilsProvider>
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              fullWidth
              id="invoice"
              label="Invoice"
              inputProps={{ maxLength: 10 }}
              value={originalTab.invoice}
              onChange={originalTabHandleChanges("invoice")}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? "Mui-disabled" : ""
              }}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~` ]/,
                  ""
                );
              }}
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="establishedDate"
              fullWidth
              label="Established Date"
              inputProps={{ maxLength: 10 }}
              value={originalTab.establishedDate}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="last-applied-date"
              fullWidth
              label="Last Applied Date"
              inputProps={{ maxLength: 10 }}
              value={originalTab.lastAppliedDate}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="batch-number"
              fullWidth
              label="Batch Number"
              inputProps={{ maxLength: 3 }}
              value={originalTab.batchNumber}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="internal-tracking-number"
              fullWidth
              label="Internal Tracking Number"
              inputProps={{ maxLength: 15 }}
              value={originalTab.intTrackNum}
              onChange={originalTabHandleChanges("intTrackNum")}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  AppConstants.NUMBER_ONLY_REGEX,
                  ""
                );
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? "Mui-disabled" : ""
              }}
              helperText={
                showOriginalError.showIntTrackNumErr
                  ? originalErrorMsg.intTrackNumErrMSg
                  : null
              }
              error={
                showOriginalError.showIntTrackNumErr
                  ? originalErrorMsg.intTrackNumErrMSg
                  : null
              }
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1 with-date">
            <TextField
              id="pend-date"
              fullWidth
              label="Pend Date"
              inputProps={{ maxLength: 10 }}
              value={originalTab.pendDate}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
            <NativeDropDown
              id="pending-reason"
              className="override-width-22 override-m-1"
              disabled={!userInquiryPrivileges}
              label="Pending Reason"
              inputProps={{ maxLength: 3 }}
              value={pendReason !== undefined ? originalTab.pendingReason : DEFAULT_DD_VALUE}
              onChange={originalTabHandleChanges("pendingReason")}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={pendingReasonDD
                ? pendingReasonDD.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                : null}
            />
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="item-number"
              fullWidth
              label="Item Number"
              inputProps={{ maxLength: 3 }}
              value={originalTab.itemNumber}
              InputProps={{
                className: "Mui-disabled",
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              helperText={
                showOriginalError.showItemNumErr
                  ? originalErrorMsg.itemNumErrMsg
                  : null
              }
              error={
                showOriginalError.showItemNumErr
                  ? originalErrorMsg.itemNumErrMsg
                  : null
              }
            ></TextField>
          </div>
          {editFlag ? (
            <div className="mui-custom-form" style={{ lineHeight: "100%" }}>
              <label className="MuiFormLabel-root small-label">Void</label>
              <div className="sub-radio m-0">
                <Radio
                  value={true}
                  disabled={!userInquiryPrivileges}
                  checked={originalTab.voidIndicator === true}
                  id="voidconyes"
                  onChange={originalTabHandleChanges("void")}
                />
                <span className="text-black">
                  {" "}
                  <label for="voidconyes"> Yes </label>
                </span>
                <Radio
                  id="voidcontno"
                  value={false}
                  disabled={!userInquiryPrivileges}
                  checked={originalTab.voidIndicator === false}
                  onChange={originalTabHandleChanges("void")}
                  className="ml-2"
                />
                <span className="text-black">
                  {" "}
                  <label for="voidcontno"> No </label>
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id='ATRnumber'
              data-testid = 'test_ATR_txt'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              disabled={!userInquiryPrivileges}
              value={originalTab.atrNumber}
              onChange={originalTabHandleChanges("atrNumber")}
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true
              }} 
              helperText={showOriginalError.showATRErr?`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`:null}
              error= {showOriginalError.showATRErr?`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`:null}
            ></TextField>
          </div>
        </div>
      </form>
    </div>
  );
}
