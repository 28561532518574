/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const FCN_REQUIRED = 'FCN is required.';
export const MCN_REQUIRED = 'Manual Check Number is required.';
export const LOB_REQUIRED = 'LOB is required.';
export const PAYEE_REQUIRED = 'Payee ID is required.';
export const PAYEE_TYPE_REQUIRED = 'Payee Type is required.';
export const PAYEE_ID_TYPE_CODE_REQUIRED = 'Payee ID Type Code is required.';
export const FCN_TEXT = 'FCN';
export const MCN_TEXT = 'Manual Check Number';
export const PAYEE_TEXT = 'Payee';
export const NO_RECORDS_FOUND = 'No records found for the search criteria entered.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';
export const FCN_INVALID = 'Please enter the FCN in correct format.';
export const PAYEE_INVALID = 'Please enter the Payee ID in correct format.';
export const MCN_INVALID = 'Please enter the MCN in correct format.';
export const DATE_GREATER_ERROR = 'Established Thru Date must be greater than or equal to Established From Date.';
export const DATE_GREATER_ERROR2 = 'Established From Date must be less than or equal to Established Thru Date.';
export const FROM_DATE_INVALID = 'Established From Date entered is invalid.';
export const THRU_DATE_INVALID = 'Established Thru Date entered is invalid.';
export const LAST_DATE_INVALID = 'Last Applied Date entered is invalid.';
export const STARTS_WITH_REQUIRED = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';

export const AP = 'advancedPaymentReasonCodeList';
export const CR = 'wlaimRecvReasonCodeList';
export const GL = 'grossLevelReasonCodeList';
export const RA = 'adjustmentReasonCodeList';
export const RR = 'reversalReasonCodeList';
export const RT = 'transferReasonCodeList';
export const WL = 'withHoldingReasonCodeList';
export const WO = 'writeOffReasonCodeListForSearch';
export const WR = 'wrWOReestablishmentReasonCodeList';

export const AP_TEXT = 'AP';
export const CR_TEXT = 'CR';
export const GL_TEXT = 'GL';
export const RA_TEXT = 'RA';
export const RR_TEXT = 'RR';
export const RT_TEXT = 'RT';
export const WL_TEXT = 'WL';
export const WO_TEXT = 'WO';
export const WR_TEXT = 'WR';

export const PAYEE_TYPE = 'payeeType';
export const ACTIVITY_CODE = 'activityCode';
export const REASON_CODE = 'reasonCode';
export const NETWORK_ERROR = 'Network Error';
export const TRANSACTION_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const REVERSE_ERROR = 'Reversal can only be performed when the balance amount is EQUAL TO the adjusted original amount.';
