/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const ADD = 'Add';
export const RESET = 'Reset';
export const SAVE = 'Save ';
export const ADD_SYSTEM_PARAMETER_DETAIL = 'Add System Parameter Detail ';
export const ADD_SYSTEM_PARAMETER = 'Add System Parameter';
export const SYSTEM_PARAMETER_ADD = 'System Parameter Add ';

export const LOB_DETAIL = 'Display Detail for LOB';
export const DATA_FORMAT = 'Data Format';
export const DESCRIPTION = 'Description';
export const PARAMETER_NUMBER = 'Parameter Number';
export const FUNCTIONAL_AREA = 'Functional Area';
export const FUNCTIONAL_AREA_REQUIRED = 'Functional Area is required.';
export const DATA_FORMAT_REQUIRED = 'Data Format is required.';
export const DESCRIPTION_REQUIRED = 'Description is required.';
export const PARAMETER_NUMBER_REQUIRED = 'Parameter Number is required.';
export const LOB_REQUIRED = 'LOB is required.';
export const BEGIN_DATE_OVERLAPPING = 'Overlapping System Parameter detail rows for a LOB are not allowed.';
export const NUMERIC_REQUIRED = 'Number is required.';
export const PERCENT_REQUIRED = 'Percent is required.';
export const ALPHA_NUMERIC_REQUIRED = 'Text is required.';
export const CURRENCY_REQUIRED = 'Currency is required.';
export const REQUIRED = 'At least one detail row is required. Please enter at least one detail row.';
export const CURRENCY_INVALID = 'Currency does not match the Format Type. Please update value to a valid format.';
export const PERCENT_INVALID = 'Percent does not match the Format Type. Please update value to a valid format.';
export const NUMERIC_INVALID = 'Number does not match the Format Type. Please update value to a valid format.';
export const ALPHA_NUMERIC_INVALID = 'Text does not match the Format Type. Please update value to a valid format.';
export const TIMESTAMP_REQUIRED = 'Timestamp is required.';
export const BEGIN_DATE_INVALID = 'Begin Date that was entered is invalid.';
export const END_DATE_INVALID = 'End Date that was entered is invalid.';
export const DATE_INVALID = 'Date that was entered is invalid.';
export const BEGIN_DATE_DELETE = 'In order to delete this item, the begin date must be greater than or equal to the current date.';
export const BEGIN_DATE_REQUIRED = 'Begin Date is required.';
export const END_DATE_REQUIRED = 'End Date is required.';
export const DATE_REQUIRED = 'Date is required.';
export const UPDATED_SUCCESSFULLY = 'System Parameter updated successfully.';
export const SAVE_ERROR = 'Something went wrong.Please try again later.';
export const SAVED_SUCCESSFULLY = 'System successfully saved the Information.';
export const TIMESTAMP_INVALID = 'Parameter value does not match the Format Type. Please update value to a valid format.';
export const TIMESTAMP_OLD_REGEX = /^(((0[13578]|1[02])[\/\.-](0[1-9]|[12]\d|3[01])[\/\.-]((19|[2-9]\d)\d{2})\s(0[0-9]|1[0-2]):(0[0-9]|[1-59]\d):(0[0-9]|[1-59]\d)\s(AM|am|PM|pm))|((0[13456789]|1[012])[\/\.-](0[1-9]|[12]\d|30)[\/\.-]((19|[2-9]\d)\d{2})\s(0[0-9]|1[0-2]):(0[0-9]|[1-59]\d):(0[0-9]|[1-59]\d)\s(AM|am|PM|pm))|((02)[\/\.-](0[1-9]|1\d|2[0-8])[\/\.-]((19|[2-9]\d)\d{2})\s(0[0-9]|1[0-2]):(0[0-9]|[1-59]\d):(0[0-9]|[1-59]\d)\s(AM|am|PM|pm))|((02)[\/\.-](29)[\/\.-]((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))\s(0[0-9]|1[0-2]):(0[0-9]|[1-59]\d):(0[0-9]|[1-59]\d)\s(AM|am|PM|pm)))$/;
export const TIMESTAMP_REGEX = /^([0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])( (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9](.[0-9]{6}(-[0-9]{1,3})*)*)*)$/;