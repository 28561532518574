/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './budgetMaintenanceActionTypes';
import * as serviceEndPoint from '../../../../../SharedModules/services/service';
import axios from 'axios';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler/index';

export const getAuditLogDetailsAction = (tableName, keyValue, auditData) => {
  let body = {
    "tableName": tableName,
    "keyValue": keyValue
  }
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_ENDPOINT}`, body)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}

export const setAuditLogData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE,
    payload: data,
    keyName
  };
};

export const getAuditLogRowDetailsAction = (auditReq, auditData) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.AUDIT_LOG_MULTIPLE_ENDPOINT}`, auditReq)
      .then(response => {
        if (response.data)
          dispatch(setAuditLogRowData(response.data.responseAuditLogDetails, auditData));
      })
      .catch(error => {
      });
  }
}
export const setAuditLogRowData = (data, keyName) => {
  return {
    type: actionTypes.AUDIT_LOG_TABLE_AUDIT,
    payload: data,
    keyName
  };
};
export const resetSearchBudgetMaintenance = () => {
  return {
    type: actionTypes.RESETDATA,
    resetData: []
  };
};

export const getDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.DROPDOWNS}`, value)
      .then(response => {
        dispatch(setDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setBudgetMaintenance({ systemFailure: true }));
      });
  };
};

export const setDropdownData = (dropdownData) => {
  return {
    type: actionTypes.DROPDOWNDATA,
    dropdownData: dropdownData
  };
};

export const getSubDropdownDataAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.VALID_VALUE_DROPDOWNS}`, value)
      .then(response => {
        dispatch(setSubDropdownData(response.data));
      })
      .catch(error => {
        dispatch(setSubDropdownData(error));
      });
  };
};

export const setSubDropdownData = (subdropdownData) => {
  return {
    type: actionTypes.SUB_DROPDOWNDATA,
    subdropdownData: subdropdownData
  };
};

export const searchBudgetMaintenanceAction = (value, fundCodeStartsWith) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BUDGET_MAINTENANCE_MULTI_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setBudgetMaintenance([]));
        } else {
          dispatch(setBudgetMaintenance(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setBudgetMaintenance(sendingResponse));
      });
  };
};

export const searchBudgetMaintenanceIDAction = (value) => {
  return dispatch => {
    return axios.get(`${serviceEndPoint.BUDGET_MAINTENANCE_ID_SEARCH_ENDPOINT}/${value}`)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(setBudgetMaintenanceID(sendingResponse));
      })
      .catch(error => {
      });
  };
};

export const AddBudgetMaintenanceViewAction = (value, fundCodeStartsWith) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.BUDGET_MAINTENANCE_MULTI_SEARCH_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        if (sendingResponse === undefined) {
          dispatch(setBudgetMaintenanceAddView([]));
        } else {
          dispatch(setBudgetMaintenanceAddView(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(setBudgetMaintenanceAddView(sendingResponse));
      });
  };
};

export const budgetMaintenanceAddSaveAPI = (requestObject) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.ADD_BUDGET_MAINTENANCE_SAVE}`, requestObject)
      .then((response) => {
        if (response) {
          let sendingResponse = commonAPIHandler(response);
          dispatch(setBudgetMaintenanceAddSave(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setBudgetMaintenanceAddSave(sendingResponse));
      });
  };
};

export const budgetMaintenanceUpdateSaveAPI = (requestObject) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.UPDATE_BUDGET_MAINTENANCE_SAVE}`, requestObject)
      .then((response) => {
        if (response) {
          let sendingResponse = commonAPIHandler(response);
          dispatch(setBudgetMaintenanceUpdateSave(sendingResponse));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(setBudgetMaintenanceUpdateSave(sendingResponse));
      });
  };
};
export const budgetEndDateAPI = (requestObject) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.END_DATE_BUDGET}`, requestObject)
      .then((response) => {
        if (response) {
          let sendingResponse = commonAPIHandler(response)
          dispatch(setBudgetEndDate(sendingResponse));
        }
      })
      .catch(error => {
        let responseerror = commonAPIHandler(error);
        dispatch(setBudgetEndDate(responseerror));
      });
  };
};

export const setBudgetEndDate = (data) => {
  return {
    type: actionTypes.END_DATE_API,
    budgetEndDateInfo: data
  };
};

export const setBudgetMaintenanceAddSave = (data) => {
  return {
    type: actionTypes.ADD_BUDGET_MAINTENANCE_SAVE,
    BudgetMaintenanceAddSaveResponse: data
  };
};

export const setBudgetMaintenance = (budgets) => {
  return {
    type: actionTypes.SEARCH_BUDGET_MAINTENANCE,
    budgets: budgets
  };
};
export const setBudgetMaintenanceAddView = (budgets) => {
  return {
    type: actionTypes.ADD_BUDGET_MAINTENANCE_VIEW,
    budgetsview: budgets
  };
};

export const setBudgetMaintenanceID = (data) => {
  return {
    type: actionTypes.SEARCH_BUDGET_MAINTENANCE_ID,
    searchBudgetID: data
  };
};
export const setBudgetMaintenanceUpdateSave = (data) => {
  return {
    type: actionTypes.UPDATE_BUDGET_MAINTENANCE_SAVE,
    BudgetMaintenanceUpdateSaveResponse: data
  };
};
