/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as ErrorConstants from '../GrossLevelAdd/ErrorCheck';
import GrossLevelDetailsComponent from './PaymentDetails';
import GrossLevelExemptionDetailComponent from './PaymentExemption';
import GrossLevelHistoryTableComponent from './PaymentHistory';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import PaymentScheduleTable from './paymentScheduleTable';
import moment from 'moment';
import { Link, Prompt } from 'react-router-dom';
import { checkingDecimal, getLongDescription } from '../../../../../SharedModules/CommonUtilities/commonUtilities';
import {
  getUTCTimeStamp,
  generateUUID,
  validateDateMinimumValue,
  compareTwoDatesGreaterThanOrEqual,
  getDateInMMDDYYYYFormatWithApendZero
} from '../../../../../SharedModules/DateUtilities/DateUtilities';
import { redirectSystemID } from '../../../../../SharedModules/TabRedirections/TabRedirections';
import {
  getWriteOffRestablishmentDetailsReset,
  getWriteOffDetailsReset,
  getWriteOffDetails,
  getWriteOffRestablishmentDetails,
  getDropdownDataAction,
  getPayeeIdType,
  advancePaymentUpdate,
  searchFCNAccountReceivableAction,
  agingDaysList,
  ARTransferEligibility,
  ARReverseEligibility,
  getDefaultRecoupmentDetails,
  searchClaimsReceivableAction
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router';

import TabPanel from '../../../../../SharedModules/TabPanel/TabPanel';
import * as ARSearchConstants from '../ARSearch/ARSearchConstants';
import * as AppConstants from '../../../../../SharedModules/AppConstants';
import dropdownCriteria from './AdvancePayment.json';
import {
  getAuditLogDetailsAccountingAction,
  getMaxFrequencyParameter
} from '../../../../../SharedModules/store/Actions/SharedAction';
import AuditTableComponent from '../../../../../SharedModules/AuditLog/AuditTableComponent';
import Footer from '../../../../../SharedModules/Layout/footer';
import Notes from '../../../../../SharedModules/Notes/Notes';
import numeral from 'numeral';
import NoSaveMessage from '../../../../../SharedModules/Errors/NoSaveMessage';
import Attachments from '../../../../../SharedModules/Attachments/Attachments';
import Radio from '@material-ui/core/Radio';
import { searchSystemParameterAction } from '../../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function AdvancePayment (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  let errorMessagesArray = [];
  const [rowPaymentScheduleData, setRowPaymentScheduleData] = React.useState(
    []
  );
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [activityTypeData, setACtivityTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const [recoupmentData, setRecoupmentData] = React.useState([]);
  const [frequencyData, setFrequencyData] = React.useState([]);
  const [COSData, setCOSData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [historyData, setHistoryData] = React.useState([]);
  const [writeOff, setWriteOff] = React.useState(true);
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  var showOriginalArAmmountErrorTemp = false;
  var showApprovalErrorTemp = false;
  var showFiscalYearErrorTemp = false;
  var showCollocationCodeErrorTemp = false;
  var showRecoupmentTypeCodeErrorTemp = false;
  var showInvalidBeginDateErrorTemp = false;
  var showInvalidEndDateErrorTemp;
  var showEndDateLesserErrorTemp;
  var showBeginDateRquiredErrorTemp = false;
  var showEndDateRequiredErrorTemp = false;
  var showPercentErrorTemp = false;
  var showInstallmentErrorTemp = false;
  var startDateErrorTemp = false;
  var startDateReqErrorTemp = false;
  var minimumBeginDateError = false;
  var minimumEndDateError = false;
  var minimumRecoupmentDaateError = false;
  var manualCheckNumberError = false;
  var showATRErrorTemp = false;
  var showOriginalArAmountErrorTextTemp = '';
  var showApprovalErrorTextTemp = '';
  var showFiscalYearErrorTextTemp = '';
  var showCollocationCodeErrorTextTemp = '';
  var showRecoupmentTypeCodeErrorTextTemp = '';
  var showInvalidBeginDateTextTemp = '';
  var showInvalidEndDateInvalidTextTemp = '';
  var showEndDateLesserErrorTextTemp = '';
  var showBeginDateRequiredErrorTextTemp = '';
  var showEndDateRequiredErrorTextTemp = '';
  var minBeginDateTextTemp = '';
  var minEndDateTextTemp = '';
  var minRecoupmentDateTextTemp = '';
  var showManualCheckNumberTextTemp = '';
  var showPercentErrorTextTemp = '';
  var showInstallmentErrorTextTemp = '';
  var showStartDateErrorTextTemp = '';
  var startDateReqErrorTextTemp = '';
  var showATRErrorTextTemp = '';
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);

  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // Attachments Variables
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);

  const [writeOffSaveDetails, setWriteOffSaveDetails] = React.useState();
  const [ClaimsReceivableSaveWO, setClaimsReceivableSaveWO] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);

  const [manualCheckDate, setSelectedManualCheckDate] = React.useState(null);
  const [selectedRecoupmentDate, setSelectedRecoupmentDate] = React.useState(
    null
  );
  const [selectedDueDate, setSelectedDueDate] = React.useState(null);
  const [paymentScheduleData, setPaymentSchedleData] = React.useState([]);
  const [manualEditFlag, setManualEditFlag] = React.useState(false);

  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  // add notes
  let notesDataArray = [];
  const [recoupmentTypeClicked, setRecoupmentTypeClicked] = React.useState(false);
  const optSelectOne = DEFAULT_DD_VALUE;
  const drpOptFin6001 = 'Financial#6001';
  const drpOptFin1009 = 'Financial#1009';
  const addDate = 'MM/DD/YYYY';
  const Muidisabled = 'Mui-disabled';
  const [paymentSettingChanged, setPaymentSettingChanged] = React.useState(
    false
  );
  const [paymentInd, setpaymentInd] = React.useState(false);
  const [auditDialogRowData, setAuditDialogRowData] = React.useState([]);
  const [auditRowData, setAuditRowData] = React.useState([]);
  const [defaultRecoupment, setDefaultRecoupment] = React.useState(false);
  const [values, setValues] = React.useState({
    originalARAmount: 0.0,
    approval: 'N',
    fiscalYear: '',
    categoryOfService: optSelectOne,
    collocationCode: optSelectOne,
    recoupmentTypeCode: optSelectOne,
    installmentValue: '',
    percentValue: '',
    frequency: '1',
    exemptFromPenalty: 'N',
    exemptFromInterest: 'N',
    exemptFromCollectionAgency: 'N',
    amountDue: '',
    defaultOnPaymentSchedule: 'N',
    defaultOnPaymentSchedulePopup: 'N',
    lob: optSelectOne,
    payeeType: optSelectOne,
    payeeIdTypeCode: optSelectOne,
    reasonCode: optSelectOne,
    activityCode: optSelectOne,
    systemPayeeId: '',
    payeeId: '',
    payeePayerName: '',
    fcn: '',
    adjustedARAmount: '',
    balanceAmount: '',
    establishedDate: '',
    lastDateApplied: '',
    arLettersent: '',
    categoryOfServiceData: [],
    collocationCodeData: [],
    calculatedInterest: '',
    calculatedPenalty: '',
    feesAssesed: '',
    frequencyData: [],
    recoupmentData: [],
    originalARAmountData: 0,
    appliedAmountData: 0,
    balanceAmountData: 0,
    manualCheckNumber: '',
    memberIdType: optSelectOne,
    payeeStatus: optSelectOne,
    memberId: '',
    arAgeInDays: '',
    paymentScheduleType: optSelectOne,
    recoupmentStartDate: null,
    numberofInstallments: '',
    interestCalculation: 'N',
    interestPercentage: '',
    paymentScheduleFrequency: optSelectOne,
    recoupedAmount: '0.00',
    installmentAmount: '',
    federal: '',
    state: '',
    county: '',
    other: '',
    atrNumber: ''
  });

  const [amountObj, setAmountObj] = React.useState({
    tempOriginalARAmount: '',
    tempBalanceAmount: ''
  });

  const popUpErrorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    {
      showOriginalArAmmountError,
      showFiscalYearError,
      showCollocationCodeError,
      showRecoupmentTypeCodeError,
      showDueDateError,
      showAmountDueError,
      showInvalidBeginDateError,
      showInvalidEndDateError,
      showEndDateLesserError,
      showBeginDateRequiredError,
      showEndDateRequiredError,
      showPercentError,
      showInstallementError,
      showStartDateError,
      showStartDateRequiredError,
      showDueDateGreaterError,
      showMinBeginDateError,
      showMinEndDateError,
      showMinRecoupmentDateError,
      showMinDueDateError,
      showDueDateInvalidError,
      showNegativeAmountError,
      showGreaterThanZeroAmountError,
      showOriginalArAmountPopError,
      showManualCheckNumberError,
      showFrequencyError,
      showATRError
    },
    setShowError
  ] = React.useState(false);
  const [
    {
      showOriginalArAmountErrorText,
      showFiscalYearErrorText,
      showCollocationCodeErrorText,
      showRecoupmentTypeCodeErrorText,
      showDueDateErrorText,
      showAmountDueErrorText,
      showInvalidBeginDateText,
      showInvalidEndDateInvalidText,
      showEndDateLesserErrorText,
      showBeginDateRequiredErrorText,
      showEndDateRequiredErrorText,
      showPercentErrorText,
      showInstallmentErrorText,
      showStartDateErrorText,
      showStartDateRequiredErrorText,
      showDueDateGreaterErrorText,
      showMinBeginDateErrorText,
      showMinEndDateErrorText,
      showMinRecoupmentDateErrorText,
      showMinDueDateErrorText,
      showDueDateInvalidErrorText,
      showNegativeAmmountErrorText,
      showGreaterThanZeroAmountText,
      showOriginalArAmountPopErrorText,
      showManualCheckNumberText,
      showFrequencyErrorText,
      showATRErrorText
    },
    setShowErrorText
  ] = React.useState('');

  const [tabOne, setTabOne] = React.useState(true);
  const [tabTwo, setTabTwo] = React.useState(false);
  const [tabThree, setTabThree] = React.useState(false);
  const [tabFour, setTabFour] = React.useState(false);
  const [add, setAdd] = React.useState(true);
  const [retainEdit, setRetainEdit] = React.useState({});

  const addNotes = data => {
    setFocusCheck(true);
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  const claimsReceivableDataWriteOff = useSelector(
    state => state.accountReceivableState.payloadClaims
  );
  const writeOffDetailsSave = useSelector(state => state.writeOffState.saveWriteOffData);
  const [errorMessageCopy, setErrorMessageCopy] = React.useState([]);

  const [auditLogRow, setAuditLogRow] = React.useState(null);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [payoutRCodes, setPayoutRCodes] = React.useState([]);
  const [receiptRCodes, setReceiptRCodes] = React.useState([]);
  const [accountRRCodes, setAccountRRCodes] = React.useState([]);
  const [dispositionRCodes, setDispositionRCodes] = React.useState([]);
  const [advancePayment, setAdvancePayment] = React.useState({});
  const [frequencyDataDropdown, setFrequencyDataDropdown] = React.useState([]);
  const [
    selectedRecoupmentStartDate,
    setSelectedRecoupmentStartDate
  ] = React.useState(null);
  const [retainSelectedDueDate, setRetainSelectedDueDate] = React.useState(
    null
  );
  const handleRecoupmentStartDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentStartDate(date);
    setPaymentSettingChanged(generateUUID());
    setPaymentSchedleData([]);
  };
  const [paymentScheduleVO, setPaymentScheduleVO] = React.useState({
    installmentAmount: '',
    recoupmentAmount: '0.00'
  });

  React.useEffect(() => {
    if (writeOffDetailsSave !== undefined) {
      setWriteOffSaveDetails(writeOffDetailsSave);
      dispatch(searchClaimsReceivableAction(writeOffDetailsSave.newFCN));
    }
  }, [writeOffDetailsSave]);

  React.useEffect(() => {
    if (claimsReceivableDataWriteOff !== undefined) { setClaimsReceivableSaveWO(claimsReceivableDataWriteOff); }
  }, [claimsReceivableDataWriteOff]);
  const [advancePaymentDetails, setadvancePaymentDetails] = React.useState(props.location.state.advancePaymentData);

  React.useEffect(() => {
    if ((ClaimsReceivableSaveWO !== undefined) && (writeOffSaveDetails !== undefined)) {
      setadvancePaymentDetails(ClaimsReceivableSaveWO);
    }
  }, [ClaimsReceivableSaveWO, writeOffSaveDetails]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    setShowErrorText('');
    setShowError([]);
  };

  let paramsData;
  const amountFormatCheck = value => {
    return typeof value === 'string'
      ? numeral(value.replace(/,/g, '').replace('$', '')).format('0.00')
      : numeral(value).format('0.00');
  };

  const formatDecimalField = name => event => {
    if (event.target.value !== '') {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0,0.00')
      });
    }
  };
  const formatPercent = name => event => {
    if (event.target.value !== '') {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0.00')
      });
    }
  };

  useEffect(() => {
    if (props.location.state.advancePaymentData) {
      paramsData = props.location.state.advancePaymentData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
      if (
        paramsData &&
        paramsData.attachmentsList &&
        paramsData.attachmentsList.length > 0
      ) {
        setAttachmentsTableData(paramsData.attachmentsList);
      } else {
        setAttachmentsTableData([]);
      }
      setAttachmentFile([]);
    }
  }, []);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: optSelectOne,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        ).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End

  // Audit Log Implementation starts
  const [close, setClose] = React.useState(true);
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const onClickAuditDialogLog = data => {
    setShowLogDialogTable(true);
    dispatch(
      getAuditLogDetailsAccountingAction(
        data.scheduleSK,
        '',
        true,
        'auditDialogData'
      )
    );
  };
  const onClickAuditLog = fcn => {
    dispatch(
      getAuditLogDetailsAccountingAction(fcn, '', false, 'auditLogData')
    );
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  useEffect(() => {
    if (showLogDialogTable && document.getElementById('audit-dialog-table')) { document.getElementById('audit-dialog-table').scrollIntoView(); }
  }, [showLogDialogTable]);
  const auditLogData = useSelector(state => state.sharedState.auditLogData);
  const auditDialogData = useSelector(
    state => state.sharedState.auditDialogData
  );

  useEffect(() => {
    if (auditDialogData) {
      setAuditDialogRowData(auditDialogData);
    }
    if (auditLogData) {
      setAuditRowData(auditLogData);
    }
  }, [auditDialogData, auditLogData]);

  // Audit Log Implementation ends

  const onPayeeTypeIdDropDown = value => dispatch(getPayeeIdType(value));
  const onAgingDaysList = () => dispatch(agingDaysList());
  const onAdvancePaymetAction = (value, index) =>
    dispatch(
      advancePaymentUpdate(
        value,
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : null
      )
    );
  const onSearch = values => dispatch(searchFCNAccountReceivableAction(values));
  const checkARTransfer = values => {
    dispatch(ARTransferEligibility(values));
  };
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );

  React.useEffect(() => {
    if (
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
  }, [searchSingleFinancialEntityRes]);

  // System Payee redirection ends
  const transferDetails = useSelector(
    state => state.accountReceivableState.arTransferEligibleDetails
  );
  const checkARREverse = values => {
    dispatch(ARReverseEligibility(values));
  };
  const reverseDetails = useSelector(
    state => state.accountReceivableState.arReverseEligibleDetails
  );

  const fetchWriteOffData = values => {
    dispatch(getWriteOffDetails(values));
  };
  const writeOffDetails = useSelector(
    state => state.accountReceivableState.writeOffData
  );

  const fetchWriteOffReEstablishmentData = values => {
    dispatch(getWriteOffRestablishmentDetails(values));
  };
  const writeOffReEstablishmentDetails = useSelector(
    state => state.accountReceivableState.writeOffRestablishmentData
  );

  const onGetRecoupmetDetails = values => {
    dispatch(getDefaultRecoupmentDetails(values));
  };
  const getRecoupmetDetails = useSelector(
    state => state.accountReceivableState.fetchRecoupmetDetails
  );
  const getPayeeIdFromSystemParameter = systemParameter =>
    dispatch(getMaxFrequencyParameter(systemParameter));
  const frequencyResult = useSelector(
    state => state.sharedState.frequencyParameter
  );

  let dropdown = [];
  let arAgingDays = [];
  const claimsData = JSON.parse(JSON.stringify(advancePaymentDetails));

  useEffect(() => {
    window.scrollTo(0, 0);
    getDropdownData(dropdownCriteria);
    onAgingDaysList();
    getPayeeIdFromSystemParameter({
      paramNumber: 9025,
      functionalArea: 'F1',
      lobCode: AppConstants.DEFAULT_LOB_VALUE,
      asOfDate: moment(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      ).format('YYYY-DD-MM')
    });
  }, []);

  const handleChangePaymentSchedule = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    if (name === 'installmentAmount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
        }
      } else {
        setPaymentScheduleVO({ ...paymentScheduleVO, [name]: event.target.value });
      }
    } else {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        [name]: event.target.value
      });
    }
  };
  const [paymentScheduleChanged, setPaymentScheduleChanged] = React.useState(
    false
  );

  useEffect(() => {
    if (paymentScheduleChanged) {
      setPaymentSchedleData([]);
      setpaymentInd(true);
      setValues({
        ...values,
        recoupmentStartDate: null,
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      });
      setSelectedRecoupmentStartDate(null);
    }
  }, [paymentScheduleChanged]);

  useEffect(() => {
    if (paymentSettingChanged) {
      setPaymentSchedleData([]);
      setpaymentInd(true);
    }
  }, [paymentSettingChanged]);
  const [scheduleTable, setScheduleTable] = React.useState(false);
  const [
    paymentScheduleTypeError,
    setPaymentScheduleTypeError
  ] = React.useState('');
  const [
    recoupmentStartDateError,
    setRecoupmentStartDateError
  ] = React.useState('');
  const [
    numberofInstallmentError,
    setNumberofinstallmentError
  ] = React.useState('');
  const [frequencyError, setFrequencyError] = React.useState('');
  const [interestCalError, setInstallmentCalError] = React.useState('');
  const [retainPaymentSchedule, setRetainPaymentSchedule] = React.useState({});
  const [paymentScheduleTypeData, setPaymentScheduleTypeData] = React.useState(
    []
  );
  const [adjustedReasonCode, setAdjustedReasonCode] = React.useState([]);
  const [frequencyRecoupData, setFrequencyRecoupData] = React.useState([]);

  dropdown = useSelector(state => state.accountReceivableState.dropdownData);
  arAgingDays = useSelector(
    state => state.accountReceivableState.agingDaysList
  );

  const updateAdvancePaymentResponse = useSelector(
    state => state.accountReceivableState.advancePaymentResponse
  );
  // Search the newly added Gross Level details after successful save
  const searchFcnResult = useSelector(
    state => state.accountReceivableState.payload
  );

  const sucessMessages = [];
  const systemErrorMessges = [];
  const userInquiryPrivileges = !global.globalIsReadOnly();
  useEffect(() => {
    if (
      writeOffDetails &&
      writeOffDetails.length !== 0 &&
      !writeOffDetails.SystemError
    ) {
      if (writeOffDetails.errorMessgaes !== null) {
        seterrorMessages([writeOffDetails.errorMessgaes[0]]);
      } else {
        props.history.push({
          pathname: '/WriteOff',
          state: { writeOffDetails }
        });
      }
    }
    if (writeOffDetails && writeOffDetails.SystemError === true) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffDetails]);

  useEffect(() => {
    if (
      writeOffReEstablishmentDetails &&
      !writeOffReEstablishmentDetails.SystemError &&
      writeOffReEstablishmentDetails.length !== 0
    ) {
      if (writeOffReEstablishmentDetails.errorMessgaes !== null) {
        seterrorMessages([writeOffReEstablishmentDetails.errorMessgaes[0]]);
      } else {
        props.history.push({
          pathname: '/WriteOffReEstablishment',
          state: { writeOffReEstablishmentDetails, values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }
    if (
      writeOffReEstablishmentDetails &&
      writeOffReEstablishmentDetails.SystemError === true
    ) {
      errorMessagesArray.push(ErrorConstants.TRANSACTION_ERROR);
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffReEstablishmentDetails]);

  const getDateFromFcn = (fcn) => {
    return fcn.slice(4, 6) + '/' + fcn.slice(6, 8) + '/' + fcn.slice(0, 4);
  };
  useEffect(() => {
    if (
      searchFcnResult &&
      searchFcnResult.respcode === '02' &&
      searchFcnResult.respdesc !== ''
    ) {
      errorMessagesArray.push([searchFcnResult.respdesc]);
      seterrorMessages(errorMessagesArray);
    }
    if (
      searchFcnResult != null &&
      searchFcnResult !== '' &&
      searchFcnResult.respcode === undefined &&
      searchFcnResult.systemFailure === undefined
    ) {
      searchFcnResult.establishedDateStr = getDateFromFcn(searchFcnResult.receivableFCN);
      setadvancePaymentDetails(searchFcnResult);
      setAdvancePayment(JSON.parse(JSON.stringify(searchFcnResult)));
      setAmountObj({
        tempOriginalARAmount: searchFcnResult.originalAmount,
        tempBalanceAmount: searchFcnResult.balanceAmount
      });
      if (
        searchFcnResult &&
        searchFcnResult.attachmentsList &&
        searchFcnResult.attachmentsList.length > 0
      ) {
        setAttachmentsTableData(searchFcnResult.attachmentsList);
      } else {
        setAttachmentsTableData([]);
      }
      setAttachmentFile([]);
      const scheduleDataInitial = searchFcnResult.schedulePaymentList.map(
        (option) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );
      setPaymentSchedleData(scheduleDataInitial);
    }
    if (searchFcnResult && searchFcnResult.systemFailure !== undefined) {
      errorMessagesArray.push([ErrorConstants.TRANSACTION_ERROR]);
      seterrorMessages(errorMessagesArray);
    }
  }, [searchFcnResult]);

  useEffect(() => {
    if (
      updateAdvancePaymentResponse &&
      updateAdvancePaymentResponse.respcode === '03' &&
      updateAdvancePaymentResponse.respdesc === ''
    ) {
      const error = updateAdvancePaymentResponse.exc;

      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(systemErrorMessges);
    }
    if (
      updateAdvancePaymentResponse &&
      updateAdvancePaymentResponse.respcode === '03' &&
      updateAdvancePaymentResponse.respdesc !== ''
    ) {
      setSystemSuccesMessages([]);
      if (
        updateAdvancePaymentResponse.errorMessages &&
        updateAdvancePaymentResponse.errorMessages.length > 0
      ) {
        updateAdvancePaymentResponse.errorMessages.map(error =>
          systemErrorMessges.push(error)
        );
      } else {
        systemErrorMessges.push(updateAdvancePaymentResponse.respdesc);
      }

      seterrorMessages(systemErrorMessges);
    }
    if (
      updateAdvancePaymentResponse &&
      updateAdvancePaymentResponse.respcode === '01' &&
      updateAdvancePaymentResponse.respdesc !== ''
    ) {
      const success = updateAdvancePaymentResponse.respdesc;
      setAllowNavigation(false);
      seterrorMessages([]);

      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      setFocusCheck(false);
      onSearch(advancePayment.receivableFCN);
    }
    if (
      updateAdvancePaymentResponse &&
      updateAdvancePaymentResponse.respcode === '02' &&
      updateAdvancePaymentResponse.respdesc !== ''
    ) {
      let error;
      if (
        updateAdvancePaymentResponse.errorMessages &&
        updateAdvancePaymentResponse.errorMessages.length > 0
      ) {
        error = updateAdvancePaymentResponse.errorMessages;
      } else {
        error = updateAdvancePaymentResponse.respdesc;
      }
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);

      seterrorMessages(error);
    }
    if (
      updateAdvancePaymentResponse &&
      updateAdvancePaymentResponse.systemFailure
    ) {
      const error = ErrorConstants.GENERIC_SYSTEM_ERROR;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);

      seterrorMessages(systemErrorMessges);
    }
  }, [updateAdvancePaymentResponse]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#8']) {
        setAdjustedReasonCode(dropdown.listObj['Financial#8']);
      }
      if (dropdown.listObj['Financial#6016']) {
        setFrequencyRecoupData(dropdown.listObj['Financial#6016']);
      }
      if (dropdown.listObj['Financial#3013']) {
        setACtivityTypeData(dropdown.listObj['Financial#3013']);
      }
      if (dropdown.listObj[drpOptFin6001]) {
        setReasonCodeData(dropdown.listObj[drpOptFin6001]);
      }
      if (dropdown.listObj['Financial#1007']) {
        setRecoupmentData(dropdown.listObj['Financial#1007']);
      }
      if (dropdown.listObj[drpOptFin1009]) {
        setFrequencyData(dropdown.listObj[drpOptFin1009]);
      }
      if (dropdown.listObj['Claims#1020']) {
        setCOSData(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Financial#3200']) {
        setFundCodeData(dropdown.listObj['Financial#3200']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setPayoutRCodes(dropdown.listObj['Financial#3006']);
      }
      if (dropdown.listObj['Financial#3007']) {
        setReceiptRCodes(dropdown.listObj['Financial#3007']);
      }
      if (dropdown.listObj[drpOptFin6001]) {
        setAccountRRCodes(dropdown.listObj[drpOptFin6001]);
      }
      if (dropdown.listObj['Financial#3009']) {
        setDispositionRCodes(dropdown.listObj['Financial#3009']);
      }
      if (dropdown.listObj[drpOptFin1009]) {
        setFrequencyDataDropdown(dropdown.listObj[drpOptFin1009]);
      }
      if (dropdown.listObj['Financial#6017']) {
        setPaymentScheduleTypeData(dropdown.listObj['Financial#6017']);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023']);
      }
    }
  }, [dropdown]);
  useEffect(() => {
    if (
      payoutRCodes.length > 0 &&
      receiptRCodes.length > 0 &&
      accountRRCodes.length > 0 &&
      dispositionRCodes.length > 0
    ) {
      const historyTemp = JSON.parse(JSON.stringify(historyData));
      for (var historyValue in historyTemp) {
        if (historyTemp[historyValue].receivableFCN) {
          if (historyTemp[historyValue].receivableFCN.length === 14) {
            payoutRCodes.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'Payout';
              }
              if (
                historyTemp[historyValue].reasonCode === value.description ||
                historyTemp[historyValue].reasonCode === value.code
              ) {
                historyTemp[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
            receiptRCodes.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'Receipt';
              }
              if (
                historyTemp[historyValue].reasonCode === value.description ||
                historyTemp[historyValue].reasonCode === value.code
              ) {
                historyTemp[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
            accountRRCodes.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'AccountR';
              }
              if (
                historyTemp[historyValue].reasonCode === value.description ||
                historyTemp[historyValue].reasonCode === value.code
              ) {
                historyTemp[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
            dispositionRCodes.map(value => {
              if (historyTemp[historyValue].reasonCode === value.code) {
                historyTemp[historyValue].activityType = 'AccountR';
              }
              if (
                historyTemp[historyValue].reasonCode === value.description ||
                historyTemp[historyValue].reasonCode === value.code
              ) {
                historyTemp[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
          } else {
            historyTemp[historyValue].activityType = 'FTTCN';
            historyTemp[historyValue].reasonCodeLongDesc =
              historyTemp[historyValue].reasonCode;
          }
          setHistoryData(historyTemp);
        }
      }
    }
  }, [payoutRCodes, receiptRCodes, accountRRCodes, dispositionRCodes]);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog(values.fcn);
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditDialogRowData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditDialogRowData([]);
    }
  }, [auditLogRow]);

  useEffect(() => {
    const value = values.payeeType;
    if (values.payeeType !== optSelectOne) {
      onPayeeTypeIdDropDown(value);
    }
  }, [values.payeeType]);

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
    }
  }, [payeeTypeIdDropDown]);

  useEffect(() => {
    if (
      transferDetails &&
      transferDetails.transferVO !== null &&
      transferDetails.transferVO !== undefined
    ) {
      claimsData.transferVO = transferDetails.transferVO;
      props.history.push({
        pathname: '/ARTransfer',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (transferDetails && transferDetails.SystemError === true) {
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
    if (
      transferDetails &&
      transferDetails.transferVO === null &&
      transferDetails.errorMessgaes &&
      transferDetails.errorMessgaes !== null &&
      transferDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(transferDetails.errorMessgaes);
    }
  }, [transferDetails]);
  useEffect(() => {
    if (
      reverseDetails &&
      reverseDetails.reversalVO !== null &&
      reverseDetails.reversalVO !== undefined
    ) {
      claimsData.reversalVO = reverseDetails.reversalVO;
      props.history.push({
        pathname: '/ARReverse',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (reverseDetails && reverseDetails.reversalVO === null) {
      seterrorMessages([ARSearchConstants.REVERSE_ERROR]);
    }
    if (reverseDetails && reverseDetails.SystemError === true) {
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
    if (
      reverseDetails &&
      reverseDetails.reversalVO === null &&
      reverseDetails.errorMessgaes &&
      reverseDetails.errorMessgaes !== null &&
      reverseDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(reverseDetails.errorMessgaes);
    }
  }, [reverseDetails]);

  const checkFormValidations = () => {
    var showFrequencyErrorText = '';
    var showFrequencyError = false;
    var checkFormat = false;
    const regex = /[^A-Za-z0-9]/;

    if (values.atrNumber && regex.test(values.atrNumber)) {
      showATRErrorTemp = true;
      showATRErrorTextTemp = (`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}`);
      errorMessagesArray.push(showATRErrorTextTemp);
    } else { showATRErrorTemp = false; }

    if (paymentScheduleData.length > 0 && values.recoupmentTypeCode === 'S') {
      let summation = 0;
      if (values.paymentScheduleType === 'M') {
        paymentScheduleData.map(value => {
          summation = summation + Number(numeral(value.installmentAmount).format('0.00'));
        });
        if (
          numeral(summation.toString()).format('0.00') !==
          numeral(
            values.adjustedARAmount
              .toString()
              .replace(/,/g, '')
              .replace('$', '')
              .trim()
          ).format('0.00')
        ) {
          errorMessagesArray.push(ErrorConstants.SUM_AMOUNT);
        }
      }
    }
    if (values.balanceAmount !== '0.00') {
      if (
        values.originalARAmount === '' ||
        values.originalARAmount === undefined
      ) {
        showOriginalArAmmountErrorTemp = true;
        showOriginalArAmountErrorTextTemp =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        errorMessagesArray.push(showOriginalArAmountErrorTextTemp);
      }
      if (values.fiscalYear === '' || values.fiscalYear === undefined) {
        showFiscalYearErrorTemp = true;
        showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
        errorMessagesArray.push(showFiscalYearErrorTextTemp);
      }

      if (
        values.collocationCode === '' ||
        values.collocationCode === optSelectOne ||
        values.collocationCode === undefined
      ) {
        showCollocationCodeErrorTemp = true;
        showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
        errorMessagesArray.push(showCollocationCodeErrorTextTemp);
      }

      if (
        values.manualCheckNumber === '' ||
        values.manualCheckNumber === undefined
      ) {
        manualCheckNumberError = true;
        showManualCheckNumberTextTemp =
          ErrorConstants.MANUAL_CHECK_NUMBER_REQIRED;
        errorMessagesArray.push(showManualCheckNumberTextTemp);
      }

      if (manualCheckDate) {
        if (!moment(manualCheckDate).isValid()) {
          showInvalidBeginDateErrorTemp = true;
          showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidBeginDateTextTemp);
        }
        if (!showInvalidBeginDateErrorTemp) {
          if (validateDateMinimumValue(manualCheckDate)) {
            minimumBeginDateError = true;
            minBeginDateTextTemp = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(minBeginDateTextTemp);
          }
        }
      }

      if (
        values.recoupmentTypeCode &&
        values.recoupmentTypeCode !== DEFAULT_DD_VALUE
      ) {
        if (
          values.installmentValue &&
          parseFloat(values.installmentValue) < 0
        ) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_NEGATIVE_ERROR;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0);
        } else if (
          values.installmentValue &&
          values.adjustedARAmount &&
          parseFloat(values.installmentValue.toString().replace(/,/g, '')) >
            parseFloat(values.adjustedARAmount.toString().replace(/,/g, ''))
        ) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        }

        if (values.percentValue && parseInt(values.percentValue) > 100) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp =
            ErrorConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
          errorMessagesArray.push(showPercentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
        }
        if (values.percentValue && parseInt(values.percentValue) < 0) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_NEGATIVE_ERROR;
          errorMessagesArray.push(showPercentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
        }

        if (values.recoupmentTypeCode === 'D') {
          const RECOUPMENT_PERCENT_AND_DATE_REQUIRED =
            ErrorConstants.RECOUPMENT_PERCENT_AND_DATE_REQUIRED;
          if (!values.percentValue && !selectedRecoupmentDate) {
            showPercentErrorTemp = true;
            showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
            startDateReqErrorTemp = true;
            startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
            errorMessagesArray.push(RECOUPMENT_PERCENT_AND_DATE_REQUIRED);
          } else {
            if (!values.percentValue) {
              showPercentErrorTemp = true;
              showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
              errorMessagesArray.push(showPercentErrorTextTemp);
            }
            if (!selectedRecoupmentDate) {
              startDateReqErrorTemp = true;
              startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
              errorMessagesArray.push(startDateReqErrorTextTemp);
            }
          }
        }
        if (values.recoupmentTypeCode === 'B') {
          if (!selectedRecoupmentDate && !values.percentValue) {
            errorMessagesArray.push(ErrorConstants.START_DATE_PERCENT_REQUIRED);
            showPercentErrorTemp = true;
            showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
            startDateReqErrorTemp = true;
            startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          } else {
            if (!values.percentValue) {
              showPercentErrorTemp = true;
              showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
              errorMessagesArray.push(showPercentErrorTextTemp);
            }
            if (!selectedRecoupmentDate) {
              startDateReqErrorTemp = true;
              startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
              errorMessagesArray.push(startDateReqErrorTextTemp);
            }
            if (
              values.recoupmentTypeCode !== 'N' &&
              values.percentValue &&
              parseInt(values.percentValue) === 0
            ) {
              showPercentErrorTemp = true;
              showPercentErrorTextTemp = ErrorConstants.PERCENT_ZERO;
              errorMessagesArray.push(showPercentErrorTextTemp);
              errorMessagesArray.push(
                ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100
              );
            }
          }
          if (
            values.installmentValue !== null &&
            values.installmentValue !== ''
          ) {
            showInstallmentErrorTemp = true;
            showInstallmentErrorTextTemp =
              ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
            errorMessagesArray.push(showInstallmentErrorTextTemp);
            if (values.percentValue) {
              errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
            }
          }
        }

        if (values.recoupmentTypeCode === 'A') {
          if (!selectedRecoupmentDate && !values.installmentValue) {
            errorMessagesArray.push(
              ErrorConstants.INSTALLMENT_START_DATE_REQUIRED
            );
            startDateReqErrorTemp = true;
            startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
            showInstallmentErrorTemp = true;
            showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
          } else {
            if (!values.installmentValue) {
              showInstallmentErrorTemp = true;
              showInstallmentErrorTextTemp =
                ErrorConstants.INSTALLMENT_REQUIRED;
              errorMessagesArray.push(showInstallmentErrorTextTemp);
            } else if (parseInt(values.installmentValue) === 0) {
              showInstallmentErrorTemp = true;
              showInstallmentErrorTextTemp =
                ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0;
              errorMessagesArray.push(showInstallmentErrorTextTemp);
            }
            if (!selectedRecoupmentDate) {
              startDateReqErrorTemp = true;
              startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
              errorMessagesArray.push(startDateReqErrorTextTemp);
            }
          }
          if (values.percentValue !== '' && values.percentValue !== null) {
            showPercentErrorTemp = true;
            showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
            errorMessagesArray.push(showPercentErrorTextTemp);
            if (values.installmentValue) {
              errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
            }
          }
        }

        if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'N') {
          if (
            parseInt(values.installmentValue) > 0 ||
            parseInt(values.installmentValue) < 0 ||
            parseInt(values.percentValue) > 0 ||
            parseInt(values.percentValue) < 0
          ) {
            errorMessagesArray.push(
              ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0
            );
            if (values.percentValue !== '') {
              if (
                (values.percentValue !== '0' &&
                  values.percentValue !== '0.00') ||
                parseInt(values.percentValue) > 0 ||
                parseInt(values.percentValue) < 0
              ) {
                showPercentErrorTemp = true;
                showPercentErrorTextTemp =
                  ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
              }
            }
            if (values.installmentValue !== '') {
              if (
                (values.installmentValue !== '0' &&
                  values.installmentValue !== '0.00') ||
                parseInt(values.installmentValue) > 0 ||
                parseInt(values.installmentValue) < 0
              ) {
                showInstallmentErrorTemp = true;
                showInstallmentErrorTextTemp =
                  ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
              }
            }
          }

          if (selectedRecoupmentDate) {
            startDateReqErrorTemp = true;
            startDateReqErrorTextTemp = ErrorConstants.START_DATE_BLANK;
            errorMessagesArray.push(startDateReqErrorTextTemp);
          }

          if (
            values.frequency &&
            values.frequency !== DEFAULT_DD_VALUE
          ) {
            showFrequencyError = true;
            showFrequencyErrorText = ErrorConstants.FREQUENCY_MUST_BE_BLANK;
            errorMessagesArray.push(showFrequencyErrorText);
          }
        }

        if (selectedRecoupmentDate) {
          var startDate = new Date(selectedRecoupmentDate);

          if (!moment(startDate).isValid()) {
            startDateReqErrorTemp = true;
            startDateReqErrorTextTemp = ErrorConstants.Invalid_Format_Error;
            errorMessagesArray.push(startDateReqErrorTextTemp);
          } else if (
            validateDateMinimumValue(moment(startDate).format(addDate))
          ) {
            minimumRecoupmentDaateError = true;
            minRecoupmentDateTextTemp = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(minRecoupmentDateTextTemp);
          }
        }
        if (
          values.percentValue && (values.percentValue.toString().replace(/,/g, '').match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) == null ||
          !values.percentValue.toString().replace(/,/g, '').match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}$/) == null)
        ) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_INVALID_ERROR;
          checkFormat = true;
        }
        if (
          values.installmentValue &&
         (values.installmentValue
           .toString()
           .replace(/,/g, '')
           .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) == null || !values.installmentValue
           .toString()
           .replace(/,/g, '')
           .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}$/) == null)
        ) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_INVALID;
          checkFormat = true;
        }
        if (checkFormat) {
          errorMessagesArray.push(ErrorConstants.DATA_INVALID);
        }
        if (
          advancePaymentDetails.recoupmentVO.recoupmentTypeCode === 'S' ||
          advancePaymentDetails.recoupmentVO.recoupmentTypeCode === 'N'
        ) {
          if (
            !(
              values.recoupmentTypeCode === 'S' ||
              values.recoupmentTypeCode === 'N'
            ) &&
            paymentScheduleData.length > 0
          ) {
            showRecoupmentTypeCodeErrorTemp = true;
            showRecoupmentTypeCodeErrorTextTemp =
              ErrorConstants.RECOUPEMENT_INVAlID;
            errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
          }
        }

        if (
          !showRecoupmentTypeCodeErrorTemp &&
          !(
            values.recoupmentTypeCode === 'S' ||
            values.recoupmentTypeCode === 'N'
          )
        ) {
          if (
            values.paymentScheduleType !== optSelectOne ||
            values.paymentScheduleFrequency !== optSelectOne ||
            values.interestCalculation === 'Y' ||
            values.paymentScheduleFrequency !== optSelectOne ||
            values.interestPercentage || values.recoupmentStartDate
          ) {
            showRecoupmentTypeCodeErrorTemp = true;
            showRecoupmentTypeCodeErrorTextTemp =
              ErrorConstants.RECOUPEMENT_INVAlID;
            errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
          }
        }

        if (
          !(
            advancePayment.recoupmentVO.recoupmentTypeCode === 'S' ||
            advancePayment.recoupmentVO.recoupmentTypeCode === 'N'
          ) &&
          values.recoupmentTypeCode !== optSelectOne
        ) {
          if (
            values.recoupmentTypeCode === 'S' ||
            values.recoupmentTypeCode === 'N'
          ) {
            if (
              adjustedReasonCode.some(
                values => values.code === advancePaymentDetails.reasonCode
              )
            ) {
              if (
                numeral(values.adjustedARAmount).format('0.00') !==
                numeral(values.balanceAmount).format('0.00')
              ) {
                showRecoupmentTypeCodeErrorTemp = true;
                showRecoupmentTypeCodeErrorTextTemp =
                  ErrorConstants.RECOUPMENT_BALANCE_INVALID;
                errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
              }
            }
          }
        }
      } else {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPMENT_CODE_REQUIRED;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }

      setShowError({
        showOriginalArAmmountError: showOriginalArAmmountErrorTemp,
        showApprovalError: showApprovalErrorTemp,
        showFiscalYearError: showFiscalYearErrorTemp,
        showCollocationCodeError: showCollocationCodeErrorTemp,
        showRecoupmentTypeCodeError: showRecoupmentTypeCodeErrorTemp,
        showInvalidBeginDateError: showInvalidBeginDateErrorTemp,
        showInvalidEndDateError: showInvalidEndDateErrorTemp,
        showEndDateLesserError: showEndDateLesserErrorTemp,
        showBeginDateRequiredError: showBeginDateRquiredErrorTemp,
        showEndDateRequiredError: showEndDateRequiredErrorTemp,
        showPercentError: showPercentErrorTemp,
        showInstallementError: showInstallmentErrorTemp,
        showStartDateError: startDateErrorTemp,
        showStartDateRequiredError: startDateReqErrorTemp,
        showMinBeginDateError: minimumBeginDateError,
        showMinEndDateError: minimumEndDateError,
        showMinRecoupmentDateError: minimumRecoupmentDaateError,
        showManualCheckNumberError: manualCheckNumberError,
        showFrequencyError: showFrequencyError,
        showATRError: showATRErrorTemp
      });
      setShowErrorText({
        showOriginalArAmountErrorText: showOriginalArAmountErrorTextTemp,
        showApprovalErrorText: showApprovalErrorTextTemp,
        showFiscalYearErrorText: showFiscalYearErrorTextTemp,
        showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
        showRecoupmentTypeCodeErrorText: showRecoupmentTypeCodeErrorTextTemp,
        showInvalidBeginDateText: showInvalidBeginDateTextTemp,
        showInvalidEndDateInvalidText: showInvalidEndDateInvalidTextTemp,
        showEndDateLesserErrorText: showEndDateLesserErrorTextTemp,
        showBeginDateRequiredErrorText: showBeginDateRequiredErrorTextTemp,
        showEndDateRequiredErrorText: showEndDateRequiredErrorTextTemp,
        showPercentErrorText: showPercentErrorTextTemp,
        showInstallmentErrorText: showInstallmentErrorTextTemp,
        showStartDateErrorText: showStartDateErrorTextTemp,
        showStartDateRequiredErrorText: startDateReqErrorTextTemp,
        showMinBeginDateErrorText: minBeginDateTextTemp,
        showMinEndDateErrorText: minEndDateTextTemp,
        showMinRecoupmentDateErrorText: minRecoupmentDateTextTemp,
        showManualCheckNumberText: showManualCheckNumberTextTemp,
        showFrequencyErrorText: showFrequencyErrorText,
        showATRErrorText: showATRErrorTextTemp
      });
      seterrorMessages(errorMessagesArray);
      setErrorMessageCopy(errorMessagesArray);
      return true;
    }
  };

  function ARReverse () {
    checkFormValidations();
    setSystemSuccesMessages([]);
    if (errorMessagesArray.length === 0) {
      claimsData.accountVO.scoaFiscalYear = values.fiscalYear;
      claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
        selectedRecoupmentDate
      );
      claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
        '-'
      )[0];
      claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
        '-'
      )[0];
      claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
      if (
        parseFloat(
          claimsData.claimsReceivableDetailsVO.balanceAmount.replace(/,/g, '')
        ) ===
        parseFloat(
          claimsData.claimsReceivableDetailsVO.adjustedArAmt.replace(/,/g, '')
        )
      ) {
        checkARREverse(claimsData);
      } else {
        seterrorMessages([ARSearchConstants.REVERSE_ERROR]);
      }
    }
  }
  function ARTransfer () {
    checkFormValidations();
    if (errorMessagesArray.length === 0) {
      if (parseFloat(advancePaymentDetails.balanceAmount) === 0) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.TRANSFER_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        claimsData.accountVO.scoaFiscalYear = values.fiscalYear;
        claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
          selectedRecoupmentDate
        );
        claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
          '-'
        )[0];
        claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
          '-'
        )[0];
        claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
        checkARTransfer(claimsData);
      }
    }
  }

  useEffect(() => {
    if (paymentScheduleData && paymentScheduleData.length > 0) {
      paymentScheduleData.map(value => {
        if (
          value.recoupedAmount &&
          numeral(value.recoupedAmount).format('0.00') !== '0.00'
        ) {
          setManualEditFlag(true);
        }
      });
    }
  }, [paymentScheduleData]);

  const handleManualCheckDate = date => {
    setFocusCheck(true);
    setSystemSuccesMessages([]);
    setSelectedManualCheckDate(date);
  };

  const handleRecoupmentDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentDate(date);
  };

  const handleDueDateChange = date => {
    setFocusCheck(true);
    setSystemSuccesMessages([]);
    setAllowNavigation(true);
    setSelectedDueDate(date);
  };

  function setInputValues (advancePaymentData, values, recoupmentsection) {
    return {
      lob: AppConstants.DEFAULT_LOB_VALUE,
      atrNumber: advancePaymentData?.atrNumber,
      payeeType: advancePaymentData
        ? advancePaymentData.payeePayerTypeCode
        : optSelectOne,
      payeeIdTypeCode: advancePaymentData
        ? advancePaymentData.payeePayerIDTypeCode
        : optSelectOne,
      reasonCode: advancePaymentData
        ? advancePaymentData.accountReceivableReasonCode
        : optSelectOne,
      activityCode: advancePaymentData
        ? advancePaymentData.activityTypeCode
        : optSelectOne,
      systemPayeeId: advancePaymentData
        ? advancePaymentData.payeePayerIdFromDB
        : '',
      payeeId: advancePaymentData ? advancePaymentData.payeePayerID : '',
      payeePayerName: advancePaymentData
        ? advancePaymentData.payeePayerNameFromDB
        : '',
      fcn: advancePaymentData ? advancePaymentData.receivableFCN : '',
      payeeStatus: advancePaymentData ? advancePaymentData.payeeStatus : '',
      adjustedARAmount: advancePaymentData
        ? advancePaymentData.adjustedArAmt
        : '',
      balanceAmount: advancePaymentData ? advancePaymentData.balanceAmount : '',
      approval: advancePaymentData
        ? advancePaymentData.approvalIndicator === '1'
          ? 'Y'
          : 'N'
        : 'N',
      establishedDate: advancePaymentData
        ? advancePaymentData.establishedDateStr
        : '',
      lastDateApplied: advancePaymentData
        ? advancePaymentData.lastAppliedDateStr
        : null,
      arLettersent: values.arLettersent,
      arAgeInDays: advancePaymentData
        ? advancePaymentData.arAgeInDays
        : values.arAgeInDays,
      categoryOfService:
        values.categoryOfService === optSelectOne
          ? advancePaymentData && advancePaymentData.accountVO
            ? advancePaymentData.accountVO.cos
            : values.categoryOfService
          : values.categoryOfService,
      exemptFromCollectionAgency: recoupmentsection.exemptFromCollectionAgency,
      exemptFromInterest: recoupmentsection.exemptFromInterest,
      exemptFromPenalty: recoupmentsection.exemptFromPenalty,
      collocationCode:
        values.collocationCode === optSelectOne
          ? advancePaymentData && advancePaymentData.accountVO
            ? advancePaymentData.accountVO.fundCode
            : values.collocationCode
          : values.collocationCode,
      recoupmentTypeCode: recoupmentsection.recoupment
        ? recoupmentsection.recoupment
        : values.recoupmentTypeCode,
      frequency: recoupmentsection.frequency,
      amountDue: recoupmentsection.amount,
      percentValue: recoupmentsection.percent
        ? numeral(recoupmentsection.percent).format('0.00')
        : recoupmentsection.percent,
      installmentValue: recoupmentsection.installment
        ? numeral(recoupmentsection.installment).format('0,0.00')
        : recoupmentsection.installment,
      defaultOnPaymentSchedule: recoupmentsection.defaultOnPaymentSchedule,
      defaultOnPaymentSchedulePopup: recoupmentsection.defaultOnPaymentSchedule,
      originalARAmountData:
        advancePaymentData && advancePaymentData.historyVO
          ? advancePaymentData.historyVO.originalAmount
          : 0,
      balanceAmountData:
        advancePaymentData && advancePaymentData.historyVO
          ? advancePaymentData.historyVO.balanceAmount
          : 0,
      appliedAmountData:
        advancePaymentData && advancePaymentData.historyVO
          ? advancePaymentData.historyVO.appliedAmount
          : 0,
      fiscalYear:
        values.fiscalYear === ''
          ? advancePaymentData && advancePaymentData.accountVO
            ? advancePaymentData.accountVO.scoaFiscalYear
            : values.fiscalYear
          : values.fiscalYear,
      originalARAmount:
        parseFloat(values.originalARAmount) === 0
          ? advancePaymentData
            ? advancePaymentData.originalAmount
            : amountFormatCheck(values.originalARAmount)
          : amountFormatCheck(values.originalARAmount),
      manualCheckNumber:
        values.manualCheckNumber === ''
          ? advancePaymentData && advancePaymentData.advancePaymentReceivableVO
            ? advancePaymentData.advancePaymentReceivableVO.manualCheckNumber
            : values.manualCheckNumber
          : values.manualCheckNumber,
      paymentScheduleType: values.paymentScheduleType,
      paymentScheduleFrequency: values.paymentScheduleFrequency,
      recoupmentStartDate: values.recoupmentStartDate,
      numberofInstallments: values.numberofInstallments,
      interestCalculation: values.interestCalculation,
      interestPercentage: values.interestPercentage,
      recoupedAmount: values.recoupedAmount,
      installmentAmount: values.installmentValue,
      federal:
        advancePaymentData && advancePaymentData.fundSourceAllocationVO
          ? numeral(
            advancePaymentData.fundSourceAllocationVO.federalPercentage
          ).format('0.00')
          : '',
      state:
        advancePaymentData && advancePaymentData.fundSourceAllocationVO
          ? numeral(
            advancePaymentData.fundSourceAllocationVO.statePercentage
          ).format('0.00')
          : '',
      county:
        advancePaymentData && advancePaymentData.fundSourceAllocationVO
          ? numeral(
            advancePaymentData.fundSourceAllocationVO.countyPercentage
          ).format('0.00')
          : '',
      other:
        advancePaymentData && advancePaymentData.fundSourceAllocationVO
          ? numeral(
            advancePaymentData.fundSourceAllocationVO.otherPercentage
          ).format('0.00')
          : ''
    };
  }
  let systemParamValuetoYFlag = false;
  let systemParamValuetoFundCodeFlag = false;
  const systemParamValuetoY = {
    parameterNumber: '9007',
    paramNumber: null,
    functionalArea: 'F1',
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };
  const systemParamValuetoFundCode = {
    parameterNumber: '9008',
    paramNumber: null,
    functionalArea: 'F1',
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };
  const searchSystemParamForFundCode = value => {
    dispatch(searchSystemParameterAction(value));
  };

  const systemParameterPayloadtoY = useSelector(
    state => state.appConfigState.systemParameterState.payload
  );

  useEffect(() => {
    if (systemParameterPayloadtoY && systemParameterPayloadtoY.length > 0) {
      const systemParameter = systemParameterPayloadtoY[0];
      const systemparamValueData =
        systemParameter.systemParameterDetail[0].valueData;
      if (systemparamValueData === 'Y') {
        systemParamValuetoYFlag = false;
        systemParamValuetoFundCodeFlag = true;
      } else {
        systemParamValuetoYFlag = false;
        systemParamValuetoFundCodeFlag = false;
        if (
          systemparamValueData !== 'N' &&
          systemParameter.parameterTypeCode === 'T'
        ) {
          setValues({ ...values, collocationCode: systemparamValueData });
        }
      }
      if (systemParameter.parameterTypeCode === 'P') {
        const percentValue =
          systemParameter.systemParameterDetail[0].valuePCT * 100;
        const installmentValue = values.installmentValue;
        const recoupmenttypeCode =
          values.recoupmentTypeCode === optSelectOne
            ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
              ? advancePaymentDetails.recoupmentVO.recoupmentTypeCode
              : 'B'
            : values.recoupmentTypeCode;
        const frequency =
          values.frequency === optSelectOne
            ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
              ? advancePaymentDetails.recoupmentVO.frequency
              : '1'
            : '1';
        const amount = '';
        const defaultOnPaymentSchedule = 'N';
        const defaultOnPaymentSchedulePopup = 'N';
        const approval = advancePaymentDetails
          ? advancePaymentDetails.approvalIndicator === '1'
            ? 'N'
            : 'Y'
          : values.approval;
        const exemptFromCollectionAgency =
          advancePaymentDetails && advancePaymentDetails.exemptionsVO
            ? advancePaymentDetails.exemptionsVO.exemptFromCollectionAgency ===
              true
              ? 'Y'
              : 'N'
            : values.exemptFromCollectionAgency;
        const exemptFromInterest =
          advancePaymentDetails && advancePaymentDetails.exemptionsVO
            ? advancePaymentDetails.exemptionsVO.exemptFromInterest === true
              ? 'Y'
              : 'N'
            : values.exemptFromInterest;
        const exemptFromPenalty =
          advancePaymentDetails && advancePaymentDetails.exemptionsVO
            ? advancePaymentDetails.exemptionsVO.exemptFromPenalty === true
              ? 'Y'
              : 'N'
            : values.exemptFromPenalty;
        const recoupmentsection = {
          percent: percentValue,
          installment: installmentValue
            ? numeral(installmentValue).format('0,0.00')
            : installmentValue,
          recoupment: recoupmenttypeCode,
          frequency: frequency,
          amount: amount,
          defaultOnPaymentSchedule: defaultOnPaymentSchedule,
          defaultOnPaymentSchedulePopup: defaultOnPaymentSchedulePopup,
          approval: approval,
          exemptFromCollectionAgency: exemptFromCollectionAgency,
          exemptFromInterest: exemptFromInterest,
          exemptFromPenalty: exemptFromPenalty
        };
        setValues(setInputValues(advancePayment, values, recoupmentsection));
        setSelectedRecoupmentDate(
          new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
        );
      }
      if (!systemParamValuetoYFlag && systemParamValuetoFundCodeFlag) {
        searchSystemParamForFundCode(systemParamValuetoFundCode);
      }
    }
  }, [systemParameterPayloadtoY]);

  const setInput = () => {
    const percentValue =
      values.percentValue === ''
        ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
          ? advancePaymentDetails.recoupmentVO.percentageOriginalAmount
          : values.percentValue
        : values.percentValue;
    const installmentValue =
      values.installmentValue === ''
        ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
          ? advancePaymentDetails.recoupmentVO.installmentAmount
          : values.installmentValue
        : values.installmentValue;
    const recoupmenttypeCode =
      values.recoupmentTypeCode === optSelectOne
        ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
          ? advancePaymentDetails.recoupmentVO.recoupmentTypeCode
          : values.recoupmentTypeCode
        : values.recoupmentTypeCode;
    const frequency =
      values.frequency === optSelectOne || values.frequency === ''
        ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
          ? advancePaymentDetails.recoupmentVO.frequency
          : values.frequency
        : values.frequency;
    const amount = values.amountDue;
    const defaultOnPaymentSchedule = values.defaultOnPaymentSchedule;
    const defaultOnPaymentSchedulePopup = values.defaultOnPaymentSchedule;
    const approval = advancePaymentDetails
      ? advancePaymentDetails.approvalIndicator === '1'
        ? 'Y'
        : 'N'
      : 'N';
    const exemptFromCollectionAgency =
      advancePaymentDetails && advancePaymentDetails.exemptionsVO
        ? advancePaymentDetails.exemptionsVO.exemptFromCollectionAgency === true
          ? 'Y'
          : 'N'
        : 'N';
    const exemptFromInterest =
      advancePaymentDetails && advancePaymentDetails.exemptionsVO
        ? advancePaymentDetails.exemptionsVO.exemptFromInterest === true
          ? 'Y'
          : 'N'
        : 'N';
    const exemptFromPenalty =
      advancePaymentDetails && advancePaymentDetails.exemptionsVO
        ? advancePaymentDetails.exemptionsVO.exemptFromPenalty === true
          ? 'Y'
          : 'N'
        : 'N';

    const recoupmentsection = {
      percent: percentValue,
      installment: installmentValue
        ? numeral(installmentValue).format('0,0.00')
        : installmentValue,
      recoupment: recoupmenttypeCode,
      frequency: frequency,
      amount: amount,
      defaultOnPaymentSchedule: defaultOnPaymentSchedule,
      defaultOnPaymentSchedulePopup: defaultOnPaymentSchedulePopup,
      approval: approval,
      exemptFromCollectionAgency: exemptFromCollectionAgency,
      exemptFromInterest: exemptFromInterest,
      exemptFromPenalty: exemptFromPenalty
    };

    setAmountObj({
      tempOriginalARAmount: advancePaymentDetails.originalAmount,
      tempBalanceAmount: advancePaymentDetails.balanceAmount
    });
    setSelectedRecoupmentStartDate(
      advancePaymentDetails && advancePaymentDetails.recoupmentStartDate
        ? advancePaymentDetails.recoupmentStartDate
        : null
    );
    setValues(
      setInputValues(
        advancePaymentDetails,
        {
          ...values,
          paymentScheduleType: advancePaymentDetails.paymentScheduleType
            ? advancePaymentDetails.paymentScheduleType
            : optSelectOne,
          recoupmentStartDate: advancePaymentDetails.recoupmentStartDate
            ? advancePaymentDetails.recoupmentStartDate
            : null,
          numberofInstallments: advancePaymentDetails.numberofInstallments
            ? advancePaymentDetails.numberofInstallments
            : '',
          interestCalculation: advancePaymentDetails.interestCalculation
            ? advancePaymentDetails.interestCalculation
            : 'N',
          interestPercentage: advancePaymentDetails.interestPercentage
            ? numeral(advancePaymentDetails.interestPercentage).format('0,0.00')
            : '',
          paymentScheduleFrequency: advancePaymentDetails.paymentScheduleFrequency
            ? advancePaymentDetails.paymentScheduleFrequency
            : optSelectOne
        },
        recoupmentsection
      )
    );
  };

  useEffect(() => {
    advancePaymentDetails.establishedDateStr = getDateFromFcn(advancePaymentDetails.receivableFCN);
    if (advancePaymentDetails) {
      setInput();
      setAdvancePayment(JSON.parse(JSON.stringify(advancePaymentDetails)));

      const scheduleDataInitial = advancePaymentDetails.schedulePaymentList.map(
        (option) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );
      setPaymentSchedleData(scheduleDataInitial);
      setHistoryData(
        advancePaymentDetails ? advancePaymentDetails.historyList : []
      );
      setSelectedManualCheckDate(
        advancePaymentDetails &&
          advancePaymentDetails.advancePaymentReceivableVO
          ? advancePaymentDetails.advancePaymentReceivableVO.manualCheckDateStr
          : new Date(
            new Date().toLocaleString('en-US', {
              timeZone: process.env.REACT_APP_TIMEZONE
            })
          )
      );
      if (advancePaymentDetails.activityTypeCode === 'WO') {
        setWriteOff(false);
      }
      if (advancePaymentDetails.accountReceivableReasonCode === '107' || advancePaymentDetails.accountReceivableReasonCode === '108') {
        systemParamValuetoYFlag = true;
        systemParamValuetoFundCodeFlag = false;
        searchSystemParamForFundCode(systemParamValuetoY);
      }
    }
  }, [advancePaymentDetails]);

  useEffect(() => {
    const agingDaysTemp =
      advancePaymentDetails && advancePaymentDetails.agingCode
        ? advancePaymentDetails.agingCode
        : null;
    if (arAgingDays) {
      const agingCodeData = arAgingDays.filter(agingCode => {
        return agingCode.code === agingDaysTemp;
      });
      values.arLettersent =
        agingCodeData && agingCodeData.length > 0
          ? agingCodeData[0].description
          : null;
    }
  }, [arAgingDays, advancePaymentDetails]);

  const searchSystemParameterPercentage = value => {
    dispatch(searchSystemParameterAction(value));
  };

  const systemParamValuetoPercentage = {
    parameterNumber: '9005',
    paramNumber: null,
    functionalArea: 'F1',
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };

  useEffect(() => {
    if (values.recoupmentTypeCode === 'N') {
      const percentValue = '0';
      const installmentValue = values.installmentValue;
      const recoupmenttypeCode =
        values.recoupmentTypeCode === optSelectOne
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.recoupmentTypeCode
            : values.recoupmentTypeCode
          : values.recoupmentTypeCode;
      const frequency =
        values.frequency === optSelectOne
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.frequency
            : values.frequency
          : optSelectOne;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const defaultOnPaymentSchedulePopup = 'N';
      const approval = advancePaymentDetails
        ? advancePaymentDetails.approvalIndicator === '1'
          ? 'N'
          : 'Y'
        : values.approval;
      const exemptFromCollectionAgency =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromCollectionAgency ===
            true
            ? 'Y'
            : 'N'
          : values.exemptFromCollectionAgency;
      const exemptFromInterest =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromInterest === true
            ? 'Y'
            : 'N'
          : values.exemptFromInterest;
      const exemptFromPenalty =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromPenalty === true
            ? 'Y'
            : 'N'
          : values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format('0,0.00')
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        defaultOnPaymentSchedulePopup: defaultOnPaymentSchedulePopup,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setPaymentSchedleData([]);
      setValues(setInputValues(advancePayment, {
        ...values,
        paymentScheduleType: optSelectOne,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      }, recoupmentsection));
      setSelectedRecoupmentDate(null);
    }
    if (values.recoupmentTypeCode === 'S' && !defaultRecoupment) {
      const percentValue = '';
      const installmentValue = values.installmentValue;
      const recoupmenttypeCode =
        values.recoupmentTypeCode === optSelectOne
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.recoupmentTypeCode
            : values.recoupmentTypeCode
          : values.recoupmentTypeCode;
      const frequency = optSelectOne;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const defaultOnPaymentSchedulePopup = 'N';
      const approval = values.approval;
      const exemptFromCollectionAgency = values.exemptFromCollectionAgency;
      const exemptFromInterest = values.exemptFromInterest;
      const exemptFromPenalty = values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format('0,0.00')
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        defaultOnPaymentSchedulePopup: defaultOnPaymentSchedulePopup,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(advancePayment, values, recoupmentsection));
      setSelectedRecoupmentDate(null);
    }
    if (values.recoupmentTypeCode === 'A') {
      const percentValue = '';
      const installmentValue =
        values.installmentValue === ''
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.installmentAmount
            : '0'
          : values.installmentValue;
      const recoupmenttypeCode =
        values.recoupmentTypeCode === optSelectOne
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.recoupmentTypeCode
            : 'A'
          : values.recoupmentTypeCode;
      const frequency =
        values.frequency === optSelectOne
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.frequency
            : '1'
          : values.frequency;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const defaultOnPaymentSchedulePopup = 'N';
      const approval = advancePaymentDetails
        ? advancePaymentDetails.approvalIndicator === '1'
          ? 'N'
          : 'Y'
        : values.approval;
      const exemptFromCollectionAgency =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromCollectionAgency ===
            true
            ? 'Y'
            : 'N'
          : values.exemptFromCollectionAgency;
      const exemptFromInterest =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromInterest === true
            ? 'Y'
            : 'N'
          : values.exemptFromInterest;
      const exemptFromPenalty =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromPenalty === true
            ? 'Y'
            : 'N'
          : values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format('0,0.00')
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        defaultOnPaymentSchedulePopup: defaultOnPaymentSchedulePopup,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(advancePayment, {
        ...values,
        paymentScheduleType: optSelectOne,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      }, recoupmentsection));
      setPaymentSchedleData([]);
      setSelectedRecoupmentDate(
        selectedRecoupmentDate === null
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.startDateStr
            : new Date(
              new Date().toLocaleString('en-US', {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          : selectedRecoupmentDate
      );
    }
    if (values.recoupmentTypeCode === 'B') {
      const percentValue =
        values.percentValue === ''
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.percentageOriginalAmount
            : '100'
          : values.percentValue;
      const installmentValue = values.installmentValue;
      const recoupmenttypeCode =
        values.recoupmentTypeCode === optSelectOne
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.recoupmentTypeCode
            : 'B'
          : values.recoupmentTypeCode;
      const frequency =
        values.frequency === optSelectOne
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.frequency
            : '1'
          : values.frequency;
      const amount = '';
      const defaultOnPaymentSchedule = 'N';
      const defaultOnPaymentSchedulePopup = 'N';
      const approval = advancePaymentDetails
        ? advancePaymentDetails.approvalIndicator === '1'
          ? 'N'
          : 'Y'
        : values.approval;
      const exemptFromCollectionAgency =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromCollectionAgency ===
            true
            ? 'Y'
            : 'N'
          : values.exemptFromCollectionAgency;
      const exemptFromInterest =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromInterest === true
            ? 'Y'
            : 'N'
          : values.exemptFromInterest;
      const exemptFromPenalty =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromPenalty === true
            ? 'Y'
            : 'N'
          : values.exemptFromPenalty;
      const recoupmentsection = {
        percent: percentValue,
        installment: installmentValue
          ? numeral(installmentValue).format('0,0.00')
          : installmentValue,
        recoupment: recoupmenttypeCode,
        frequency: frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        defaultOnPaymentSchedulePopup: defaultOnPaymentSchedulePopup,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(advancePayment, {
        ...values,
        paymentScheduleType: optSelectOne,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne
      }, recoupmentsection));
      setPaymentSchedleData([]);
      setSelectedRecoupmentDate(
        selectedRecoupmentDate === null
          ? advancePaymentDetails && advancePaymentDetails.recoupmentVO
            ? advancePaymentDetails.recoupmentVO.startDateStr
            : new Date(
              new Date().toLocaleString('en-US', {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          : selectedRecoupmentDate
      );
    }
    if (values.recoupmentTypeCode === 'D') {
      setPaymentSchedleData([]);
      if (recoupmentTypeClicked) {
        onGetRecoupmetDetails(claimsData);
      }
      setValues({
        ...values,
        recoupmentStartDate: '',
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: optSelectOne,
        paymentScheduleType: optSelectOne
      }
      );
      searchSystemParameterPercentage(systemParamValuetoPercentage);
    }
  }, [values.recoupmentTypeCode]);

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (open) {
      document.getElementById('form_pop_up').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [open]);

  const handleClickOpenPaymentSchedule = () => {
    const errorArray = [];
    if (values.paymentScheduleType === optSelectOne) {
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (!selectedRecoupmentStartDate) {
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      if (!moment(startDate).isValid()) {
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format(addDate))
      ) {
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format(addDate))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (errorArray.length === 0) {
      setSystemSuccesMessages([]);
      setSelectedDueDate(null);
      setOpen(true);
      setAdd(true);
      setShowError(false);
      setShowErrorText('');
      setPaymentScheduleVO({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainPaymentSchedule({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainSelectedDueDate(selectedDueDate);
      setRecoupmentStartDateError('');
      setPaymentScheduleTypeError('');
      seterrorMessages([]);
    } else {
      seterrorMessages(errorArray);
    }
  };

  const handleClose = () => {
    seterrorMessages([]);
    setAuditLogRow(null);
    setOpen(false);
    setShowError({
      showDueDateError: false,
      showAmountDueError: false,
      showDueDateGreaterError: false,
      showMinDueDateError: false,
      showMinDefaultDateError: false,
      showDueDateInvalidError: false,
      showDefaultDateError: false,
      showNegativeAmountError: false,
      showGreaterThanZeroAmountError: false,
      showOriginalArAmountPopError: false
    });
    setPaymentScheduleVO({
      installmentAmount: '',
      recoupedAmount: '0.00'
    });
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
    if (newValue === 1) {
      setTabTwo(true);
    }
    if (newValue === 2) {
      setTabThree(true);
    }
    if (newValue === 3) {
      setTabFour(true);
    }
    // const colorCondition = checkFormValidations();
    // if (newValue > 0) {
    //   if (colorCondition) {
    //     if (errorMessagesArray.length > 0) {
    //       setColor('red');
    //     } else {
    //       setColor('green');
    //     }
    //   }
    // }
  };

  const savePaymentSchedule = () => {
    var selectedDueDateError;
    var amountDueError = false;
    var selectedDueDateGreaterErrorTemp = false;
    var minDueDateError = false;
    var minDefaultDateError = false;
    var invalidDueDateError = false;
    var invalidDefaultDateError = false;
    var negativeAmountError = false;
    var amountGreaterThanZero = false;
    var originalArAmountRequired = false;
    var selectedDueDateErrorText;
    var amountDueErrorText = '';
    var selectedDueDateErrorTextTemp = '';
    var minDueDateErrorText = '';
    var minDefaultDateErrorText = '';
    var invalidDueDateErrorText = '';
    var invalidDefaultDateErrorText = '';
    var negativeAmountErrorText = '';
    var amountGreaterThanZeroText = '';
    var originalArAmountRequiredText = '';

    if (!selectedDueDate) {
      selectedDueDateError = true;
      selectedDueDateErrorText = ErrorConstants.DUE_DATE_REQUIRED;
      popUpErrorMessagesArray.push(selectedDueDateErrorText);
    }

    if (selectedDueDate) {
      if (!moment(selectedDueDate).isValid()) {
        invalidDueDateError = true;
        invalidDueDateErrorText = ErrorConstants.DUE_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDueDateErrorText);
      }
      if (!invalidDueDateError) {
        if (validateDateMinimumValue(selectedDueDate)) {
          minDueDateError = true;
          minDueDateErrorText = AppConstants.DATE_ERROR_1964;
          popUpErrorMessagesArray.push(minDueDateErrorText);
        }
      }
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_ERROR;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(selectedRecoupmentStartDate)
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_RECOUP;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (paymentScheduleVO.installmentAmount) {
      if (
        values.originalARAmount === '' ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        popUpErrorMessagesArray.push(originalArAmountRequiredText);
      } else {
        if (
          paymentScheduleVO.installmentAmount
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) == null
        ) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.DATA_INVALID;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        } else {
          if (

            paymentScheduleVO.installmentAmount
              .toString()
              .replace(/,/g, '')
              .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) !== null

          ) {
            negativeAmountError = true;
            negativeAmountErrorText = ErrorConstants.DATA_INVALID;
            popUpErrorMessagesArray.push(negativeAmountErrorText);
          }
        }
        if (+paymentScheduleVO.installmentAmount < 0) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.NEGATIVE_AMOUNT;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        }
        if (+paymentScheduleVO.installmentAmount === 0) {
          amountGreaterThanZero = true;
          amountGreaterThanZeroText = ErrorConstants.GREATER_THAN_ZERO_AMOUNT;
          popUpErrorMessagesArray.push(amountGreaterThanZeroText);
        }
        if (
          paymentScheduleVO.installmentAmount &&
          values.adjustedARAmount &&
          parseFloat(paymentScheduleVO.installmentAmount.replace(/,/g, '')) >
            parseFloat(
              values.adjustedARAmount
                .toString()
                .replace(/,/g, '')
                .replace('$', '')
                .trim()
            )
        ) {
          amountDueError = true;
          amountDueErrorText =
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION;
          popUpErrorMessagesArray.push(
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION
          );
        }
      }
    } else {
      amountDueError = true;
      amountDueErrorText = ErrorConstants.AMOUNT_DUE;
      popUpErrorMessagesArray.push(amountDueErrorText);
    }

    seterrorMessages(popUpErrorMessagesArray);
    setShowError({
      showDueDateError: selectedDueDateError,
      showAmountDueError: amountDueError,
      showDueDateGreaterError: selectedDueDateGreaterErrorTemp,
      showMinDueDateError: minDueDateError,
      showMinDefaultDateError: minDefaultDateError,
      showDueDateInvalidError: invalidDueDateError,
      showDefaultDateError: invalidDefaultDateError,
      showNegativeAmountError: negativeAmountError,
      showGreaterThanZeroAmountError: amountGreaterThanZero,
      showOriginalArAmountPopError: originalArAmountRequired
    });
    setShowErrorText({
      showDueDateErrorText: selectedDueDateErrorText,
      showAmountDueErrorText: amountDueErrorText,
      showDueDateGreaterErrorText: selectedDueDateErrorTextTemp,
      showMinDueDateErrorText: minDueDateErrorText,
      showMinDefaultDateErrorText: minDefaultDateErrorText,
      showDueDateInvalidErrorText: invalidDueDateErrorText,
      showDefaultDUeDateErrorText: invalidDefaultDateErrorText,
      showNegativeAmmountErrorText: negativeAmountErrorText,
      showGreaterThanZeroAmountText: amountGreaterThanZeroText,
      showOriginalArAmountPopErrorText: originalArAmountRequiredText
    });
    if (add) {
      const scheduleData = paymentScheduleData;

      if (popUpErrorMessagesArray.length === 0) {
        var value = {
          id: generateUUID(),
          overlapId: generateUUID(),
          scheduleSK: null,
          dueDate: selectedDueDate
            ? moment(new Date(selectedDueDate).getTime()).format(addDate)
            : null,
          installmentAmount: paymentScheduleVO.installmentAmount,
          recoupedAmount: paymentScheduleVO.recoupedAmount
        };
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (option.dueDate === value.dueDate) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            errorMessagesArray = errorMessageCopy;
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            scheduleData.push(value);
            setPaymentSchedleData(scheduleData);
            handleClose();
          }
        } else {
          scheduleData.push(value);
          setPaymentSchedleData(scheduleData);
          handleClose();
        }
      }
    } else {
      const scheduleData = paymentScheduleData;
      if (popUpErrorMessagesArray.length === 0) {
        if (scheduleData.length > 0) {
          let dateFlag = false;
          scheduleData.map((option) => {
            if (
              option.dueDate ===
                moment(new Date(selectedDueDate)).format('L') &&
              option.overlapId !== paymentScheduleVO.overlapId
            ) {
              dateFlag = true;
            }
          });
          if (dateFlag) {
            errorMessagesArray = errorMessageCopy;
            const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
            setShowError({
              showDueDateError: true
            });
            setShowErrorText({
              showDueDateErrorText: duplicateMessage
            });
            window.scrollTo(0, 0);
          } else {
            paymentScheduleData.map(value => {
              if (paymentScheduleVO.id && value.id === paymentScheduleVO.id) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    addDate
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }
              if (
                paymentScheduleVO.scheduleSK &&
                value.scheduleSK === paymentScheduleVO.scheduleSK
              ) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    addDate
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }
              setPaymentSchedleData(paymentScheduleData);
              setOpen(false);
              setAdd(true);
            });
            handleClose();
          }
        }
      }
    }
  };

  const resetPaymentSchedule = () => {
    seterrorMessages([]);
    setShowError([]);
    setShowErrorText([]);
    setPaymentScheduleVO(retainPaymentSchedule);
    setSelectedDueDate(retainSelectedDueDate);
  };

  function PaymentRowDeleteClick () {
    setAllowNavigation(true);
    let temNewDialogData = [...paymentScheduleData];
    for (
      let i = 0;
      i < rowPaymentScheduleData.rowPaymentScheduleData.length;
      i++
    ) {
      if (rowPaymentScheduleData.rowPaymentScheduleData[i] !== undefined) {
        temNewDialogData = temNewDialogData.filter(
          payment =>
            payment.id !== rowPaymentScheduleData.rowPaymentScheduleData[i]
        );
      }
    }
    setPaymentSchedleData(temNewDialogData);
    setRowPaymentScheduleData([]);
  }

  const handleChange = name => event => {
    setFocusCheck(true);
    setSystemSuccesMessages([]);
    setAllowNavigation(true);
    if (name === 'defaultOnPaymentSchedule' && event.target.value === 'Y') {
      setDefaultRecoupment(true);
    }

    if (
      name === 'paymentScheduleType' &&
      values.paymentScheduleType !== event.target.value
    ) {
      setPaymentScheduleChanged(generateUUID());
      setPaymentScheduleTypeError('');
      setRecoupmentStartDateError('');
      setFrequencyError('');
      setNumberofinstallmentError('');
      setInstallmentCalError('');
      if (event.target.value === 'M') {
        setScheduleTable(true);
      } else {
        setScheduleTable(false);
      }
    }
    if (
      name === 'numberofInstallments' ||
      name === 'paymentScheduleFrequency'
    ) {
      setPaymentSettingChanged(generateUUID());
    }
    if (name === 'recoupmentTypeCode') {
      setShowErrorText('');
      setRecoupmentTypeClicked(true);
    }
    if (name === 'interestCalculation') {
      setValues({
        ...values,
        [name]: event.target.value,
        interestPercentage: ''
      });
    }
    if (name === 'amount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    } else if (name === 'installmentValue') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };
  const saveAdvancePayment = () => {
    setSystemSuccesMessages([]);
    let estDate = advancePayment ? advancePayment.establishedDate : null;
    estDate = new Date(estDate);
    let date = estDate.getDate();
    let month = estDate.getMonth() + 1;
    if (date < 10) {
      date = '0' + date;
    }
    if (month < 10) {
      month = '0' + month;
    }

    const paymentScheduleTemp = paymentScheduleData;
    paymentScheduleTemp.map(value => {
      if (paymentScheduleChanged || paymentSettingChanged) {
        value.auditUserID = logInUserID;
        value.auditTimeStamp = getUTCTimeStamp();
        value.addedAuditUserID = logInUserID;
        value.addedAuditTimeStamp = getUTCTimeStamp();
      } else {
        if (value.edited && value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
        }
        if (!value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
          value.addedAuditUserID = logInUserID;
          value.addedAuditTimeStamp = getUTCTimeStamp();
        }
      }
      value.installmentAmount = numeral(value.installmentAmount).format('0.00');
      value.recoupedAmount = numeral(value.recoupedAmount).format('0.00');
    });
    checkFormValidations();
    const value = {
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: advancePayment.addedAuditUserID
        ? advancePayment.addedAuditUserID
        : logInUserID,
      addedAuditTimeStamp: advancePayment.addedAuditTimeStamp
        ? advancePayment.addedAuditTimeStamp
        : getUTCTimeStamp(),
      versionNo: advancePayment ? advancePayment.versionNo : 0,
      atrNumber: values?.atrNumber?.toUpperCase(),
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      versionNoForFinancialDetail: advancePayment
        ? advancePayment.versionNoForFinancialDetail
        : 0,
      versionNoForFinancialCrossReference: 0,
      versionNoForCommonEntity: 0,
      versionNoForWLCommonEntity: 0,
      payoutFCN: null,
      noteSet: null,
      adjustedArAmt: advancePayment ? advancePayment.adjustedArAmt : 0.0,
      receivableFCN: advancePayment ? advancePayment.receivableFCN : '',
      viewLienPayouts: false,
      fcnSequenceNo: advancePayment ? advancePayment.fcnSequenceNo : '',
      lob: AppConstants.DEFAULT_LOB_VALUE,
      payeePayerTypeCode: advancePayment
        ? advancePayment.payeePayerTypeCode
        : '',
      payeePayerTypeCodeDesc: null,
      payeePayerIDTypeCode: advancePayment
        ? advancePayment.payeePayerIDTypeCode
        : '',
      payeePayerIDTypeCodeDesc: null,
      establishedDate: advancePayment ? advancePayment.establishedDate : null,
      lastAppliedDate: advancePayment ? advancePayment.lastAppliedDate : null,
      calculatedPenalty: advancePayment
        ? advancePayment.calculatedPenalty
        : null,
      calculatedInterest: advancePayment
        ? advancePayment.calculatedInterest
        : null,
      calculatedFees: advancePayment ? advancePayment.calculatedFees : null,
      memberName: advancePayment ? advancePayment.memberName : null,
      confirmDailog: false,
      pageLoadIndicator: true,
      memberIdDisable: false,
      memberID: null,
      memberIDTypeCodeDisable: false,
      memberIDTypeCode: null,
      activityTypeCode: advancePayment ? advancePayment.activityTypeCode : '',
      activityTypeCodeDesc: null,
      originalAmount: values.originalARAmount,
      originalAmountDisable: false,
      agingCode: advancePayment ? advancePayment.agingCode : '',
      agingCodeDesc: null,
      agingCodeDisable: false,
      arAgeInDays: advancePayment ? advancePayment.arAgeInDays : '',
      balanceAmount: advancePayment ? advancePayment.balanceAmount : '',
      relatedFTTCN: advancePayment ? advancePayment.relatedFTTCN : '',
      payeePayerID: advancePayment ? advancePayment.payeePayerID : '',
      paymentSchedule: null,
      exemptions: null,
      recoupment: null,
      accountVO: {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: advancePayment.accountVO.addedAuditUserID
          ? advancePayment.accountVO.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: advancePayment.accountVO.addedAuditTimeStamp
          ? advancePayment.accountVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        scoaFiscalYearDisable: false,
        scoaFiscalYear: values.fiscalYear,
        fundCodeDisable: false,
        fundCode:
          values.collocationCode === optSelectOne
            ? null
            : values.collocationCode,
        cosDisable: false,
        cos:
          values.categoryOfService === optSelectOne
            ? null
            : values.categoryOfService,
        cosValue: null,
        fundCodeValue: null
      },
      advancePaymentReceivableVO: {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: advancePayment.advancePaymentReceivableVO
          .addedAuditUserID
          ? advancePayment.advancePaymentReceivableVO.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: advancePayment.advancePaymentReceivableVO
          .addedAuditTimeStamp
          ? advancePayment.advancePaymentReceivableVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        versionNoForFinancialDetail: 0,
        versionNoForFinancialCrossReference: 0,
        versionNoForCommonEntity: 0,
        versionNoForWLCommonEntity: 0,
        payoutFCN: null,
        noteSet: null,
        adjustedArAmt: '0.00',
        receivableFCN: null,
        viewLienPayouts: false,
        fcnSequenceNo: null,
        lob: null,
        payeePayerTypeCode: null,
        payeePayerTypeCodeDesc: null,
        payeePayerIDTypeCode: null,
        payeePayerIDTypeCodeDesc: null,
        establishedDate: null,
        lastAppliedDate: null,
        calculatedPenalty: '0.00',
        calculatedInterest: '0.00',
        calculatedFees: '0.00',
        memberName: null,
        confirmDailog: false,
        pageLoadIndicator: true,
        memberIdDisable: false,
        memberID: null,
        memberIDTypeCodeDisable: false,
        memberIDTypeCode: null,
        activityTypeCode: null,
        activityTypeCodeDesc: null,
        originalAmount: null,
        originalAmountDisable: false,
        agingCode: null,
        agingCodeDesc: null,
        agingCodeDisable: false,
        arAgeInDays: null,
        balanceAmount: '0.00',
        relatedFTTCN: null,
        payeePayerID: null,
        paymentSchedule: null,
        exemptions: null,
        recoupment: null,
        accountVO: null,
        advancePaymentReceivableVO: null,
        grossLevelReceivableVO: null,
        fundSourceVO: null,
        accountReceivableReasonCode: null,
        accountReceivableReasonCodeDesc: null,
        payeePayerIdFromDB: null,
        payeePayerNameFromDB: null,
        sysID: null,
        withHoldingStatus: null,
        exemptionsVO: null,
        adjustmentVO: null,
        transferVO: null,
        recoupmentVO: null,
        approvalIndicator: null,
        approvalIndicatorDisable: false,
        schedulePaymentList: paymentScheduleTemp,
        paymentInd: paymentInd,
        schedulePaymentMap: {},
        schedulePaymentMapFromDB: {},
        paymentScheduleVO: {
          auditUserID: null,
          auditTimeStamp: null,
          addedAuditUserID: null,
          addedAuditTimeStamp: null,
          versionNo: 0,
          dbRecord: false,
          sortColumn: null,
          auditKeyList: [],
          auditKeyListFiltered: false,
          dueDate: null,
          dueAmount: null,
          defaultedDate: null,
          defaultedIndicator: '0',
          defaultedIndicatorImage: false,
          renderAddPaymentHeader: false,
          renderEditPaymentHeader: false,
          paymentScheduleRowIndex: null,
          renderDeletePaymentSection: false,
          paymentScheduleKey: null,
          defaultDateStr: null,
          dueDateStr: null,
          renderPaymentSection: false,
          enableOnSchedulePayment: false,
          disableEditPaymentSchedule: false
        },
        reversalVO: null,
        withholdingVO: null,
        historyList: [],
        historyMap: {},
        historyVO: null,
        reestablishReceivableVO: null,
        disableManualCheckEntry: false,
        feeAssdAmount: null,
        writeOffReasonCode: null,
        auditForUpdate: false,
        update: false,
        originatingClaimsList: [],
        defaultEntityTypeDisable: false,
        memberSysID: '0',
        syspayeeID: null,
        voidIndicator: null,
        fcnMediaCode: null,
        agingInDays: null,
        originalFcn: null,
        establishedDateStr: null,
        lastAppliedDateStr: null,
        adjustedARAmountDisable: false,
        balanceAmountTemp: '0.00',
        claimsReceivableDetailsVO: null,
        manualCheckNumberDisable: false,
        manualCheckNumber: values.manualCheckNumber,
        manualCheckDate: new Date(manualCheckDate),
        manualCheckDateStrDisable: false,
        manualCheckDateStr: manualCheckDate
          ? moment(new Date(manualCheckDate).getTime()).format(addDate)
          : null,
        approvalReqIndicator: false
      },
      grossLevelReceivableVO:
        advancePayment && advancePayment.grossLevelReceivableVO
          ? advancePayment.grossLevelReceivableVO
          : null,
      fundSourceVO:
        advancePayment && advancePayment.fundSourceVO
          ? advancePayment.fundSourceVO
          : null,
      accountReceivableReasonCode: advancePayment
        ? advancePayment.accountReceivableReasonCode
        : '',
      accountReceivableReasonCodeDesc: null,
      payeePayerIdFromDB: advancePayment
        ? advancePayment.payeePayerIdFromDB
        : '',
      payeePayerNameFromDB: advancePayment
        ? advancePayment.payeePayerNameFromDB
        : '',
      sysID: advancePayment ? advancePayment.sysID : '',
      withHoldingStatus: null,
      exemptionsVO: {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: advancePayment.exemptionsVO.addedAuditUserID
          ? advancePayment.exemptionsVO.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: advancePayment.exemptionsVO.addedAuditTimeStamp
          ? advancePayment.exemptionsVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        exemptFromPenalty: true,
        exemptFromCollectionAgency: true,
        exemptFromInterest: true,
        penaltyExemptionsIndDisable: false,
        interestExemptionsIndDisable: false,
        collectionAgencyIndDisable: false,
        penaltyExemptionsInd: values.exemptFromPenalty === 'Y' ? '1' : '0',
        interestExemptionsInd: values.exemptFromInterest === 'Y' ? '1' : '0',
        collectionAgencyInd:
          values.exemptFromCollectionAgency === 'Y' ? '1' : '0'
      },
      adjustmentVO: null,
      transferVO:
        advancePayment && advancePayment.transferVO
          ? advancePayment.transferVO
          : null,
      recoupmentVO: {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: advancePayment.recoupmentVO.addedAuditUserID
          ? advancePayment.recoupmentVO.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: advancePayment.recoupmentVO.addedAuditTimeStamp
          ? advancePayment.recoupmentVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        releaseDate: null,
        returnDate: null,
        recoupmentTypeCodeDisable: false,
        recoupmentTypeCode:
          values.recoupmentTypeCode === optSelectOne
            ? null
            : values.recoupmentTypeCode,
        installmentAmount: values.installmentValue,
        installmentAmountDisable: false,
        percentageOriginalAmount: values.percentValue,
        percentageAmountDisable: false,
        startDate: selectedRecoupmentDate
          ? new Date(selectedRecoupmentDate)
          : null,
        frequency:
          values.frequency === optSelectOne ? null : values.frequency,
        frequencyDisable: false,
        renderedForWithHolding: false,
        startDateStr: selectedRecoupmentDate
          ? moment(new Date(selectedRecoupmentDate).getTime()).format(
            addDate
          )
          : null,
        startDateStrDisable: false,
        releaseDateStrDisable: false,
        returnDateStrDisable: false,
        releaseDateStr: null,
        returnDateStr: null,
        defaultRecoupmentDisable: false
      },
      approvalIndicator: values.approval === 'Y' ? '1' : '0',
      approvalIndicatorDisable: false,
      schedulePaymentList: paymentScheduleData,
      schedulePaymentMap: {},
      schedulePaymentMapFromDB: {},
      paymentScheduleVO:
        advancePayment && advancePayment.paymentScheduleVO
          ? advancePayment.paymentScheduleVO
          : null,
      reversalVO:
        advancePayment && advancePayment.reversalVO
          ? advancePayment.reversalVO
          : null,
      withholdingVO:
        advancePayment && advancePayment.withholdingVO
          ? advancePayment.withholdingVO
          : null,
      historyList:
        advancePayment && advancePayment.historyList
          ? advancePayment.historyList
          : null,
      historyMap:
        advancePayment && advancePayment.historyMap
          ? advancePayment.historyMap
          : null,
      historyVO:
        advancePayment && advancePayment.historyVO
          ? advancePayment.historyVO
          : null,
      reestablishReceivableVO:
        advancePayment && advancePayment.reestablishReceivableVO
          ? advancePayment.reestablishReceivableVO
          : null,
      disableManualCheckEntry: false,
      feeAssdAmount: null,
      writeOffReasonCode: null,
      auditForUpdate: false,
      update: false,
      originatingClaimsList: [],
      defaultEntityTypeDisable: false,
      memberSysID: '0',
      syspayeeID: null,
      voidIndicator: false,
      fcnMediaCode: '0',
      agingInDays: null,
      originalFcn: null,
      establishedDateStr: advancePayment
        ? advancePayment.establishedDate
        : null,
      lastAppliedDateStr: advancePayment
        ? advancePayment.lastAppliedDate
        : null,
      adjustedARAmountDisable: false,
      balanceAmountTemp: '0.00',
      claimsReceivableDetailsVO:
        advancePayment && advancePayment.claimsReceivableDetailsVO
          ? advancePayment.claimsReceivableDetailsVO
          : null,
      approvalReqIndicator: false,
      noteSetVO: notesInput,
      paymentScheduleType:
        values.paymentScheduleType === optSelectOne
          ? null
          : values.paymentScheduleType,
      recoupmentStartDate: selectedRecoupmentStartDate
        ? moment(selectedRecoupmentStartDate).format(addDate)
        : null,
      numberofInstallments: values.numberofInstallments
        ? values.numberofInstallments
        : null,
      interestCalculation: values.interestCalculation,
      interestPercentage:
        values.interestPercentage !== ''
          ? numeral(values.interestPercentage).format('0.00')
          : null,
      paymentScheduleFrequency:
        values.paymentScheduleFrequency !== optSelectOne
          ? values.paymentScheduleFrequency
          : null,
      payeeStatus: values.payeeStatus !== optSelectOne
        ? values.payeeStatus
        : null

    };
    if (values.recoupmentTypeCode === 'D') {
      value.recoupmentVO.startDateStr = advancePayment.establishedDate;
    }
    const index = attachmentsTableData.length - 1;
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map(value => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(
              new Date(
                new Date().toLocaleString('en-US', {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description: value ? value.description : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName: attachmentsTableData && value ? value.fileName : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    value.attachmentVO = null;
    value.attachmentsList = attachmentVOList;
    if (values.recoupmentTypeCode === 'S') {
      const errorArray = [];
      var originalArAmountRequired = false;
      var originalArAmountRequiredText = '';
      let paymentScheduleTypeText = '';
      let recoupmentStartDateText = '';
      let noOfInstallmentText = '';
      let frequencyText = '';
      let calPercentText = '';
      if (values.paymentScheduleType === optSelectOne) {
        paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
        errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
        setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      }
      if (
        values.originalARAmount === '' ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        errorArray.push(originalArAmountRequiredText);
      }
      if (!selectedRecoupmentStartDate) {
        recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
        setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
        errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
      }

      if (selectedRecoupmentStartDate && !manualEditFlag) {
        var startDate = new Date(selectedRecoupmentStartDate);
        estDate = new Date(values.establishedDate);

        if (!moment(startDate).isValid()) {
          recoupmentStartDateText = ErrorConstants.DATA_INVALID;
          setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
          errorArray.push(ErrorConstants.DATA_INVALID);
        } else if (
          validateDateMinimumValue(moment(startDate).format(addDate))
        ) {
          recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
          setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
          errorArray.push(minRecoupmentDateTextTemp);
        }
        if (
          moment(startDate).isValid() &&
          !validateDateMinimumValue(moment(startDate).format(addDate))
        ) {
          if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
            recoupmentStartDateText =
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
            setRecoupmentStartDateError(
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
            );
            errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
          }
        }
      }
      if (
        values.paymentScheduleFrequency === optSelectOne &&
        values.paymentScheduleType === 'A'
      ) {
        frequencyText = ErrorConstants.FREQUENCY;
        setFrequencyError(ErrorConstants.FREQUENCY);
        errorArray.push(ErrorConstants.FREQUENCY);
      }
      if (
        values.numberofInstallments === '' &&
        values.paymentScheduleType === 'A'
      ) {
        noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
        setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
        errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      }
      if (
        values.numberofInstallments !== '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
          noOfInstallmentText =
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.';
          setNumberofinstallmentError(
            ErrorConstants.INSTALLMENT_MORE +
              frequencyResult.valueNum.toString() +
              '.'
          );
          errorArray.push(
            ErrorConstants.INSTALLMENT_MORE +
              frequencyResult.valueNum.toString() +
              '.'
          );
        }
      }
      if (
        values.interestCalculation === 'Y' &&
        values.interestPercentage === '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (!errorMessagesArray.includes(ErrorConstants.INTEREST_PERCENT)) {
          calPercentText = ErrorConstants.INTEREST_PERCENT;
          setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
          errorArray.push(ErrorConstants.INTEREST_PERCENT);
        }
      }
      if (
        values.interestPercentage &&
        values.interestPercentage !== '' &&
        values.interestPercentage
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) == null &&
        values.paymentScheduleType === 'A'
      ) {
        calPercentText = ErrorConstants.DATA_INVALID;
        setInstallmentCalError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      }
      if (errorArray.length === 0) {
        setPaymentScheduleTypeError('');
        setRecoupmentStartDateError('');
        setFrequencyError('');
        setNumberofinstallmentError('');
        setInstallmentCalError('');
      } else {
        setPaymentScheduleTypeError(paymentScheduleTypeText);
        setRecoupmentStartDateError(recoupmentStartDateText);
        setFrequencyError(frequencyText);
        setNumberofinstallmentError(noOfInstallmentText);
        setInstallmentCalError(calPercentText);
        if (errorArray.length !== 0) {
          errorArray.map(value => {
            errorMessagesArray.push(value);
          });
        }
      }
    }

    if (errorMessagesArray.length === 0) {
      if (allowNavigation === false) {
        NoSaveMessage();
      } else {
        onAdvancePaymetAction(value, index);
      }
    }
  };

  const editRow = data => () => {
    seterrorMessages([]);
    setAuditLogRow(data);
    setAdd(false);
    setRetainEdit(data);
    setOpen(true);
    setPaymentScheduleVO({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainPaymentSchedule({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainSelectedDueDate(data.dueDate);
    setSelectedDueDate(data.dueDate);
    setShowError(false);
    setShowErrorText(false);
  };

  useEffect(() => {
    if (
      getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && !recoupmentTypeClicked
    ) {
      const amount = values.dueAmount ? values.dueAmount : retainEdit.amountDue;
      const defaultOnPaymentSchedule = 'Y';
      const approval = advancePaymentDetails
        ? advancePaymentDetails.approvalIndicator === '1'
          ? 'Y'
          : 'N'
        : values.approval;

      const exemptFromCollectionAgency =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromCollectionAgency ===
            true
            ? 'Y'
            : 'N'
          : values.exemptFromCollectionAgency;
      const exemptFromInterest =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromInterest === true
            ? 'Y'
            : 'N'
          : values.exemptFromInterest;
      const exemptFromPenalty =
        advancePaymentDetails && advancePaymentDetails.exemptionsVO
          ? advancePaymentDetails.exemptionsVO.exemptFromPenalty === true
            ? 'Y'
            : 'N'
          : values.exemptFromPenalty;
      const recoupmentsection = {
        percent: getRecoupmetDetails.recoupmentVO.percentageOriginalAmount,
        installment: getRecoupmetDetails.recoupmentVO.installmentAmount
          ? numeral(getRecoupmetDetails.recoupmentVO.installmentAmount).format(
            '0,0.00'
          )
          : getRecoupmetDetails.recoupmentVO.installmentAmount,
        recoupment: getRecoupmetDetails.recoupmentVO.recoupmentTypeCode,
        frequency: getRecoupmetDetails.recoupmentVO.frequency,
        amount: amount,
        defaultOnPaymentSchedule: defaultOnPaymentSchedule,
        approval: approval,
        exemptFromCollectionAgency: exemptFromCollectionAgency,
        exemptFromInterest: exemptFromInterest,
        exemptFromPenalty: exemptFromPenalty
      };
      setValues(setInputValues(advancePayment, values, recoupmentsection));
      setSelectedRecoupmentDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format(addDate));
      seterrorMessages([]);
    } else if (getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && recoupmentTypeClicked) {
      setSelectedRecoupmentDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format(addDate));
    }
    if (getRecoupmetDetails && getRecoupmetDetails.systemFailure) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [getRecoupmetDetails]);

  const handleDefaultRecoupment = () => {
    setFocusCheck(true);
    setRecoupmentTypeClicked(false);
    setAllowNavigation(true);
    if (Number(values.balanceAmount.toString().replace(/,/g, '')) > 0) {
      onGetRecoupmetDetails(claimsData);
      setShowErrorText('');
    }
  };

  const rowDeletePaymentSchedule = data => {
    setRowPaymentScheduleData({
      ...rowPaymentScheduleData,
      rowPaymentScheduleData: data
    });
  };
  // Delete Dialog implemented
  const [openDialog, setOpenDialog] = React.useState(false);
  const confirmDelete = () => {
    PaymentRowDeleteClick();
    setOpenDialog(false);
  };

  const closeDeletePopUpDialog = () => {
    setOpenDialog(false);
  };
  // Delete Dialog implementation ends
  const NavigateToWriteOff = () => {
    checkFormValidations();
    if (errorMessagesArray.length === 0) {
      if (parseFloat(advancePaymentDetails.balanceAmount) === 0) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.WRITE_OFF_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        advancePaymentDetails.recoupmentVO.startDateStr = selectedRecoupmentDate
          ? moment(new Date(selectedRecoupmentDate).getTime()).format(
            addDate
          )
          : null;
        seterrorMessages([]);
        setSystemSuccesMessages([]);

        dispatch(getWriteOffDetailsReset());
        fetchWriteOffData(advancePaymentDetails);
      }
    }
  };

  const NavigateToWriteOffRestablish = () => {
    checkFormValidations();
    if (errorMessagesArray.length === 0) {
      if (
        advancePaymentDetails.balanceAmount === '0' ||
        advancePaymentDetails.balanceAmount === '0.0' ||
        advancePaymentDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(ErrorConstants.BALNACE_AMOUNT_ZERO_ERROR);
        seterrorMessages(systemErrorMessges);
      } else {
        advancePaymentDetails.recoupmentVO.startDateStr = selectedRecoupmentDate
          ? moment(new Date(selectedRecoupmentDate).getTime()).format(
            addDate
          )
          : null;
        seterrorMessages([]);
        setSystemSuccesMessages([]);

        dispatch(getWriteOffRestablishmentDetailsReset());
        fetchWriteOffReEstablishmentData(advancePaymentDetails);
      }
    }
  };

  const removeFormatDecimalFieldPercent = () => () => {
    if (values.interestPercentage) {
      setValues({
        ...values,
        interestPercentage: numeral(values.interestPercentage).format('0.00')
      });
    }
  };

  const removeFormatDecimalFieldInstallment = () => () => {
    if (values.installmentValue) {
      setValues({
        ...values,
        installmentValue: numeral(values.installmentValue).format('0.00')
      });
    }
  };
  const formatDecimalFieldInstallment = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0,0.00'
        )
      });
    }
  };

  const removeFormatDecimalFieldAmountDue = () => () => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0.00'
        )
      });
    }
  };
  const calculateSchedule = () => {
    const errorArray = [];
    var originalArAmountRequired = false;
    var originalArAmountRequiredText = '';
    let paymentScheduleTypeText = '';
    let recoupmentStartDateText = '';
    let noOfInstallmentText = '';
    let frequencyText = '';
    let calPercentText = '';
    setPaymentScheduleTypeError('');
    setRecoupmentStartDateError('');
    setFrequencyError('');
    setNumberofinstallmentError('');
    setInstallmentCalError('');
    if (values.paymentScheduleType === optSelectOne) {
      paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (
      values.originalARAmount === '' ||
      values.originalARAmount === undefined
    ) {
      originalArAmountRequired = true;
      originalArAmountRequiredText = ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorArray.push(originalArAmountRequiredText);
    }
    if (!selectedRecoupmentStartDate) {
      recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      if (!moment(startDate).isValid()) {
        recoupmentStartDateText = ErrorConstants.DATA_INVALID;
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format(addDate))
      ) {
        recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format(addDate))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          recoupmentStartDateText =
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (values.paymentScheduleFrequency === optSelectOne) {
      frequencyText = ErrorConstants.FREQUENCY;
      setFrequencyError(ErrorConstants.FREQUENCY);
      errorArray.push(ErrorConstants.FREQUENCY);
    }
    if (values.numberofInstallments === '') {
      noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
      setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
    }
    if (values.numberofInstallments !== '') {
      if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
        noOfInstallmentText =
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.';
        setNumberofinstallmentError(
          ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
        );
        errorArray.push(
          ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
        );
      }
    }
    if (
      values.interestCalculation === 'Y' &&
      values.interestPercentage === ''
    ) {
      calPercentText = ErrorConstants.INTEREST_PERCENT;
      setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
      errorArray.push(ErrorConstants.INTEREST_PERCENT);
    }
    if (
      values.interestPercentage &&
      values.interestPercentage !== '' &&
      values.interestPercentage
        .toString()
        .replace(/,/g, '')
        .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) == null &&
      values.paymentScheduleType === 'A'
    ) {
      calPercentText = ErrorConstants.DATA_INVALID;
      setInstallmentCalError(ErrorConstants.DATA_INVALID);
      errorArray.push(ErrorConstants.DATA_INVALID);
    }
    if (errorArray.length === 0) {
      const scheduleArray = [];
      const amountStep = numeral(
        Number(
          Number(values.balanceAmount.toString().replace(/,/g, '')) /
            Number(values.numberofInstallments)
        )
      ).format('0.00');
      const numberOfRows = Number(
        numeral(
          Number(values.balanceAmount.toString().replace(/,/g, '')) /
            Number(amountStep)
        ).format('0')
      );
      const dateTemp = new Date(selectedRecoupmentStartDate);
      let daysStep = 0;
      switch (values.paymentScheduleFrequency) {
        case '2':
          daysStep = 7;
          break;
        case '3':
          daysStep = 14;
          break;
        case '4':
          daysStep = 30;
          break;
      }
      let sumOfInstallmentAmount = 0;
      for (let i = 0; i < numberOfRows; i++) {
        let value;
        if (i === 0) {
          value = {
            scheduleSK: null,
            dueDate: moment(dateTemp.setDate(dateTemp.getDate())).format(
              addDate
            ),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        } else {
          value = {
            scheduleSK: null,
            dueDate: moment(
              dateTemp.setDate(dateTemp.getDate() + daysStep)
            ).format(addDate),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        }
        sumOfInstallmentAmount = numeral(
          Number(numeral(sumOfInstallmentAmount).format('0.00')) +
            Number(numeral(amountStep).format('0.00'))
        ).format('0.00');
        scheduleArray.push(value);
      }
      if (
        numeral(values.balanceAmount).format('0.00') !==
        numeral(sumOfInstallmentAmount).format('0.00')
      ) {
        scheduleArray[numberOfRows - 1].installmentAmount = numeral(
          parseFloat(
            numeral(scheduleArray[numberOfRows - 1].installmentAmount).format(
              '0.00'
            )
          ) +
            (parseFloat(numeral(values.balanceAmount).format('0.00')) -
              parseFloat(numeral(sumOfInstallmentAmount).format('0.00')))
        ).format('0,0.00');
      }
      setPaymentSchedleData(scheduleArray);
      setPaymentScheduleTypeError('');
      setRecoupmentStartDateError('');
      setFrequencyError('');
      setNumberofinstallmentError('');
      setInstallmentCalError('');
      seterrorMessages([]);
    } else {
      window.scrollTo(0, 0);
      setShowError({
        showOriginalArAmountPopError: originalArAmountRequired
      });
      setShowErrorText({
        showOriginalArAmountPopErrorText: originalArAmountRequiredText
      });
      setPaymentScheduleTypeError(paymentScheduleTypeText);
      setRecoupmentStartDateError(recoupmentStartDateText);
      setFrequencyError(frequencyText);
      setNumberofinstallmentError(noOfInstallmentText);
      setInstallmentCalError(calPercentText);
      seterrorMessages(errorArray);
    }
  };

  const valuesFromAREdit = props.history && props.history.location && props.history.location.state && props.history.location.state.values;
  const payloadPayees = props.history && props.history.location && props.history.location.state && props.history.location.state.payloadPayees;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      if (!valuesFromAREdit) {
        props.history.push({
          pathname: '/FinancialAccountInquiry',
          tableRender: false
        });
      } else {
        props.history.push({
          pathname: '/FinancialAccountInquiry',
          tableRender: !props.history.location?.state?.isFromAdd,
          state: { values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }, 100);
  };
  const majorCancel = () => {
    seterrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/FinancialAccountInquiry',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
  };
  useEffect(() => {
    onDropdowns([Dropdowns.PAYEE_TYPE,Dropdowns.ACTIVITY_TYPE,  Dropdowns.F_RSN_CD,Dropdowns.FUND_CODE,Dropdowns.COS, 
      Dropdowns.RECOUPMENT])
  }, []);

  // getting payeerecord to set desc
  // getting ActivityTypeRecord to set desc

  // getting reasonCodeRecord  to set desc

  return (
    <div>
      <div className="tabs-container" ref={toPrintRef}>
        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <div
          align="right"
          className="nav-menu"
          style={{ paddingRight: '5px' }}
        >
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARReverse}>&nbsp;&nbsp;Reverse</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Reverse</span>
          )}
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a onClick={ARTransfer}>&nbsp;&nbsp;Transfer</a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Transfer</span>
          )}
          {writeOff ? (
            userInquiryPrivileges ? (
              <span>
                <a onClick={NavigateToWriteOff}>&nbsp;&nbsp;Write-Off </a>{' '}
              </span>
            ) : (
              <span> &nbsp;&nbsp;Write-Off</span>
            )
          ) : userInquiryPrivileges ? (
            <span>
              <a onClick={NavigateToWriteOffRestablish}>
                &nbsp;&nbsp;Write-Off Re-establish{' '}
              </a>{' '}
            </span>
          ) : (
            <span>&nbsp;&nbsp;Write-Off Re-establish</span>
          )}
        </div>

        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Edit Advance Payment</h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveAdvancePayment()}
              disabled={!userInquiryPrivileges}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button
              color="primary"
              className={
                auditButtonToggle
                  ? 'btn btn-primary ml-1'
                  : 'btn btn-text1 btn-transparent ml-1'
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
              Audit Log
            </Button>
            {window.location.pathname === '/AdvancePayment' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                id="standard-payee-type"
                label="Payee Type"
                value={values.payeeType}
                onChange={handleChange('payeeType')}
                inputProps={{ maxlength: 3 }}
                disabled={true}
                dropdownList={addDropdowns && addDropdowns['G1#G_CMN_ENTY_TY_CD'] &&
                          addDropdowns['G1#G_CMN_ENTY_TY_CD'].length > 0
                  ? addDropdowns['G1#G_CMN_ENTY_TY_CD'].map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                  : null}
              />
              <NativeDropDown
                id="standard-payeeid-type-code"
                label="Payee ID Type Code"
                value={values.payeeIdTypeCode}
                onChange={handleChange('payeeIdTypeCode')}
                inputProps={{ maxlength: 3 }}
                disabled={true}
                dropdownList={payeeTypeIdCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="AdvancePaymentstandard-PayeeID"
                  fullWidth
                  label="Payee ID"
                  InputProps={{
                    readOnly: true,
                    className: Muidisabled
                  }}
                  value={values.payeeId}
                  onChange={handleChange('payeeId')}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form  input-md">
                <TextField
                  InputProps={{
                    readOnly: true,
                    className: Muidisabled
                  }}
                  fullWidth
                  id="AdvancePaymentstandardPayeeName"
                  label="Payee Name "
                  placeholder=""
                  inputProps={{ maxlength: 60 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeePayerName}
                ></TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form  input-md has-link">
                <TextField
                  InputProps={{
                    readOnly: true,
                    className: Muidisabled,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values)} target='_blank'>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  id="AdvancePaymentstandardSystemPayeeID"
                  label="System Payee ID"
                  placeholder=""
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <NativeDropDown
                disabled={true}
                id="AdvancePaymentstandardActivityType"
                label="Activity Type"
                value={values.activityCode}
                onChange={handleChange('activityCode')}
                inputProps={{ maxlength: 3 }}
                dropdownList={addDropdowns && addDropdowns['F1#F_ACTV_TY_CD'] &&
                          addDropdowns['F1#F_ACTV_TY_CD'].length > 0
                  ? addDropdowns['F1#F_ACTV_TY_CD'].map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                  : null}
              />
              <NativeDropDown
                disabled={true}
                id="AdvancePaymentstandardReasonCode"
                label="Reason Code"
                value={values.reasonCode}
                onChange={handleChange('reasonCode')}
                inputProps={{ maxlength: 3 }}
                title={getLongDescription(reasonCodeData, values.reasonCode)}
                dropdownList={addDropdowns && addDropdowns['F1#F_RSN_CD'] &&
                          addDropdowns['F1#F_RSN_CD'].length > 0
                  ? addDropdowns['F1#F_RSN_CD'].map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                  : null}
              />
            </div>
          </form>
          <div className="tab-panelbody m-3">
            <div className="tab-holder mb-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="Payment Detail" />
                  <Tab label="Interest and Penalty" />
                  <Tab label="Payment Schedule" />
                  <Tab label="History" style={{ marginLeft: '-7px' }} />
                  <Tab label="Notes" style={{ marginLeft: '-6px' }} />
                  {<Tab label="Attachments" />}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder my-3 p-0">
                    <GrossLevelDetailsComponent
                      addDropdowns= {addDropdowns}
                      payeePaymentStatusFrmState={payeePaymentStatusFrmState}
                      frequencyDataDropdown={frequencyDataDropdown}
                      values={values}
                      handleChange={handleChange}
                      errors={[
                        showOriginalArAmmountError,
                        showFiscalYearError,
                        showCollocationCodeError,
                        showRecoupmentTypeCodeError,
                        showInvalidBeginDateError,
                        showInvalidEndDateError,
                        showEndDateLesserError,
                        showBeginDateRequiredError,
                        showEndDateRequiredError,
                        showPercentError,
                        showInstallementError,
                        showStartDateError,
                        showStartDateRequiredError,
                        showMinBeginDateError,
                        showMinEndDateError,
                        showMinRecoupmentDateError,
                        showManualCheckNumberError,
                        showFrequencyError,
                        showATRError
                      ]}
                      errorText={[
                        showOriginalArAmountErrorText,
                        showFiscalYearErrorText,
                        showCollocationCodeErrorText,
                        showRecoupmentTypeCodeErrorText,
                        showInvalidBeginDateText,
                        showInvalidEndDateInvalidText,
                        showEndDateLesserErrorText,
                        showBeginDateRequiredErrorText,
                        showEndDateRequiredErrorText,
                        showPercentErrorText,
                        showInstallmentErrorText,
                        showStartDateErrorText,
                        showStartDateRequiredErrorText,
                        showMinBeginDateErrorText,
                        showMinEndDateErrorText,
                        showMinRecoupmentDateErrorText,
                        showManualCheckNumberText,
                        showFrequencyErrorText,
                        showATRErrorText
                      ]}
                      manualCheckDate={manualCheckDate}
                      handleManualCheckDate={handleManualCheckDate}
                      selectedRecoupmentDate={selectedRecoupmentDate}
                      handleRecoupmentDateChange={handleRecoupmentDateChange}
                      defaultRecoupment={handleDefaultRecoupment}
                      recoupmentData={recoupmentData}
                      COSData={COSData}
                      fundCodeData={fundCodeData}
                      frequencyData={frequencyData}
                      amountObj={amountObj}
                      formatDecimalField={formatDecimalField}
                      formatPercent={formatPercent}
                      removeFormatDecimalFieldInstallment={
                        removeFormatDecimalFieldInstallment
                      }
                    ></GrossLevelDetailsComponent>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                {value === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <GrossLevelExemptionDetailComponent
                      values={values}
                      handleChange={handleChange}
                    ></GrossLevelExemptionDetailComponent>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={2}>
                {value === 2 && (
                  <>
                    <div className="tab-holder">
                      <form autoComplete="off">
                        <div className="form-wrapper">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <NativeDropDown
                              className='min-md'
                              id="standard-GrossDetailsCategoryofService"
                              label="Payment Schedule"
                              value={values.paymentScheduleType}
                              onChange={handleChange('paymentScheduleType')}
                              inputProps={{ maxlength: 3 }}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                      manualEditFlag
                              }
                              isRequired={values.recoupmentTypeCode === 'S'}
                              errTxt={
                                paymentScheduleTypeError !== ''
                                  ? paymentScheduleTypeError
                                  : null
                              }
                              dropdownList={paymentScheduleTypeData.map(
                                (option, index) => (
                                  <option key={index} value={option.code}>
                                    {option.description}
                                  </option>
                                )
                              )}
                            />
                            <div className="mui-custom-form with-date">
                              <KeyboardDatePicker
                                fullWidth
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                id="GrossLevelDetailBegindate-picker-dialog"
                                label="Recoupment Start Date"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                minDate={
                                  new Date(
                                    new Date().toLocaleString('en-US', {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                initialFocusedDate={
                                  new Date(
                                    new Date().toLocaleString('en-US', {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                value={selectedRecoupmentStartDate}
                                onChange={handleRecoupmentStartDateChange}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                required={values.recoupmentTypeCode === 'S'}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                      manualEditFlag
                                }
                                helperText={
                                  recoupmentStartDateError !== ''
                                    ? recoupmentStartDateError
                                    : null
                                }
                                error={
                                  recoupmentStartDateError !== ''
                                    ? recoupmentStartDateError
                                    : null
                                }
                              />
                            </div>
                          </MuiPickersUtilsProvider>
                          <div className="mui-custom-form input-md override-m-2">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Number of Installments"
                              placeholder=""
                              inputProps={{ maxlength: 10 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={values.numberofInstallments}
                              onChange={handleChange('numberofInstallments')}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                              }
                              required={values.paymentScheduleType === 'A'}
                              helperText={
                                numberofInstallmentError !== ''
                                  ? numberofInstallmentError
                                  : null
                              }
                              error={
                                numberofInstallmentError !== ''
                                  ? numberofInstallmentError
                                  : null
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/,
                                  ''
                                );
                              }}
                            ></TextField>
                          </div>
                          <NativeDropDown
                            className='min-md override-m-2'
                            id="standard-GrossDetailsCategoryofService"
                            label="Frequency"
                            value={values.paymentScheduleFrequency}
                            onChange={handleChange(
                              'paymentScheduleFrequency'
                            )}
                            inputProps={{ maxlength: 3 }}
                            disabled={
                              !userInquiryPrivileges
                                ? !userInquiryPrivileges
                                : values.recoupmentTypeCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                            }
                            isRequired={values.paymentScheduleType === 'A'}
                            errTxt={
                              frequencyError !== '' ? frequencyError : null
                            }
                            dropdownList={frequencyRecoupData.map((option, index) => (
                              <option key={index} value={option.code}>
                                {option.description}
                              </option>
                            ))}
                          />
                        </div>
                        <div className="form-wrapper">
                          <div
                            className="mui-custom-form min-md"
                            style={{ lineHeight: '100%' }}
                          >
                            <label className="MuiFormLabel-root small-label">
                              Interest Calculation
                            </label>
                            <div className="sub-radio no-margin">
                              <Radio
                                value="Y"
                                id="GroosLevelDetailsapprovalIdYes"
                                checked={values.interestCalculation === 'Y'}
                                onChange={handleChange('interestCalculation')}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                      values.paymentScheduleType !== 'A' ||
                                      manualEditFlag
                                }
                              />
                              <label
                                className="text-black"
                                htmlFor="GroosLevelDetailsapprovalIdYes"
                              >
                                Yes
                              </label>
                              <Radio
                                value="N"
                                id="GroosLevelDetailsapprovalIdNo"
                                checked={values.interestCalculation === 'N'}
                                onChange={handleChange('interestCalculation')}
                                className="ml-2"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                      values.paymentScheduleType !== 'A' ||
                                      manualEditFlag
                                }
                              />
                              <label
                                className="text-black"
                                htmlFor="GroosLevelDetailsapprovalIdNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div className="mui-custom-form input-md">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Interest Percentage"
                              placeholder=""
                              inputProps={{ maxlength: 14 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment>%</InputAdornment>
                              }}
                              value={values.interestPercentage}
                              onBlur={formatDecimalField('interestPercentage')}
                              onFocus={removeFormatDecimalFieldPercent(
                                'interestPercentage'
                              )}
                              onChange={handleChange('interestPercentage')}
                              onKeyDown={evt =>
                                evt.key === 'e' && evt.preventDefault()
                              }
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    values.interestCalculation === 'N' ||
                                    manualEditFlag
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                  ''
                                );
                              }}
                              required={values.interestCalculation === 'Y'}
                              helperText={
                                interestCalError !== ''
                                  ? interestCalError
                                  : null
                              }
                              error={
                                interestCalError !== ''
                                  ? interestCalError
                                  : null
                              }
                            ></TextField>
                          </div>
                        </div>
                      </form>
                      <div className="float-right mt-3">
                        <Button
                          className="btn-text-main-add btn-success"
                          disabled={
                            values.recoupmentTypeCode !== 'S' ||
                            values.paymentScheduleType !== 'A' ||
                            manualEditFlag || !userInquiryPrivileges
                          }
                          title={'Calculate'}
                          onClick={calculateSchedule}
                        >
                          Calculate
                        </Button>
                        <Button
                          className="btn-text-main-delete btn-transparent ml-1"
                          onClick={PaymentRowDeleteClick}
                          disabled={
                            values.recoupmentTypeCode !== 'S' ||
                            values.paymentScheduleType === 'A' || !userInquiryPrivileges
                          }
                          title={'Delete'}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          onClick={handleClickOpenPaymentSchedule}
                          disabled={
                            values.recoupmentTypeCode !== 'S' ||
                            values.paymentScheduleType === 'A' || !userInquiryPrivileges
                          }
                          title={'Add Payment Schedule'}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>
                      <div className="clearfix"></div>
                      <div className="my-3 p-0">
                        {values.paymentScheduleType === 'M' ? (
                          <PaymentScheduleTable
                            tableData={paymentScheduleData}
                            onRowClick={editRow}
                            rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                            onTableCellButtonClick={onClickAuditDialogLog}
                            uniqueKey={'scheduleSK'}
                            setClose={setClose}
                            close={close}
                            isSearch={false}
                          />
                        ) : (
                          <PaymentScheduleTable
                            tableData={paymentScheduleData}
                            onRowClick={editRow}
                            rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                            onTableCellButtonClick={onClickAuditDialogLog}
                            uniqueKey={'scheduleSK'}
                            setClose={setClose}
                            close={close}
                            isSearch={true}
                          />
                        )}
                      </div>{' '}
                      {open ? (
                        <div className="tabs-container" id="form_pop_up">
                          <div className="tab-header  mini-tab-header">
                            <div className="tab-heading float-left">
                              {add
                                ? 'Add Payment Schedule'
                                : 'Edit Payment Schedule'}
                            </div>
                            <div className="float-right mt-1">
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-success ml-1"
                                onClick={savePaymentSchedule}
                                disabled={values.paymentScheduleType === 'A' || !userInquiryPrivileges}
                                title={add ? 'Add' : 'Update'}
                              >
                                <i className="fa fa-check" aria-hidden="true"></i>
                                {add ? 'Add' : 'Update'}
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="bt-reset btn-transparent ml-1"
                                onClick={resetPaymentSchedule}
                                disabled={values.paymentScheduleType === 'A' || !userInquiryPrivileges}
                                title={'Reset'}
                              >
                                <i className="fa fa-undo" aria-hidden="true"></i>
                                Reset
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-primary btn btn-outlined ml-1"
                                onClick={handleClose}
                                title={'Cancel'}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                          <div className="form-border my-3">
                            <form autoComplete="off">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="form-wrapper">
                                  <div className="mui-custom-form with-date override-width-20">
                                    <KeyboardDatePicker
                                      maxDate={Date.parse(
                                        '31 Dec 9999 00:00:00 GMT'
                                      )}
                                      required
                                      fullWidth
                                      id="dueDate"
                                      label="Due Date"
                                      minDate={
                                        new Date(
                                          new Date().toLocaleString('en-US', {
                                            timeZone:
                                              process.env.REACT_APP_TIMEZONE
                                          })
                                        )
                                      }
                                      initialFocusedDate={
                                        new Date(
                                          new Date().toLocaleString('en-US', {
                                            timeZone:
                                              process.env.REACT_APP_TIMEZONE
                                          })
                                        )
                                      }
                                      format="MM/dd/yyyy"
                                      placeholder="mm/dd/yyyy"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      value={selectedDueDate}
                                      onChange={handleDueDateChange}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                      }}
                                      disabled={
                                        values.paymentScheduleType === 'A'
                                      }
                                      helperText={
                                        showDueDateError
                                          ? showDueDateErrorText
                                          : null || showDueDateGreaterError
                                            ? showDueDateGreaterErrorText
                                            : null || showMinDueDateError
                                              ? showMinDueDateErrorText
                                              : null || showDueDateInvalidError
                                                ? showDueDateInvalidErrorText
                                                : null
                                      }
                                      error={
                                        showDueDateError
                                          ? showDueDateErrorText
                                          : null || showDueDateGreaterError
                                            ? showDueDateGreaterErrorText
                                            : null || showMinDueDateError
                                              ? showMinDueDateErrorText
                                              : null || showDueDateInvalidError
                                                ? showDueDateInvalidErrorText
                                                : null
                                      }
                                    />
                                  </div>
                                  <div className="mui-custom-form input-md override-width-20">
                                    <TextField
                                      id="amountdue"
                                      fullWidth
                                      label="Installment Amount"
                                      required
                                      inputProps={{ maxlength: 14 }}
                                      value={
                                        paymentScheduleVO.installmentAmount
                                      }
                                      onChange={handleChangePaymentSchedule(
                                        'installmentAmount'
                                      )}
                                      onBlur={formatDecimalFieldInstallment(
                                        'installmentAmount'
                                      )}
                                      onFocus={removeFormatDecimalFieldAmountDue(
                                        'installmentAmount'
                                      )}
                                      disabled={
                                        values.paymentScheduleType === 'A'
                                      }
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        )
                                      }}
                                      helperText={
                                        showAmountDueError
                                          ? showAmountDueErrorText
                                          : null || showNegativeAmountError
                                            ? showNegativeAmmountErrorText
                                            : null ||
                                            showGreaterThanZeroAmountError
                                              ? showGreaterThanZeroAmountText
                                              : null || showOriginalArAmountPopError
                                                ? showOriginalArAmountPopErrorText
                                                : null
                                      }
                                      error={
                                        showAmountDueError
                                          ? showAmountDueErrorText
                                          : null || showNegativeAmountError
                                            ? showNegativeAmmountErrorText
                                            : null ||
                                            showGreaterThanZeroAmountError
                                              ? showGreaterThanZeroAmountText
                                              : null || showOriginalArAmountPopError
                                                ? showOriginalArAmountPopErrorText
                                                : null
                                      }
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                          ''
                                        );
                                      }}
                                    ></TextField>
                                  </div>
                                  <div className="mui-custom-form input-md override-width-20">
                                    <TextField
                                      id="amountdue"
                                      fullWidth
                                      label="Recouped Amount"
                                      required
                                      disabled
                                      inputProps={{ maxlength: 14 }}
                                      value={paymentScheduleVO.recoupedAmount}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        )
                                      }}
                                    ></TextField>
                                  </div>
                                </div>
                              </MuiPickersUtilsProvider>
                            </form>
                          </div>
                          {showLogDialogTable ? (
                            <div id="audit-dialog-table">
                              <AuditTableComponent
                                print
                                auditRowData={auditDialogRowData}
                                setAuditRowData={setAuditDialogRowData}
                                setShowLogTable={setShowLogDialogTable}
                                setClose={setClose}
                              />{' '}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {value === 3 && (
                  <>
                    <div className="tab-holder my-3 p-0">
                      <GrossLevelHistoryTableComponent
                        values={values}
                        historyData={historyData}
                        advancePayment={advancePayment}
                        dropdowns={addDropdowns}
                      />
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={4}>
                {value === 4 && (
                  <div className="tab-holder p-0">
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      setErrorMessages={seterrorMessages}
                      setFocusCheck={setFocusCheck}
                      userInquiryPrivileges = {userInquiryPrivileges}
                    />
                  </div>
                )}

              </TabPanel>
              {
                <TabPanel
                  value={value}
                  index={5}
                >
                  <div className="tab-holder p-0">
                    <Attachments
                      userInquiryPrivileges={userInquiryPrivileges}
                      attachmentFile={attachmentFile}
                      setAttachmentFile={setAttachmentFile}
                      attachmentsTableData={attachmentsTableData}
                      setAttachmentsTableData={setAttachmentsTableData}
                      parentErrorMesage={seterrorMessages}
                      setAllowNavigation={setAllowNavigation}
                      setSystemSuccesMessages={clearSuccessErrorMsgs}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                </TabPanel>
              }
              {showLogTable ? (
                <div id="audit-table">
                  <AuditTableComponent
                    print
                    auditRowData={auditRowData}
                    setAuditRowData={setAuditRowData}
                    setShowLogTable={setShowLogTable}
                  />{' '}
                </div>
              ) : null}
            </div>
          </div>
          <Dialog
            className="custom-dialog dialog-500 with-padding"
            fullWidth={true}
            maxWidth={'sm'}
            open={openDialog}
            disableBackdropClick
          >
            <DialogContent dividers>
              <div>Are you sure you want to Delete?</div>
            </DialogContent>

            <DialogActions>
              <Button
                className="btn btn-primary ml-1"
                onClick={confirmDelete}
                disabled={!userInquiryPrivileges}
              >
                OK
              </Button>
              <Button
                className="btn btn-primary ml-1"
                onClick={closeDeletePopUpDialog}
                disabled={!userInquiryPrivileges}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
                  STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(AdvancePayment);
