/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from '../../../../SharedModules/Layout/footer';
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  DialogContent
} from "../../../../SharedModules/Dialog/DialogUtilities";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { Button, Form } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../../../../SharedModules/TabPanel/TabPanel";
import PropsInit from "../../../../SharedModules/Navigation/NavHOC";
import DateFnsUtils from "@date-io/date-fns";
import dropdownCriteria from "../PaymentCriteriaDD.json";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import PaymentCriteriaDetails from "./PaymentCriteriaDetails";
import Attachments from "../../../../SharedModules/Attachments/Attachments";
import {
  compareTwoDatesGreaterThanOrEqual,
  generateUUID,
  getDateInMMDDYYYYFormat,
  getDateInMMDDYYYYFormatWithApendZero,
  getUTCTimeStamp
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import Notes from "../../../../SharedModules/Notes/Notes";
import * as moment from "moment";
import { getDropdownDataAction } from "../../../FundCode/Store/Actions/FundCodeSearchActions";
import { systemListActions } from "../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions";
import * as dropdowns from '../../../../SharedModules/Dropdowns/dropdowns';
import * as ErrorConstants from '../PaymentCriteriaConstant';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import { paymentCriteriaSaveUpdate, paymentCriteriaRowAction, resetPaymentCriteriaSearch, deletePayeeAction, PaymentCriteriaSearchAction, getAuditLogDetailsAction, getAuditLogRowDetailsAction } from '../../Store/Actions/PaymentCriteriaAction';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent';
import { Prompt } from "react-router-dom";
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
export default PropsInit({
  Wrapped: PaymentCriteriaAdd,
  action: PaymentCriteriaSearchAction,
  pathTo: "/PaymentCriteriaEdit",
  selector: "paymentCriteriaState.rowSearchResults",
  actionKey: "paymentCriteria"
});
function PaymentCriteriaAdd (props) {
  const [editScreen, seteditScreen] = React.useState(false);

  const [totalCharacters] = React.useState(100);
  const toPrintRef = useRef();

  const [reasonErrorMessages, setReasonErrorMessages] = React.useState([]);
  const [payeeErrorMessages, setPayeeErrorMessages] = React.useState([]);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    criteriaID: "",
    beginDate: null,
    endDate: null,
    hasBeenProcessed: "N",
    description: ""
  });
  const saveUpdateAPI = (value) =>
    dispatch(
      paymentCriteriaSaveUpdate(
        value,
        attachmentsTableData?.length > 0 ? attachmentFile : null
      )
    );
  const saveAPIResults = useSelector(
    (state) => state.paymentCriteriaState?.saveUpadteData
  );

  const paymentCriteriaAddToEditAction = (values) => {
    dispatch(paymentCriteriaRowAction(values));
  };

  const paymentCriteriaResetAction = () => {
    dispatch(resetPaymentCriteriaSearch());
  };

  const searchResultAfterAdd = useSelector(
    (state) => state.paymentCriteriaState?.rowSearchResults
  );

  const paymentCriteriaDeleteAction = (values) => {
    dispatch(deletePayeeAction(values));
  };

  const resultAfterDelete = useSelector(
    (state) => state.paymentCriteriaState?.deletePayeeData
  );

  const getDropdownData = (dropdownValues) => {
    dispatch(getDropdownDataAction(dropdownValues));
  };

  const dispatch = useDispatch();

  const [reasonTable, setReasonTable] = React.useState([]);
  const [payeeTable, setPayeeTable] = React.useState([]);
  const [tabOne, setTabOne] = React.useState(true);
  const [tabTwo, setTabTwo] = React.useState(false);
  const [tabThree, setTabThree] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [disableNotes, setDisableNotes] = React.useState(false);
  const [disableFields, setDisableFields] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [typeList, setTypeList] = React.useState([]);
  const [payeeTypeList, setPayeeTypeList] = React.useState([]);
  const [valueList, setValueList] = React.useState([]);
  const [pValueList, setPValueList] = React.useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [newRecord, setNewRecord] = React.useState(true);
  const [ProcessedValue, setProcessedValue] = React.useState([]);
  const [deleteReason, setDeleteReason] = React.useState(false);
  const [deletePayee, setDeletePayee] = React.useState(false);
  const dropdown = useSelector((state) => state.fundCodeState.dropdownData);
  const [paymentCriteriaBgnDate, setPaymentCriteriaBgnDate] =
    React.useState(null);
  const [paymentCriteriaEndDate, setPaymentCriteriaEndDate] =
    React.useState(null);
  const [paymentReqCriteriaSet, setPaymentReqCriteriaSet] = React.useState([]);
  const [reasonDataValues, setReasonDataValues] = React.useState([]);
  const [payeeDataValues, setPayeeDataValues] = React.useState([]);
  const [deleteReqCriteriaSet, setDeleteReqCriteriaSet] = React.useState([]);
  const [deletePayeeCriteriaSet, setDeletePayeeCriteriaSet] = React.useState(
    []
  );
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState([]);
  const [
    {
      showBgnDtErr,
      showBgnDtGtrErr,
      showBgnDtInvalidErr,
      showEndDateErr,
      showEndDtInvalidErr,
      showEndDtGtrErr,
      showEndDtLessthanBgnDtErr
},
    setAddFormShowErr
  ] = React.useState(false);
  const paymentCriteriaSts = (value) => dispatch(systemListActions(value));
  const reasonCodeValueDropdowns = (values) => { dispatch(GET_APP_DROPDOWNS(values)); };
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const hasBeenProcessedSts = useSelector(
    (state) => state.appConfigState.systemListState.payload
  );
  const ValuesFromPayment =
    props.history &&
    props.history.location &&
    props.history.location.state &&
    props.history.location.state.values;
  // Attachments variables
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const firstRenderRef = React.useRef(true);

  // Notes start
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  const logInUserID = useSelector((state) => state.sharedState.logInUserID);
  let notesDataArray = [];
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: '',
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = (data) => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  // Notes ENd

  const handleChange = (name) => (event) => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
  };
  const handlePaymentBgnDate = (date) => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setPaymentCriteriaBgnDate(date);
  };
  const handlePaymentEndDate = (date) => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setPaymentCriteriaEndDate(date);
  };

  const handleChangeTabs = (event, newValue) => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
    if (newValue === 1) {
      setTabTwo(true);
    }
    if (newValue === 2) {
      setTabThree(true);
    }
  };

  useEffect(() => {
    let saveAPIResults = [];
    setSystemSuccesMessages([]);
    if (props.history.location.state) {
      if (props.history.location.state.saveMessage !== undefined) {
        saveAPIResults = [props.history.location.state.saveMessage];
        setSystemSuccesMessages(saveAPIResults);
        seteditScreen(true);
        setDeletePayee(false);
        setDeleteReason(false);
        paymentCriteriaResetAction();
      } else {
        saveAPIResults = [];
      }
    }
    getDropdownData(dropdownCriteria);
    paymentCriteriaSts(dropdowns.PAYMENT_CRITERIA_STS_CD);
    reasonCodeValueDropdowns([dropdowns.F_PAYMENT_REQ_TY_CD, dropdowns.F_RSN_CD]);
  }, []);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["Financial#9012"]) {
        setTypeList(dropdown.listObj["Financial#9012"]);
      }
      if (dropdown.listObj["Financial#9013"]) {
        setPayeeTypeList(dropdown.listObj["Financial#9013"]);
      }
      if (dropdown.listObj["Financial#3006"]) {
        setValueList(dropdown.listObj["Financial#3006"]);
      }
      if (dropdown.listObj["Financial#3002"]) {
        setPValueList(dropdown.listObj["Financial#3002"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
    }
  }, [dropdown]);
  useEffect(() => {
    if (hasBeenProcessedSts && hasBeenProcessedSts.length > 0) {
      const payload = hasBeenProcessedSts[0]?.systemListDetails;
      setProcessedValue(payload);
    }
  }, [hasBeenProcessedSts]);
  const errorMessagesArray = [];
  const addFormValidation = () => {
    setDialogOpen(false);
    let showBgnDtErr = false;
    let showEndDateErr = false;
    let showBgnDtGtrErr = false;
    let showBgnDtInvalidErr = false;
    let showEndDtGtrErr = false;
    let showEndDtInvalidErr = false;
    let showEndDtLessthanBgnDtErr = false;
    let showAtleastOneErr = false;
    setAllowNavigation(false);

    if (
      getDateInMMDDYYYYFormat(paymentCriteriaBgnDate) === "" ||
      getDateInMMDDYYYYFormat(paymentCriteriaBgnDate) === null
    ) {
      showBgnDtErr = true;
      errorMessagesArray.push(ErrorConstants.BEGIN_DATE_IS_REQUIRED);
      seterrorMessages(errorMessagesArray);
    }

    if (paymentCriteriaBgnDate && values.hasBeenProcessed !== 'Y') {
      var bgnDt = getDateInMMDDYYYYFormat(paymentCriteriaBgnDate);
      var sysCurrentDt = getDateInMMDDYYYYFormat(new Date());
      if (compareTwoDatesGreaterThanOrEqual(bgnDt, sysCurrentDt)) {
        showBgnDtGtrErr = true;
      errorMessagesArray.push(ErrorConstants.BEGIN_DATE_GREATER_ERROR);
      seterrorMessages(errorMessagesArray);
      }
    }
    if (
      paymentCriteriaBgnDate &&
      paymentCriteriaBgnDate.toString() == "Invalid Date"
    ) {
      showBgnDtInvalidErr = true;
      errorMessagesArray.push(ErrorConstants.BEGIN_DATE_IS_INVALID);
      seterrorMessages(errorMessagesArray);
    }

    // End date
    if (
      getDateInMMDDYYYYFormat(paymentCriteriaEndDate) === "" ||
      getDateInMMDDYYYYFormat(paymentCriteriaEndDate) === null
    ) {
      showEndDateErr = true;
      errorMessagesArray.push(ErrorConstants.END_DATE_IS_REQUIRED);
      seterrorMessages(errorMessagesArray);
    }
    // update screen validation
    if (
      editScreen &&
      (moment(paymentCriteriaEndDate).format("MM/DD/YYYY") < moment(new Date()).format("MM/DD/YYYY")) &&
      values.hasBeenProcessed === "Y"
    ) {
      showEndDtGtrErr = true;
      errorMessagesArray.push(ErrorConstants.END_DATE_GREATER_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      paymentCriteriaEndDate &&
      paymentCriteriaEndDate.toString() == "Invalid Date"
    ) {
      showEndDtInvalidErr = true;
      errorMessagesArray.push(ErrorConstants.END_DATE_IS_INVALID);
      seterrorMessages(errorMessagesArray);
    }
    if (
      new Date(paymentCriteriaEndDate) <
      new Date(paymentCriteriaBgnDate)
    ) {
      console.log("new Date(paymentCriteriaEndDate)-1", new Date(paymentCriteriaEndDate), "new Date(paymentCriteriaBgnDate)-@#", new Date(paymentCriteriaBgnDate));
      showEndDtLessthanBgnDtErr = true;
      errorMessagesArray.push(ErrorConstants.BEGIN_DT_END_DT_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (reasonDataValues.length == 0 && payeeDataValues.length == 0 && values.hasBeenProcessed !== "Y") {
      showAtleastOneErr = true;
      errorMessagesArray.push(ErrorConstants.ATLEAST_ONE_ROW);
      seterrorMessages(errorMessagesArray);
    }

    setAddFormShowErr({
      showBgnDtErr: showBgnDtErr,
      showEndDateErr: showEndDateErr,
      showBgnDtGtrErr: showBgnDtGtrErr,
      showBgnDtInvalidErr: showBgnDtInvalidErr,
      showEndDtGtrErr: showEndDtGtrErr,
      showEndDtInvalidErr: showEndDtInvalidErr,
      showEndDtLessthanBgnDtErr: showEndDtLessthanBgnDtErr,
      showAtleastOneErr: showAtleastOneErr
    });
  };

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      if (window.location.pathname === '/PaymentCriteriaAdd') {
          setDialogOpen(false);
          setFocusCheck(false);
          props.history.push({
            pathname: '/PaymentCriteriaSearch',
            tableRender: false
          });
      } else if (window.location.pathname === '/PaymentCriteriaEdit') {
        props.history.push({
          pathname: '/PaymentCriteriaSearch',
          tableRender: !props.history.location?.state?.isFromAdd,
          state: { values: ValuesFromPayment }
        });
      }
    }, 100);
    };

  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [close, setClose] = React.useState(true);
  const [auditRowData, setAuditRowData] = React.useState([]);
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
 const [paymentCriteriaSK, setPaymentCriteriaSK] = React.useState(null);
 const [auditTableData, setAuditTableData] = React.useState([]);

  const onClickAuditLog = () => {
    const tableName = "f_payment_req_tb";

    const keyValue = {
      f_payment_req_sk: paymentCriteriaSK
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById("audit-table")) { document.getElementById("audit-table").scrollIntoView(); }
  }, [showLogTable]);
  const auditLogData = useSelector(state => state.paymentCriteriaState?.auditDetails);
  const auditLogTableData = useSelector(state => state.paymentCriteriaState?.auditRowDetails);
  const filterAuditTable = rowData => {
    return rowData.afterValue || rowData.beforeValue;
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  const onClickAuditDialogLog = row => {
    const tableName = "f_payment_req_tb";
    const keyValue = {
      f_payment_req_sk: paymentCriteriaSK,
      r_fund_cd_crit_seq_num: row.sequenceNumber
    };
    dispatch(
      getAuditLogRowDetailsAction(tableName, keyValue, "auditLogTableData")
    );
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById("audit-dialog-table")) { document.getElementById("audit-dialog-table").scrollIntoView(); }
  }, [showLogDialogTable]);
  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);
  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);

const majorSave = () => {
  setAddFormShowErr(false);
  seterrorMessages([]);
  setAllowNavigation(false);
  setDialogOpen(false);
  addFormValidation();
  if (errorMessagesArray.length === 0) {
    let attachmentsSet = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map(value => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date()),
            fileUrl: "abcd",
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description: value && value.description ? value.description : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName: attachmentsTableData && value ? value.fileName : null,
            file1: [1, 2, 3, 4],
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentsSet.push(attachmentVO);
        }
      });
    } else {
      attachmentsSet = null;
    }
    const saveUpdateRequestPayload = {
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      paymentReqSk: values.criteriaID,
      description: values.description,
      beginDate: moment(paymentCriteriaBgnDate).format('YYYY-MM-DD'),
      endDate: moment(paymentCriteriaEndDate).format('YYYY-MM-DD'),
      hasProcessedInd: values.hasBeenProcessed,
      paymentReqCriteriaSet: [...reasonDataValues, ...payeeDataValues],
      deleteReqCriteriaSet: deleteReason || deletePayee ? [...deleteReqCriteriaSet, ...deletePayeeCriteriaSet] : null,
      newRecord: newRecord,
      attachmentsList: attachmentsSet,
      noteSetVO: allowNavigation ? notesInput : notesTableData.length > 0 ? notesTableData : null
    };
    saveUpdateAPI(saveUpdateRequestPayload);
  }
};

const deleteParent = () => {
  setAllowNavigation(false);
  setDialogOpen(false);
  if (values.hasBeenProcessed === 'Y') {
    seterrorMessages([ErrorConstants.HAS_BEEN_PROCESSED_YES]);
  } else {
    paymentCriteriaDeleteAction(values.criteriaID);
  }
};

useEffect(() => {
  if (resultAfterDelete?.respcode == '01') {
    paymentCriteriaResetAction();
    props.history.push({
      pathname: "/PaymentCriteriaSearch",
      state: {
        deleteMessage: ErrorConstants.DELETESUCCESS,
        isDelete: true
      }
    });
  }
}, [resultAfterDelete]);

  useEffect(() => {
    if (
      saveAPIResults &&
      saveAPIResults.respdesc === "Request saved successfully"
    ) {
      paymentCriteriaAddToEditAction(saveAPIResults.respcode);
    } else if (saveAPIResults?.systemFailure === true) {
      seterrorMessages([ErrorConstants.GENERIC_SYSTEM_ERROR]);
    } else if (saveAPIResults?.respcode == '02') {
      seterrorMessages([saveAPIResults?.errorMessages[0]]);
    }
  }, [saveAPIResults]);

  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else if (searchResultAfterAdd && searchResultAfterAdd.enterpriseBaseVO) {
      const requestObject = JSON.parse(JSON.stringify(searchResultAfterAdd));
      if (requestObject) {
        setTimeout(() => {
          props.history.push({
            pathname: "/"
          });
          props.history.push({
            pathname: "/PaymentCriteriaEdit",
            state: {
              searchResult: requestObject.enterpriseBaseVO,
              saveMessage: ErrorConstants.SAVESUCCESS,
              values: ValuesFromPayment,
              isFromAdd: true
            }
          });
        }, 200);
      } else {
        seterrorMessages([ErrorConstants.GENERIC_SYSTEM_ERROR]);
      }
    }
  }, [searchResultAfterAdd]);

  useEffect(() => {
    const getData = props.history.location.state?.searchResult
      ? props.history.location.state?.searchResult
      : props.history.location.state?.paymentCriteriaRowData?.enterpriseBaseVO
      ? props.history.location.state?.paymentCriteriaRowData?.enterpriseBaseVO
      : props.history.location.state?.paymentCriteriaData[0];
      setNewRecord(false);
    if (getData && addDropdowns) {
      setValues({
        criteriaID: getData.paymentReqSk,
        hasBeenProcessed: getData.hasProcessedInd,
        description: getData.description
      });
      setPaymentCriteriaSK(getData.paymentReqSk);
      setPaymentCriteriaBgnDate(getData.beginDate);
      setPaymentCriteriaEndDate(getData.endDate);
      const rTable = [];
      const pTable = [];
      getData.paymentReqCriteriaSet.map((data) => {
        const reqTyCd =
        addDropdowns && addDropdowns['F1#F_PAYMENT_REQ_TY_CD'] &&
        addDropdowns['F1#F_PAYMENT_REQ_TY_CD'].filter(
          value => ((value.code === data.reqTyCd))
        );
        data.reqTyCdDesc =
          reqTyCd && reqTyCd.length > 0 ? reqTyCd[0].description : "";
        const payeeReqTyCd =
          addDropdowns && addDropdowns['F1#F_PAYMENT_REQ_TY_CD'] &&
        addDropdowns['F1#F_PAYMENT_REQ_TY_CD'].filter(
            (value) => value.code === data.reqTyCd
          );
        data.payeeReqTyCdDesc =
         payeeReqTyCd && payeeReqTyCd.length > 0 ? payeeReqTyCd[0].description : "";

        const reqValue1 =
        addDropdowns && addDropdowns['F1#F_RSN_CD'] &&
        addDropdowns['F1#F_RSN_CD'].filter(
            (value) => value.code == data.reqValue
          );

        data.reqValueDesc =
          reqValue1 && reqValue1.length > 0
            ? reqValue1[0].description
            : "";

        if (data.reqTyCd === "F") {
          rTable.push({ ...data, id: generateUUID() });
        } else {
          pTable.push({ ...data, id: generateUUID() });
        }
      });
      setReasonTable(rTable);
      setPayeeTable(pTable);
      setPayeeDataValues(pTable);
      setReasonDataValues(rTable);
      setAttachmentsTableData(getData?.attachmentsList);
      if (getData?.noteSetVO) {
        const noteSetVO = getData.noteSetVO;
        const notesTable = getData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable || []);
      }
      seteditScreen(true);
    }
  }, [props.history.location.state?.searchResult, addDropdowns]);

  const paymentCriteriaValues =
    props.history &&
    props.history.location &&
    props.history?.location?.state &&
    props.history?.location?.state?.values;
  const majorCancel = () => {
    seterrorMessages([]);
    setAllowNavigation(false);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      if (window.location.pathname === "/PaymentCriteriaEdit") {
        props.history.push({
          pathname: "/PaymentCriteriaSearch",
          tableRender: !props.history.location?.state?.isFromAdd,
          state: { values: paymentCriteriaValues }
        });
        console.log(paymentCriteriaValues);
      } else if (window.location.pathname === "/PaymentCriteriaAdd") {
        props.history.push({
          pathname: "/PaymentCriteriaSearch",
          tableRender: false
        });
      }
    }
  };

  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    seterrorMessages([]);
    setAddFormShowErr(false);
  };

  return (
    <div className="tabs-container" ref={toPrintRef}>

        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      {reasonErrorMessages.length > 0 ? <ErrorMessages errorMessages={reasonErrorMessages} />
       : payeeErrorMessages.length > 0 ? <ErrorMessages errorMessages={payeeErrorMessages} /> : null}

      <ErrorMessages errorMessages={errorMessages} />
      {errorMessages.length === 0 ? (
        <SuccessMessages successMessages={systemSuccessMessages} />
      ) : null}

      <div className="tab-header">
        {editScreen ? (
          <h1 className="tab-heading float-left">Edit Payment Criteria</h1>
        ) : null}{" "}
        {!editScreen ? (
          <h1 className="tab-heading float-left">Add Payment Criteria</h1>
        ) : null}
        <div className="float-right mt-2">
          <Button
            className="btn btn-success"
            onClick={() => {
              majorSave();
            }}
          >
            <i className="fa fa-check" aria-hidden="true"></i>
            Save
          </Button>
          {editScreen ? (
            <Button
              className="btn btn-delete"
              onClick={() => {
                deleteParent();
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
              Delete
            </Button>
          ) : (
            ""
          )}
          {editScreen ? (
            <Button
              color="primary"
              className={
                auditButtonToggle
                  ? "btn btn-primary ml-1"
                  : "btn btn-text1 btn-transparent ml-1"
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i className="fa fa-question-circle" aria-hidden="true"></i>
              Audit Log
            </Button>
          ) : null}
          <Button title="Cancel" onClick={() => majorCancel()}>
            Cancel
          </Button>
        </div>
        <div className="clearfix"></div>
      </div>
      <div className="tab-body">
        <form autoComplete="off">
          <div className="form-wrapper">
            <div className="mui-custom-form input-md">
              <TextField
                id="standard-SystemPayeeID"
                fullWidth
                label="Criteria ID"
                value={values.criteriaID}
                onChange={handleChange("criteriaID")}
                disabled
                inputProps={{ maxlength: 10 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="mui-custom-form with-date">
                <KeyboardDatePicker
                  maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                  id="payeeBeginDob"
                  label="Begin Date"
                  required
                  format="MM/dd/yyyy"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={values.hasBeenProcessed === 'Y'}
                  placeholder="mm/dd/yyyy"
                  value={paymentCriteriaBgnDate}
                  onChange={handlePaymentBgnDate}
                  helperText={
                    showBgnDtErr
                      ? ErrorConstants.BEGIN_DATE_IS_REQUIRED
                      : showBgnDtGtrErr
                      ? ErrorConstants.BEGIN_DATE_GREATER_ERROR
                      : showBgnDtInvalidErr
                      ? ErrorConstants.BEGIN_DATE_IS_INVALID
                      : null
                  }
                  error={
                    showBgnDtErr
                      ? ErrorConstants.BEGIN_DATE_IS_REQUIRED
                      : showBgnDtGtrErr
                      ? ErrorConstants.BEGIN_DATE_GREATER_ERROR
                      : showBgnDtInvalidErr
                      ? ErrorConstants.BEGIN_DATE_IS_INVALID
                      : null
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="mui-custom-form with-date">
                <KeyboardDatePicker
                  maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                  id="payeeEndDob"
                  label="End Date"
                  format="MM/dd/yyyy"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={paymentCriteriaEndDate}
                  onChange={handlePaymentEndDate}
                  placeholder="mm/dd/yyyy"
                  helperText={
                    showEndDateErr
                      ? ErrorConstants.END_DATE_IS_REQUIRED
                      : showEndDtGtrErr
                      ? ErrorConstants.END_DATE_GREATER_ERROR
                      : showEndDtInvalidErr
                      ? ErrorConstants.END_DATE_IS_INVALID
                      : showEndDtLessthanBgnDtErr
                      ? ErrorConstants.BEGIN_DT_END_DT_ERROR
                      : null
                  }
                  error={
                    showEndDateErr
                      ? ErrorConstants.END_DATE_IS_REQUIRED
                      : showEndDtGtrErr
                      ? ErrorConstants.END_DATE_GREATER_ERROR
                      : showEndDtInvalidErr
                      ? ErrorConstants.END_DATE_IS_INVALID
                      : showEndDtLessthanBgnDtErr
                      ? ErrorConstants.BEGIN_DT_END_DT_ERROR
                      : null
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>

            <div className="mui-custom-form input-md">
              <TextField
                id="hasBeenProcessed"
                fullWidth
                label="Has been processed"
                onChange={handleChange("hasBeenProcessed")}
                disabled
                value={values.hasBeenProcessed}
                placeholder=""
                inputProps={{ maxlength: 3 }}
                InputLabelProps={{
                  shrink: true
                }}
              >
              </TextField>
            </div>
          </div>
          <div className="from-wrapper">
          <div className="mui-custom-form with-textarea override-width-95">
              <Form.Group>
                <Form.Label className="MuiFormLabel-root small-label">
                  Description
                </Form.Label>
                <Form.Control
                  aria-label="form-controll-01"
                  as="textarea"
                  rows="4"
                  name="text"
                  value={values.description}
                  disabled={values.hasBeenProcessed === 'Y'}
                  onChange={handleChange("description")}
                  maxLength={totalCharacters}
                  style={{ width: "100%" }}
                />
              </Form.Group>
              <div
                  style={{
                    border: "1px solid rgb(169, 169, 169)",
                    borderRadius: ".25rem",
                    paddingLeft: "5px",
                    width: "200px",
                    alignText: "center"
                  }}
              >
                Characters Remaining:{values.description ? (100 - values.description?.length) : 100}
              </div>
            </div>
          </div>
        </form>
        <div className="tab-panelbody">
          <div className="tab-holder mb-3 mt-2">
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                aria-label="simple tabs example"
              >
                {<Tab label="Payment Criteria" />}
                <Tab label="Attachments" />
                {<Tab label="Notes" />}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {value === 0 && (
                <div className="tab-holder p-0">
                  <PaymentCriteriaDetails
                    seteditScreen={seteditScreen}
                    editScreen={editScreen}
                    typeList={typeList}
                    payeeTypeList={payeeTypeList}
                    valueList={valueList}
                    pValueList={pValueList}
                    setPaymentReqCriteriaSet={setPaymentReqCriteriaSet}
                    setReasonDataValues={setReasonDataValues}
                    reasonDataValues={reasonDataValues}
                    setPayeeDataValues={setPayeeDataValues}
                    paymentReqCriteriaSet={paymentReqCriteriaSet}
                    tableData={paymentReqCriteriaSet || []}
                    logInUserID={logInUserID}
                    setReasonTable={setReasonTable}
                    reasonTable={reasonTable}
                    setPayeeTable={setPayeeTable}
                    payeeTable={payeeTable}
                    setDeleteReqCriteriaSet={setDeleteReqCriteriaSet}
                    deleteReqCriteriaSet={deleteReqCriteriaSet}
                    setDeletePayeeCriteriaSet={setDeletePayeeCriteriaSet}
                    deletePayeeCriteriaSet={deletePayeeCriteriaSet}
                    payeeDataValues={payeeDataValues}
                    paymentCriteriaResetAction={paymentCriteriaResetAction}
                    setDeleteReason={setDeleteReason}
                    setDeletePayee={setDeletePayee}
                    setSystemSuccesMessages={setSystemSuccesMessages}
                    systemSuccessMessages={systemSuccessMessages}
                    seterrorMessages={seterrorMessages}
                    errorMessages={errorMessages}
                    setReasonErrorMessages={setReasonErrorMessages}
                    setPayeeErrorMessages={setPayeeErrorMessages}
                    setValues={setValues}
                    values={values}
                    addFormValidation={addFormValidation}
                    setAddFormShowErr={setAddFormShowErr}
                    errors={[showBgnDtErr, showEndDateErr]}
                    focusCheck={focusCheck}
                    setFocusCheck={setFocusCheck}
                    setAllowNavigation={setAllowNavigation}
                    dropdowns={addDropdowns}
                  />
                  {showLogTable ? (
                    <div id="audit-table">
                      <AuditTableComponent
                        print
                        auditRowData={auditRowData}
                        setAuditRowData={setAuditRowData}
                        setShowLogTable={setShowLogTable}
                      />{" "}
                    </div>
                  ) : null}
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {value === 1 && (
                <div className="tab-holder p-0">
                  <Attachments
                    disableAttachments={disableNotes}
                    attachmentFile={attachmentFile}
                    setAttachmentFile={setAttachmentFile}
                    attachmentsTableData={attachmentsTableData || []}
                    setAttachmentsTableData={setAttachmentsTableData}
                    parentErrorMesage={seterrorMessages}
                    setAllowNavigation={setAllowNavigation}
                    setSystemSuccesMessages={clearSuccessErrorMsgs}
                    userInquiryPrivileges={userInquiryPrivileges}
                    setFocusCheck={setFocusCheck}
                    values={values}
                  />
                  {showLogTable ? (
                    <div id="audit-table">
                      <AuditTableComponent
                        print
                        auditRowData={auditRowData}
                        setAuditRowData={setAuditRowData}
                        setShowLogTable={setShowLogTable}
                      />{" "}
                    </div>
                  ) : null}
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {value === 2 && (
                <div className="tab-holder p-0">
                  <Notes
                    addNotes={addNotes}
                    disableNotes={disableNotes}
                    notesTableData={notesTableData || []}
                    noteSetListInput={noteSetListInput}
                    setNoteSetListInput={setNoteSetListInput}
                    usageTypeCodeData={usageTypeCodeData}
                    editNoteData={editNoteData}
                    setEditNoteData={setEditNoteData}
                    disableFields={disableFields}
                    setSuccessMessages={clearSuccessErrorMsgs}
                    isNotesTable={true}
                    setFocusCheck={setFocusCheck}
                    setErrorMessages={seterrorMessages}
                    userInquiryPrivileges={userInquiryPrivileges}
                    paymentValues={values}
                  />
                </div>
              )}
            </TabPanel>
          </div>
        </div>
      </div>
      <Footer print />
      <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
      </Dialog>
    </div>
  );
}
