/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const DESCRIPTION_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const NO_RECORD_FOUND = 'No record is found. Please review your search criteria.';
export const SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const FUNDCODE_ERROR = 'A minimum of 2 characters must be entered for a starts with search.';
export const TOTAL_PERCENTAGE_ERROR = 'The total of all Percentage fields per fund code must total to 100%.';
export const ADD_LOB_ERROR = 'LOB is required.';
export const ADD_FISCAL_YEAR_ERROR = 'State Fiscal Year is required.';
export const ADD_BUDGET_DESCRIPTION_ERROR = 'Budget Description is required.';
export const ADD_GL_ACCOUNTING_UNIT_ERROR = 'GL Accounting Unit is required.';
export const ADD_GL_ACCOUNT_ERROR = 'GL Account is required.';
export const ADD_AGENCY_ERROR = 'Agency is required.';
export const ADD_GL_COMPANY_ERROR = 'GL Company is required.';
export const ADD_AC_ACCOUNT_CATEGORY_ERROR = 'AC Account Category is required.';
export const ADD_AC_ACTIVITY_ERROR = 'AC Activity is required.';
export const ADD_FUND_CODE_ERROR = 'Fund Code is required.';
export const BEGINDATE_ENDDATE_MATCH = 'Funding Source Start Date must be less than or equal to Funding Source End Date.';
export const BEGINDATE_ENDDATE_MATCH_FUND_CODE = 'Fund Code Begin Date must be less than or equal to Fund Code End Date.';
export const BEGINDATE_ERROR = 'Begin Date is required.';
export const FCODE_BEGINDATE_INVALID_ERROR = 'Fund Code Begin date must be a valid date.';
export const FSOURCE_BEGINDATE_INVALID_ERROR = 'Funding Source Begin date must be a valid date.';
export const ENDDATE_ERROR = 'End Date is required.';
export const FCODE_ENDDATE_INVALID_ERROR = 'Fund Code End date must be a valid date.';
export const FSOURCE_ENDDATE_INVALID_ERROR = 'Funding Source End date must be a valid date.';
export const FCODE_BEGINDATE_IN_SELECTED_FISCALYEAR = 'Fund Code Begin Date should fall in Selected Fiscal Year.';
export const FCODE_ENDDATE_IN_SELECTED_FISCALYEAR = 'Fund Code End Date should fall in Selected Fiscal Year.';
export const FSOUCE_STARTDATE_GREATEROREQALTO_FCODE_STARTDATE = 'Funding Source Start Date must be greater than or equal to the current system date and equal to or greater than its associated Fund Code\'s Start Date';
export const FSOUCE_ENDDATE_LESSTHANOREQALTO_FCODE_ENDDATE = 'Funding Source End Date must be equal to or less than its associated Fund Code End Date.';
export const FSOURCE_OVERLAPPING_ERROR = 'There cannot be overlaps between date spans across the detail rows.';
export const FUND_CODE_COVERAGE_ERROR = 'Funding Source records must cover entire Fund Code date span.';
export const BEGIN_DATE_OVERLAPPING = 'Exact duplicate rows not allowed.';
export const ADD_FEDERAL_ERROR = 'Federal is required.';
export const ADD_STATE_ERROR = 'State is required.';
export const ADD_COUNTY_ERROR = 'County is required.';
export const ADD_OTHER_ERROR = 'Other is required.';
export const ADD_BUDGET_AMOUNT_ERROR = 'Budget Amount is required.';
export const BUDGET_AMOUNT_FORMAT_ERROR = 'Please enter the data in correct format.';
export const ADD_FUNDING_SOURCE_EMPTY_ERROR = 'At least one Funding Source must be added per Fund Code record.';
export const DATABASE_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const DECIMAL_REGEX = /^[0-9]{1,11}([.]{0,1}[0-9]{0,2})?$/g;
export const FUNDCODE_COS_COMBINATION = 'The Fund Code/COS combination with overlapping dates exists.';
export const DATA_INVALID = 'Please enter the data in correct format.';
export const ADJUST_LESS = 'Adjusted Authorized Amount must be greater than or equal to zero.';
export const BUDGET_LESS = 'Budget Amount must be greater than or equal to zero';
export const ONE_BUDGETACTIVE_RECORD = 'At least one Fund Code must be added per Budget record.';
export const FCODE_BEGINDATE_IS_PASTDATE = 'Fund Code Begin Date cannot be less than current/system date.';
