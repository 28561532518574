/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { systemParameterRowClickAction } from '../../Store/Actions/TextManagement/TextManagementActions';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCellsMultiRecord = [
  { id: 'eombFromCode', disablePadding: false, label: 'From Code' },
  { id: 'eombThruCode', disablePadding: false, label: 'Thru Code' },
  { id: 'eombDesc', disablePadding: false, label: 'Text', isText: true }
];
const headCellsSingleRecord = [
  { id: 'eombFromCode', disablePadding: false, label: 'From Code' },
  { id: 'eombThruCode', disablePadding: false, label: 'Thru Code' },
  { id: 'eombDesc', disablePadding: false, label: 'Text', isText: true }
];

function RevenueResult (props) {
  const dispatch = useDispatch();
  const onRowClick = values => dispatch(systemParameterRowClickAction(values));
  const payloadViewData = useSelector(state => state.appConfigState.systemParameterState.rowSearchSysParam);
  const [redirect, setRedirect] = React.useState(false);
  const payloadRecordData = payloadViewData || (props.tableData.length === 1 ? props.tableData : null);
  const payloadData = Array.isArray(payloadRecordData) ? payloadRecordData[0] : payloadRecordData;

  if (redirect === 1) {
    if (payloadData !== undefined && payloadData !== null) {
      props.history.push({
        pathname: '/TextUpdate',
        state: { row: payloadData, TextType: 'EOMB', EombType: 'revenue' }
      });
    }
  }

  const formatSearchCriteria = (row) => {
    return ({
      eombProcedureTypeCode: 'R',
      lobCode: [row.lobCode],
      eombFromCode: row.eombFromCode,
      eombthruCode: row.eombTromCode,
      eombSequenceNumber: row.eombSequenceNumber
    });
  };
  const editRow = row => event => {
    const searchCriteria = {
      eombProcedureTypeCode: 'R',
      lobCode: [row.lobCode || row.lob],
      eombFromCode: row.eombFromCode,
      eombthruCode: row.eombTromCode,
      eombSequenceNumber: row.eombSequenceNumber
    };
    onRowClick(searchCriteria);
    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };

  const headCells = props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;
  return (
    <TableComponent pathTo="/TextUpdate?data="
      formatSearchCriteria={formatSearchCriteria} isSearch={true} headCells={headCells} tableData={props.tableData ? props.tableData : []} onTableRowClick={editRow} defaultSortColumn={headCells[0].id} />
  );
}
export default withRouter((RevenueResult));
