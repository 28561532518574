/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useState, useRef } from 'react';
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../../../SharedModules/TabPanel/TabPanel";
import { getLongDescription } from '../../../SharedModules/CommonUtilities/commonUtilities';
import TableComponent from "../../../SharedModules/Table/Table";
import Footer from "../../../SharedModules/Layout/footer";
import * as AppConstants from "../../../SharedModules/AppConstants";
import { getPayeeIdType } from "../../../Modules/AccountReceivables/Store/Actions/accountReceivable/accountReceivableActions";
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    getDropdownDataAction,
    getActivityTypeDataAction,
    resetSearchRecords
} from "../Store/Actions/DataUploadActions";
import dropdownCriteria from "./DataUploadView.json";
import activityCriteria from "./ValidValueForActivity.json";
import * as ErrConstants from "./ErrorConstants";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import numeral from "numeral";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';

function FinancialUploadView (props) {
    const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
    const dispatch = useDispatch();
    const toPrintRef = useRef();
    const propsData = props.history.location?.state;
    const financialStatusDesc = Array.isArray(propsData?.fileStatusRecord) ? propsData.fileStatusRecord[0].description : null;

    const [fileUploadDetails, setFileUploadDetails] = useState(props.history.location.state ? props.history.location.state.dataFromSearch ? props.history.location.state.dataFromSearch : null : null);
    const [detailsTable, setDetailsTable] = useState(props.history.location.state ? props.history.location.state.dataFromSearch ? props.history.location.state.dataFromSearch.ifaceTrackDetailsVOs ? props.history.location.state.dataFromSearch.ifaceTrackDetailsVOs : [] : [] : []);
    const headCellsErrorTable = [
        {
            id: "errorCode",
            numeric: false,
            disablePadding: true,
            label: "Error Code",
            width: "25%"
        },
        {
            id: "errorDesc",
            numeric: false,
            disablePadding: true,
            label: "Error Description",
            width: "75%"
        }
    ];
    const headCells = [
        {
            id: "lobDesc",
            numeric: false,
            disablePadding: true,
            label: "LOB",
            width: "10%"
        },
        {
            id: "txnTypeCodeDesc",
            numeric: false,
            disablePadding: true,
            label: "Transaction Type",
            width: "14%"
        },
        {
            id: "reasonCodeDesc",
            numeric: false,
            disablePadding: true,
            label: "Reason Code",
            width: "12%",
            isTitle: "reasonCodeLongDesc",
            isTooltip: true
        },
        {
            id: "payeeTypeDesc",
            numeric: false,
            disablePadding: true,
            label: "Payee Type",
            width: "12%"
        },
        {
            id: "entityId",
            numeric: false,
            disablePadding: true,
            label: "Payee ID",
            width: "12%"
        },
        {
            id: "originalAMount",
            numeric: false,
            disablePadding: true,
            label: "Original Amount",
            width: "15%",
            isBalance: true
        },
        {
            id: "financeIfaceRecordStatusDesc",
            numeric: false,
            disablePadding: true,
            label: "Record Status",
            width: "10%"
        },
        {
            id: "fcnNumber",
            numeric: false,
            disablePadding: true,
            label: "FCN",
            width: "16%",
            isRedirectable: true,
            openInNewTab: true
        }
    ];
    const [filterValues, setFilterValues] = React.useState({
        lob: DEFAULT_DD_VALUE,
        transactionType: DEFAULT_DD_VALUE,
        reasonCode: DEFAULT_DD_VALUE,
        longDesc: DEFAULT_DD_VALUE,
        recordStatus: DEFAULT_DD_VALUE,
        payeeType: DEFAULT_DD_VALUE,
        payeeID: '',
        originalAmount: '',
        fcn: ''
    });

    const [showAdditionalDetails1, setShowAdditionalDetails1] = React.useState(
        false
    );

    let payeeIdTypeDropdown = useSelector(
        state => state.accountReceivableState.payeeIdTypeDropdown
    );
    useEffect(() => {
        if (payeeIdTypeDropdown && payeeIdTypeDropdown.payeeIdTypeCode) {
            setPayeeTypeCodeDD(payeeIdTypeDropdown.payeeIdTypeCode);
        }
    }, [payeeIdTypeDropdown]);

    const [originalAmountError, setOriginalAmountError] = useState('');

    const handleSystemListFilterChanges = name => event => {
        let longDesc = null;
        if (name === 'reasonCode') {
            longDesc = getLongDescription(reasonCodeDD1.concat(reasonCodeDD2.concat(reasonCodeDD3.concat(reasonCodeDD4))), event.target.value);
        }
        setFilterValues({
            ...filterValues,
            [name]: event.target.value,
            longDesc: longDesc || DEFAULT_DD_VALUE
        });
    };
    const [errorMessages, setErrorMessages] = useState([]);
    const applyFilter = () => {
        let error = '';
        if (filterValues.originalAmount) {
            if (
                filterValues.originalAmount
                    .replace(/,/g, '')
                    .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}?$/) === null
            ) {
                error = ErrConstants.INVALID_AMOUNT;
                setOriginalAmountError(ErrConstants.INVALID_AMOUNT);
                setErrorMessages([ErrConstants.INVALID_AMOUNT]);
            } else {
                if (
                    !(
                        filterValues.originalAmount
                            .replace(/,/g, '')
                            .match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null
                    )
                ) {
                    error = ErrConstants.INVALID_AMOUNT;
                    setOriginalAmountError(ErrConstants.INVALID_AMOUNT);
                    setErrorMessages([ErrConstants.INVALID_AMOUNT]);
                } else {
                    setOriginalAmountError('');
                    setErrorMessages([]);
                }
            }
        }
        if (!error) {
            setAdditionalDetails(null);
            const tempData = JSON.parse(JSON.stringify(fileUploadDetails.ifaceTrackDetailsVOs));
            const tempArray = [];
            if (filterValues.lob !== DEFAULT_DD_VALUE ||
                filterValues.transactionType !== DEFAULT_DD_VALUE ||
                filterValues.reasonCode !== DEFAULT_DD_VALUE ||
                filterValues.recordStatus !== DEFAULT_DD_VALUE ||
                filterValues.payeeType !== DEFAULT_DD_VALUE ||
                filterValues.payeeID || filterValues.fcn || filterValues.originalAmount) {
                tempData.map((values) => {
                    let flag = true;
                    if (filterValues.lob !== DEFAULT_DD_VALUE) {
                        if (values.lobCode === filterValues.lob) {
                            flag = true;
                        } else {
                            flag = false;
                        }
                    }
                    if (filterValues.transactionType !== DEFAULT_DD_VALUE) {
                        if (values.txnTypeCode === filterValues.transactionType) {
                            flag = flag && true;
                        } else {
                            flag = false;
                        }
                    }
                    if (filterValues.reasonCode !== DEFAULT_DD_VALUE) {
                        if (values.reasonCode === filterValues.reasonCode) {
                            flag = flag && true;
                        } else {
                            flag = false;
                        }
                    }
                    if (filterValues.recordStatus !== DEFAULT_DD_VALUE) {
                        if (values.financeIfaceRecordStatus === filterValues.recordStatus) {
                            flag = flag && true;
                        } else {
                            flag = false;
                        }
                    }
                    if (filterValues.payeeType !== DEFAULT_DD_VALUE) {
                        if (values.commonEntityTypeCode === filterValues.payeeType) {
                            flag = flag && true;
                        } else {
                            flag = false;
                        }
                    }
                    if (filterValues.payeeID) {
                        if (values.entityId === filterValues.payeeID) {
                            flag = flag && true;
                        } else {
                            flag = false;
                        }
                    }
                    if (filterValues.originalAmount) {
                        if (values.originalAMount) {
                            if (numeral(values.originalAMount.toString().replace(/,/g, '')).format('0.00') === numeral(filterValues.originalAmount
                                .replace(/,/g, '')).format('0.00')) {
                                flag = flag && true;
                            } else {
                                flag = false;
                            }
                        } else {
                            flag = false;
                        }
                    }
                    if (filterValues.fcn) {
                        if (values.fcnNumber === filterValues.fcn) {
                            flag = flag && true;
                        } else {
                            flag = false;
                        }
                    }
                    if (flag) {
                        tempArray.push(values);
                    };
                });
                setDetailsTable(JSON.parse(JSON.stringify(tempArray)));
            } else {
                setDetailsTable(JSON.parse(JSON.stringify(tempData)));
            }
        }
    };
    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const [additionalDetails, setAdditionalDetails] = useState(null);

    const editRow = row => event => {
        setAdditionalDetails(row);
        if (row.commonEntityTypeCode) {
            dispatch(getPayeeIdType(row.commonEntityTypeCode));
        }
    };

    const DropDownAPI = dropdownValues =>
        dispatch(getDropdownDataAction(dropdownValues));
    const dropDownData = useSelector(state => state.dataUploadState.dropdownData);
    const activityDropdown = useSelector(state => state.dataUploadState.activityDropDown);

    const [lobDD, setLobDD] = useState([]);
    const [transactionTypeDD, setTransactionTypeDD] = useState([]);
    const [reasonCodeDD, setReasonCodeDD] = useState([]);
    const [reasonCodeDD1, setReasonCodeDD1] = useState([]);
    const [reasonCodeDD2, setReasonCodeDD2] = useState([]);
    const [reasonCodeDD3, setReasonCodeDD3] = useState([]);
    const [reasonCodeDD4, setReasonCodeDD4] = useState([]);
    const [payeeTypeDD, setPayeeTypeDD] = useState([]);
    const [payeeTypeCodeDD, setPayeeTypeCodeDD] = useState([]);
    const [activityTypeDD, setActivityTypeDD] = useState([]);
    const [fileStatusDD, setFileStatusDD] = useState([]);
    const entityIDTypeCode = additionalDetails && additionalDetails.payeeTypeDesc?.split('-')[0];
    const payeeIDTypeDropdown = entityIDTypeCode === 'P' ? 'F1#P_ALT_ID_TY_CD'
        : entityIDTypeCode === 'M' ? 'F1#B_ALT_ID_TY_CD'
        : entityIDTypeCode === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD'
        : entityIDTypeCode === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null;

    useEffect(() => {
        DropDownAPI(dropdownCriteria);
        dispatch(getActivityTypeDataAction(activityCriteria));
        payeeIdTypeDropdown = [];
    }, []);

    useEffect(() => {
        if (activityDropdown && activityDropdown.listObj && activityDropdown.listObj['Financial#F_ACTV_TY_CD']) {
            setActivityTypeDD(activityDropdown.listObj['Financial#F_ACTV_TY_CD']);
        }
    }, [activityDropdown]);

    useEffect(() => {
        if (dropDownData && dropDownData.listObj) {
            if (dropDownData.listObj["Reference#1019"]) {
                setLobDD(dropDownData.listObj["Reference#1019"]);
            }
            if (dropDownData.listObj["Financial#6018"]) {
                setFileStatusDD(dropDownData.listObj["Financial#6018"]);
            }
            if (dropDownData.listObj["Financial#6019"]) {
                setTransactionTypeDD(dropDownData.listObj["Financial#6019"]);
            }
            if (dropDownData.listObj["Financial#3006"]) {
                setReasonCodeDD1(dropDownData.listObj["Financial#3006"]);
            }
            if (dropDownData.listObj["Financial#6010"]) {
                setReasonCodeDD2(dropDownData.listObj["Financial#6010"]);
            }
            if (dropDownData.listObj["Financial#5"]) {
                setReasonCodeDD3(dropDownData.listObj["Financial#5"]);
            }
            if (dropDownData.listObj["Financial#7"]) {
                setReasonCodeDD4(dropDownData.listObj["Financial#7"]);
            }
            if (dropDownData.listObj["General#6002"]) {
                setPayeeTypeDD(dropDownData.listObj["General#6002"]);
            }
            descriptionSetter(dropDownData.listObj["Reference#1019"], dropDownData.listObj["Financial#6018"],
                dropDownData.listObj["Financial#6019"], dropDownData.listObj["Financial#3006"], dropDownData.listObj["Financial#6010"],
                dropDownData.listObj["Financial#5"], dropDownData.listObj["Financial#7"], dropDownData.listObj["General#6002"]);
        }
    }, [dropDownData]);

    const descriptionSetter = (lobDD, fileStatusDD, transactionTypeDD, reasonCodeDD1, reasonCodeDD2, reasonCodeDD3, reasonCodeDD4, payeeTypeDD) => {
        const tempData = JSON.parse(JSON.stringify(fileUploadDetails));
        if (tempData && tempData.ifaceTrackDetailsVOs) {
            tempData.ifaceTrackDetailsVOs.map((values) => {
                values.txnTypeCodeDesc = valueForDescription(values.txnTypeCode, transactionTypeDD) || values.txnTypeCode;
                values.financeIfaceRecordStatusDesc = valueForDescription(values.financeIfaceRecordStatus, fileStatusDD) || values.financeIfaceRecordStatus;
                values.reasonCodeDesc = valueForDescription(values.reasonCode, reasonCodeDD1) || values.reasonCode;
                values.reasonCodeDesc = valueForDescription(values.reasonCodeDesc, reasonCodeDD2) || values.reasonCodeDesc;
                values.reasonCodeDesc = valueForDescription(values.reasonCodeDesc, reasonCodeDD3) || values.reasonCodeDesc;
                values.reasonCodeDesc = valueForDescription(values.reasonCodeDesc, reasonCodeDD4) || values.reasonCodeDesc;
                let reasonCodeArray = [];
                if (reasonCodeDD1 && reasonCodeDD2 && reasonCodeDD3 && reasonCodeDD4) {
                    reasonCodeArray = reasonCodeDD1.concat(reasonCodeDD2.concat(reasonCodeDD3.concat(reasonCodeDD4)));
                    setReasonCodeDD(reasonCodeArray.sort((a, b) => a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1));
                }
                values.reasonCodeLongDesc = getLongDescription(reasonCodeArray, values.reasonCode);
                values.lobDesc = valueForDescription(values.lobCode, lobDD) || values.lobCode;
                values.commonEntityTypeCodeDesc = valueForDescription(values.commonEntityTypeCode, payeeTypeDD) || values.commonEntityTypeCode;
                if (values.activityTypeCode === 'RC') {
                    values.activityType = 'Receipt';
                }
                if (values.activityTypeCode === 'PO') {
                    values.activityType = 'Payout';
                }
                if (values.activityTypeCode === 'AP' || values.activityTypeCode === 'GL' || values.activityTypeCode === 'RR' ||
                    values.activityTypeCode === 'WR' || values.activityTypeCode === 'WO' || values.activityTypeCode === 'RT' ||
                    values.activityTypeCode === 'RA' || values.activityTypeCode === 'WL' || values.activityTypeCode === 'CR') {
                    values.activityType = 'AccountR';
                }
            });
        }
        setFileUploadDetails(JSON.parse(JSON.stringify(tempData)));
        setDetailsTable(tempData.ifaceTrackDetailsVOs);
    };

    const valueForDescription = (value, Dropdown) => {
        let desc = value;
        if (Dropdown) {
            Dropdown.map((values) => {
                if (values.code === value) {
                    desc = values.description;
                }
            });
        }
        return desc;
    };
    const getTableData = d => {
        if (d && d.length) {
          let tData = JSON.stringify(d);
          tData = JSON.parse(tData);
          tData.map((each, index) => {
            if (each !== null) {
              each.index = index;
              const reasonCode =
                propsData.dropdowns &&
                propsData.dropdowns['F1#F_RSN_CD'] &&
                propsData.dropdowns['F1#F_RSN_CD'].filter(
                  value => value.code === each.reasonCode
                );
              each.reasonCodeDesc =
              reasonCode && reasonCode.length > 0
                  ? reasonCode[0].description
                  : '';
            }
        // getting respective record from dropdown list which also has desc
            const transTypeRecord =
                propsData.dropdowns &&
                propsData.dropdowns['F1#F_TXN_TY_CD'] &&
                propsData.dropdowns['F1#F_TXN_TY_CD'].filter(
                  value => value.code === each.txnTypeCode
                );
        // updating the transaction record with desc
            each.txnTypeCodeDesc =
                 transTypeRecord && transTypeRecord.length > 0
                ? transTypeRecord[0].description
                : each.txnTypeCodeDesc ? each.txnTypeCodeDesc : '';

            const transStatusRecord =
                propsData.dropdowns &&
                propsData.dropdowns['F1#G_BATCH_TXN_STAT_CD'] &&
                propsData.dropdowns['F1#G_BATCH_TXN_STAT_CD'].filter(
                  value => value.code === each.financeIfaceRecordStatus
                );
        // updating the transaction status record with desc
            each.financeIfaceRecordStatusDesc =
                 transStatusRecord && transStatusRecord.length > 0
                ? transStatusRecord[0].description
                : each.financeIfaceRecordStatusDesc ? each.financeIfaceRecordStatusDesc : '';
        // getting respective record from dropdown list which also has desc
          const payeeType =
          propsData.dropdowns &&
          propsData.dropdowns['G1#G_CMN_ENTY_TY_CD'] &&
          propsData.dropdowns['G1#G_CMN_ENTY_TY_CD'].filter(
            value => value.code === each.commonEntityTypeCode
          );
        // updating the record with desc
        each.payeeTypeDesc =
          payeeType && payeeType.length > 0
            ? payeeType[0].description
            : each.payeeType ? each.payeeType : '';
            return each;
          });
          return tData;
        }
        return [];
      };

const majorCancel = () => {
    props.history.push({
        pathname: '/FinancialUpload',
        state: {
            searchCriteria: propsData.searchCriteria,
            isFromView: true
        }
    });

    dispatch(resetSearchRecords());
};

    return (
        <div className="pos-relative w-100 h-100">
            <div className="tabs-container" ref={ toPrintRef }>

                <ErrorMessages
                    errorMessages={ errorMessages }
                />
                <div className="tab-header">
                    <div className="tab-heading float-left">Financial Upload Transaction Details </div>
                    <div className="float-right  mt-1 pt-1 hide-on-print">
                        {window.location.pathname === '/FinancialUploadView' && (
              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>
            )}
                    </div>
                </div>
                <div className="tab-body">
                    <form autoComplete="off">
                        <div className="form-wrapper">

                            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                                <div
                                    className="mui-custom-form with-date md" // style={{ marginLeft: '54px' }}
                                >

                                    <KeyboardDatePicker
                                        maxDate={ Date.parse("31 Dec 9999 00:00:00 GMT") }
                                        id="date-picker-begindate"
                                        fullWidth
                                        disabled
                                        label="Uploaded Date"
                                        InputLabelProps={ {
                                            shrink: true
                                        } }
                                        placeholder="mm/dd/yyyy"
                                        format="MM/dd/yyyy"
                                        value={ fileUploadDetails ? fileUploadDetails.recievedDateStr : null }
                                        KeyboardButtonProps={ {
                                            "aria-label": "change date"
                                        } }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>

                            <div
                                className="mui-custom-form input-md" // style={{ marginLeft: '54px' }}
                            >
                                <TextField
                                    id="standard-listNumber1"
                                    fullWidth
                                    InputProps={ {
                                        readOnly: true,
                                        className: "Mui-disabled"
                                    } }
                                    label="File Control Number"
                                    value={ fileUploadDetails ? fileUploadDetails.fileControlNum : null }
                                    placeholder=""
                                    InputLabelProps={ {
                                        shrink: true
                                    } }
                                    inputProps={ { maxLength: 8 } }
                                />
                            </div>
                            <div
                                className="mui-custom-form input-md" // style={{ marginLeft: '54px' }}
                            >
                                <TextField
                                    id="standard-listNumber2"
                                    fullWidth
                                    InputProps={ {
                                        readOnly: true,
                                        className: "Mui-disabled"
                                    } }
                                    label="File Control Sequence"
                                    value={ fileUploadDetails ? fileUploadDetails.fileControlTime : null }
                                    placeholder=""
                                    InputLabelProps={ {
                                        shrink: true
                                    } }
                                    inputProps={ { maxLength: 6 } }
                                />
                            </div>
                            <div
                                className="mui-custom-form input-md" // style={{ marginLeft: '54px' }}
                            >
                                <TextField
                                    id="standard-listNumber3"
                                    fullWidth
                                    InputProps={ {
                                        readOnly: true,
                                        className: "Mui-disabled"
                                    } }
                                    label="Total Records"
                                    value={ fileUploadDetails ? fileUploadDetails.totalRecordsNum : null }
                                    placeholder=""
                                    InputLabelProps={ {
                                        shrink: true
                                    } }
                                    inputProps={ { maxLength: 10 } }
                                />
                            </div>
                        </div>
                        <div className="form-wrapper">
                                <NativeDropDown
                                    id="standard-listNumber4"
                                    disabled
                                    InputProps={ {
                                        readOnly: true,
                                        className: "Mui-disabled"
                                    } }
                                    label="File Status"
                                    value={financialStatusDesc}
                                    placeholder=""
                                    InputLabelProps={ {
                                        shrink: true
                                    } }
                                    inputProps={ { maxLength: 1 } }
                                    dropdownList={[<option
                                        selected
                                        key={ financialStatusDesc }
                                        value={ financialStatusDesc }
                                    >
                                        { financialStatusDesc }
                                    </option>]}
                                />
                            <div
                                className="mui-custom-form override-width-45" // style={{ marginLeft: '54px' }}
                            >
                                <TextField
                                    id="standard-listNumber5"
                                    fullWidth
                                    InputProps={ {
                                        readOnly: true,
                                        className: "Mui-disabled"
                                    } }
                                    label="File Name"
                                    value={ fileUploadDetails ? fileUploadDetails.fileName : null }
                                    placeholder=""
                                    InputLabelProps={ {
                                        shrink: true
                                    } }
                                    inputProps={ { maxLength: 150 } }
                                />
                            </div>
                        </div>
                        <div className="form-wrapper">
                            <div
                                className="mui-custom-form input-md" // style={{ marginLeft: '54px' }}
                            >
                                <TextField
                                    id="standard-listNumber6"
                                    fullWidth
                                    InputProps={ {
                                        readOnly: true,
                                        className: "Mui-disabled"
                                    } }
                                    label="Error Code"
                                    value={ fileUploadDetails ? fileUploadDetails.errorCode : null }
                                    placeholder=""
                                    InputLabelProps={ {
                                        shrink: true
                                    } }
                                    inputProps={ { maxLength: 8 } }
                                />
                            </div>
                            <div
                                className="mui-custom-form override-width-45" // style={{ marginLeft: '54px' }}
                            >
                                <TextField
                                    id="standard-listNumber"
                                    fullWidth
                                    InputProps={ {
                                        readOnly: true,
                                        className: "Mui-disabled"
                                    } }
                                    label="Error Description"
                                    value={ fileUploadDetails ? fileUploadDetails.errorDesc : null }
                                    placeholder=""
                                    InputLabelProps={ {
                                        shrink: true
                                    } }
                                    inputProps={ { maxLength: 150 } }
                                />
                            </div>
                        </div>
                    </form>

                    <div></div>
                    <div className="tab-panelbody m-0">
                        <div className="tab-holder">
                            <AppBar position="static">
                                <Tabs
                                    value={ value }
                                    onChange={ handleChangeTabs }
                                    aria-label="simple tabs example"
                                >
                                    <Tab label="Details" />
                                </Tabs>
                            </AppBar>
                            <TabPanel
                                value={ value }
                                index={ 0 }
                            >
                                <div className="tab-holder p-0">
                                    { value === 0 && (
                                        <>
                                            <div
                                                className="rounded-top"
                                                onClick={ () =>
                                                    setShowAdditionalDetails1(!showAdditionalDetails1)
                                                }
												data-testid="clickable-div"
                                                style={ {
                                                    backgroundColor: "#274463",
                                                    color: "#fff",
                                                    padding: "10px",
                                                    margin: "10px 0px",
                                                    fontWeight: "500",
                                                    fontSize: "14px"
                                                } }
                                            >
                                                Display Filters
                                                    <div className="pull-right">
                                                    { showAdditionalDetails1 ? (
                                                        <span>
                                                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                                                        </span>
                                                    ) : (
                                                            <span>
                                                                <i
                                                                    className="fa fa-angle-down"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        ) }
                                                </div>
                                            </div>
                                        </>
                                    ) }
                                    { (() => {
                                        if (showAdditionalDetails1) {
                                            return (
                                                <form autoComplete="off">
                                                    <div className="form-wrapper">
                                                        <div className="form-wrapper">
                                                                <NativeDropDown
                                                                    id="standard-select-functionalArea14"
                                                                    label="LOB"
                                                                    value={ filterValues.lob }
                                                                    onChange={ handleSystemListFilterChanges("lob") }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 3 } }
                                                                    dropdownList={ lobDD
                                                                        ? lobDD.map((item, index) => (
                                                                            <option key={ index } value={ item.code }>
                                                                                { item.description }
                                                                            </option>
                                                                        ))
                                                                        : null }
                                                                />
                                                                <NativeDropDown
                                                                    id="standard-select-13"
                                                                    label="Transaction Type"
                                                                    value={ filterValues.transactionType }
                                                                    onChange={ handleSystemListFilterChanges("transactionType") }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 1 } }
                                                                    dropdownList={ transactionTypeDD
                                                                        ? transactionTypeDD.map((item, index) => (
                                                                            <option key={ index } value={ item.code }>
                                                                                { item.description }
                                                                            </option>
                                                                        ))
                                                                        : null }
                                                                />
                                                                <NativeDropDown
                                                                    id="standard-select-functionalArea12"
                                                                    label="Reason Code"
                                                                    value={ filterValues.reasonCode }
                                                                    onChange={ handleSystemListFilterChanges("reasonCode") }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 3 } }
                                                                    dropdownList={ reasonCodeDD
                                                                        ? reasonCodeDD.map((item, index) => (
                                                                            <option key={ index } value={ item.code }
                                                                            >
                                                                                { item.description }
                                                                            </option>
                                                                        ))
                                                                        : null }
                                                                />
                                                                <NativeDropDown
                                                                    id="standard-select-functionalArea11"
                                                                    label="Record Status"
                                                                    value={ filterValues.recordStatus }
                                                                    onChange={ handleSystemListFilterChanges("recordStatus") }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 1 } }
                                                                    dropdownList={ fileStatusDD
                                                                        ? fileStatusDD.map((item, index) => (
                                                                            <option key={ index } value={ item.code }>
                                                                                { item.description }
                                                                            </option>
                                                                        ))
                                                                        : null }
                                                                />
                                                                <NativeDropDown
                                                                    id="standard-select-functionalArea10"
                                                                    label="Payee Type"
                                                                    value={ filterValues.payeeType }
                                                                    onChange={ handleSystemListFilterChanges("payeeType") }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 2 } }
                                                                    dropdownList = { payeeTypeDD
                                                                        ? payeeTypeDD.map((item, index) => (
                                                                            <option key={ index } value={ item.code }>
                                                                                { item.description }
                                                                            </option>
                                                                        ))
                                                                        : null }
                                                                />
                                                            <div className="mui-custom-form input-md">
                                                                <TextField
                                                                    id="standard-select-functionalArea9"
                                                                    fullWidth
                                                                    label="Payee ID"
                                                                    value={ filterValues.payeeID }
                                                                    onChange={ handleSystemListFilterChanges("payeeID") }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 15 } }
                                                                >
                                                                </TextField>
                                                            </div>
                                                            <div className="mui-custom-form input-md">
                                                                <TextField
                                                                    id="standard-select-functionalArea8"
                                                                    fullWidth
                                                                    label="Original Amount"
                                                                    value={ filterValues.originalAmount }
                                                                    onChange={ handleSystemListFilterChanges("originalAmount") }
                                                                    onBlur={ () => {
                                                                        setFilterValues({
                                                                            ...filterValues, originalAmount: filterValues.originalAmount !== '' ? numeral(filterValues.originalAmount).format('0,0.00') : ''
                                                                        });
                                                                    } }
                                                                    onFocus={ () => {
                                                                        setFilterValues({
                                                                            ...filterValues, originalAmount: filterValues.originalAmount !== '' ? numeral(filterValues.originalAmount).format('0.00') : ''
                                                                        });
                                                                    } }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 12 } }
                                                                    error={ originalAmountError || null }
                                                                    helperText={ originalAmountError || null }
                                                                    InputProps={ {
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'>$</InputAdornment>
                                                                        )
                                                                    } }
                                                                >
                                                                </TextField>
                                                            </div>
                                                            <div className="mui-custom-form input-md">
                                                                <TextField
                                                                    id="standard-select-8"
                                                                    fullWidth
                                                                    label="FCN"
                                                                    value={ filterValues.fcnNumber }
                                                                    onChange={ handleSystemListFilterChanges("fcn") }
                                                                    placeholder=""
                                                                    InputLabelProps={ {
                                                                        shrink: true
                                                                    } }
                                                                    inputProps={ { maxLength: 14 } }
                                                                    onInput={ e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            AppConstants.NUMBER_ONLY_REGEX,
                                                                            ''
                                                                        );
                                                                    } }
                                                                >
                                                                </TextField>
                                                            </div>
                                                        </div>
                                                        <div className="form-wrapper" style={ {
                                                            display: "flex",
                                                            justifyContent: "flex-end"
                                                        } }>
                                                            <div
                                                                className="float-right mr-4 mb-3"
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    className="btn btn-primary"
                                                                    onClick={ () => {
                                                                        applyFilter();
                                                                    } }
                                                                >
                                                                    Filter
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </form>
                                            );
                                        }
                                    })() }
                                </div>

                                <div className="pb-1 tableNoWrap">
                                    <TableComponent
                                        fixedTable
                                        isSearch={ true }
                                        headCells={ headCells }
                                        tableData={getTableData(detailsTable || [])}
                                        onTableRowClick={ editRow }
                                        defaultSortColumn={ headCells[0].id }
                                        order={ "desc" }
                                    />
                                </div>
                                { additionalDetails
                                    ? <div className="tab-holder p-0">
                                        <div className="tab-header mini-tab-header">
                                            <div className="tab-heading float-left">
                                                Additional Details
                                        </div>
                                        </div>
                                        <div>
                                            <form autoComplete="off">
                                                <div className="form-wrapper">
                                                    <div className="mui-custom-form with-select with-select input-md">
                                                        <TextField
                                                            id="standard-select-functionalArea7"
                                                            fullWidth
                                                            disabled
                                                            label="Transaction Type"
                                                            value={ additionalDetails.txnTypeCodeDesc }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 1 } }
                                                        >
                                                        </TextField>
                                                    </div>
                                                        <NativeDropDown
                                                            id="standard-select-functionalArea6"
                                                            disabled
                                                            label="Activity Type"
                                                            value={ additionalDetails.activityTypeCode }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 3 } }
                                                            dropdownList={ activityTypeDD
                                                                ? activityTypeDD.map((item, index) => (
                                                                    <option key={ index } value={ item.code }>
                                                                        { item.description }
                                                                    </option>
                                                                ))
                                                                : null }
                                                        />
                                                    <div className="mui-custom-form with-select with-select input-md">
                                                        <TextField
                                                            id="standard-select-functionalArea5"
                                                            fullWidth
                                                            disabled
                                                            label="Reason Code"
                                                            value={ additionalDetails.reasonCodeDesc }
                                                            title={ additionalDetails.reasonCodeLongDesc }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 3 } }
                                                        >
                                                        </TextField>
                                                    </div>
                                                    <div className="mui-custom-form with-select with-select input-md">
                                                        <TextField
                                                            id="standard-select-functionalArea"
                                                            fullWidth
                                                            disabled
                                                            label="Record Status"
                                                            value={ additionalDetails.financeIfaceRecordStatusDesc }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 1 } }
                                                        >
                                                        </TextField>
                                                    </div>
                                                </div>
                                                <div className="form-wrapper">
                                                    <div className="mui-custom-form with-select with-select input-md">
                                                        <TextField
                                                            id="standard-select-functionalArea4"
                                                            fullWidth
                                                            disabled
                                                            label="Payee Type"
                                                            value={ additionalDetails.payeeTypeDesc }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 2 } }
                                                        >
                                                        </TextField>
                                                    </div>
                                                        <NativeDropDown
                                                            id="standard-select-functionalArea3"
                                                            disabled
                                                            label="Payee ID Type"
                                                            value={ additionalDetails.entityIdTypeCode }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 3 } }
                                                            dropdownList={ propsData.dropdowns
                                                                ? propsData.dropdowns[payeeIDTypeDropdown] && propsData.dropdowns[payeeIDTypeDropdown].map((item, index) => (
                                                                    <option key={ index } value={ item.code }>
                                                                        { item.description }
                                                                    </option>
                                                                ))
                                                                : null }
                                                        />
                                                    <div className="mui-custom-form input-md">
                                                        <TextField
                                                            id="standard-select-functionalArea2"
                                                            fullWidth
                                                            disabled
                                                            label="Payee ID"
                                                            value={ additionalDetails.entityId }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 15 } }
                                                        />
                                                    </div>
                                                    <div className="mui-custom-form input-md">
                                                        <TextField
                                                            id="standard-select-functionalArea1"
                                                            fullWidth
                                                            disabled
                                                            label="Original Amount"
                                                            value={ numeral(additionalDetails.originalAMount).format('0,0.00') }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 12 } }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-wrapper">
                                                    <div className="mui-custom-form input-md">
                                                        <TextField
                                                            id='row-number'
                                                            fullWidth
                                                            disabled
                                                            label="Row number in the file"
                                                            value={ additionalDetails.seqNo }
                                                            placeholder=""
                                                            InputLabelProps={ {
                                                                shrink: true
                                                            } }
                                                            inputProps={ { maxLength: 7 } }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-wrapper">
                                                    <div className="tab-holder pb-1">
                                                        <TableComponent
                                                            fixedTable
                                                            isSearch={ true }
                                                            headCells={ headCellsErrorTable }
                                                            tableData={ additionalDetails.ifaceTrackErrorDetailsVOs || [] }
                                                            defaultSortColumn={ headCells[0].id }
                                                            order={ "desc" }
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    : null }

                            </TabPanel>
                        </div>
                    </div>
                </div>
                <Footer print />
            </div >
        </div >
    );
}
export default withRouter(FinancialUploadView);
