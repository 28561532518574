/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { providerNoticeRowClickAction } from '../../Store/Actions/TextManagement/TextManagementActions';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCells = [
  {
    id: 'providerTypeDesc',
    disablePadding: false,
    label: 'Provider Type',
    width: '18%'
  },
  {
    id: 'providerSpecialityDesc',
    disablePadding: false,
    label: 'Provider Specialty',
    width: '18%'
  },
  {
    id: 'beginDate',
    disablePadding: false,
    label: 'Begin Date',
    width: '12%',
    isDate: true
  },
  {
    id: 'endDate',
    disablePadding: false,
    label: 'End Date',
    width: '12%',
    isDate: true
  },
  {
    id: 'providerNoticeText',
    disablePadding: false,
    label: 'Text',
    width: '28%',
    isText: true
  },
  {
    id: 'voidDate',
    disablePadding: false,
    label: 'Void Date',
    width: '12%',
    isDate: true
  }
];

function ProviderNoticeTable (props) {
  const [redirect, setRedirect] = React.useState(false);
  // API CAll

  const dispatch = useDispatch();
  const onRowClick = values => dispatch(providerNoticeRowClickAction(values));
  const payloadData = useSelector(
    state => state.appConfigState.textManagementState.payload
  );
  if (redirect === 1) {
    if (payloadData != null) {
      if (payloadData.length === 1) {
        props.history.push({
          pathname: '/TextUpdate',
          state: { row: payloadData[0], TextType: 'Provider Notice' }
        });
      }
    }
  }

  const formatSearchCriteria = (row) => {
    return ({
      lobCode: [row.lobCode],
      providerTextSk: row.providerSeqNo
    });
  };
  const editRow = row => event => {
      const searchCriteria = {
      providerTextSk: row.providerSeqNo
    };
    onRowClick(searchCriteria);

    let valuetoredirect = 0;
    valuetoredirect = valuetoredirect + 1;
    setRedirect(valuetoredirect);
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const providerType =
            props.dropdowns &&
            props.dropdowns['P1#P_TY_CD'] &&
            props.dropdowns['P1#P_TY_CD'].filter(
              value => value.code === each.providerType
            );
          each.providerTypeDesc =
          providerType && providerType.length > 0
              ? providerType[0].description
              : each.providerType ? each.providerType : '';

          const providerSpeciality =
            props.dropdowns &&
            props.dropdowns['P1#P_SPECL_CD'] &&
            props.dropdowns['P1#P_SPECL_CD'].filter(
              value => value.code === each.providerSpeciality
            );
          each.providerSpecialityDesc =
          providerSpeciality && providerSpeciality.length > 0
              ? providerSpeciality[0].description
              : each.providerSpeciality ? each.providerSpeciality : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const tableComp = (
    <div className="tableNoWrap">
      <TableComponent
        print={props.print}
        fixedTable
        isSearch={true}
        pathTo='/TextUpdate?data='
        formatSearchCriteria={formatSearchCriteria}
        headCells={headCells}
        tableData={getTableData(props.tableData ? props.tableData : [])}
        onTableRowClick={editRow}
        defaultSortColumn={headCells[0].id}
      />
    </div>
  );
  return tableComp;
}

export default withRouter(ProviderNoticeTable);
