/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const headCells = [
    {
        id: 'timestamp',
        numeric: false,
        disablePadding: true,
        label: 'Timestamp',
        isDateTime: true,
        width: '18%'
    },
    {
        id: 'user',
        numeric: false,
        disablePadding: false,
        label: 'User',
        width: '10%'
    },
    {
        id: 'activity',
        numeric: false,
        disablePadding: false,
        label: 'Activity',
        isNote: true,
        width: '9%'
    },
    {
        id: 'columnName',
        numeric: false,
        disablePadding: false,
        label: 'Column',
        width: '23%'
    },
    {
        id: 'beforeValue',
        numeric: false,
        disablePadding: false,
        label: 'Before',
        width: '20%'
    },
    {
        id: 'afterValue',
        numeric: false,
        disablePadding: false,
        label: 'After',
        width: '20%'
    }
];

export const R_PARAM_TB = 'r_param_tb'
export const R_PARAM_DTL_TB = 'r_param_dtl_tb'
export const F_HDR_TB = 'f_hdr_tb'
export const F_PMT_TB = 'f_recv_sched_tb'