/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const RETRY_TRANSACTION =
  "Service is under maintenance, please try again later.";
export const BEGIN_DATE_VALID = "Fund Code Begin date must be a valid date.";
export const END_DATE_VALID = "Fund Code End date must be a valid date.";
export const END_DATE_GREATER = "End Date cannot be less than Begin Date.";
export const NO_RECORD = "No records found for the search criteria entered.";
export const AGENCY_REQUIRED = "Agency is required.";
export const FUND_CODE_REQUIRED = "Fund Code is required.";
export const LOB_REQUIRED = "LOB is required.";
export const BEGIN_DATE_REQUIRED = "Begin Date is required.";
export const END_DATE_REQUIRED = "End Date is required.";
export const RANK_REQUIRED = "Rank is required.";
export const SERVICE_BEGIN_DATE_REQUIRED = "Service Begin Date is required.";
export const SERVICE_END_DATE_REQUIRED = "Service End Date is required.";
export const DETAIL_ROW_REQUIRED =
  "At least one Fund Code Assignment criteria must be defined for a fund code.";
export const FROM_TO_CODE_REQUIRED =
  "From Procedure Code and To Procedure Code required if Proc Code Range is selected.";
export const PROCEDURE_FROM_CODE_GREATER =
  "From Procedure Code must be less than or equal to To Procedure Code.";
export const BEGIN_AGE_GREATER =
  "Begin Member age must be less than or equal to End Member Age.";
export const PROCEDURE_CODE_VALUE_MUST_BE_SELECTED =
  "Modifiers may only be entered if a Procedure Code Value is entered.";
export const HEADER_VALUE_DUPLICATE =
  "Fund Code Header and/or detail data already exists.";
export const DATES_OVERLAP = "Fund Code Header Begin and End Dates Overlap.";
export const MAP_SET_WITH_CRITERIA_ERROR =
  "Mapset ID cannot be entered if other detail criteria is entered.";
export const PROVIDER_ID_NOT_FOUND =
  "The Provider ID is not found on database.";

export const BEGIN_DATE_INVALID = "Fund Code Begin date must be a valid date.";
export const END_DATE_INVALID = "Fund Code End date must be a valid date.";
export const SERVICE_BEGIN_DATE_INVALID =
  "Service Begin Date must be a valid date.";
export const SERVICE_END_DATE_INVALID =
  "Service End Date must be a valid date.";
export const BEGIN_DATE_GREATER_ERROR =
  "Begin Date must be less than or equal to End Date.";
export const SERVICE_BEGIN_DATE_GREATER_ERROR =
  "Service Begin Date must be less than or equal to Service End Date.";
export const PROV_ID_AND_TYPE_REQUIRED =
  "Provider ID and Provider ID Type both are required.";
export const FUND_CODE_ADD_TEXT = "Add Fund Code";
export const FUND_CODE_EDIT_TEXT = "Edit Fund Code";
export const NETWORK_ERROR = "Network Error";
export const TRANSACTION_ERROR =
  "There was an error processing the request. Please retry the transaction.";
export const PROV_ID_REQUIRED = "Billing Provider ID is required";
export const PROV_ID_TYPE_REQUIRED = "Billing Provider ID Type is required";
export const PROV_TYPE_REQUIRED = "Billing Provider Type is required";
export const PROV_TYPE_AND_ID_REQUIRED =
  "Provider ID and Provider ID Type are both required.";
/* Defining fund code add table columns */
export const headCellsAdd = [
  {
    id: "cosForTable",
    disablePadding: false,
    label: "COS",
    enableHyperLink: false,
    width: "12%",
    fontSize: "14px",
    isTooltip: true,
    isCOS: true,
    isTitle: "cosCodeLongDesc"
  },
  {
    id: "benefitPlanId",
    disablePadding: false,
    label: "Benefit Plan ID",
    enableHyperLink: false,
    width: "12%",
    fontSize: "14px",
    isTooltip: true,
    isTitle: "benefitPlanIdForTable"
  },
  {
    id: "bProviderIdTypeForTable",
    disablePadding: false,
    label: " Prov ID Type",
    enableHyperLink: false,
    width: "12%",
    fontSize: "14px"
  },
  {
    id: "bProviderId",
    disablePadding: false,
    label: "Prov ID",
    enableHyperLink: false,
    width: "15%",
    fontSize: "14px"
  },
  {
    id: "bProviderTypeForTable",
    disablePadding: false,
    label: "Prov Type",
    enableHyperLink: false,
    width: "12%",
    fontSize: "14px"
  },
  {
    id: "memberAgeForTable",
    disablePadding: false,
    label: "Member Age",
    enableHyperLink: false,
    width: "12%",
    fontSize: "14px"
  },
  {
    id: "claimTypeForTable",
    disablePadding: false,
    label: "Claim Type",
    enableHyperLink: false,
    width: "9%",
    fontSize: "14px"
  },
  {
    id: "mapsetId",
    disablePadding: false,
    label: "Mapset ID",
    enableHyperLink: false,
    width: "10%",
    fontSize: "14px",
    isTooltip: true,
    isTitle: "mapsetIdForTable"
  }
];

/* Defining fund code edit table columns */
export const headCellsEdit = [
  {
    id: "cosForTable",
    disablePadding: false,
    label: "COS",
    enableHyperLink: false,
    width: "12%",
    isTooltip: true,
    isCOS: true,
    isTitle: "cosCodeLongDesc"
  },
  {
    id: "benefitPlanId",
    disablePadding: false,
    label: "Benefit Plan ID",
    enableHyperLink: false,
    width: "10%",
    isTooltip: true,
    isTitle: "benefitPlanIdForTable"
  },
  {
    id: "bProviderIdTypeForTable",
    disablePadding: false,
    label: "Prov ID Type",
    enableHyperLink: false,
    width: "10%"
  },
  {
    id: "bProviderId",
    disablePadding: false,
    label: "Prov ID",
    enableHyperLink: false,
    width: "13%"
  },
  {
    id: "bProviderTypeForTable",
    disablePadding: false,
    label: "Prov Type",
    enableHyperLink: false,
    width: "13%"
  },
  {
    id: "memberAgeForTable",
    disablePadding: false,
    label: "Member Age",
    enableHyperLink: false,
    width: "8%"
  },
  {
    id: "claimTypeForTable",
    disablePadding: false,
    label: "Claim Type",
    enableHyperLink: false,
    width: "10%"
  },
  {
    id: "mapsetId",
    disablePadding: false,
    label: "Mapset ID",
    enableHyperLink: false,
    width: "10%",
    isTooltip: true,
    isTitle: "mapsetIdForTable"
  },
  {
    id: "voidDate",
    disablePadding: false,
    label: "Void Date",
    enableHyperLink: false,
    width: "10%"
  }
];

/* Defining fund code history table columns */
export const headCellsHistory = [
  {
    id: "cosForTable",
    disablePadding: false,
    label: "COS",
    enableHyperLink: false,
    width: "12%",
    isCOS: true,
    isTooltip: true,
    isTitle: "cosCodeLongDesc"
  },
  {
    id: "benefitPlanId",
    disablePadding: false,
    label: "Benefit Plan ID",
    enableHyperLink: false,
    width: "10%",
    isTooltip: true,
    isTitle: "benefitPlanIdForTable"
  },
  {
    id: "bProviderIdTypeForTable",
    disablePadding: false,
    label: "Prov ID Type",
    enableHyperLink: false,
    width: "10%"
  },
  {
    id: "bProviderId",
    disablePadding: false,
    label: "Prov ID",
    enableHyperLink: false,
    width: "13%"
  },
  {
    id: "bProviderTypeForTable",
    disablePadding: false,
    label: "Prov Type",
    enableHyperLink: false,
    width: "8%"
  },
  {
    id: "memberAge",
    disablePadding: false,
    label: "Member Age",
    enableHyperLink: false,
    width: "10%"
  },
  {
    id: "claimType",
    disablePadding: false,
    label: "Claim Type",
    enableHyperLink: false,
    width: "10%"
  },
  {
    id: "mapsetId",
    disablePadding: false,
    label: "Mapset ID",
    enableHyperLink: false,
    width: "10%",
    isTooltip: true,
    isTitle: "mapsetId"
  },
  {
    id: "voidDate",
    disablePadding: false,
    label: "Void Date",
    enableHyperLink: false,
    width: "10%"
  }
];
