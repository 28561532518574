/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

const ROUTE_HOST_ENV = process.env.REACT_APP_ROUTE_ENV;
const ROUTE_CLAIMS_HOST_ENV_ = process.env.REACT_APP_ROUTE_CLAIMS_HOST_ENV;
const PERFORMANCE_INSIGHTS_URL = process.env.REACT_APP_PERFORMANCE_INSIGHTS_URL;
const OPERATIONAL_INSIGHTS_URL = process.env.REACT_APP_OPERATIONAL_INSIGHTS_URL;
const ROUTE_CLAIMS_ENV = process.env.REACT_APP_CLAIMS_URL;
const IDP_LOGOUT_SSO_URL = process.env.REACT_APP_IDP_LOGOUT;

// Maven
// export const MAVEN_INTG_HOST_ENV = process.env.NODE_APP_HOST_MAVEN_INTG_ENV;
export const MAVEN_UI_HOST_ENV = process.env.NODE_APP_HOST_MAVEN_APP_ENV;

export const REACT_APP_WORKFLOW_ITEMS = process.env.REACT_APP_WORKFLOW_ITEMS;
export const REACT_APP_MAVEN_REDIRECT = process.env.REACT_APP_MAVEN_REDIRECT;
export const DROPDOWNS = ROUTE_HOST_ENV + "SystemList/getReferenceData";
export const VALID_VALUE_DROPDOWNS =
  ROUTE_HOST_ENV + "ValidValue/getdomainAllvalues";
export const VALID_VALUE_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'ValidValue/getValidValue';
export const REASON_CODE_DROPDOWNS =
  ROUTE_HOST_ENV + "SystemList/getReasonCodeMap";
export const MAP_ID_DROPDOWN = ROUTE_HOST_ENV + "SystemList/getMapIDList";
export const BENEFIT_PLAN_ID_DROPDOWN =
  ROUTE_HOST_ENV + "SystemList/getBenefitPlanIdsList";

export const MANAGE_ACCOUNT_USER_DETAILS = ROUTE_HOST_ENV + "usermgmt/userDetails/";

/* User Authentication End-Points Start */
// export const GET_USER_PRIVILEGES = ROUTE_HOST_ENV + 'UserAuth/getauthorizationsforUser';
// SSO changes
export const GET_USER_PRIVILEGES =
  ROUTE_HOST_ENV + "UserAuth/getauthorizationsforUser";
export const GET_USER_DETAILS = ROUTE_HOST_ENV + "UserAuth/UserDetails";
export const MAVEN_LOGOUT = process.env.REACT_APP_MAVEN_LOGOUT + process.env.REACT_APP_UI_URL;
export const USER_LOGOUT = ROUTE_HOST_ENV + 'security/auth/logout';
export const LOGIN = ROUTE_HOST_ENV + 'security/auth/login';
export const TOKEN_LOGIN = ROUTE_HOST_ENV + 'security/auth/tokenlogin';
export const REFRESH_TOKEN = ROUTE_HOST_ENV + 'security/token/refresh';
export const USER_ROLES = ROUTE_HOST_ENV + 'userManagement/getUserRoles';
export const CHANGE_PASSWORD = ROUTE_HOST_ENV + 'usermgmt/changePassword';
export const GET_USER_DATA = ROUTE_HOST_ENV + 'security/getUserData';

// SSO change
export const GET_USER_PRIVILEGES_SSO =
  ROUTE_HOST_ENV + "UserAuth/getauthorizations";
export const VALIDATE_USER_ID = ROUTE_HOST_ENV + "Security/validateUser";
export const ADD_NEW_USER = ROUTE_HOST_ENV + "Security/createUser";
export const GET_WORKUNIT_LIST = ROUTE_HOST_ENV + "Security/getWorkUnitList";
export const UPDATE_EXISTING_USER = ROUTE_HOST_ENV + "Security/updateUser";
export const IDP_LOGOUT_URL = IDP_LOGOUT_SSO_URL + process.env.REACT_APP_UI_URL;
/* User Authentication End-Points End */

/* Budget Maintenance End-Points Start */
export const BUDGET_MAINTENANCE_MULTI_SEARCH_ENDPOINT =
  ROUTE_HOST_ENV + "Budget/getBudgets";
export const BUDGET_MAINTENANCE_ID_SEARCH_ENDPOINT =
  ROUTE_HOST_ENV + "Budget/getBudget";
export const ADD_BUDGET_MAINTENANCE_SAVE =
  ROUTE_HOST_ENV + "Budget/createBudgets";
export const UPDATE_BUDGET_MAINTENANCE_SAVE =
  ROUTE_HOST_ENV + "Budget/updateBudget";
export const END_DATE_BUDGET = ROUTE_HOST_ENV + "Budget/addFundCode";
/* Budget Maintenance End-Points End */

/* Bank account and Lockbox End-Points Start */
export const ADD_BANK_ACCOUNT_AND_LOCKBOX =
  ROUTE_HOST_ENV + "BankAccount/createFinancialBankAccount";
export const BANKACCOUNT_LOCKBOX_SEARCH_ENDPOINT =
  ROUTE_HOST_ENV + "BankAccount/searchFinancialBankAccount";
export const UPDATE_BANK_ACCOUNT_AND_LOCKBOX =
  ROUTE_HOST_ENV + "BankAccount/updateFinancialBankAccount";
/* Bank account and Lockbox End-Points End */

/* Account Receivables End-Points Start */
export const FCN_SEARCH_ENDPOINT =
  ROUTE_HOST_ENV + "AccountReceivables/getAccountsReceivable";
export const PAYEE_SEARCH_ENDPOINT =
  ROUTE_HOST_ENV + 'AccountReceivables/searchAccountsReceivable';
export const PAYEE_TYPE_ID_DROPDOWN_ENDPOINT =
  ROUTE_HOST_ENV + "AccountReceivables/getPayeeIdTypeCodeList";
export const SAVE_CLAIMS_RECEIVABLE =
  ROUTE_HOST_ENV + "AccountReceivables/updateAccountsReceivable";
export const GO_TO_NEXT_PAGE_END =
  ROUTE_HOST_ENV + "AccountReceivables/goToNextPage";
export const ADD_GROSS_LEVEL_ADD =
  ROUTE_HOST_ENV + "AccountReceivables/createAccountsReceivable";
export const LIEN_LEVY_HOLDER_UPDATE =
  ROUTE_HOST_ENV + "AccountReceivables/updateAccountsReceivable";
export const AR_AGING_DAYS =
  ROUTE_HOST_ENV + "AccountReceivables/getAgingCodeList";
export const ARTRANSFER_VIEW =
  ROUTE_HOST_ENV + "AccountReceivables/viewTransferAccountsReceivable";
export const ARTRANSFER_TRANSFER =
  ROUTE_HOST_ENV + "AccountReceivables/transferAccountsReceivable";
export const ARTRANSFER_VALIDATE =
  ROUTE_HOST_ENV +
  "AccountReceivables/verifyPayeePayerTransferAccountsReceivable";
export const GROSS_LEVEL_EDIT_ENDPOINT =
  ROUTE_HOST_ENV + "AccountReceivables/updateAccountsReceivable";
export const ARREVERSE_VIEW =
  ROUTE_HOST_ENV + "AccountReceivables/viewReversalAccountsReceivable";
export const ARREVERSE_SAVE =
  ROUTE_HOST_ENV + "AccountReceivables/reverseAccountsReceivable";
export const ARADJUST_VIEW =
  ROUTE_HOST_ENV + "AccountReceivables/viewAdjustAccountsReceivable";
export const AR_ADJUST_ENDPOINT =
  ROUTE_HOST_ENV + "AccountReceivables/adjustAccountsReceivable";
export const FETCH_WRITE_OFF_DETAILS =
  ROUTE_HOST_ENV + "AccountReceivables/viewWriteOffAccountsReceivable";
export const SAVE_WRITE_OFF =
  ROUTE_HOST_ENV + "AccountReceivables/writeOffAccountsReceivable";
export const FETCH_WRITE_OFF_RESTABLISHMENT_DETAILS =
  ROUTE_HOST_ENV + "AccountReceivables/writeOffReestablishment";
export const SAVE_WRITE_OFF_RESTABLISHMENT =
  ROUTE_HOST_ENV + "AccountReceivables/saveReEstablishDetails";
export const GET_DEFAULT_RECOUPMENT_DETAILS =
  ROUTE_HOST_ENV + "AccountReceivables/getDefaultRecoupmentDetails";
export const VALIDATE_LIEN_HOLDER_NAME_DETAILS =
  ROUTE_HOST_ENV + 'AccountReceivables/fetchLienholdername';

/* Account Receivables End-Points End */
export const BULKVIEWTRANSFER =
  ROUTE_HOST_ENV + "AccountReceivables/bulkViewTransferAccountsReceivable";
export const SAVEBULKTRANSFER =
  ROUTE_HOST_ENV + "AccountReceivables/bulkTransferAccountsReceivable";
export const BULKVIEWREVERSE =
  ROUTE_HOST_ENV + "AccountReceivables/bulkViewReversalAccountsReceivable";
export const SAVEBULKVIEWREVERS =
  ROUTE_HOST_ENV + "AccountReceivables/bulkReversalAccountsReceivable";
export const BULK_VIEW_WRITEOFF =
  ROUTE_HOST_ENV + "AccountReceivables/bulkViewWriteOffAccountReceivable";
export const SAVE_BULK_VIEW_WRITEOFF =
  ROUTE_HOST_ENV + "AccountReceivables/bulkWriteOffAccountReceivable";
export const BULK_VIEW_WRITEOFF_REEST =
  ROUTE_HOST_ENV +
  "AccountReceivables/bulkViewWOReestablishmentAccountReceivable";
export const SAVE_BULK_WRITEOFF_REEST =
  ROUTE_HOST_ENV +
  "AccountReceivables/bulkWriteOffReestablishmentAccountReceivable";
export const BULK_VIEW_CHANGE_PAYEE_STATUS =
  ROUTE_HOST_ENV + "AccountReceivables/bulkViewChangePayeeStatusAR";
export const BULK_SAVE_CHANGE_PAYEE_STATUS =
  ROUTE_HOST_ENV + "AccountReceivables/bulkChangePayeeStatusAR";

/**/

/* Financial Payout End-Points Start */
export const FINANCIAL_PAYOUT_SEARCH = ROUTE_HOST_ENV + 'Payout/searchPayOut';
export const PAYOUT_PAYEE_ID_TYPE_CODE_ENDPOINT =
  ROUTE_HOST_ENV + "Payout/getPayeeIdTypeCodeList";
export const ADD_NEW_PAYOUT_RESPONSE = ROUTE_HOST_ENV + "Payout/addNewPayout";
export const PAYOUT_ADD_ENDPOINT = ROUTE_HOST_ENV + "Payout/createPayout";
export const PAYOUT_UPDATE_ENDPOINT = ROUTE_HOST_ENV + "Payout/updatePayout";
export const PAYOUT_ADJUST_VIEW_ENDPOINT = ROUTE_HOST_ENV + "Payout/viewAdjustment";
export const PAYOUT_ADJUST_SAVE_ENDPOINT = ROUTE_HOST_ENV + "Payout/savePayoutAdjustment";
export const VALIDATE_PAYEE_DETAILS =
  ROUTE_HOST_ENV + "Payout/verifyPayeePayerPayout";
export const VALIDATE_MEMBER_DETAILS = ROUTE_HOST_ENV + "Payout/verifyMember";
export const PAYOUT_APPROVAL_SEARCH =
  ROUTE_HOST_ENV + 'Payout/searchPayoutApproval';
export const UPDATE_PAYOUT_APPROVAL =
  ROUTE_HOST_ENV + "Payout/updatePayoutApproval";
export const FINANCIAL_PAYOUT_DETAILS = ROUTE_HOST_ENV + "Payout/searchPayOut";
/* Financial Payout End-Points End */

/* Fund Code End-Points Start */
export const FUND_CODE_SEARCHCRITERIA =
  ROUTE_HOST_ENV + "FundCodeAssign/getFundCodeAssignListUsingSearchCriteria";
export const FUND_CODE_ADD = ROUTE_HOST_ENV + "FundCodeAssign/createFundCode";
export const FUND_CODE_UPDATE =
  ROUTE_HOST_ENV + 'FundCodeAssign/updateFundCode';
/* Fund Code End-Points End */

/* Financial Entity End-Points Start */
export const FINANCIALENTITY_SEARCH =
  ROUTE_HOST_ENV + "FinancialEntity/searchFinancialEntity";
export const FINANCIALENTITY_GETPAYEEIDNAME =
  ROUTE_HOST_ENV + "FinancialEntity/validatePayeePayerID";
export const FINANCIALENTITY_GETPAYEEIDTYPECODE =
  ROUTE_HOST_ENV + "FinancialEntity/getPayeeIdTypeCodeList";
export const FINANCIALENTITY_GETREFERENCEDATA =
  ROUTE_HOST_ENV + "SystemList/getReferenceData/FinancialEntity";
export const FINANCIALENTITY_ADD =
  ROUTE_HOST_ENV + "FinancialEntity/createFinancialEntity ";
export const FINANCIALENTITY_UPDATE =
  ROUTE_HOST_ENV + "FinancialEntity/updateFinancialEntity";
export const FINANCIALENTITY_SINGLE_RECORD_SEARCH =
  ROUTE_HOST_ENV + "FinancialEntity/getFinancialEntityDetails";
/* Financial Entity End-Points End */

/* Financial Receipts End-Points Start */
export const MEMBER_ID_VALIDATE = ROUTE_HOST_ENV + "Receipt/verifyMember";
export const FINANCEIAL_RECEIPT_FCN_SEARCH =
  ROUTE_HOST_ENV + "Receipt/searchReceipt";
export const FINANCIAL_RECEIPT_GET_ADJUST_DATA =
  ROUTE_HOST_ENV + "Receipt/viewAdjustment";
export const FINANCIAL_RECEIPT_CALCULATE =
  ROUTE_HOST_ENV + "Receipt/adjustReceipt";
export const FINANCIAL_RECEIPT_ADJUST_SAVE =
  ROUTE_HOST_ENV + 'Receipt/saveReceiptAdjustment';
export const FINANCEIAL_RECEIPT_ADD_GENERATE_FCN_NUM =
  ROUTE_HOST_ENV + "Receipt/generateFCN";
export const FINANCEIAL_RECEIPT_VALIDATE_PAYEE =
  ROUTE_HOST_ENV + "Receipt/validatePayeePayerID";
export const FINANCIAL_RECEIPT_VALIDATE_PAYEEID =
  ROUTE_HOST_ENV + "Receipt/validateReceiptData";
export const FINANCIAL_RECEIPT_FCN_UPDATE =
  ROUTE_HOST_ENV + "Receipt/updateEftInfo";
export const FINANCIAL_ADD_REFUND_RECOVERY_VALIDATE =
  ROUTE_HOST_ENV + "Receipt/refundRecoveryDispositionSave ";
export const FINANCIAL_ADD = ROUTE_HOST_ENV + "Receipt/addReceipt";
export const FINANCIAL_UPDATE = ROUTE_HOST_ENV + "Receipt/updateReceipt";
export const FINANCIAL_RECEIPT_REVERSE_SAVE =
  ROUTE_HOST_ENV + "Receipt/saveReceiptReversal";
export const FINANCIAL_RECEIPT_REVERSE_ADD =
  ROUTE_HOST_ENV + "Receipt/addReceiptReversal";
export const FINANCIAL_RECEIPT_GET_CHECK_REPLACEMENT_DATA =
  ROUTE_HOST_ENV + "Receipt/searchCheckReplacement";
export const FINANCIAL_RECEIPT_SAVE_CHECK_REPLACEMENT =
  ROUTE_HOST_ENV + "Receipt/saveCheckReplacement";
/* Financial Receipts End-Points End */

/* Fiscal Pend End-Points Start */
export const SEARCH_FISCAL_PEND_CRITERIA =
  ROUTE_HOST_ENV + "FiscalPend/searchFiscalPend";
export const FISCAL_PEND_ADD = ROUTE_HOST_ENV + "FiscalPend/saveFiscalPend";
export const FISCAL_PEND_UPDATE =
  ROUTE_HOST_ENV + "FiscalPend/updateFiscalPend";
export const FISCAL_PEND_ID = ROUTE_HOST_ENV + "FiscalPend/addFiscalPend";
export const GET_FISCAL_PEND_RECORD =
  ROUTE_HOST_ENV + "FiscalPend/getFiscalPend";
export const FISCAL_PEND_DELETE =
  ROUTE_HOST_ENV + "FiscalPend/deleteFiscalPendCriterias";

/* Fiscal Pend End-Points End */

/* 1099 Correction End-Points Start */
export const SEARCH_CORRECTION_1099 =
  ROUTE_HOST_ENV + "Fin1099Correction/searchCorrection1099";
export const SEARCH_CORRECTION_APPROVAL_ENDPOINT =
  ROUTE_HOST_ENV + "Fin1099Correction/searchApproveCorrection1099";
export const CORRECTION_DETAIL_UPDATE_ENDPOINT =
  ROUTE_HOST_ENV + "Fin1099Correction/update1099CorrectionDetail";
export const CORRECTION_APPROVAL_UPDATE_ENDPOINT =
  ROUTE_HOST_ENV + 'Fin1099Correction/updateCorrectionApproval1099';
export const CORRECTION_APPROVAL_VALIDATE =
  ROUTE_HOST_ENV + 'Fin1099Correction/validate1099Approval';
export const CORRECTION_DETAIL_LIST_ENDPOINT =
  ROUTE_HOST_ENV + "Fin1099Correction/get1099CorrectionDetail";
export const CORRECTION_DETAIL_VIEW_ENDPOINT =
  ROUTE_HOST_ENV + 'Fin1099Correction/viewCorrectionDetails';
/* 1099 Correction End-Points End */
/* Financial Inquiry End-Points Start */
export const FINANCIAL_INQUIRY_SEARCH =
  ROUTE_HOST_ENV + "FinancailClaim/searchFinancialclaim";
export const FINANCIAL_INQUIRY_DROPDOWN =
  ROUTE_HOST_ENV + "SystemList/getReferenceData/claimFinancialInquiry";
export const FINANCIAL_CLAIM_SUMMARY_DETAILS =
  ROUTE_HOST_ENV + "FinancailClaim/getClaimDetails";
/* Financial Inquiry End-Points End */

/* Project Control End-Points Start */
export const SEARCH_EVENT_LOGGING = ROUTE_HOST_ENV + "searchEventLogging";
export const SEARCH_INQUIRY_LOGGING = ROUTE_HOST_ENV + "searchInquiryLogging";
export const EVENT_LOG_INQUIRY_SEARCH =
ROUTE_HOST_ENV + "eventlogs/eventloginquiry?skipDateConversionInRequest=true";

export const EVENT_LOG_EVENT_SEARCH =
ROUTE_HOST_ENV +"eventlogs/events?skipDateConversionInRequest=true";
export const GLOBAL_AUDIT_LOG_DETAILS =
ROUTE_HOST_ENV + 'auditlogs/getGlobalAuditRequest';
export const AUDIT_LOG_DETAILS = ROUTE_HOST_ENV + "auditlogs/getAuditRequest";
/* Project Control End-Points End */

/* Address Verification End_Points Start */
export const ADDRESS_VERIFY = ROUTE_HOST_ENV + "USPS/validateAddress";
/* Address Verification End_Points End */

/* DownloadFile in Attachments End_Points Start */
export const DOWNLOAD_ACTION_LINK = ROUTE_HOST_ENV + "Payout/downloadFile";
export const REFRESH_ATTACHMENTS_LINK =
  ROUTE_HOST_ENV + "Payout/showAttachments";
/* DownloadFile in Attachments End_Points End */

/* Operational and Performance Insight End_Points Start */
export const OPERATIONAL_INSIGHT_LINK = OPERATIONAL_INSIGHTS_URL;
export const PERFORMANCE_INSIGHT_LINK =
  PERFORMANCE_INSIGHTS_URL + "controller/";
/* Operational and Performance Insight End_Points End */
const FEDERAL_CLAIMS = process.env.REACT_APP_FEDERALREPORTING;
export const MARS_INSIGHT_LINK =
  FEDERAL_CLAIMS +
  "bi/v1/disp?b_action=icd&pathinfo=/main&ui.gateway=%2fbi%2fv1%2fdisp&ui.tool=DashboardConsole&ui.action=edit&src=%2fbi%2fv1%2fdisp%2ficd%2ffeeds%2fcm%2fid%2fiE7A0C459828E4DBDA6D959D3F3248B60%3fentry%3d&backURL=..%2Futils%2Fclose.html&ui.backURL=..%2Futils%2Fclose.html";

  export const FADS_INSIGHT_LINK =
  FEDERAL_CLAIMS +
  "bi/v1/disp?b_action=icd&pathinfo=/main&ui.gateway=%2fbi%2fv1%2fdisp&ui.tool=DashboardConsole&ui.action=edit&src=%2fbi%2fv1%2fdisp%2ficd%2ffeeds%2fcm%2fid%2fi9575F4A43BED4628B9CA2FABF3C61475%3fentry%3d&backURL=..%2Futils%2Fclose.html&ui.backURL=..%2Futils%2Fclose.html";
/* Operational and Performance Insight End_Points End */

/* Finance and Claims Module Connection URL For TCN Redirection */
export const CLAIMS_INQUIRY_TCN_LINK = ROUTE_CLAIMS_ENV + "Inquiry?tcnno=";

/* Maven integration End_Points Start */
export const MAVEN_INTG_HOST_ENV = process.env.REACT_APP_HOST_MAVEN_INTG_ENV;
export const MAVEN_INTG_CALL = ROUTE_HOST_ENV + "MavenIntgCall";
export const CASE_CREATION = ROUTE_HOST_ENV + 'Payout/getCaseApprovalCount';
/* Maven integration End_Points End */

/* EMAR End_Points  start*/
export const SEARCH_EMAR = ROUTE_HOST_ENV + "Emar/searchEmar";
export const ADD_EMAR = ROUTE_HOST_ENV + "Emar/createEmar";
export const UPDATE_EMAR = ROUTE_HOST_ENV + "Emar/updateEmar";
/* EMAR End_Points  end*/

/* FRC End_point starts*/
export const SEARCH_FRC =
  ROUTE_HOST_ENV + "FinanceRsnCode/searchFinanceRsnCode";
export const ADD_FRC = ROUTE_HOST_ENV + "FinanceRsnCode/createFinanceRsnCode";
export const UPDATE_FRC =
  ROUTE_HOST_ENV + "FinanceRsnCode/updateFinanceRsnCode";
/* FRC End_point ends*/

/* Waiver End_Point starts*/
export const SEARCH_WAIVER = ROUTE_HOST_ENV + "Waiver/searchWaiver";
export const ADD_WAIVER = ROUTE_HOST_ENV + "Waiver/createWaiver";
export const UPDATE_WAIVER = ROUTE_HOST_ENV + "Waiver/updateWaiver";
/* CMS 64 End_point starts*/
export const SEARCH_CMS64 = ROUTE_HOST_ENV + "CMS64/searchCMS64";
export const ADD_CMS64 = ROUTE_HOST_ENV + "CMS64/createCMS64";
export const UPDATE_CMS64 = ROUTE_HOST_ENV + "CMS64/updateCMS64";
/* CMS 64 End_point ends*/

/* Audit log End-Points Start */
export const AUDIT_LOG_ENDPOINT = ROUTE_HOST_ENV + 'auditlogs/getAuditRequest';
export const AUDIT_LOG_MULTIPLE_ENDPOINT =  ROUTE_HOST_ENV + 'auditlogs/getAuditRequests';
/* Audit log End-Points End */

/**
 * Payment Criteria End Point Start
 */
export const  PAYMENT_CRITERIA_SEARCH = ROUTE_HOST_ENV + 'paymentCriteria/search';
export const  PAYMENT_GET_DETAILS = ROUTE_HOST_ENV + 'paymentCriteria/get';
export const  PAYMENT_CRITERIA_ADD_UPDATE = ROUTE_HOST_ENV + 'paymentCriteria/saveOrUpdate';
export const  FIND_PAYEE = ROUTE_HOST_ENV + 'paymentCriteria/validatePayeePayer/';
export const  DELETE_PAYEE = ROUTE_HOST_ENV + 'paymentCriteria/delete/';

/* Financial Transaction starts*/
export const SEARCH_FINANCE_UPLOAD =
  ROUTE_HOST_ENV + "financeIface/searchFileControlDetails";
export const SEARCH_UNQ_FINANCE_UPLOAD =
  ROUTE_HOST_ENV + "financeIface/getFinanceIfaceControlTrackDetails?controlSk=";
export const UPLOAD_FINANCE_TXN = ROUTE_HOST_ENV + "financeIface/uiMassUpload";
export const UPLOADED_USER_DROPDOWN = ROUTE_HOST_ENV + "financeIface/getUniqueUserIdsList";
export const REACT_APP_IDLE_TIME = 1800000;
export const USER_INACTIVITY_TIME =  25 * 60 * 1000;
export const USER_NOTIFY_TIME = 5 * 60 * 1000;
export const TITLE_CHANGE_TIME = 4 * 1000;

/* Payment Status Inquiry Starts*/
export const PAYMENT_STATUS_INQUIRY =
  ROUTE_HOST_ENV + "FinancailClaim/searchPaymentStatus";
  
/* Payment Status Inquiry Starts*/

// Help Implementation
export const HELP_DOCUMENT = process.env.REACT_APP_HELP_DOCUMENT_URL;

// Footer Links
export const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const TERMS_OF_USE = process.env.REACT_APP_TERMS_OF_USE_URL;
export const BROWSER_REQUIREMENTS = process.env.REACT_APP_BROWSER_REQUIREMENTS_URL;
export const ACCESSIBILITY_COMPLIANCE = process.env.REACT_APP_ACCESSIBILITY_COMPLIANCE_URL;
export const SYSTEM_LIST_SEARCH_ENDPOINT = ROUTE_HOST_ENV + 'SystemList/getSystemList';
