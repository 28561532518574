/**

* � [2020] Conduent, Inc. All rights reserved. Conduent�and Conduent Agile

* Star�are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogContent
} from '../../../../SharedModules/Dialog/DialogUtilities';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import InputAdornment from '@material-ui/core/InputAdornment';
import PayoutDetailsComponent from './PayoutDetailsComponent';
import Swal from 'sweetalert2';
import { checkPayoutAddValidations, checkPayeeIDValidations, checkDropdownValue, checkReasonCode, validatePayeeRequest, validateMemberRequest } from '../financialPayoutValidationUtilities';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import {
  getDropdownDataAction, getNewPayoutResponse, getPayeeIdType, validatePayeeDetails, validateMemberDetails,
  financialPayoutAdd, getFinancialPayoutDetails, financialPayoutUpdate, financialPayOutSearchAction
} from '../../Store/Actions/financialPayout/financialPayoutActions';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import * as ErrorConstants from '../ErrorCheck';

import { Prompt, Link } from 'react-router-dom';
import PayoutHistoryComponent from './PayoutHistoryComponent';
import NoSaveMessage from '../../../../SharedModules/Errors/NoSaveMessage';
import { getUTCTimeStamp, getDateInMMDDYYYYFormatWithApendZero, generateUUID, getDateInYYYYMMDDFormatWithApendZero, compareTwoDatesGreaterThanOrEqual } from '../../../../SharedModules/DateUtilities/DateUtilities';
import dropdownCriteria from './PayoutAddUpdate.json';
import Attachments from '../../../../SharedModules/Attachments/Attachments';
import Notes from '../../../../SharedModules/Notes/Notes';
import moment from 'moment';
import { getAuditLogDetailsAccountingAction } from '../../../../SharedModules/store/Actions/SharedAction';
import PropsInit from '../../../../SharedModules/Navigation/NavHOC';
import Footer from '../../../../SharedModules/Layout/footer';
import { systemListActions, systemListActions1021, systemListViewAction, systemListActions9003 } from "../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions";
import axios from 'axios';
import * as serviceEndPoint from '../../../../SharedModules/services/service';
import { redirectSystemID } from '../../../../SharedModules/TabRedirections/TabRedirections';
import numeral from 'numeral';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent';
import SystemPayeeTable from '../../../../SharedModules/SystemIDTable/SystemPayeeIDTable';
import { removeLeadingTrailingSpaces, checkingDecimal } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { PAYOUT_APPROVAL_STATUS_YES } from '../../../../SharedModules/AppConstants';
import { DEFAULT_LOB_VALUE } from '../../../../SharedModules/AppConstants';

export default PropsInit({
  Wrapped: FinancialPayoutAdd,
  action: getFinancialPayoutDetails,
  pathTo: '/FinancialPayoutUpdate',
  selector: 'financialPayoutState.singlePayoutDetails',
  actionKey: 'payout'
});
function FinancialPayoutAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const valuesFromFinAdjustPayout = props.location && props.location.state && props.location.state.valuesFromFinAdjustPayout;
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const [atrDisableReasonCode, setATRDisableCodeData] = useState([]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [addPayoutRsCodes, setAddPayoutRsCodes] = React.useState([]);
  const [checkStatusDesc, setCheckStatusDesc] = React.useState('');
  const [subReasonCodeData, setSubReasonCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [disableReasonCodeData, setDisableReasonCodeData] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [COSData, setCOSData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [subFundCodeData, setSubFundCodeData] = React.useState([]);
  const [memberIDTypeData, setMemberIDTypeData] = React.useState([]);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState(props.location.state &&
    props.location.state.systemSuccessMessages
    ? props.location.state.systemSuccessMessages : []);

  const [costsettlementBeginDate, setSettlementBeginDate] = React.useState(null);
  const [costsettlementEndDate, setSettlementEndDate] = React.useState(null);
  const [checkDate, setcheckDate] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const errorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  // Payee Popup Implementation Starts
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [{
    showPayeeTypeError,
    showPayeeIDTypeCodeError,
    showATRError,
    showPayeeIDError,
    showCheckNumberError,
    showCheckDateError,
    showMemberIDError,
    showMemberIDTypeCodeError,
    showReasonCodeError,
    showAmountError,
    showCompareDatesError,
    showFiscalYearError, showCollocationCodeError,
    showBeginDateError, showEndDateError,
    showCheckNumberRangeError, showFridayCheck
  }, setShowError] = React.useState(false);
  const [{
    showPayeeTypeErrorText,
    showPayeeIDTypeCodeErrorText,
    showPayeeIDErrorText,
    showCheckNumberErrorText,
    showCheckDateErrorText,
    showMemberIDErrorText,
    showMemberIDTypeCodeErrorText,
    showReasonCodeErrorText,
    showATRErrorText,
    showAmountErrorText, showCompareDatesErrorText,
    showFiscalYearErrorText, showCollocationCodeErrorText,
    showBeginDateErrorText, showEndDateErrorText,
    showCheckNumberRangeErrorText, showFridayCheckText
  }, setShowErrorText] = React.useState('');
  const [tabOne, setTabOne] = React.useState(true);
  const [tabTwo, setTabTwo] = React.useState(false);
  const [tabThree, setTabThree] = React.useState(false);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [editScreen, seteditScreen] = React.useState(!!(props.location.state &&
    props.location.state.payoutUpdate));
  const [searchData, setsearchData] = React.useState(props.location.state &&
    props.location.state.addSuccessData ? props.location.state.addSuccessData : null);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const reasonCdValues = (values) => dispatch(systemListViewAction(values));
  const dropdownData = useSelector(state => state.appDropDowns.appdropdowns);
  const [disableATR, setDisableATR] = useState(false);

  const manualReasonCodes = (values) => dispatch(systemListViewAction(values));

  let onAddNewPayoutResponse = {};
  let dropdown = [];
  let payeeTypeIdCodeDropdown = [];
  let onValidatePayeeResponse = {};
  let onValidateMemberResponse = {};
  const sucessMessages = [];
  const systemErrorMessges = [];
  const editField = true;
  const getFullYear1021 = useSelector(state => state.appConfigState.systemListState.F1021);
  const getRsCd9003 = useSelector(state => state.appConfigState.systemListState);

  const [payoutRsnCd, setPayoutRsnCd] = useState([]);
  const [beginEndDateReqSts, setBeginEndDateReqSts] = useState(false);
  const currentDate = new Date();
  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    payeeId: '',
    entityID: '',
    memberIDType: DEFAULT_DD_VALUE,
    memberID: '',
    memberName: '',
    payeePayerName: '',
    systemPayeeId: '',
    fcn: '',
    fcnSeqNum: '',
    checkNumber: '',
    reasonCode: DEFAULT_DD_VALUE,
    amount: '0.00',
    approval: 'No',
    establishedDate: getDateInYYYYMMDDFormatWithApendZero(new Date(new Date().toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE }))),
    establishedDateStr: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE }))),
    relatedFTTCN: '',
    fiscalYear: '',
    categoryOfService: DEFAULT_DD_VALUE,
    collocationCode: DEFAULT_DD_VALUE,
    payoutHistoryList: [],
    federal: '',
    state: '',
    county: '',
    other: '',
    fiscalPendID: '',
    provBillingSysID: '',
    atrNumber: ''
  });

  useEffect(() => {
   if (valuesFromFinAdjustPayout !== undefined && props.location && props.location.payoutDataRender) {
     seteditScreen(true);
     setsearchData(valuesFromFinAdjustPayout);
   }
  }, [props.location.payoutDataRender]);

useEffect(() => {
  if (getFullYear1021 && !editScreen) {
   const currentYear1021 = getFullYear1021 && getFullYear1021[0]?.systemListDetails
  .filter((range) => {
    const startDate = new Date(range.beginDate);
    const endDate = new Date(range.endDate);
    return startDate <= currentDate && currentDate <= endDate;
  })
  .map((item) => item?.startingValue)[0];
  setValues({ ...values, fiscalYear: currentYear1021 });
 }
}, [getFullYear1021]);
  // Audit Log Implementation starts
  const [showLogTable, setShowLogTable] = React.useState(false);
  const onClickAuditLog = (fcn) => {
    dispatch(getAuditLogDetailsAccountingAction(fcn, '', false, 'auditLogData'));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);

  const auditLogData = useSelector(state => (state.sharedState.auditLogData));
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = (rowData) => {
    return (rowData.afterValue || rowData.beforeValue);
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);
  // Audit Log Implementation ends
  // Attachments variables
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);

  const onReasonCd = (values) => dispatch(systemListActions(values));
  const [reasonCd, setReasonCd] = useState([]);

  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [payoutRCodes, setPayoutRCodes] = React.useState([]);
  const [payoutHistory, setPayoutHistory] = React.useState([]);
  const [receiptRCodes, setReceiptRCodes] = React.useState([]);
  const [accountRRCodes, setAccountRRCodes] = React.useState([]);
  const [dispositionRCodes, setDispositionRCodes] = React.useState([]);
  const [approvalStatus, setApprovalStatus] = React.useState(false);
  let notesDataArray = [];
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date(new Date().toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE }))).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date(new Date().toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE }))).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = (data) => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  // Notes ENd

  // Map function to get the Description for corresponding Code
  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      };
    };
    return _code;
  };

  useEffect(() => {
    onDropdowns([Dropdowns.PAYEE_TYPE, Dropdowns.F_RSN_CD, Dropdowns.LOB_CODE, Dropdowns.MEMBER_TYPE_DD, Dropdowns.FUND_CODE,
    Dropdowns.COS, Dropdowns.PAY_STATUS])
    reasonCdValues(Dropdowns.PAYOUT_F_RSN_CD);
    onReasonCd(Dropdowns.AR_BULK_RN9023_CD);  
    onReasonCd(Dropdowns.AR_BULK_RN16_CD) ;
    onReasonCd(Dropdowns.RSN_CD_3001);
    manualReasonCodes(Dropdowns.MANUAL_F_RSN_CD);
  }, []);

  useEffect(() => {
    if (values.paymentStatusCode) {
      const paymentStatusCode =
        dropdownData &&
        dropdownData['F1#C_STAT_CD'] &&
        dropdownData['F1#C_STAT_CD'].filter(
          value1 => value1.code == values.paymentStatusCode
        );
      const paymentStatusDesc =
        paymentStatusCode && paymentStatusCode.length > 0
          ? paymentStatusCode[0].description
          : values.paymentStatusCode ? values.paymentStatusCode : '';
      setCheckStatusDesc(paymentStatusDesc);
    }

    // }
  }, [dropdownData]);

  const onAddNewPayout = () => dispatch(getNewPayoutResponse());
  onAddNewPayoutResponse = useSelector(state => state.financialPayoutState.newPayoutResponse);

  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  dropdown = useSelector(state => state.financialPayoutState.dropdownData);

  let getCOSReasonCodes = useSelector(state => state.appConfigState.systemListState.payload);
  getCOSReasonCodes = getCOSReasonCodes && getCOSReasonCodes[0]?.systemListDetails?.map(e => +e.startingValue);
  let getHIPReasonCodes = useSelector(state => state.appConfigState.systemListState.hipReasonCodes);
  getHIPReasonCodes = getHIPReasonCodes && getHIPReasonCodes[0]?.systemListDetails?.map(e => +e.startingValue);
  const onPayeeTypeIdDropDown = (value) => dispatch(getPayeeIdType(value));
  payeeTypeIdCodeDropdown = useSelector(state => state.financialPayoutState.payeeIdTypeDropdown);
  const [isVoidedRsnCd, setIsVoidedRsnCd] = useState([]);
  const [isVoidedInd, setIsVoidedInd] = useState(false);
  const onValidatePayeeDetails = (value) => dispatch(validatePayeeDetails(value));
  onValidatePayeeResponse = useSelector(state => state.financialPayoutState.payoutDetails);

  onValidateMemberResponse = useSelector(state => state.financialPayoutState.payoutMemberDetails);

  const checkDuplicate = (values) => dispatch(financialPayOutSearchAction(values));
  let payload = useSelector(state => state.financialPayoutState.payeeRes);
  let getReasonCds = useSelector(state => state.appConfigState.systemListState.systemListViewData);
  getReasonCds = getReasonCds && getReasonCds[0]?.systemListDetails?.map(code => +code.startingValue);
  const [rsnCodeVal, setrsnCodeVal] = useState(false);
  // Payout Add action call
  const onFinancialPayoutAdd = (value) => dispatch(financialPayoutAdd(value, attachmentsTableData.length > 0 ? attachmentFile : null));

  const financialPayoutAddResponse = useSelector(state => state.financialPayoutState.financialPayoutAddResponse);
  // On successfull save of payout search the created payout details
  const onAddSuccess = values => dispatch(getFinancialPayoutDetails(values));
  let addSuccessData = useSelector(state => state.financialPayoutState.singlePayoutDetails);

  // Payout Update action call
  const onFinancialPayoutUpdate = (value, index) => dispatch(financialPayoutUpdate(value, attachmentsTableData && index >= 0 && attachmentsTableData[index] && attachmentsTableData[index].id ? attachmentFile : null));
  let financialPayoutUpdateResponse = useSelector(state => state.financialPayoutState.financialPayoutUpdateResponse);

  const payeeTypeIDCode = (payeeTypeValue) => {
    if (payeeTypeValue && payeeTypeValue !== DEFAULT_DD_VALUE && payeeTypeValue !== '') {
      onPayeeTypeIdDropDown(payeeTypeValue);
    }
  };

  const payoutAddAfterDuplicateCheck = () => {
    setAllowNavigation(false);
    var payoutAddInput = {
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: getUTCTimeStamp(),
      atrNumber: values.atrNumber.toUpperCase(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      payeePayerName: checkDropdownValue(values.payeePayerName),
      payoutHistoryList: values.payoutHistoryList,
      approvedUserID: null,
      memberName: values.memberName,
      beginDate: getDateInMMDDYYYYFormatWithApendZero(costsettlementBeginDate),
      endDate: getDateInMMDDYYYYFormatWithApendZero(costsettlementEndDate),
      fcnSeqNum: values.fcnSeqNum,
      fcnMediaCode: null,
      entityType: checkDropdownValue(values.payeeType),
      entityIDTypeCode: checkDropdownValue(values.payeeIdTypeCode),
      entityID: values.entityID,
      lineOfBusiness: DEFAULT_LOB_VALUE,
      fcn: values.fcn,
      checkNumber: values.checkNumber,
      checkDate: getDateInMMDDYYYYFormatWithApendZero(checkDate),
      reasonCode: checkDropdownValue(values.reasonCode),
      amount: values.amount,
      approval: checkReasonCode(values.reasonCode, disableReasonCodeData) ? 'Yes' : values.approval,
      establishedDate: values.establishedDate,
      settlementThruDate: null,
      establishedBehalfOfMemberID: values.memberID,
      memberIDType: checkDropdownValue(values.memberIDType),
      relatedFTTCN: values.relatedFTTCN,
      account: {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        scoaFiscalYearDisable: false,
        scoaFiscalYear: values.fiscalYear,
        fundCodeDisable: false,
        fundCode: checkDropdownValue(values.collocationCode),
        cosDisable: false,
        cos: checkDropdownValue(values.categoryOfService) === "Please Select One" ? null : checkDropdownValue(values.categoryOfService),
        cosValue: null,
        fundCodeValue: null
      },
      payeePayerID: values.payeeId,
      updateAuditTimeStamp: null,
      orgAuditTimeStamp: null,
      receiptVersionNo: 0,
      cmnEntVersionNo: 0,
      sysID: values.systemPayeeId,
      lineOfBusinessValue: null,
      entityTypeValue: null,
      entityIDTypeCodeValue: '',
      reasonCodeValue: null,
      memberIDTypeValue: null,
      activeTypeCode: null,
      voidIndicator: false,
      voidDate: null,
      associatedClaim: false,
      entityIDType: [
        ''
      ],
      establishedDateStr: values.establishedDateStr,
      fdetailVersionNo: 0,
      noteSetVO: notesInput
    };
    const attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0) {
      attachmentsTableData.map((value) => {
        if (value.id) {
          const attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE }))),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description: value
              ? value.description
              : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName:
              attachmentsTableData && value
                ? value.fileName
                : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      });
    }
    payoutAddInput.attachmentVO = null;
    payoutAddInput.attachmentsList = attachmentVOList;

    onFinancialPayoutAdd(removeLeadingTrailingSpaces(payoutAddInput));
  };

  function Confirm () {
    Swal.fire({
      customClass: {
        confirmButton: 'btn-text1 btn-transparent mr-3',
        cancelButton: 'btn1 btn-success',
        container: 'unsaved-changes-popup1'
      },
      allowOutsideClick: false,
      buttonsStyling: false,
      heightAuto: false,
      icon: 'warning',
      html:
        '<h4>A Payout was submitted today for this payee with same Financial reason code. Do you still want to save this ? </h4 >',
      showCancelButton: true,
      confirmButtonText: 'Cancel!',
      cancelButtonText: 'Continue! <i class="fa fa-arrow-right ml-1"></i>'
    }).then(result => {
      if (result.value) {
      } else {
        payoutAddAfterDuplicateCheck();
      }
    });
  }
  useEffect(() => {
    onDropdowns([Dropdowns.PAYEE_TYPE, Dropdowns.F_RSN_CD, Dropdowns.MEMBER_TYPE_DD, Dropdowns.FUND_CODE,
    Dropdowns.COS, Dropdowns.PAY_STATUS])
    reasonCdValues(Dropdowns.PAYOUT_F_RSN_CD);
    onReasonCd(Dropdowns.AR_BULK_RN9023_CD);
    onReasonCd(Dropdowns.AR_BULK_RN16_CD);
    onReasonCd(Dropdowns.RSN_CD_3001);
	  getDropdownData(dropdownCriteria);

    dispatch(
      systemListActions1021({
        listNumber: "1021",
        functionalArea: "F1",
        dataElementName: "DM_YR_NUM"
      }, 'F1021')
    );
    dispatch(
      systemListActions({
        listNumber: "9002",
        functionalArea: "F1",
        dataElementName: "F_RSN_CD"
      }, 'hipReasonCodes')
    );
	dispatch(
		systemListActions9003({
        listNumber: "9003",
        functionalArea: "F1",
        dataElementName: "F_RSN_CD"
      }, 'PayOutRsnCd')
    );
    if (!editScreen && props.location && !props.location.payoutDataRender) {
      onAddNewPayout();
    }
    payload = null;
    addSuccessData = null;
    financialPayoutUpdateResponse = null;
  }, []);

  useEffect(() => {
	if (getRsCd9003 && getRsCd9003?.payload9003Data) {
		const nonVoidedRsnCdsList = getRsCd9003?.payload9003Data[0]?.systemListDetails?.filter(
			(code) => code.voidDate === null
		  );
		  const date = moment(new Date()).format("MM/DD/YYYY");
		  const nonVoidedEndDtRsnCdsList = nonVoidedRsnCdsList.filter(
			(code) => compareTwoDatesGreaterThanOrEqual(date, code.endDate)
		  );
		  const nonVoidedRsnCds = nonVoidedEndDtRsnCdsList?.map(rsnCd => rsnCd.startingValue);
		  setPayoutRsnCd(nonVoidedRsnCds);
	}
  }, [getRsCd9003]);

  useEffect(() => {
    if (payload && payload.enterpriseSearchresultVO) {
      Confirm();
    }
    if (payload && !payload.systemFailure && payload.length === 0) {
      payoutAddAfterDuplicateCheck();
    }
    if (payload && payload.systemFailure) {
      seterrorMessages([ErrorConstants.SYSTEM_ERROR]);
    }
  }, [payload]);

  const setInputValues = (searchData) => {
    if (searchData && searchData.payoutHistoryList && searchData.payoutHistoryList.length > 0) {
      searchData.payoutHistoryList.map((history) => {
        if (reasonCodeData && reasonCodeData.length > 0 && history.reasonCode) {
          reasonCodeData.map(RCDD => {
            if (RCDD.description === history.reasonCode) {
              history.resonCodeLongDesc = RCDD.longDescription;
            }
          });
        }
      });
    }
    setValues({
      ...searchData,
...{
        isEdited: false,
        account: searchData?.account,
        atrNumber: searchData.atrNumber ? String(searchData?.atrNumber).toUpperCase() : '',
        lob: searchData.lineOfBusiness ? searchData.lineOfBusiness : DEFAULT_DD_VALUE,
        payeeType: searchData.entityType ? searchData.entityType : DEFAULT_DD_VALUE,
        payeeIdTypeCode: searchData.entityIDTypeCode ? searchData.entityIDTypeCode : DEFAULT_DD_VALUE,
        payeeId: searchData.payeePayerID,
        entityID: searchData.entityID,
        memberIDType: searchData.memberIDType ? searchData.memberIDType : DEFAULT_DD_VALUE,
        memberID: searchData.establishedBehalfOfMemberID,
        memberName: searchData.memberName,
        payeePayerName: searchData.payeePayerName,
        systemPayeeId: searchData.sysID,
        fcn: searchData.fcn,
        fcnSeqNum: searchData.fcnSeqNum,
        checkNumber: searchData.checkNumber,
        reasonCode: searchData.reasonCode ? searchData.reasonCode : DEFAULT_DD_VALUE,
        amount: searchData.amount,
        approval: searchData.approval,
        establishedDate: searchData.establishedDate,
        establishedDateStr: searchData.establishedDateStr,
        relatedFTTCN: searchData.relatedFTTCN,
        fiscalYear: searchData.account && searchData.account.scoaFiscalYear ? searchData.account.scoaFiscalYear : '',
        categoryOfService: searchData.account && searchData.account.cos ? searchData.account.cos : DEFAULT_DD_VALUE,
        collocationCode: searchData.account && searchData.account.fundCode ? searchData.account.fundCode : DEFAULT_DD_VALUE,
        payoutHistoryList: searchData.payoutHistoryList ? searchData.payoutHistoryList : [],
        raNumber: searchData.raNumber,
        paymentStatusCode: searchData.paymentStatusCode,
        fiscalPendID: searchData.fiscalPendID,
        federal: searchData.fundSourceAllocationVO ? numeral(searchData.fundSourceAllocationVO.federalPercentage).format('0.00') : '',
        state: searchData.fundSourceAllocationVO ? numeral(searchData.fundSourceAllocationVO.statePercentage).format('0.00') : '',
        county: searchData.fundSourceAllocationVO ? numeral(searchData.fundSourceAllocationVO.countyPercentage).format('0.00') : '',
        other: searchData.fundSourceAllocationVO ? numeral(searchData.fundSourceAllocationVO.otherPercentage).format('0.00') : ''
      }
    });
    setPayoutHistory(searchData.payoutHistoryList ? searchData.payoutHistoryList : []);
    setSettlementBeginDate(searchData.beginDate);
    setSettlementEndDate(searchData.endDate);
    setcheckDate(searchData.checkDate);
    if (searchData.noteSetVO) {
      const noteSetVO = searchData.noteSetVO;
      const notesTable = searchData.noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: noteSetVO.addedAuditUserID ? noteSetVO.addedAuditUserID : logInUserID,
        addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp ? noteSetVO.addedAuditTimeStamp : getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTable,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });

      setNotesTableData(notesTable);
    }
    if (searchData && searchData.attachmentsList && searchData.attachmentsList.length > 0) {
      setAttachmentsTableData(searchData.attachmentsList);
    } else {
      setAttachmentsTableData([]);
    }
    setAttachmentFile([]);
  };

  useEffect(() => {
    if (reasonCodeData && searchData && reasonCodeData.length > 0 && searchData.payoutHistoryList) {
      const tempPayload = [];
      searchData.payoutHistoryList.map((row, index) => {
        const outputData = searchData.payoutHistoryList;
        tempPayload.push({
          ...outputData[index],
          reasonCodeDesc: mapCodeDescription(row.reasonCode, reasonCodeData)
        });
      });
      searchData.payoutHistoryList = tempPayload;
      setsearchData(searchData);
      setInputValues(searchData);
    }
  }, [reasonCodeData]);

  useEffect(() => {
    if (props.location.state && props.location.state.payoutUpdate === true && searchData) {
      seteditScreen(true);
      setApprovalStatus(searchData.approval === PAYOUT_APPROVAL_STATUS_YES)
      setInputValues(searchData);
    }
  }, [searchData]);

  useEffect(() => {
    if (onAddNewPayoutResponse && onAddNewPayoutResponse !== undefined && !onAddNewPayoutResponse.message) {
      setValues({
        ...values,
        fcn: onAddNewPayoutResponse.fcn,
        fcnSeqNum: onAddNewPayoutResponse.fcnSeqNum,
        payeeId: onAddNewPayoutResponse.entityID,
        relatedFTTCN: onAddNewPayoutResponse.relatedFTTCN
      });
    } else if (onAddNewPayoutResponse && onAddNewPayoutResponse !== undefined && onAddNewPayoutResponse.message) {
      errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [onAddNewPayoutResponse]);

  useEffect(() => {
    if (payeeTypeIdCodeDropdown && payeeTypeIdCodeDropdown.payeeIdTypeCode) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (payeeTypeIdCodeDropdown.payeeIdTypeCode.length === 1) {
        tempData.payeeTypeIDCode = payeeTypeIdCodeDropdown.payeeIdTypeCode[0].code;
      }
      setPayeeTypeIdCodeData(payeeTypeIdCodeDropdown.payeeIdTypeCode);
      setValues(tempData);
      setPayoutHistory(tempData.payoutHistoryList);
    } else if (payeeTypeIdCodeDropdown && payeeTypeIdCodeDropdown.systemFailure) {
      errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [payeeTypeIdCodeDropdown]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      const tempData = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj['Financial#16'] || dropdown.listObj['Financial#3001'] ) {
        setReasonCd((dropdown.listObj['Financial#16']).map(obj=>obj.code));
        setATRDisableCodeData((dropdown.listObj['Financial#3001']).concat(dropdown.listObj['Financial#16']));
      }
      if (dropdown.listObj['General#6002']) {
        if (dropdown.listObj['General#6002'].length === 1) {
          tempData.payeeType = dropdown.listObj['General#6002'][0].code;
        }
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#3006']) {
        if (dropdown.listObj['Financial#3006'].length === 1) {
          tempData.reasonCode = dropdown.listObj['Financial#3006'][0].code;
        }
        setReasonCodeData(dropdown.listObj['Financial#3006']);
      }
      if (!editScreen && dropdown.listObj['Financial#27']) {
        if (dropdown.listObj['Financial#3006'].length === 1) {
          tempData.reasonCode = dropdown.listObj['Financial#27'][0].code;
        }
        setAddPayoutRsCodes(dropdown.listObj['Financial#27']);
      }
      if (dropdown.listObj['Financial#3002']) {
        setDisableReasonCodeData(dropdown.listObj['Financial#3002']);
      }
      if (dropdown.listObj['Claims#1020']) {
        if (dropdown.listObj['Claims#1020'].length === 1) {
          tempData.categoryOfService = dropdown.listObj['Claims#1020'][0].code;
        }
        setCOSData(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Financial#3200']) {
        if (dropdown.listObj['Financial#3200'].length === 1) {
          tempData.collocationCode = dropdown.listObj['Financial#3200'][0].code;
        }
        setFundCodeData(dropdown.listObj['Financial#3200']);
      }
      if (dropdown.listObj['Financial#3020']) {
        if (dropdown.listObj['Financial#3020'].length === 1) {
          tempData.collocationCode = dropdown.listObj['Financial#3020'][0].code;
        }
        setSubFundCodeData(dropdown.listObj['Financial#3020']);
      }
      if (dropdown.listObj['Member#1003']) {
        if (dropdown.listObj['Member#1003'].length === 1) {
          tempData.memberIDType = dropdown.listObj['Member#1003'][0].code;
        }
        setMemberIDTypeData(dropdown.listObj['Member#1003']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      if (dropdown.listObj['Financial#6006']) {
        setSubReasonCodeData(dropdown.listObj['Financial#6006']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setPayoutRCodes(dropdown.listObj['Financial#3006']);
      }
      if (dropdown.listObj['Financial#3007']) {
        setReceiptRCodes(dropdown.listObj['Financial#3007']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setAccountRRCodes(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['Financial#3009']) {
        setDispositionRCodes(dropdown.listObj['Financial#3009']);
      }
      setValues(tempData);
      setPayoutHistory(tempData.payoutHistoryList);
    }
  }, [dropdown]);
  useEffect(() => {
    if (payoutRCodes.length > 0 && receiptRCodes.length > 0 && accountRRCodes.length > 0 && dispositionRCodes.length > 0) {
      const historyTemp = JSON.parse(JSON.stringify(values.payoutHistoryList));
      for (var historyValue in historyTemp) {
        if (historyTemp[historyValue].tcn) {
          if (historyTemp[historyValue].tcn.length === 14) {
            payoutRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.description) {
                historyTemp[historyValue].activityType = 'Payout';
              }
            });
            receiptRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.description) {
                historyTemp[historyValue].activityType = 'Receipt';
              }
            });
            accountRRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.description) {
                historyTemp[historyValue].activityType = 'AccountR';
              }
            });
            dispositionRCodes.map((value) => {
              if (historyTemp[historyValue].reasonCode === value.description) {
                historyTemp[historyValue].activityType = 'AccountR';
              }
            });
          } else {
            historyTemp[historyValue].activityType = 'FTTCN';
          }

          setValues({ ...values, payoutHistoryList: historyTemp });
        }
      }
    }
  }, [payoutRCodes, receiptRCodes, accountRRCodes, dispositionRCodes, payoutHistory]);

  useEffect(() => {
    if (onValidatePayeeResponse && onValidatePayeeResponse !== undefined && onValidatePayeeResponse.respcode !== '02' &&
      onValidatePayeeResponse.enterpriseSearchresultVO && onValidatePayeeResponse.enterpriseSearchresultVO.searchResults && !canCel) {
      const onValidateResult = onValidatePayeeResponse.enterpriseSearchresultVO.searchResults[0];

      if (onValidateResult.multiplePayeeDetails && onValidateResult.multiplePayeeDetails.length > 1) {
        setOpenSysPayeeTable(true);
        setSystemPayeeTableData(onValidateResult.multiplePayeeDetails);
      } else {
        if (onValidateResult) {
          setValues({
            ...values,
            payeePayerName: onValidateResult.payeePayerName,
            payeeId: onValidateResult.payeePayerID,
            systemPayeeId: onValidateResult.sysID
          });
        }
      }
    } else if (onValidatePayeeResponse && onValidatePayeeResponse.respcode && onValidatePayeeResponse.respcode === '02') {
      errorMessagesArray.push(onValidatePayeeResponse.errorMessages);
      seterrorMessages(errorMessagesArray);
      setValues({
        ...values,
        payeePayerName: '',
        payeeId: '',
        systemPayeeId: ''
      });
    } else if (onValidatePayeeResponse && onValidatePayeeResponse.systemFailure) {
      errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [onValidatePayeeResponse]);

  useEffect(() => {
    if (onValidateMemberResponse && onValidateMemberResponse !== undefined && onValidateMemberResponse.respcode !== '02' &&
      onValidateMemberResponse.enterpriseBaseVO) {
      const onValidateResult = onValidateMemberResponse.enterpriseBaseVO;
      if (onValidateResult) {
        setValues({
          ...values,
          memberName: onValidateResult.memberName
        });
      }
    } else if (onValidateMemberResponse && onValidateMemberResponse.respcode && onValidateMemberResponse.respcode === '02') {
      errorMessagesArray.push(onValidateMemberResponse.respdesc);
      seterrorMessages(errorMessagesArray);
      setValues({
        ...values,
        memberName: ''
      });
    } else if (onValidateMemberResponse && onValidateMemberResponse.systemFailure) {
      errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
    } else {
      if (values.memberIDType && values.memberIDType !== DEFAULT_DD_VALUE && !values.memberID) {
        errorMessagesArray.push(ErrorConstants.MEMBERID_REQUIRED);
        setValues({
          ...values,
          memberName: ''
        });
      }
    }
  }, [onValidateMemberResponse]);
  useEffect(() => {
    const payoutUpdate = true;
    if (addSuccessData && addSuccessData.enterpriseSearchresultVO &&
      addSuccessData.enterpriseSearchresultVO.searchResults &&
      addSuccessData.enterpriseSearchresultVO.searchResults.length > 0) {
      addSuccessData = addSuccessData.enterpriseSearchresultVO.searchResults[0];
      const isFromAdd = JSON.parse(sessionStorage.getItem('isFromAdd'));
      props.history.push({
        pathname: '/FinancialPayoutUpdate',
        state: { payoutUpdate, addSuccessData, systemSuccessMessages, values: ValuesFromFinPayout, isFromAdd: isFromAdd }
      });
      seteditScreen(payoutUpdate);
      setsearchData(addSuccessData);
    } else if (addSuccessData && addSuccessData.respcode === '02') {
      const temErrArr = [];
      temErrArr.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(temErrArr);

      setSystemSuccesMessages([]);
    } else {

    };
  }, [addSuccessData]);

  useEffect(() => {
    if (financialPayoutAddResponse && financialPayoutAddResponse.respcode && financialPayoutAddResponse.respcode === '01' && financialPayoutAddResponse.respdesc !== '') {
      const success = ErrorConstants.SYSTEM_SUCCESS_MESSAGE;
      setAllowNavigation(false);
      seterrorMessages([]);
      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      setFocusCheck(false);
      // Redirect to Edit Screen after successful save
      const searchCriteria = {
        fcn: values.fcn,
        searchBy: 'searchByFCN',
        lob: [DEFAULT_LOB_VALUE],
        fcnSearch: 1,
        payeeSearch: 0,
        payeeType: null,
        payeeID: null,
        payeeIDTypeCode: null
      };
      onAddSuccess(searchCriteria);
      sessionStorage.setItem('isFromAdd', JSON.stringify(true));
    }
    if (financialPayoutAddResponse && financialPayoutAddResponse.respcode && (financialPayoutAddResponse.respcode === '02' || financialPayoutAddResponse.respcode === '03')) {
      setSystemSuccesMessages([]);

      if (financialPayoutAddResponse.errorMessages?.length > 0) {
        financialPayoutAddResponse.errorMessages.map((error) => (
          systemErrorMessges.push(error)
        ));
      } else {
        systemErrorMessges.push(financialPayoutAddResponse.errorMessages || financialPayoutAddResponse.respdesc);
      };
      seterrorMessages(systemErrorMessges);
    }
    if (financialPayoutAddResponse && financialPayoutAddResponse.respcode && financialPayoutAddResponse.respcode === '03' && financialPayoutAddResponse.respdesc === '') {
      const error = financialPayoutAddResponse.exc ? financialPayoutAddResponse.exc : ErrorConstants.SYSTEM_ERROR;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(systemErrorMessges);
    }
    if (financialPayoutAddResponse && financialPayoutAddResponse.systemFailure) {
      const error = ErrorConstants.SYSTEM_ERROR;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(systemErrorMessges);
    }
  }, [financialPayoutAddResponse]);
  useEffect(() => {
    if (values.payeeType !== DEFAULT_DD_VALUE && values.payeeType !== '') {
      payeeTypeIDCode(values.payeeType);
    }
  }, [values.payeeType]);
  useEffect(() => {
    if (financialPayoutUpdateResponse && financialPayoutUpdateResponse.respcode && financialPayoutUpdateResponse.respcode === '01' && financialPayoutUpdateResponse.respdesc !== '') {
      const success = ErrorConstants.SYSTEM_SUCCESS_MESSAGE;
      setAllowNavigation(false);
      seterrorMessages([]);
      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      setFocusCheck(false);
      // Redirect to Edit Screen after successful save
      const searchCriteria = {
        fcn: values.fcn,
        searchBy: 'searchByFCN',
        lob: [DEFAULT_LOB_VALUE],
        fcnSearch: 1,
        payeeSearch: 0,
        payeeType: null,
        payeeID: null,
        payeeIDTypeCode: null
      };
      onAddSuccess(searchCriteria);
    }
    if (financialPayoutUpdateResponse && financialPayoutUpdateResponse.respcode && (financialPayoutUpdateResponse.respcode === '02' || financialPayoutUpdateResponse.respcode === '03')) {
      setSystemSuccesMessages([]);

      if (financialPayoutUpdateResponse.errorMessages.length > 0) {
        financialPayoutUpdateResponse.errorMessages.map((error) => (
          systemErrorMessges.push(error)
        ));
      } else {
        systemErrorMessges.push(financialPayoutUpdateResponse.errorMessages);
      };
      seterrorMessages(systemErrorMessges);
    }
    if (financialPayoutUpdateResponse && financialPayoutUpdateResponse.respcode && financialPayoutUpdateResponse.respcode === '03' && financialPayoutUpdateResponse.respdesc === '') {
      const error = financialPayoutUpdateResponse.exc;
      setSystemSuccesMessages([]);
      systemErrorMessges.push(error);
      seterrorMessages(systemErrorMessges);
    }
    if (financialPayoutUpdateResponse && financialPayoutUpdateResponse.systemFailure) {
      setSystemSuccesMessages([]);
      systemErrorMessges.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(systemErrorMessges);
    } else {

    }
  }, [financialPayoutUpdateResponse]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
    if (newValue === 1) {
      setTabTwo(true);
    }
    if (newValue === 2) {
      setTabThree(true);
    }
  };

  const handleSettlementBeginDateChange = date => {
    setFocusCheck(true);
    setSystemSuccesMessages([]);
    setAllowNavigation(true);
    setSettlementBeginDate(date);
  };

  const handleSettlementEndDateChange = date => {
    setFocusCheck(true);
    setSystemSuccesMessages([]);
    setAllowNavigation(true);
    setSettlementEndDate(date);
  };

  const handleCheckDate = date => {
    setFocusCheck(true);
    setSystemSuccesMessages([]);
    setAllowNavigation(true);
    setcheckDate(date);
  };

  const formatDecimalForAmount = () => {
    if (values.amount !== '') {
      setValues({
        ...values,
        amount: numeral(values.amount)
          .format('0,0.00')
      });
    }
  };

  const removeFormatDecimalForAmount = () => {
    if (values.amount !== '') {
      setValues({
        ...values,
        amount: numeral(values.amount)
          .format('0.00')
      });
    }
  };

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };

  const handleChange = name => event => {
    values.isEdited = true;
    setFocusCheck(true);
    setAllowNavigation(true);
    setSystemSuccesMessages([]);
    if (name === 'reasonCode') {
      setShowError({
        showPayeeTypeError: showPayeeTypeError,
        showPayeeIDTypeCodeError: showPayeeIDTypeCodeError,
        showPayeeIDError: showPayeeIDError,
        showCheckNumberError: false,
        showCheckDateError: false,
        showMemberIDError: showMemberIDError,
        showATRError: showATRError,
        showMemberIDTypeCodeError: showMemberIDTypeCodeError,
        showReasonCodeError: showReasonCodeError,
        showAmountError: showAmountError,
        showCompareDatesError: showCompareDatesError,
        showFiscalYearError: showFiscalYearError,
        showCollocationCodeError: showCollocationCodeError,
        showBeginDateError: showBeginDateError,
        showEndDateError: showEndDateError,
        showCheckNumberRangeError: showCheckNumberRangeError,
        showFridayCheck: showFridayCheck
      });
      setShowErrorText({
        showPayeeTypeErrorText: showPayeeTypeErrorText,
        showPayeeIDTypeCodeErrorText: showPayeeIDTypeCodeErrorText,
        showPayeeIDErrorText: showPayeeIDErrorText,
        showATRErrorText,
        showCheckNumberErrorText: '',
        showCheckDateErrorText: '',
        showReasonCodeErrorText,
        showAmountErrorText,
        showFiscalYearErrorText,
        showCollocationCodeErrorText,
        showMemberIDErrorText,
        showMemberIDTypeCodeErrorText,
        showCompareDatesErrorText,
        showBeginDateErrorText,
        showEndDateErrorText,
        showCheckNumberRangeErrorText: showCheckNumberRangeErrorText,
        showFridayCheckText: showFridayCheckText
      });

      if (atrDisableReasonCode.map(x => x.code)?.includes(event?.target?.value)) {
        setDisableATR(true);
      } else { setDisableATR(false); }
    }

    if (name === 'reasonCode' && (!getHIPReasonCodes?.includes(event?.target?.value ? +event.target.value : ''))) {
      setShowError({
        showATRError: showATRError,
        showPayeeTypeError: showPayeeTypeError,
        showPayeeIDTypeCodeError: showPayeeIDTypeCodeError,
        showPayeeIDError: showPayeeIDError,
        showCheckNumberError: showCheckNumberError,
        showCheckDateError: showCheckDateError,
        showMemberIDError: false,
        showMemberIDTypeCodeError: showMemberIDTypeCodeError,
        showReasonCodeError: showReasonCodeError,
        showAmountError: showAmountError,
        showCompareDatesError: showCompareDatesError,
        showFiscalYearError: showFiscalYearError,
        showCollocationCodeError: showCollocationCodeError,
        showBeginDateError: showBeginDateError,
        showEndDateError: showEndDateError
      });
    }
    if (name === 'reasonCode' && !checkReasonCode(event.target.value, reasonCodeData)) {
      setSettlementBeginDate(null);
      setSettlementEndDate(null);
      setcheckDate(null);
      setValues({ ...values, checkNumber: '', [name]: event.target.value });
    }
    if (name === 'reasonCode' && checkReasonCode(event.target.value, disableReasonCodeData)) {
      setValues({ ...values, [name]: event.target.value, approval: 'Yes' });
    } else if (name === 'reasonCode') {
      setValues({ ...values, [name]: event.target.value, approval: 'No', checkNumber: '' });
      setcheckDate(null);
    }
    if (name === 'reasonCode' && (payoutRsnCd?.includes(event.target.value ? +event.target.value : '') || event.target.value === DEFAULT_DD_VALUE)) {
		if (event.target.value !== DEFAULT_DD_VALUE) {
			setBeginEndDateReqSts(true);
		} else {
			setBeginEndDateReqSts(false);
		}
	  setSettlementBeginDate(null);
      setSettlementEndDate(null);
      setcheckDate(null);
      setValues({ ...values, [name]: event.target.value });
      } else if (name === 'reasonCode' && !reasonCd.includes(event.target.value)) {
		setBeginEndDateReqSts(false);
        setSettlementBeginDate(new Date(new Date().toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE })));
        setSettlementEndDate(moment('12/31/9999').format('MM/DD/YYYY'));
		setShowError({
			showBeginDateError: false,
			showEndDateError: false
		  });
		  seterrorMessages([]);
		} else {
      setValues({ ...values, [name]: event.target.value });
    }
    if(name === 'amount') {
      if (event.target.value && event.target.value.includes('.')) {
        if (checkingDecimal(event.target.value)) {
          setValues({ ...values, [name]: event.target.value });
        }
      } else {
        setValues({ ...values, [name]: event.target.value });
      }    
    }

    if (name === 'payeeType' && event.target.value !== DEFAULT_DD_VALUE && event.target.value !== '') {
      setValues({
        ...values,
        payeeType: event.target.value,
        payeeIdTypeCode: DEFAULT_DD_VALUE
      });
      const payeeTypeDesc = fieldDescription(payeeTypeData, event.target.value);
      setPayeeTypeDesc(payeeTypeDesc);
    }
    if (name === 'payeePayerTypeCode' && event.target.value === DEFAULT_DD_VALUE) {
      setPayeeTypeIdCodeData([]);
    } else if (name === 'payeeIdTypeCode' && event.target.value !== '' && event.target.value !== DEFAULT_DD_VALUE) {
      const payeeIdTypeCodeDesc = fieldDescription(payeeTypeIdCodeData, event.target.value);
      setPayeeTypeIdCodeDesc(payeeIdTypeCodeDesc);
    }
  };

  const validatePayoutSystemDetails = () => {
    seterrorMessages([]);
    checkPayeeIDValidations(values, errorMessagesArray, setShowError, setShowErrorText, seterrorMessages);

    setCancel(false);

    if (errorMessagesArray.length <= 0) {
      const validateRequest = validatePayeeRequest(values);
      onValidatePayeeDetails(removeLeadingTrailingSpaces(validateRequest));
    }
  };

  const masterPayoutAdd = () => {
    checkPayoutAddValidations(values, checkDate, disableReasonCodeData, costsettlementBeginDate, costsettlementEndDate, errorMessagesArray, setShowError, setShowErrorText, seterrorMessages, getHIPReasonCodes,payoutRsnCd, null, null);
    
    if (errorMessagesArray.length <= 0) {
      const requestObj = {
        fcn: null,
        lob: [DEFAULT_LOB_VALUE],
        searchBy: 'searchByPayee',
        lineOfBusiness: DEFAULT_LOB_VALUE,
        fcnSearch: 0,
        payeeSearch: 1,
        payeeType:
          values.payeeType !== '' && values.payeeType !== DEFAULT_DD_VALUE
            ? values.payeeType
            : null,
        payeeID: values.entityID !== '' ? values.entityID : null,
        payeeIDTypeCode:
          values.payeeIdTypeCode !== '' &&
            values.payeeIdTypeCode !== DEFAULT_DD_VALUE
            ? values.payeeIdTypeCode
            : null,
        reasonCode:
          values.reasonCode !== '' && values.reasonCode !== DEFAULT_DD_VALUE
            ? values.reasonCode
            : null,
        establishedFromDate:
          values.establishedDateStr !== null
            ? moment(values.establishedDateStr).format('MM/DD/YYYY')
            : null,
        establishedThruDate: null,
        approvalStatus: 'B',
        payeePayerID: values.systemPayeeId,
        sysID: values.systemPayeeId,
        provBillSysID: values.provBillingSysID !== '' ? values.provBillingSysID : null
      };
      checkDuplicate(requestObj);
    }
  };
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState.singleSearchResults);
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    if (redirect === 1 && searchSingleFinancialEntityRes && !searchSingleFinancialEntityRes.systemFailue) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes, values: ValuesFromFinPayout }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  // System Payee redirection ends
  const masterPayoutUpdate = () => {
    setSystemSuccesMessages([]);
    checkPayoutAddValidations(values, checkDate, disableReasonCodeData, costsettlementBeginDate, costsettlementEndDate, errorMessagesArray, setShowError, setShowErrorText, seterrorMessages, getHIPReasonCodes, payoutRsnCd, getCOSReasonCodes, payoutParentreceipt);

    if (errorMessagesArray.length <= 0) {
      setAllowNavigation(false);
      if (allowNavigation === false) {
        NoSaveMessage();
      } else {
        searchData.auditUserID = logInUserID;
        searchData.auditTimeStamp = getUTCTimeStamp();
        searchData.payeePayerName = checkDropdownValue(values.payeePayerName);
        searchData.memberName = values.memberName;
        searchData.establishedBehalfOfMemberID = values.memberID;
        searchData.beginDate = getDateInMMDDYYYYFormatWithApendZero(costsettlementBeginDate);
        searchData.endDate = getDateInMMDDYYYYFormatWithApendZero(costsettlementEndDate);
        searchData.entityType = checkDropdownValue(values.payeeType);
        searchData.entityIDTypeCode = checkDropdownValue(values.payeeIdTypeCode);
        searchData.entityID = values.entityID;
        searchData.lineOfBusiness = DEFAULT_LOB_VALUE;
        searchData.checkNumber = values.checkNumber;
        searchData.checkDate = getDateInMMDDYYYYFormatWithApendZero(checkDate);
        searchData.reasonCode = checkDropdownValue(values.reasonCode);
        searchData.amount = values.amount;
        searchData.settlementThruDate = null;
        searchData.memberIDType = values.memberIDType !== DEFAULT_DD_VALUE ? values.memberIDType : null;
        searchData.account.scoaFiscalYear = values.fiscalYear;
        searchData.account.fundCode = checkDropdownValue(values.collocationCode);
        searchData.account.cos = checkDropdownValue(values.categoryOfService);
        searchData.payeePayerID = values.systemPayeeId;
        searchData.sysID = values.systemPayeeId;
        searchData.atrNumber = values.atrNumber.toUpperCase();
        searchData.provBillSysID = values.provBillingSysID !== '' ? values.provBillingSysID : searchData.provBillingSysID;
        searchData.noteSetVO = notesInput;
        const index = attachmentsTableData.length - 1;
        const attachmentVOList = [];
        if (attachmentsTableData && attachmentsTableData.length > 0) {
          attachmentsTableData.map((value) => {
            if (value.id) {
              const attachmentVO = {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: true,
                sortColumn: null,
                attachmentSK: null,
                attachmentPageID: null,
                finalPath: null,
                detachInd: false,
                historyIndicator: false,
                cascadeKey: null,
                attachmentIndicator: null,
                dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE }))),
                fileUrl: null,
                showDeleteForSearched: false,
                rowIndex: 0,
                addedBy: logInUserID,
                description: value
                  ? value.description
                  : null,
                edmsStoredDate: null,
                anEDMSPageId: null,
                fileName:
                  attachmentsTableData && value
                    ? value.fileName
                    : null,
                file1: null,
                edmsWrkUnitLevel: null,
                edmsDocType: null
              };
              attachmentVOList.push(attachmentVO);
            }
          });
        }
        searchData.attachmentVO = null;
        searchData.attachmentsList = attachmentVOList;

        onFinancialPayoutUpdate(removeLeadingTrailingSpaces(searchData), index);
        getDropdownData(dropdownCriteria);
      }
    }
  };
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    let flag = false;
    if (values.payeeType === '' || values.payeeType === undefined || values.payeeType === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (values.payeeIdTypeCode === '' || values.payeeIdTypeCode === undefined || values.payeeIdTypeCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (values.entityID === '' || values.entityID === undefined) {
      flag = flag || true;
    }
    if (values.memberIDType && values.memberIDType !== DEFAULT_DD_VALUE &&
      (values.memberID === '' || !values.memberID)) {
      flag = flag || true;
    }
    if (values.memberID && (values.memberIDType === '' || values.memberIDType === DEFAULT_DD_VALUE)) {
      flag = flag || true;
    }
    return flag;
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText('');
  };

  const rowClick = () => () => {
    window.open('fcn', '_blank');
  };
  const [canCel, setCancel] = useState(false);

  const handleCloseSysPayeeTable = () => {
    setCancel(true);
    setOpenSysPayeeTable(false);
    setValues({
      ...values,
      entityID: ''
    });
      };
  useEffect(() => {
    if (inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  }, [canCel]);
  const onRadioButtonClick = (row) => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    if (currentSysID) {
            setValues({
        ...values,
        systemPayeeId: currentSysID.sysID,
        payeePayerName: currentSysID.payeeName,
        provBillingSysID: currentSysID.provBillingSysID
      });
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);

  useEffect(() => {
    if (getReasonCds !== undefined && getReasonCds !== null) {
       const reasonCodeValue = getReasonCds?.includes(Number(values.reasonCode));
       setrsnCodeVal(reasonCodeValue);
      }
    }, [getReasonCds, values?.reasonCode]);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog(values.fcn);
    } else {
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  // Payee popup Implementation ends
  const ValuesFromFinPayout = props.history && props.history.location && props.history.location.state && props.history.location.state.values;

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(() => {
      setDialogOpen(false);
      setFocusCheck(false);
      if (window.location.pathname === '/FinancialPayOutAdd') {
        props.history.push({
          pathname: '/FinancialPayOutSearch',
          tableRender: false,
          state: { ValuesFromFinPayout: ValuesFromFinPayout }
        });
      } else if (window.location.pathname === '/FinancialPayoutUpdate') {
        props.history.push({
          pathname: '/FinancialPayOutSearch',
          tableRender: !props?.history?.location?.state?.isFromAdd,
          state: { ValuesFromFinPayout: ValuesFromFinPayout }
        });
      }
    }, 100);
  };

  const majorCancel = () => {
    clearSuccessErrorMsgs();
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      if (window.location.pathname === '/FinancialPayOutAdd') {
        props.history.push({
          pathname: '/FinancialPayOutSearch',
          tableRender: false,
          state: { ValuesFromFinPayout: ValuesFromFinPayout }
        });
      } else if (window.location.pathname === '/FinancialPayoutUpdate') {
        props.history.push({
          pathname: '/FinancialPayOutSearch',
          tableRender: !props?.history?.location?.state?.isFromAdd,
          state: { ValuesFromFinPayout: ValuesFromFinPayout }
        });
      }
    }
  };

  //  getting payee type record
const payeeTypeRecord = dropdownData['G1#G_CMN_ENTY_TY_CD'] && dropdownData['G1#G_CMN_ENTY_TY_CD'].find(value => value.code === values.payeeType);
  // if desc is present setting it
  let payeeDesc;
if (payeeTypeRecord !== undefined) {
    payeeDesc = payeeTypeRecord.description ? payeeTypeRecord.description : null;
  }
  const memberTypeRecord = dropdownData['Member#B_ALT_ID_TY_CD'] && dropdownData['Member#B_ALT_ID_TY_CD'].find(value => value.code === values.memberIDType);
  let memberTypeDesc;
  if (memberTypeRecord !== undefined) {
    memberTypeDesc = memberTypeRecord.description ? memberTypeRecord.description : null;
  }
  const entityIDTypeCode = props.location?.state?.addSuccessData?.entityType;
    const payeeTypeIDDropdown = entityIDTypeCode === 'P' ? 'P1#P_ALT_ID_TY_CD'
    : entityIDTypeCode === 'M' ? 'F1#B_ALT_ID_TY_CD'
      : entityIDTypeCode === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD'
        : entityIDTypeCode === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null;
  let payeetypeCodeDesc;
  const payeeTypeCodeRecord = dropdownData[payeeTypeIDDropdown] && dropdownData[payeeTypeIDDropdown].find(value => value.code === values.payeeIdTypeCode);
  if (payeeTypeCodeRecord !== undefined) {
    payeetypeCodeDesc = payeeTypeCodeRecord.description ? payeeTypeCodeRecord.description : null;
  }
    // checking if current record is present in current dropdown list or not and updating the payee type based on this
  const payeeVal = payeeTypeData.find(item => item.code === values.payeeType);
  const payeeTypeVal = payeeTypeIdCodeData.find(item => item.code === values.payeeIdTypeCode);
  const memberTypeVal = memberIDTypeData.find(item => item.code === values.memberIDType);

  // if  payeeval is undefined means it is voided else it is non-voided()
const isDisabled = values.paymentStatusCode === 'P' || !userInquiryPrivileges || false;

const payoutParentreceipt = (searchData == null && props?.history?.location?.pathname == '/FinancialPayOutAdd')
 ? false : searchData?.isParentReceipt && props?.history?.location?.pathname == '/FinancialPayoutUpdate';

 const payoutAdjust = () => {
    values.lineOfBusiness = DEFAULT_LOB_VALUE;
    axios.post(`${serviceEndPoint.PAYOUT_ADJUST_VIEW_ENDPOINT}`, values)
      .then(response => {
        if (response?.data?.responseCodeVO?.respdesc === 'Success' && response?.data?.payoutVO) {
          response.data.payoutVO.payeePayerTypeCode = values.payeeType;
          response.data.payoutVO.payeePayerIDTypeCode = values.payeeIdTypeCode;
          response.data.payoutVO.sysID = values.systemPayeeId;
          response.data.payoutVO.memberIDType = values.memberIDType !== DEFAULT_DD_VALUE ? values.memberIDType : '';
          response.data.payoutVO.memberID = values.memberID;
          response.data.payoutVO.memberName = values.memberName;
          response.data.payoutVO.fiscalYear = values.fiscalYear;
          response.data.payoutVO.fiscalPendID = values.fiscalPendID;
          response.data.payoutVO.categoryOfService = values.categoryOfService ? values.categoryOfService : DEFAULT_DD_VALUE;
          response.data.payoutVO.collocationCode = values.collocationCode;
          response.data.payoutVO.financialPayoutAdjustmentVO.checkDate = values.checkDate;
          response.data.payoutVO.financialPayoutAdjustmentVO.adjusmentReasonCode = values.reasonCode;
          response.data.payoutVO.financialPayoutAdjustmentVO.adjustmentAmount = values.amount;
          response.data.payoutVO.financialAdjustmentAccountVO.fiscalYear = values.fiscalYear;
          response.data.payoutVO.financialAdjustmentAccountVO.categoryOfServices = response.data.payoutVO.categoryOfService;
          response.data.payoutVO.financialAdjustmentAccountVO.fundCode = response.data.payoutVO.collocationCode;
          response.data.payoutVO.atrNumber = values?.atrNumber?.toUpperCase();
          props.history.push({
            pathname: '/FPAdjust',
            state: {
              claimsData: response?.data?.payoutVO,
              dropdownData: dropdownData,
              payeeTypeIDDropdown: payeeTypeIDDropdown,
              values: searchData || valuesFromFinAdjustPayout,
              payeeTypeIdCodeData: payeeTypeIdCodeData
            }
          });
        }
      })
      .catch(() => {

      });
  };
  const inputRefCursor = useRef(null);

  return (
    <div className={'pos-relative'}>
      <Prompt
        when={allowNavigation}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />

      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? <SuccessMessages successMessages={systemSuccessMessages} /> : null}
        <div
          align="right"
          className="nav-menu"
          style={{ paddingRight: '5px' }}
        >
          {userInquiryPrivileges && values.paymentStatusCode === 'P' && !Object.keys(values?.history || {}).length && !reasonCd?.includes(values?.reasonCode) ? (
            <span>
              <a onClick={payoutAdjust}>&nbsp;&nbsp;Adjust</a>
            </span>
          ) : values?.paymentStatusCode === 'P' ? (
            <span>&nbsp;&nbsp;Adjust</span>
          ) : null}
        </div>
        <div className="tab-header">
          {editScreen ? <h1 className="tab-heading float-left">
            Edit Payout
          </h1> : null
          } {!editScreen ? <h1 className="tab-heading float-left">
            Add Payout
          </h1> : null
          }
          <div className="float-right mt-2">
            <Button className='btn btn-success' onClick={editScreen ? () => masterPayoutUpdate() : () => masterPayoutAdd()} disabled={!userInquiryPrivileges}>
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            {editScreen ? (<Button color="primary"
              className={
                auditButtonToggle
                  ? 'btn btn-primary ml-1'
                  : 'btn btn-text1 btn-transparent ml-1'
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}>
              <i className="fa fa-question-circle" aria-hidden="true"></i>
              Audit Log
            </Button>) : null}
            <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className='tab-body'>
          <form autoComplete="off">
            <div className="form-wrapper">
                <NativeDropDown
                  id="standard-PayeeType"
                  isRequired
                  label="Payee Type"
                  disabled={ payoutParentreceipt || values.paymentStatusCode === 'P' || !userInquiryPrivileges || false }
                  value={payeeVal !== undefined ? values.payeeType : "-1"}
                  onChange={handleChange('payeeType')}
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  errTxt={showPayeeTypeError ? showPayeeTypeErrorText : null}
                  dropdownList={payeeTypeData ? payeeTypeData.map((option, index) => (
                    <option key={index} value={option.code}>
                      {option.description}
                    </option>
                  )) : null}
                />
                <NativeDropDown
                  id="standard-PayeeIDTypeCode"
                  isRequired
                  label="Payee ID Type Code"
                  disabled={ !!((payoutParentreceipt || values.paymentStatusCode === 'P' || !userInquiryPrivileges || values.payeeType === '')) }
                  value={payeeTypeVal !== undefined ? values.payeeIdTypeCode : ''}
                  onChange={handleChange('payeeIdTypeCode')}
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  errTxt={showPayeeIDTypeCodeError ? showPayeeIDTypeCodeErrorText : null}
                  dropdownList={payeeTypeIdCodeData && payeeTypeIdCodeData !== undefined && payeeTypeIdCodeData.length > 0 ? payeeTypeIdCodeData.map((option, index) => (
                    <option key={index} value={option.code}>
                      {option.description}
                    </option>
                  )) : null}
                />
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-PayeeID"
                  required
                  fullWidth
                  label="Payee ID"
                  inputRef={inputRefCursor
                  }
                  onBlur={validatePayoutSystemDetails}
                  inputProps={{ maxlength: 15 }}
                  InputLabelProps={{
                    shrink: true
                  } }
                  disabled={payoutParentreceipt || values.paymentStatusCode === 'P' || !userInquiryPrivileges || false }
                  value={ values.entityID }
                  onChange={ handleChange('entityID') }
                  helperText={ showPayeeIDError ? showPayeeIDErrorText : null }
                  error={ showPayeeIDError ? showPayeeIDErrorText : null }
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-md has-link">
                <TextField
                  id="standard-SystemPayeeID"
                  fullWidth
                  label="System Payee ID"
                  disabled={payoutParentreceipt || values.paymentStatusCode === 'P' || !userInquiryPrivileges || false }
                  InputProps={ {
                    readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : editField,
                    className: !userInquiryPrivileges ? !userInquiryPrivileges : editField ? 'Mui-disabled' : '',
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          values.systemPayeeId !== ''
                            ? <Link to={ redirectSystemID(values) } target='_blank'>
                              { values.systemPayeeId }
                            </Link> : null
                        }

                      </InputAdornment>
                    )
                  }}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-PayeeName"
                  fullWidth
                  label="Payee Name"
                  placeholder=""
                  inputProps={{ maxlength: 60 }}
                  InputLabelProps={{
                    shrink: true
                  } }
                  disabled={payoutParentreceipt || values.paymentStatusCode === 'P' || !userInquiryPrivileges || false }
                  InputProps={ { readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : editField, className: !userInquiryPrivileges ? !userInquiryPrivileges : editField ? 'Mui-disabled' : '' } }
                  value={ values.payeePayerName }
                >
                </TextField>
              </div>

                <NativeDropDown
                  id="standard-MemberIDType"
                  isRequired={values.memberID}
                  label="Member ID Type"
                  disabled={payoutParentreceipt || values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                  value={isDisabled ? memberTypeDesc : (memberTypeVal !== undefined ? values.memberIDType : "-1")}
                  onChange={handleChange('memberIDType')}
                  placeholder=""
                  inputProps={{ maxlength: 3 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  errTxt={showMemberIDTypeCodeError ? showMemberIDTypeCodeErrorText : null}
                  dropdownList={memberIDTypeData ? memberIDTypeData.map((option, index) => (
                    <option key={index} value={option.code}>
                      {option.description}
                    </option>
                  )) : null}
                />
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-MemberID"
                  fullWidth
                  label="Member ID"
                  disabled={payoutParentreceipt || values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                  required={(values.memberIDType && values.memberIDType !== DEFAULT_DD_VALUE) || showMemberIDError}
                  placeholder=""
                  inputProps={{ maxlength: 15 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.memberID}
                  onChange={handleChange('memberID')}
                  helperText={showMemberIDError ? showMemberIDErrorText : null}
                  error={showMemberIDError ? showMemberIDErrorText : null}
                  // onBlur={ validatePayoutMemberDetails }
                  onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-MemberName"
                  fullWidth
                  label="Member Name"
                  disabled={payoutParentreceipt || true }
                  value={ values.memberName }
                  onChange={ handleChange('memberName') }
                  inputProps={ { maxlength: 60 } }
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>

            </div>
          </form>
          <div className='tab-panelbody'>
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
                  {<Tab label="Payout" />}
                  {editScreen ? <Tab label="History" /> : null}
                  <Tab label="Notes" />
                  {<Tab label="Attachments" />}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {value === 0 && (
                  <div className="tab-holder p-0">
                    <PayoutDetailsComponent
                      values={ values }
                      approvalStatus={approvalStatus}
                      payoutParentreceipt ={payoutParentreceipt}
                      reasonCodeData={ reasonCodeData || []}
                      COSData={ COSData }
                      searchData={ searchData }
                      editScreen={ editScreen }
                      fundCodeData={ subReasonCodeData.some(value => value.code === values.reasonCode) ? subFundCodeData : fundCodeData }
                      handleChange={ handleChange }
                      errors={ [showReasonCodeError, showAmountError, showFiscalYearError, showCollocationCodeError, showCheckNumberError, showCheckDateError, showCompareDatesError, showBeginDateError, showEndDateError, showATRError, showCheckNumberRangeError, showFridayCheck] }
                      errorText={ [showReasonCodeErrorText, showAmountErrorText, showFiscalYearErrorText, showCollocationCodeErrorText, showCheckNumberErrorText, showCheckDateErrorText, showCompareDatesErrorText, showBeginDateErrorText, showEndDateErrorText, showATRErrorText, showCheckNumberRangeErrorText, showFridayCheckText] }
                      costsettlementBeginDate={ costsettlementBeginDate }
					  beginEndDateReqSts = {beginEndDateReqSts}
                      handleSettlementBeginDateChange={ handleSettlementBeginDateChange }
                      costsettlementEndDate={ costsettlementEndDate }
                      handleSettlementEndDateChange={ handleSettlementEndDateChange }
                      checkDate={ checkDate }
                      disableReasonCodeData={ disableReasonCodeData }
                      disableATR = {disableATR}
                      handleCheckDate={ handleCheckDate }
                      formatDecimalForAmount={ formatDecimalForAmount }
                      removeFormatDecimalForAmount={ removeFormatDecimalForAmount }
                      checkStatusDesc={ checkStatusDesc }
                      setFocusCheck ={setFocusCheck}
                      getCOSReasonCodes = {getCOSReasonCodes || []}
                      dropdowns={dropdownData}
                      addPayoutRsCodes={addPayoutRsCodes || []}
					  payoutRsnCd={payoutRsnCd}
                    ></PayoutDetailsComponent>
                    {
                      showLogTable
                        ? (<div id='audit-table'>
                          <AuditTableComponent
                            print
                            auditRowData={auditRowData}
                            setAuditRowData={setAuditRowData}
                            setShowLogTable={setShowLogTable}
                          /> </div>) : null
                    }
                  </div>
                )}
              </TabPanel>
              {editScreen
                ? <TabPanel>
                  {value === 1 && (
                    <>
                      <div className="tab-holder p-0">
                        <PayoutHistoryComponent values={values.payoutHistoryList} history={values.history} onRowClick={rowClick} />
                      </div>
                    </>
                  )}
                </TabPanel>
                : null}

              {

                <TabPanel value={value} index={editScreen ? 2 : 1}>

                  {value === 2 || value === 1 ? (
                    <>
                      <div className="tab-holder p-0">
                        <Notes addNotes={addNotes}
                          disableNotes={disableNotes}
                          notesTableData={notesTableData}
                          noteSetListInput={noteSetListInput}
                          setFocusCheck={setFocusCheck}
                          setNoteSetListInput={setNoteSetListInput}
                          usageTypeCodeData={usageTypeCodeData}
                          editNoteData={editNoteData}
                          setEditNoteData={setEditNoteData}
                          setSuccessMessages={clearSuccessErrorMsgs}
                          isNotesTable={true}
                          setErrorMessages={seterrorMessages}
                          userInquiryPrivileges={userInquiryPrivileges}
                        />
                      </div>
                    </>
                  ) : null}
                </TabPanel>
              }
              {<TabPanel value={value} index={editScreen ? 3 : 2}>
                <div className="tab-holder p-0">
                  <Attachments
                    disableAttachments={disableNotes}
                    userInquiryPrivileges={userInquiryPrivileges}
                    attachmentFile={attachmentFile}
                    setAttachmentFile={setAttachmentFile}
                    attachmentsTableData={attachmentsTableData}
                    setAttachmentsTableData={setAttachmentsTableData}
                    parentErrorMesage={seterrorMessages}
                    setAllowNavigation={setAllowNavigation}
                    setSystemSuccesMessages={clearSuccessErrorMsgs}
                    setFocusCheck={setFocusCheck}
                  />
                </div>
              </TabPanel>}
            </div>
          </div>
        </div>
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                Unsaved changes will be lost. <br />
                Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
              STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
              CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
            <SystemPayeeTable

        payeeType={payeeTypeDesc || payeeDesc}
        payeeIdTypeCode={values.entityIDTypeCodeValue ? values.entityIDTypeCodeValue : payeeTypeIdCodeDesc || payeetypeCodeDesc}
        payeeId={values.entityID}
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={'sysID'}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        currentSysID={currentSysID}
        onContinue={onContinue}
      />
    </div>
  );
}
