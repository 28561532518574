/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TableComponent from '../../../../SharedModules/Table/Table';
import TextField from '@material-ui/core/TextField';
import '../../AccountReceivables.scss';
import { withRouter } from 'react-router';

/* ClaimsReceivableHistory()
* This component is used to display Claims Receivable history (inside 4th tab in claims receivables screen)
* Inside this component we are getting values using props sent by ClaimsReceivable.js
*/
function ClaimsReceivableHistory (props) {
  let tableDetails = [];
  if (props.tabData !== undefined) {
    tableDetails = props.tabData.historyList;
  }

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const reasonCode =
            props.addDropdowns['F1#F_RSN_CD'] &&
            props.addDropdowns['F1#F_RSN_CD'].filter(
              value => value.code === each.reasonCode
            );
          each.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
              ? reasonCode[0].description
              : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const editRow = row => event => { };

  const headCells = [
    { id: 'receivableFCN',
    disablePadding: false,
    label: 'FCN/TCN',
    width: 150,
    fontSize: 12, 
    isRedirectable: true
  },
    {
      id: 'reasonCodeDesc',
      disablePadding: false,
      label: 'Reason Code',
      enableHyperLink: false,
      width: 150,
      fontSize: 12,
      isTooltip: true,
      isTitle: 'reasonCodeLongDesc'
    },
    { id: 'appliedDateStr', disablePadding: false, label: 'Applied Date', enableHyperLink: false, isDate: true, width: 150, fontSize: 12 },
    { id: 'appliedAmount', disablePadding: false, label: 'Applied Amount', enableHyperLink: false, isBalance: true, width: 150, fontSize: 12 }
  ];

  return (
    <div>
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="originalARAmount"
            label="Original A/R Amount"
            value={props.values.originalAmountHistory}
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          />
        </div>
        <div className="mui-custom-form input-md margin-left-128">
          <TextField
            id="appliedAmount"
            label="Applied Amount"
            value={props.values.appliedAmount}
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          />
        </div>
        <div className="mui-custom-form input-md margin-left-128">
          <TextField
            id="historybalanceAmount"
            label="Balance Amount"
            value={props.values.balanceAmountHistory}
            InputLabelProps={{
              shrink: true
            }}
            disabled={true}
          />
        </div>
      </div>
      <div className="tab-holder">
        <TableComponent 
          headCells={headCells}
          tableData={getTableData(tableDetails)}
          onTableRowClick={editRow}
          defaultSortColumn={headCells[0].id}
          isSearch={true}
          />
      </div>
    </div>
  );
}

export default withRouter((ClaimsReceivableHistory));
