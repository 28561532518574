/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const WRITEOFFSET_HEADER = 'Account Receivable Write-Off';
export const WITHHOLDSET_HEADER = 'Withholding/Lien/Levy';
export const WRITEOFFESTABLISHMENT_HEADER = 'Account Receivable Write-Off Re-Establishment';
export const DATA_ELEMENT_NAME = 'Date Element Name';
export const FIELD_VALUE = 'Field Value';
export const REASON_CODE_REQUIRED = 'Reason Code is required.';
export const PAYEE_TYPE_REQUIRED = 'Payee Type is required.';
export const PAYEE_TYPE_ID_CODE_REQUIRED = 'Payee Type Id Code is required.';
export const PAYEE_ID_REQUIRED = 'Payee ID is required.';
export const PAYEE_INVALID = 'Please enter the Payee ID in correct format.';
export const TRANSACTION_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const NETWORK_ERROR = 'Network Error';
export const ESTABLISH_DATE_INVALID = 'Established Date is invalid.';
export const TRANSACTION_NUMBER_FORMAT = 'should not contain spaces or special characters.';
