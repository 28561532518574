import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../../../SharedModules/Table/Table";
import { withRouter } from "react-router";
import { mapSetActionView } from "../../Store/Actions/MapSet/mapSetActions";

const headCellsMultiRecord = [
  {
    id: "mapSetID",
    numeric: false,
    disablePadding: true,
    label: "Map ID",
    width: 200,
    isVarChar: true,
    enableHyperLink: true
  },
  {
    id: "mapTypeDesc",
    numeric: false,
    disablePadding: false,
    label: "Map Type",
    width: 200
  },
  {
    id: "mapDesc",
    numeric: false,
    disablePadding: false,
    label: "Map Description",
    width: 300,
    isVarChar: true
  }
];
const headCellsSingleRecord = [
  {
    id: "mapID",
    numeric: false,
    disablePadding: true,
    label: "Map ID",
    width: 200,
    isVarChar: true,
    enableHyperLink: true
  },
  {
    id: "mapType",
    numeric: false,
    disablePadding: false,
    label: "Map Type",
    width: 200
  },
  {
    id: "mapDescription",
    numeric: false,
    disablePadding: false,
    label: "Map Description",
    width: 300,
    isVarChar: true
  }
];

function MapSetSearchTableComponent (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const [showTable, setShowTable] = React.useState(false);

  useEffect(() => {
    if (props.tableData && props.tableData.length > 0) {
      setShowTable(true);
    }
  }, [props.tableData]);

  const [redirect, setRedirect] = React.useState(0);

  const onSearchView = searchviewvalues =>
    dispatch(mapSetActionView(searchviewvalues));
  const payloadViewData = useSelector(
    state => state.appConfigState.mapSetState.mapSetView
  );
  const payloadData = payloadViewData?.length === 1 ? payloadViewData : props.tableData?.length === 1 ? props.tableData : null;

  if (redirect === 1) {
    if (payloadData) {
      payloadData.map(var1 => {
        var1.mapDefDetail.map(var2 => {
          props.functionalAreaReference.map(fDesc => {
            if (var2.functionalArea === fDesc.code) {
              var2.functionalAreaDesc = fDesc.description;
            }
          });
        });
      });

      props.history.push({
        pathname: "/MapSetEdit",
        state: { payloadData, values: props.values }
      });
    }
  }

  const formatSearchCriteria = (_row) => {
    if (props.tableData?.length === 1) {
      return {
      lineOfBusiness: _row.lineOfBusiness !== DEFAULT_DD_VALUE ? _row.lineOfBusiness.split("-", 1) : null,
      mapType: _row.mapType !== DEFAULT_DD_VALUE ? _row.mapType.split("-", 1).toString() : null,
      mapSetID: _row.mapID !== "" ? _row.mapID : null,
      mapDescription: _row.mapDescription && _row.mapDescription !== "" ? _row.mapDescription : null
      };
    } else if (props.tableData?.length > 1) {
      return {
        lineOfBusiness:
        _row.lineOfBusiness !== DEFAULT_DD_VALUE ? [_row.lobCode] : null,
      mapType: _row.mapTypeCode !== DEFAULT_DD_VALUE ? _row.mapTypeCode : null,
      mapSetID: _row.mapSetID !== "" ? _row.mapSetID : null,
      mapDescription: _row.mapDesc && _row.mapDesc !== "" ? _row.mapDesc : null,
      fieldValue:
        _row.fieldValue && _row.fieldValue !== "" ? _row.fieldValue : null,
      mapSetIDStartsOrContains:
        _row.mapSetIDStartsOrContains === "StartsWith"
          ? 0
          : _row.mapSetIDStartsOrContains === "Contains"
          ? 1
          : null,
      mapDescStartsOrContains:
        _row.mapDescStartsOrContains === "StartsWith"
          ? 0
          : _row.mapDescStartsOrContains === "Contains"
          ? 1
          : null,
      dataElementName:
        _row.dataElementName && _row.dataElementName !== DEFAULT_DD_VALUE
          ? _row.dataElementName
          : null
      };
    }
  };

  const editRow = values => event => {
    const searchCriteria = formatSearchCriteria(values);
    onSearchView(searchCriteria);
    setRedirect(1);
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const lobCode =
          props.dropdowns &&
          props.dropdowns['Reference#R_LOB_CD'] &&
          props.dropdowns['Reference#R_LOB_CD'].filter(
            value => value.code === (each.lobCode ? each.lobCode : each.lineOfBusiness));
          each.lineOfBusiness =
             lobCode && lobCode.length > 0
              ? lobCode[0].description
              : each.lobCode ? each.lobCode : '';

          each.lobDesc =
             lobCode && lobCode.length > 0
              ? lobCode[0].description
              : each.lobCode ? each.lobCode : '';

          const mapCode =
              props.dropdowns &&
              props.dropdowns['G1#R_MAP_TY_CD'] &&
              props.dropdowns['G1#R_MAP_TY_CD'].filter(
                value => value.code === (each.mapTypeCode ? each.mapTypeCode : each.mapType)
              );
          each.mapTypeDesc =
              mapCode && mapCode.length > 0
                  ? mapCode[0].description
                  : each.mapTypeCode ? each.mapTypeCode : '';
          each.mapType =
              mapCode && mapCode.length > 0
                  ? mapCode[0].description
                  : each.mapTypeCode ? each.mapTypeCode : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  const headCells = props.tableData && props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;
  const tableComp = (
    <TableComponent
      print={props.print}
      pathTo="/MapSetEdit?data="
      formatSearchCriteria={formatSearchCriteria}
      isSearch={true}
      headCells={headCells}
      tableData={getTableData(props.tableData ? props.tableData : [])}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
    />
  );
  return showTable ? tableComp : null;
}

export default withRouter(MapSetSearchTableComponent);
