/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getLongDescription } from "../../../../SharedModules/CommonUtilities/commonUtilities";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { NO_CHECK_TO_USE } from '../FinancialReceiptConstants';

export default function EditDisposition(props) {
  const {
    editDispositionFormat,
    userInquiryPrivileges,
    editDispositionValues,
    dispositionReasonCode,
    establishedDate,
    handleEstablishedDate,
    handleChangeEditDisposition,
    updateDisposition,
    removeEditDispositionFormat,
    resetDisposition,
    amountError,
    amountError2,
    dispositionError,
    openDisposition,
    seterrorMessages
  } = props;

  React.useEffect(() => {
    if (openDisposition) {
      if (!dispositionReasonCode?.length) {
        seterrorMessages([NO_CHECK_TO_USE]);
      }
    }
  }, [openDisposition]);

  return (
    <div className="form-border my-3">
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-20">
            <TextField
              id="fcn"
              label="Receivable FCN"
              inputProps={{ maxLength: 14 }}
              value={editDispositionValues.receivableFcn}
              onChange={handleChangeEditDisposition("receivableFcn")}
              InputProps={{
                readOnly: true,
                className: "Mui-disabled"
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form override-width-20 with-date">
              <KeyboardDatePicker
                disabled
                InputProps={{
                  readOnly: true,
                  className: "Mui-disabled"
                }}
                maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                id="ffp-calculated-date"
                label="Established Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={establishedDate}
                onChange={handleEstablishedDate}
                InputLabelProps={{
                  shrink: true
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </div>
          </MuiPickersUtilsProvider>

            <NativeDropDown
              id="payeeIDTypeCode"
              className="override-width-20"
              isRequired
              label="Disposition Reason Code"
              inputProps={{ maxLength: 3 }}
              value={editDispositionValues.recvRsnCd}
              onChange={handleChangeEditDisposition("recvRsnCd")}
              disabled={!userInquiryPrivileges}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={dispositionError || (!dispositionReasonCode?.length && NO_CHECK_TO_USE)}
              dropdownList={dispositionReasonCode
                ? dispositionReasonCode.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.description}
                    </option>
                  ))
                : null}
            />
          <div className="mui-custom-form override-width-20">
            <TextField
              id="fcn"
              label="A/R original Amount"
              inputProps={{ maxLength: 14 }}
              value={editDispositionValues.arOriginalAmount}
              onChange={handleChangeEditDisposition("arOriginalAmount")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: true,
                className: "Mui-disabled"
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-20">
            <TextField
              id="fcn"
              label="A/R Balance Amount"
              inputProps={{ maxLength: 14 }}
              value={editDispositionValues.arBalanceAmount}
              onChange={handleChangeEditDisposition("arBalanceAmount")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: true,
                className: "Mui-disabled"
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>
          <div className="mui-custom-form override-width-20">
            <TextField
              id="fcn"
              required
              label="Amount To Apply"
              inputProps={{ maxLength: 14 }}
              value={editDispositionValues.amountToApply}
              onBlur={editDispositionFormat}
              onFocus={removeEditDispositionFormat}
              onChange={handleChangeEditDisposition("amountToApply")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? "Mui-disabled" : ""
              }}
              InputLabelProps={{
                shrink: true
              }}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                  ""
                );
              }}
              helperText={
                amountError !== ""
                  ? amountError
                  : amountError2 !== ""
                  ? amountError2
                  : null
              }
              error={
                amountError !== ""
                  ? amountError
                  : amountError2 !== ""
                  ? amountError2
                  : null
              }
            ></TextField>
          </div>
        </div>
      </form>
      <div className="clearfix"></div>
    </div>
  );
}
